import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import wall1 from "../assets/images/waterproofingservices/wall1.jpg"
import wall2 from "../assets/images/waterproofingservices/wall2.jpg"
import wall3 from "../assets/images/waterproofingservices/wall3.jpg"

export const WallWaterproofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={wall1} alt="Wall Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={wall2} alt="Wall Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={wall3} alt="Wall Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Wall Waterproofing</h3>
            <div className="text">
                <p>
                    Homex Builders Corp provides specialized wall waterproofing services designed to protect your home’s walls from water damage, mold growth, and structural issues. Waterproofing walls is essential to maintain the structural integrity of your property, prevent moisture penetration, and ensure a healthy living environment. Here’s an overview of the wall waterproofing services offered by Homex Builders Corp and why you should choose them for your waterproofing needs.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Wall Waterproofing Services?</h3>
                <p>
                    Homex Builders Corp offers a range of wall waterproofing solutions tailored to different types of walls and construction materials. Their services are designed to prevent water infiltration, manage moisture, and protect your home from water-related damage.
                </p>
                <ul>
                    <li><strong>Waterproof Coatings and Sealants: </strong>Homex Builders applies high-quality waterproof coatings and sealants to exterior walls to create a protective barrier against water penetration. These coatings are designed to withstand harsh weather conditions and prevent water from seeping into the walls.</li>
                    <li><strong>Waterproof Membranes:</strong> For additional protection, Homex Builders installs waterproof membranes on exterior walls. These membranes act as a physical barrier that prevents water from coming into contact with the wall surface, effectively stopping water infiltration.</li>
                    <li><strong>Masonry Waterproofing: </strong>For brick and masonry walls, Homex Builders uses specialized waterproofing products that penetrate deep into the porous material. These products create a water-resistant barrier that protects the masonry from moisture and prevents efflorescence and mold growth.</li>
                    <li><strong>Crack and Joint Sealing: </strong>Homex Builders also focuses on sealing cracks and joints in exterior walls, which are common entry points for water. They use high-quality sealants and epoxy injections to fill and seal cracks, preventing water from penetrating through the wall.</li>
                    <li><strong>Interior Sealants: </strong>Homex Builders applies interior waterproofing sealants to the walls to create a moisture-resistant barrier. These sealants are particularly effective in basements, bathrooms, and other areas where moisture levels are high.</li>
                    <li><strong>Vapor Barriers: </strong>Vapor barriers are installed on interior walls to prevent moisture from passing through and causing damage to drywall, insulation, and other interior components. These barriers are especially useful in areas with high humidity or where groundwater pressure is a concern.</li>
                    <li><strong>Waterproof Paints and Coatings: </strong>For additional protection, Homex Builders applies waterproof paints and coatings to interior walls. These products provide a durable, moisture-resistant finish that prevents water penetration and resists mold and mildew growth.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Wall Waterproofing?</h4>
                    <p>
                        Choosing Homex Builders Corp for wall waterproofing services comes with several advantages that make them a trusted partner for homeowners looking to protect their property from water damage and structural issues. Here’s why you should consider their services:
                    </p>
                    <ul>
                        <li><strong>Skilled Professionals: </strong>Homex Builders has a team of highly skilled and experienced professionals who specialize in wall waterproofing. Their expertise ensures that every project is handled with precision and care, using the most effective techniques and materials.</li>
                        <li><strong>In-Depth Knowledge: </strong>With years of experience in the industry, Homex Builders understands the unique challenges associated with waterproofing different types of walls and construction materials. They have the knowledge to recommend the most suitable waterproofing solutions based on the specific needs of your home.</li>
                        <li><strong>Customized Approach: </strong>Homex Builders provides customized waterproofing solutions tailored to the specific conditions of your walls. They consider factors such as the type of wall (e.g., brick, concrete, wood), local climate, and potential water sources to develop a comprehensive waterproofing plan.</li>
                        <li><strong>Range of Services: </strong>By offering both interior and exterior wall waterproofing solutions, Homex Builders provides a full range of services that can address all potential sources of water intrusion. This holistic approach ensures complete protection for your home’s walls.</li>
                        <li><strong>Durable Materials: </strong>Homex Builders uses only high-quality, durable materials that are designed to withstand moisture, temperature changes, and environmental stressors. This commitment to quality ensures that your waterproofing solution is both effective and long-lasting.</li>
                        <li><strong>Latest Technology: </strong>Homex Builders stays updated with the latest advancements in waterproofing technology. They use state-of-the-art tools and products, such as liquid-applied membranes and elastomeric coatings, that provide superior protection compared to traditional waterproofing methods.</li>
                        <li><strong>Transparent Communication:</strong> Homex Builders values transparent communication with their clients. They take the time to explain their waterproofing process, the materials they use, and the expected outcomes, ensuring that you are fully informed and confident in their services.</li>
                        <li><strong>Warranty and Support: </strong>Homex Builders offers warranties on their waterproofing services, giving you peace of mind that your investment is protected. They also provide ongoing support and maintenance services to ensure that your walls remain protected over time.</li>
                        <li><strong>Satisfied Customers: </strong>Homex Builders Corp has a strong reputation for delivering high-quality wall waterproofing services, as evidenced by numerous positive reviews and testimonials from satisfied customers. Their proven track record speaks to their reliability and commitment to excellence.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    Homex Builders Corp takes several important precautions to ensure the safety, effectiveness, and durability of their wall waterproofing services. These precautions are designed to prevent water infiltration, minimize damage, and maintain the structural integrity of your walls.
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Thorough Site Assessment: </strong>Before starting any waterproofing project, Homex Builders conducts a comprehensive assessment of the property. This involves inspecting both interior and exterior walls to identify potential water entry points, existing damage, and areas that are most vulnerable to water infiltration.</li>
                                        <li><strong>Proper Surface Preparation: </strong>Preparing the wall surfaces correctly is crucial for effective waterproofing. Homex Builders ensures that all walls are clean, dry, and free of dust, dirt, and debris. For exterior walls, this may include pressure washing and removing old paint or coatings. For interior walls, they may use anti-mold treatments to eliminate any existing mold or mildew.</li>
                                        <li><strong>High-Quality Waterproofing Materials: </strong>Homex Builders uses only high-quality waterproofing materials that are specifically designed to withstand moisture, UV exposure, temperature fluctuations, and other environmental stressors. This includes waterproof coatings, membranes, sealants, and barriers that provide long-lasting protection.</li>
                                        <li><strong>Crack and Joint Sealing: </strong>Cracks and joints are common entry points for water. Homex Builders meticulously seals all cracks, gaps, and joints in the walls using specialized sealants and epoxy injections. This prevents water from penetrating through these vulnerable points.</li>
                                        <li><strong>Weather Considerations: </strong>Homex Builders carefully schedules waterproofing work to avoid adverse weather conditions. They choose days with dry weather to ensure that waterproofing materials can cure properly and adhere to the wall surfaces effectively.</li>
                                        <li><strong>Safety Protocols: </strong>Safety is a top priority for Homex Builders. They adhere to strict safety protocols to protect both their workers and your property during the waterproofing process. This includes using appropriate personal protective equipment (PPE), securing the work area, and ensuring proper ventilation when applying waterproofing products.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Homex Builders Corp leverages its deep knowledge and extensive experience in wall waterproofing to provide high-quality, effective solutions. Their intelligence in this field comes from years of experience and continuous learning about the latest waterproofing technologies and techniques.
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Advanced Waterproofing Techniques: </strong>Homex Builders employs a variety of advanced waterproofing techniques tailored to the specific needs of different wall types. This includes using exterior waterproofing membranes, liquid-applied coatings, elastomeric paints, and vapor barriers to prevent water infiltration.</li>
                                        <li><strong>Customized Solutions: </strong>Recognizing that every wall is unique, Homex Builders provides customized waterproofing solutions based on the specific conditions and requirements of each home. They consider factors such as wall material (e.g., brick, concrete, wood), local climate, exposure to water sources, and existing structural issues to develop a tailored waterproofing plan.</li>
                                        <li><strong>Innovative Technologies: </strong>Homex Builders stays up-to-date with the latest advancements in waterproofing technology. They use state-of-the-art products and tools, such as flexible waterproof membranes, breathable coatings, and high-performance sealants, which provide superior protection and durability compared to traditional methods.</li>
                                        <li><strong>Knowledgeable Team: </strong>The team at Homex Builders consists of highly trained professionals who understand the complexities of wall waterproofing. Their expertise allows them to quickly diagnose water intrusion problems, recommend effective solutions, and execute the work with precision.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    Homex Builders Corp specializes in wall waterproofing, making them experts in protecting your home from water damage and preserving the integrity of your walls. Their specialization is evident in several key areas:
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Comprehensive Waterproofing Systems: </strong>Homex Builders offers complete waterproofing systems that combine multiple methods to provide maximum protection against water intrusion. This includes both interior and exterior solutions, as well as integrated drainage systems to manage and redirect water away from the walls.</li>
                                        <li><strong>Masonry and Concrete Wall Waterproofing: </strong>Homex Builders has specialized knowledge in waterproofing masonry and concrete walls, which require specific products and techniques to effectively prevent water penetration. They use breathable sealers and hydrophobic coatings that protect the walls without trapping moisture inside.</li>
                                        <li><strong>Interior Wall Protection: </strong>For interior walls, especially in basements and other moisture-prone areas, Homex Builders provides specialized solutions such as vapor barriers, waterproof paints, and anti-mold treatments. These solutions help maintain a dry, healthy indoor environment.</li>
                                        <li><strong>Long-Term Maintenance and Prevention: </strong>Homex Builders emphasizes the importance of long-term maintenance and prevention. They offer ongoing support and maintenance services to ensure that waterproofing solutions remain effective over time. This includes regular inspections, reapplications, and repairs as needed to maintain optimal wall protection.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Homex Builders Corp’s wall waterproofing services are characterized by their expertise, comprehensive solutions, use of high-quality materials, and commitment to customer satisfaction. Their focus on both interior and exterior waterproofing ensures that every potential source of water intrusion is addressed, providing maximum protection for your home’s walls.
                </p>
                <p>
                    By choosing Homex Builders Corp for your wall waterproofing needs, you are opting for a company that prioritizes quality, safety, and long-term results. Their customized solutions and advanced techniques make them a trusted choice for maintaining the integrity of your home.
                </p>
                <p>
                    If you’re looking to protect your walls from water damage and maintain a healthy living environment, consider reaching out to Homex Builders Corp. Their expertise and dedication to excellence will help ensure your home remains dry, safe, and secure.
                </p>
            </div>
        </>
    );
}
