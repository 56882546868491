import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import services_bg from "../assets/images/services_bg.jpg";

export const ServicesList = (props) => {
    const { serviceType, title } = props;
    return (
        <section className="services-section-two" style={{ backgroundImage: `url(${services_bg})` }}>
            <div className="auto-container">
                <div className="sec-title centered">
                    <div className="title">Services We Offer &amp; Solutions</div>
                    <h2><span className="theme_color">Our</span> Main {title} Services</h2>
                </div>
                <div className={`row clearfix ${serviceType == "roofing-services" ? "" : "d-none"}`}>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Commercial Roofing">Commercial Roofing</a></h3>
                            </div>
                            <div className="text">
                                With years of experience and a commitment to excellence, we specialize in providing top-quality commercial roofing solutions.
                            </div>
                            <a href="/service-detail/Commercial Roofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Residential Roofing">Residential Roofing</a></h3>
                            </div>
                            <div className="text">
                                With a focus on quality craftsmanship and exceptional service, we specialize in providing top-notch roofing services.
                            </div>
                            <a href="/service-detail/Residential Roofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Roof Maintenance">Roof Maintenance</a></h3>
                            </div>
                            <div className="text">
                                Our comprehensive roof maintenance services are designed to keep your roof in optimal condition year-round.
                            </div>
                            <a href="/service-detail/Roof Maintenance" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Roof Inspection">Roof Inspection</a></h3>
                            </div>
                            <div className="text">
                                We inspect your roof, including shingles, flashing, gutters, and vents, to ensure that everything is functioning properly.
                            </div>
                            <a href="/service-detail/Roof Inspection" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Roof Repairs">Roof Repairs</a></h3>
                            </div>
                            <div className="text">
                                We examine and repair your roof, including shingles, flashing, gutters, and vents, to ensure that nothing is overlooked.
                            </div>
                            <a href="/service-detail/Roof Repairs" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Roof Repalcement">Roof Repalcement</a></h3>
                            </div>
                            <div className="text">
                                With our expert roof replacement services, we'll ensure that your property is well-protected for years to come.
                            </div>
                            <a href="/service-detail/Roof Repalcement" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Roof Installation">Roof Installation</a></h3>
                            </div>
                            <div className="text">
                                We understand that a new roof is a significant investment in your property's safety, durability, and aesthetics. That's why we're committed to providing expert roof installation services that exceed your expectations.
                            </div>
                            <a href="/service-detail/Roof Installation" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                <div className={`row clearfix ${serviceType == "waterproofing-services" ? "" : "d-none"}`}>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Balcony Waterproofing">Balcony Waterproofing</a></h3>
                            </div>
                            <div className="text">
                                Extend the life of your outdoor balconies with our balconies waterproofing services. We'll apply waterproofing membranes and sealants to create a watertight barrier, preventing water damage and preserving the integrity of your outdoor living spaces.
                            </div>
                            <a href="/service-detail/Balcony Waterproofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Deck Waterproofing">Deck Waterproofing</a></h3>
                            </div>
                            <div className="text">
                                Extend the life of your outdoor decks with our deck waterproofing services. We'll apply waterproofing membranes and sealants to create a watertight barrier, preventing water damage and preserving the integrity of your outdoor living spaces.
                            </div>
                            <a href="/service-detail/Deck Waterproofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Basement Waterproofing">Basement Waterproofing</a></h3>
                            </div>
                            <div className="text">
                                Prevent water seepage and moisture buildup in your basement with our expert waterproofing solutions. We'll assess your basement's unique needs and provide tailored waterproofing treatments to keep it dry and secure.
                            </div>
                            <a href="/service-detail/Basement Waterproofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Foundation Waterproofing">Foundation Waterproofing</a></h3>
                            </div>
                            <div className="text">
                                Safeguard your property's foundation from water intrusion with our foundation waterproofing treatments. We'll address any cracks or vulnerabilities in the foundation and apply waterproofing coatings to prevent moisture penetration.
                            </div>
                            <a href="/service-detail/Foundation Waterproofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Wall Waterproofing">Wall Waterproofing</a></h3>
                            </div>
                            <div className="text">
                                Keep water out of your building's walls with our wall waterproofing solutions. We'll apply durable waterproofing membranes and coatings to protect your property from moisture infiltration and structural damage.
                            </div>
                            <a href="/service-detail/Wall Waterproofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Exterior Waterproofing">Exterior Waterproofing</a></h3>
                            </div>
                            <div className="text">
                                Keep water out of your building's exterior with our exterior waterproofing solutions. We'll apply durable waterproofing membranes and coatings to protect your property from moisture infiltration and structural damage.
                            </div>
                            <a href="/service-detail/Exterior Waterproofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Masonry Waterproofing">Masonry Waterproofing</a></h3>
                            </div>
                            <div className="text">
                                we recognize the critical role of waterproofing in preserving the integrity and longevity of masonry structures. Our specialized masonry waterproofing services are tailored to safeguard your property against water damage, ensuring lasting durability and protection.
                            </div>
                            <a href="/service-detail/Masonry Waterproofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Bituminous Waterproofing">Bituminous Waterproofing</a></h3>
                            </div>
                            <div className="text">
                                Bituminous waterproofing is a proven method for safeguarding structures against water infiltration, offering durability and reliability. At Kingstone, we specialize in providing expert bituminous waterproofing services to ensure the long-term protection of your property.
                            </div>
                            <a href="/service-detail/Bituminous Waterproofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Elastomeric Waterproofing">Elastomeric Waterproofing</a></h3>
                            </div>
                            <div className="text">
                                Elastomeric waterproofing is a versatile solution known for its flexibility and durability in protecting structures from water damage. At Kingstone, we specialize in providing expert elastomeric waterproofing services to ensure the long-term integrity of your property.
                            </div>
                            <a href="/service-detail/Elastomeric Waterproofing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Window Caulking">Window Caulking</a></h3>
                            </div>
                            <div className="text">
                                Window caulking is a vital component of maintaining the integrity and energy efficiency of your property. We specialize in providing expert window caulking services to ensure a tight seal around your windows, protecting your property from water infiltration, air leaks, and energy loss.
                            </div>
                            <a href="/service-detail/Window Caulking" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                <div className={`row clearfix ${serviceType == "remodeling-services" ? "" : "d-none"}`}>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Bathroom Remodeling">Bathroom Remodeling</a></h3>
                            </div>
                            <div className="text">
                                At Homex Builders Corp, we offer professional bathroom remodeling services designed to transform your bathroom into a relaxing, functional, and aesthetically pleasing space. Whether you're looking to create a spa-like oasis, update outdated fixtures, or improve the functionality of your bathroom, our experienced team is here to help. From design to installation, we handle every aspect of your bathroom remodel with precision and care.
                            </div>
                            <a href="/service-detail/Bathroom Remodeling" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Kitchen Remodeling">Kitchen Remodeling</a></h3>
                            </div>
                            <div className="text">
                                At Homex Builders Corp, we specialize in providing professional kitchen remodeling services designed to transform your kitchen into a functional, beautiful, and efficient space. Our team of experienced designers and skilled craftsmen work closely with you to understand your needs and bring your vision to life. Whether you're looking to update your kitchen's look, improve its layout, or increase its functionality, we have the expertise to handle every aspect of your kitchen remodeling project.
                            </div>
                            <a href="/service-detail/Kitchen     Remodeling" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Basement Renovation">Basement Renovation</a></h3>
                            </div>
                            <div className="text">
                                At Homex Builders Corp, we offer professional basement renovation services designed to transform your unused or underutilized basement into a functional and inviting space that adds value and enjoyment to your home. Whether you're looking to create a cozy family room, a home office, an entertainment area, or an additional bedroom, our team of experienced designers and craftsmen is here to bring your vision to life. We handle every aspect of your basement renovation, from design and planning to construction and finishing touches.
                            </div>
                            <a href="/service-detail/Basement Renovation" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                <div className={`row clearfix ${serviceType == "sidewalk-services" ? "" : "d-none"}`}>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/NYC Sidewalk Repair">NYC Sidewalk Repair</a></h3>
                            </div>
                            <div className="text">
                                Cracks, potholes, and uneven surfaces not only detract from the appearance of your sidewalk but also pose safety hazards. Our skilled technicians can assess the condition of your sidewalk and perform timely repairs to restore its integrity and functionality.
                            </div>
                            <a href="/service-detail/NYC Sidewalk Repair" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Sidewalk Replacement">Sidewalk Replacement</a></h3>
                            </div>
                            <div className="text">
                                
                            </div>
                            <a href="/service-detail/Sidewalk Replacement" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Sidwalk Installation">Sidwalk Installation</a></h3>
                            </div>
                            <div className="text">
                                We develop tailored replacement solutions to address the unique requirements of your sidewalks. Whether it's a partial replacement, full replacement, or upgrades to meet current standards, we have the expertise and resources to handle the job.
                            </div>
                            <a href="/service-detail/Sidwalk Installation" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/"></a></h3>
                            </div>
                            <div className="text">
                                Whether you're constructing a new sidewalk or replacing an existing one, our team is equipped to handle the job from start to finish. We'll work closely with you to design a sidewalk that meets your specifications and complies with local regulations.
                            </div>
                            <a href="/service-detail/" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Sidewalk Curb Repair">Sidewalk Curb Repair</a></h3>
                            </div>
                            <div className="text">
                                Our professional sidewalk curb repair services are designed to address a variety of issues, from minor damage to extensive deterioration, ensuring the safety, accessibility, and aesthetics of your property's pedestrian pathways.
                            </div>
                            <a href="/service-detail/Sidewalk Curb Repair" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Sidewalk Violation Removal">Sidewalk Violation Removal</a></h3>
                            </div>
                            <div className="text">
                                We understand the importance of promptly addressing sidewalk violations to ensure the safety and accessibility of pedestrian pathways. Our sidewalk violation removal services efficiently resolve violations and restore compliance with local regulations.
                            </div>
                            <a href="/service-detail/Sidewalk Violation Removal" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Driveway Repair">Driveway Repair</a></h3>
                            </div>
                            <div className="text">
                                Well-maintained driveway provide safety, accessibility, and curb appeal of your property. Our driveway repair services address a variety of issues, from minor cracks to extensive damage, ensuring the longevity and functionality of your driveway.
                            </div>
                            <a href="/service-detail/Driveway Repair" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                <div className={`row clearfix ${serviceType == "restoration-services" ? "" : "d-none"}`}>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Brick Pointing">Brick Pointing</a></h3>
                            </div>
                            <div className="text">
                                At Kingstone General Construction Corp. we understand the importance of maintaining the structural integrity and aesthetic appeal of brick buildings. Our professional brick pointing services are designed to address deteriorated mortar joints, ensuring the longevity and beauty of your brickwork.
                            </div>
                            <a href="/service-detail/Brick Pointing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Brick Restoration">Brick Restoration</a></h3>
                            </div>
                            <div className="text">
                                We are commited to preserve the historic charm and structural integrity of brick buildings. Our professional brick restoration services address a wide range of issues, from weathering and deterioration to damage and discoloration, ensuring that your property's brickwork retains its timeless beauty and durability.
                            </div>
                            <a href="/service-detail/Brick Restoration" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Brownstone Restoration">Brownstone Restoration</a></h3>
                            </div>
                            <div className="text">
                                We got blending time-honored techniques with modern expertise to preserve the unique beauty and character of Brownstone historic structures. From weathering and deterioration to damage and discoloration, we ensure that your property's brownstone façade maintains its timeless elegance and charm.
                            </div>
                            <a href="/service-detail/Brownstone Restoration" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Deck Restoration"></a>Deck Restoration</h3>
                            </div>
                            <div className="text">
                                We specialize in revitalizing outdoor decks, transforming worn and weathered surfaces into stunning spaces for relaxation and entertainment. Our professional deck restoration services are tailored to address a variety of issues, from wood deterioration and surface damage to discoloration and aging, ensuring that your deck remains a beautiful and functional extension of your home.
                            </div>
                            <a href="/service-detail/Deck Restoration" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Water Damage Restoration">Water Damage Restoration</a></h3>
                            </div>
                            <div className="text">
                                Water damage can wreak havoc on your property, compromising its structural integrity and creating unsafe living conditions. We help homeowners and businesses recover from water-related disasters. Our professional team is equipped with the experience, and resources to mitigate water damage, restore affected areas, and ensure the safety and well-being of your property.
                            </div>
                            <a href="/service-detail/Water Damage Restoration" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Wood Restoration">Wood Restoration</a></h3>
                            </div>
                            <div className="text">
                                At Kingstone General Construction Corp. we specialize in revitalizing and restoring wood surfaces to their original beauty and luster. Whether it's hardwood floors, furniture, decks, or antique woodwork, our professional wood restoration services are designed to breathe new life into your wooden assets, enhancing their appearance, durability, and longevity.
                            </div>
                            <a href="/service-detail/Wood Restoration" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Stone Restoration">Stone Restoration</a></h3>
                            </div>
                            <div className="text">
                                We specialize in the delicate art of stone restoration, dedicated to bringing out the timeless beauty of natural stone surfaces. From marble and granite to limestone and travertine, our stone restoration services revive, repair, and protect your stone surfaces, ensuring they remain a stunning testament to elegance and sophistication.
                            </div>
                            <a href="/service-detail/Stone Restoration" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Masonry Cleaning">Masonry Cleaning</a></h3>
                            </div>
                            <div className="text">
                                At Kingstone General Construction Corp. we specialize in revitalizing masonry surfaces, breathing new life into brick, stone, and concrete structures. Our professional masonry cleaning services are designed to remove dirt, grime, stains, and pollutants, revealing the inherent beauty and craftsmanship of your masonry.
                            </div>
                            <a href="/service-detail/Masonry Cleaning" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Local Law 11">Local Law 11</a></h3>
                            </div>
                            <div className="text">
                                Our Local Law 11 services help building owners and managers comply with New York City's strict facade inspection requirements. We are dedicated to ensuring the safety and integrity of your building's exterior, helping you navigate the complexities of Local Law 11 compliance with confidence and peace of mind.
                            </div>
                            <a href="/service-detail/Local Law 11" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                <div className={`row clearfix ${serviceType == "masonry-services" ? "" : "d-none"}`}>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Facade Repair">Facade Repair</a></h3>
                            </div>
                            <div className="text">
                                We specialize in professional facade repair services aimed at preserving the structural integrity and aesthetic appeal of your building's exterior. Our skilled team of experts is dedicated to delivering high-quality repairs that not only address existing issues but also prevent future damage, ensuring your facade remains beautiful and resilient for years to come.
                            </div>
                            <a href="/service-detail/Facade Repair" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Chimney Rebuilds">Chimney Rebuilds</a></h3>
                            </div>
                            <div className="text">
                                At Kingstone, we specialize in professional chimney rebuild services aimed at restoring the safety, integrity, and functionality of your fireplace system. Whether your chimney has suffered from structural damage, deterioration, or needs a complete rebuild, our experienced team is dedicated to delivering high-quality solutions that exceed your expectations.
                            </div>
                            <a href="/service-detail/Chimney Rebuilds" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Expansion Joints">Expansion Joints</a></h3>
                            </div>
                            <div className="text">
                                Our expansion joints service maintain the structural integrity and flexibility of your building's construction. Expansion joints allow natural movement of building materials due to temperature changes, settlement, and other factors. We do installation, repair, and maintenance of expansion joints to ensure optimal performance and longevity.
                            </div>
                            <a href="/service-detail/Expansion Joints" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Fire Escapes">Fire Escapes</a></h3>
                            </div>
                            <div className="text">
                                We aimed at ensuring the safety, functionality, and compliance of your building's emergency egress system. Fire escapes provide occupants with a means of escape in the event of a fire or other emergency. We provide installation, repair, maintenance, and inspection of fire escapes to meet regulatory requirements and protect lives.
                            </div>
                            <a href="/service-detail/Fire Escapes" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Patios">Patios</a></h3>
                            </div>
                            <div className="text">
                                We craft stunning and functional patios that transform outdoor spaces into inviting and enjoyable retreats. Whether you're looking to create a cozy backyard oasis, an expansive entertainment area, or a stylish outdoor dining space, our experienced team is dedicated to bringing your vision to life with our comprehensive patio services.
                            </div>
                            <a href="/service-detail/Patios" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Power Washing">Power Washing</a></h3>
                            </div>
                            <div className="text">
                                We provide power washing services that rejuvenate and restore the beauty of your property's exterior surfaces. Whether your home, commercial building, or outdoor space is in need of a thorough cleaning, our experienced team is here to deliver outstanding results using state-of-the-art equipment and environmentally friendly techniques.
                            </div>
                            <a href="/service-detail/Power Washing" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Retaining Walls">Retaining Walls</a></h3>
                            </div>
                            <div className="text">
                                We provide professional retaining walls services that enhance the beauty, functionality, and stability of your outdoor spaces. Whether you're looking to create terraced gardens, define property boundaries, or prevent soil erosion, our experienced team is dedicated to delivering outstanding results tailored to your specific needs and preferences.
                            </div>
                            <a href="/service-detail/Retaining Walls" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                    <div className="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="inner-box">
                            <div className="upper-box">
                                <h3><a href="/service-detail/Stair Repair & Building">Stair Repair & Building</a></h3>
                            </div>
                            <div className="text">
                                Our stair repair and building services aimed at enhancing the safety, functionality, and aesthetics of your property's staircase. Whether you need repairs to existing stairs or a new staircase constructed from scratch, our experienced team is dedicated to delivering outstanding results tailored to your specific needs and preferences.
                            </div>
                            <a href="/service-detail/Stair Repair & Building" className="read-more">Read More<span className="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
