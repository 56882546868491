import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import brickrestoration1 from "../assets/images/restoration/brickrestoration1.jpg"
import brickrestoration2 from "../assets/images/restoration/brickrestoration2.jpg"
import brickrestoration3 from "../assets/images/restoration/brickrestoration3.jpg"

export const BrickRestoration = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={brickrestoration1} alt="Brick Restoration" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={brickrestoration2} alt="Brick Restoration" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={brickrestoration3} alt="Brick Restoration" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Brick Restoration</h3>
            <div className="text">
                <p>
                    Homex Builders Corp follows a thorough process for brick restoration services to ensure the structural integrity and visual appeal of brick buildings. Here’s an outline of the key steps involved in their brick restoration process:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Brick Restoration Services?</h3>
                <ul>
                    <li><strong>Comprehensive Assessment: </strong>Homex Builders starts by conducting a detailed inspection of the brick structure, identifying damaged or deteriorated bricks, mortar issues, and any signs of moisture or structural weakening.</li>
                    <li><strong>Moisture and Stability Check: </strong>The team checks for water damage, cracks, and structural movement to determine the scope of restoration needed.</li>
                    <li><strong>Surface Cleaning: </strong>Before beginning any repair, the surface is cleaned using methods like low-pressure washing or chemical cleaning to remove dirt, grime, and efflorescence (white salt deposits). This ensures a clean base for restoration.</li>
                    <li><strong>Selection of Materials: </strong>Homex Builders carefully matches the replacement bricks and mortar to the original materials in color, texture, and size. This ensures that the repaired areas blend seamlessly with the rest of the building.</li>
                    <li><strong>Damaged Brick Removal: </strong>Any damaged, cracked, or spalled (chipped or flaking) bricks are carefully removed using chisels or grinders, ensuring minimal disruption to the surrounding structure.</li>
                    <li><strong>Brick Replacement: </strong>New or salvaged bricks that match the original are installed in place of the old ones. The new bricks are securely set into position to maintain the building's strength and appearance.</li>
                    <li><strong>Mortar Removal: </strong>Old, crumbling mortar is removed from the joints around the bricks that are being restored, using hand tools or grinders.</li>
                    <li><strong>Application of New Mortar: </strong>Homex Builders applies fresh mortar to the joints, matching the original in both color and consistency. This strengthens the bond between the bricks and helps prevent water infiltration.</li>
                    <li><strong>Tuckpointing (Optional): </strong>In cases where a more polished appearance is required, tuckpointing is done using two different mortar colors to create the appearance of very fine, well-defined joints.</li>
                    <li><strong>Final Cleaning:</strong> Once the brick repairs and mortar repointing are complete, the surface is cleaned again to remove any excess mortar, dust, or debris, giving the structure a fresh and clean look.</li>
                    <li><strong>Texture and Color Matching: </strong>Homex ensures that the new bricks and mortar blend seamlessly with the original structure, both in texture and color, maintaining the building’s historic or aesthetic value.</li>
                    <li><strong>Protective Coating:</strong> A waterproofing sealant can be applied to protect the newly restored brick and mortar from future weather damage, moisture infiltration, and general wear and tear.</li>
                    <li><strong>Thorough Review:</strong> The team performs a final inspection to ensure all restoration work meets quality standards. They verify that the repairs are visually cohesive and that the structure’s integrity is fully restored.</li>

                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Brick Restoration?</h4>
                    <p>
                        Choosing us for your brick restoration service means entrusting your project to a team that combines expertise, quality craftsmanship, and customer dedication. Here’s why you should work with us:
                    </p>
                    <ul>
                        <li><strong>Extensive Experience: </strong>We bring years of experience in brick restoration, handling everything from minor repairs to large-scale restorations. Our team understands the intricacies of restoring aging brickwork, ensuring your building’s structural integrity and aesthetic appeal are preserved.</li>
                        <li><strong>Skilled Craftsmanship: </strong>Our experts are highly trained in masonry techniques, ensuring your bricks are restored with precision and care. We focus on matching the original style and materials to provide a seamless finish that enhances the beauty of your property.</li>
                        <li><strong>Use of Quality Materials: </strong>We prioritize using the best quality materials to ensure durability and longevity. Whether it's matching the mortar color or selecting the right type of brick, we ensure a perfect blend with your existing structure.</li>
                        <li><strong>Customized Solutions: </strong>Every building is unique, and we take the time to assess your property’s specific needs. We offer tailored restoration plans that fit your budget and goals, ensuring a personalized approach to every project.</li>
                        <li><strong>Licensed and Insured:</strong> We are fully licensed and insured, giving you peace of mind that your property is in safe and professional hands.</li>
                        <li><strong>Customer Satisfaction: </strong>Our reputation is built on a foundation of trust and client satisfaction. We communicate openly throughout the process, ensuring your expectations are met every step of the way.</li>
                    </ul>
                </blockquote>
                <p>
                    When it comes to brick restoration, we bring quality, reliability, and a customer-centered approach to each project. Reach out to us for a free consultation and let us help restore the beauty and strength of your building.
                </p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Structural Integrity Assessment: </strong>Homex Builders prioritizes inspecting the structure for any weaknesses before beginning restoration. This includes checking for cracks, water damage, or loose bricks that could compromise the building during the restoration process.</li>
                                        <li><strong>Dust and Debris Control: </strong>During restoration, dust containment systems are employed to minimize airborne particles. This ensures the safety of both workers and the surrounding environment. Protective coverings are also used to shield windows and adjacent surfaces from damage.</li>
                                        <li><strong>Proper Equipment and Safety Gear:</strong> Workers are equipped with the necessary safety gear, including harnesses, helmets, and gloves, especially when working on high structures. They follow strict OSHA standards to ensure that both the workers and the building are protected throughout the process.</li>
                                        <li><strong>Scaffold and Ladder Safety: Homex </strong>Builders ensures that scaffolding and ladders are properly secured and inspected to avoid accidents. These safety measures are critical in preventing falls and ensuring smooth operations at any height.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Comprehensive Building Analysis: </strong>Homex Builders uses advanced diagnostic tools like moisture meters and thermal imaging to detect hidden issues such as internal water damage or temperature fluctuations within the brickwork. This allows for a more thorough restoration that addresses the root causes of deterioration.</li>
                                        <li><strong>Historic Material Matching: </strong>For older or historic buildings, Homex Builders excels in matching the original materials—bricks, mortar, and finishes—to ensure a seamless restoration. They perform careful analysis to replicate the original look while improving structural soundness.</li>
                                        <li><strong>Restoration Planning and Phasing: </strong>With experience and insight, Homex Builders ensures that restoration projects are executed in phases, minimizing disruption and ensuring every step is executed precisely. This methodical approach helps avoid unnecessary damage to the surrounding structure.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Historic Building Preservation: </strong>Homex Builders has a strong specialization in restoring heritage buildings. Their expertise lies in using traditional methods that preserve the historical aesthetic while reinforcing the building’s structural integrity with modern techniques.</li>
                                        <li><strong>Brick and Mortar Repointing:</strong> Alongside restoration, they are skilled in repointing brickwork where mortar joints have decayed. This process requires precise craftsmanship to match the original style, which is vital in preserving the overall look and feel of historic structures.</li>
                                        <li><strong>Waterproofing and Weatherproofing:</strong> Homex Builders specializes in protecting brick structures from future water damage by incorporating advanced waterproofing systems into their restoration process. This helps extend the life of the brickwork in harsh weather conditions.</li>
                                        <li><strong>Cleaning and Surface Treatment: </strong>A key aspect of their specialization is brick cleaning, where they remove years of dirt, pollutants, and stains using non-abrasive techniques. This not only restores the original appearance but also prolongs the durability of the bricks.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    With a blend of robust precautions, innovative intelligence, and deep specialization, Homex Builders Corp is well-equipped to handle even the most challenging brick restoration projects. Their commitment to safety, cutting-edge technology, and historic preservation make them an industry leader in ensuring brick structures are not only restored but also strengthened for the future.
                </p>
            </div>
        </>
    );
}
