import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import deckrestoration1 from "../assets/images/restoration/deckrestoration1.jpg"
import deckrestoration2 from "../assets/images/restoration/deckrestoration2.jpg"
import deckrestoration3 from "../assets/images/restoration/deckrestoration3.jpg"

export const DeckRestoration = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={deckrestoration1} alt="Deck Restoration" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={deckrestoration2} alt="Deck Restoration" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={deckrestoration3} alt="Deck Restoration" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Deck Restoration</h3>
            <div className="text">
                <p>
                    HomeX Builders Corp offers a comprehensive deck restoration service that revitalizes and extends the life of outdoor decks. Here’s what they typically do in this service:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Deck Restoration Services?</h3>
                <ul>
                    <li><strong>Inspection and Assessment: </strong>The process begins with a thorough inspection of the deck to identify areas of damage, such as rotting wood, loose boards, or rusted nails. They assess the overall condition to determine the necessary repairs and the level of restoration required.</li>
                    <li><strong>Cleaning and Stripping: </strong>HomeX Builders Corp will deep-clean the deck to remove dirt, mildew, and old finishes. This usually involves pressure washing to clean the wood's surface effectively. In some cases, they may strip away old stains or paints to prepare the deck for refinishing.</li>
                    <li><strong>Repairs: </strong>After cleaning, they address any structural issues. This can include replacing damaged or rotted wood, tightening loose boards, or securing railings. The goal is to ensure the deck is safe and sound before moving on to the next steps.</li>
                    <li><strong>Sanding: </strong>To smooth out the wood and prepare it for staining, they sand the deck. This step helps remove any rough spots, splinters, or remaining old finishes, ensuring a smooth and even surface for the next stage.</li>
                    <li><strong>Staining and Sealing: </strong>Once the deck is prepped, HomeX Builders Corp will apply a high-quality stain that not only enhances the wood's natural beauty but also provides protection against the elements. They then seal the deck to protect it from moisture, UV rays, and other environmental factors.</li>
                    <li><strong>Final Inspection: </strong>After the restoration process, a final inspection is conducted to ensure that the deck meets their quality standards and that the customer is satisfied with the result.</li>
                </ul>
                <p>
                    Their service is designed to not only improve the appearance of your deck but also to increase its longevity and safety.
                </p>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Deck Restoration?</h4>
                    <p>
                        Choosing us for your deck restoration needs is a decision rooted in quality, reliability, and expertise. Here’s why you should trust us:
                    </p>
                    <ul>

                        <li><strong>Expert Craftsmanship: </strong>We pride ourselves on our skilled team of professionals who bring years of experience in deck restoration. Our craftsmen understand the nuances of wood types, finishes, and restoration techniques, ensuring that your deck is not only restored but also enhanced to withstand time and weather.</li>
                        <li><strong>Customized Solutions: </strong>At Homex Builders Corp, we recognize that every deck is unique. We provide tailored restoration services that address the specific needs of your deck, whether it’s repairing structural damage, refinishing the surface, or replacing worn-out components. Our approach ensures that your deck is restored to its original beauty and functionality.</li>
                        <li><strong>High-Quality Materials: </strong>We use only the best materials and products in our restoration projects. From premium wood stains to durable sealants, every product we use is chosen for its quality and longevity. This commitment to quality ensures that your deck will look great and stand strong for years to come.</li>
                        <li><strong>Comprehensive Service: </strong>Our deck restoration services cover everything from initial inspection and assessment to final finishing touches. We handle every aspect of the project, so you don’t have to worry about coordinating multiple contractors or managing different stages of the process. We make the restoration experience seamless and hassle-free.</li>
                        <li><strong>Customer Satisfaction: </strong>Your satisfaction is our top priority. We work closely with you throughout the restoration process to ensure that your vision is realized. Our transparent communication, attention to detail, and commitment to excellence mean that you can trust us to deliver results that exceed your expectations.</li>
                        <li><strong>Competitive Pricing: </strong>We offer competitive pricing without compromising on quality. Our goal is to provide exceptional value, ensuring that you receive top-notch restoration services at a fair price. We provide detailed estimates and work within your budget to deliver the best possible results.</li>
                        <li><strong>Proven Track Record: </strong>Homex Builders Corp has a proven track record of successful deck restoration projects. Our portfolio and customer testimonials speak to the quality of our work and our dedication to delivering exceptional results.</li>

                        <li><strong>Call to Action: </strong>
                        Don’t settle for less when it comes to restoring your deck. Contact Homex Builders Corp today to schedule a consultation and take the first step towards revitalizing your outdoor space. Your deck deserves the best, and we’re here to deliver it.</li>
                        
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Safety Protocols: </strong>Homex Builders Corp enforces strict safety measures to protect both their workers and your property. This includes securing the area, using proper protective equipment, and adhering to OSHA standards to prevent accidents during the restoration process.</li>
                                        <li><strong>Material Protection: </strong>During deck restoration, they take care to protect the surrounding landscape and structures from damage. This involves covering nearby plants, furniture, and other features to prevent contamination from debris, chemicals, or restoration materials.</li>
                                        <li><strong>Weather Considerations: </strong>The company carefully schedules work around weather conditions, ensuring that materials such as wood and sealants are applied under optimal conditions to avoid warping, swelling, or improper adhesion.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Thorough Inspection: </strong>Homex Builders begins each deck restoration project with a comprehensive inspection to assess the extent of damage and wear. They identify issues such as rot, structural weakness, and insect damage, allowing them to create a targeted restoration plan.</li>
                                        <li><strong>Material Expertise: </strong>The team is knowledgeable about a wide range of decking materials, including wood, composite, and synthetic options. This expertise allows them to recommend the best restoration methods and materials for long-lasting results.</li>
                                        <li><strong>Innovative Techniques: </strong>Homex Builders employs the latest techniques in deck restoration, including power washing, sanding, staining, and sealing. They use advanced tools and products that are both effective and environmentally friendly, ensuring a high-quality finish that withstands the elements.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Wood Restoration: </strong>Homex Builders Corp specializes in restoring wooden decks, with a deep understanding of various wood types like cedar, redwood, and pressure-treated lumber. Their expertise ensures that the wood’s natural beauty is preserved and protected against future damage.</li>
                                        <li><strong>Custom Solutions: </strong>They offer customized restoration services tailored to the specific needs of your deck, whether it involves replacing damaged boards, reinforcing the structure, or applying a fresh finish. This specialization ensures that each deck is restored to its best possible condition.</li>
                                        <li><strong>Maintenance Plans: </strong>Beyond restoration, Homex Builders provides ongoing maintenance plans to keep your deck in top condition. Their specialized knowledge in maintaining various decking materials helps extend the life of your deck, preventing future costly repairs.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    In summary, Homex Builders Corp stands out in deck restoration through its focus on safety, intelligent problem-solving, and specialized skills. Their thorough approach ensures that every deck is restored to its original strength and beauty, with long-lasting results that enhance both the functionality and aesthetics of your outdoor space.
                </p>
            </div>
        </>
    );
}
