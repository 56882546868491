import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import facaderepair1 from "../assets/images/masonry/facaderepair1.jpg"
import facaderepair2 from "../assets/images/masonry/facaderepair2.jpg"
import facaderepair3 from "../assets/images/masonry/facaderepair3.jpg"

export const FacadeRepair = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={facaderepair1} alt="Facade Repair" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={facaderepair2} alt="Facade Repair" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={facaderepair3} alt="Facade Repair" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Facade Repair</h3>
            <div className="text">
                <p>
                    Homex Builders Corp specializes in comprehensive facade repair services that help maintain the safety, integrity, and aesthetic appeal of your building's exterior. Whether your property is a historic building needing delicate restoration or a modern structure requiring essential repairs, our expert team provides tailored solutions to meet your needs. Here’s an overview of our facade repair services and the reasons why you should choose Homex Builders Corp for your project:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Facade Repair Services?</h3>
                <ul>
                    <li><strong>Brick and Stone Repair: </strong>Over time, brick and stone facade can suffer from cracks, spalling, or general wear and tear. We provide expert brick and stone repair services, including repointing, replacing damaged bricks or stones, and filling cracks to restore the original appearance and structural stability of your building.</li>
                    <li><strong>Concrete Restoration: </strong>Concrete facade, while durable, are susceptible to issues like cracking, chipping, or water infiltration. Our concrete restoration services include repairing cracks, patching damaged areas, and applying protective coatings to prevent further deterioration. We ensure that the repairs blend seamlessly with the existing concrete, maintaining your building's aesthetic integrity.</li>
                    <li><strong>Stucco and EIFS Repairs: </strong>Stucco and Exterior Insulation and Finish Systems (EIFS) can become damaged due to weathering, impact, or improper installation. We provide comprehensive stucco and EIFS repair services, including patching holes, sealing cracks, and reapplying finishes to restore the appearance and protect the underlying structure.</li>
                    <li><strong>Waterproofing and Sealant Application: </strong>To protect your building’s facade from water damage, we offer waterproofing services that include applying high-quality sealants, caulking joints, and treating surfaces with water-repellent coatings. These measures prevent moisture penetration, which can lead to mold growth, rusting, and structural weakening.</li>
                    <li><strong>Masonry Repointing: </strong>Repointing is essential for maintaining the integrity of masonry facade. We remove deteriorated mortar from between bricks or stones and replace it with new mortar that matches the original in composition and color. This process not only improves the appearance of your facade but also enhances its structural stability and resistance to weather.</li>
                    <li><strong>Facade Cleaning and Maintenance: </strong>Regular cleaning and maintenance are crucial for the longevity of your building’s facade. We provide cleaning services using environmentally friendly products and techniques to remove dirt, grime, graffiti, and pollutants, ensuring your facade looks its best and remains in good condition.</li>
                    <li><strong>Metal Facade Repair and Refinishing: </strong>Metal facade, such as those made from aluminum, steel, or copper, can suffer from corrosion, dents, and scratches. We offer repair and refinishing services to restore these materials, including corrosion removal, refinishing, and protective coating applications to extend the life of your metal facade.</li>

                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Facade Repair?</h4>
                    <ul>
                        <li><strong>Expertise and Experience: </strong>Homex Builders Corp has extensive experience in facade repair and restoration. Our team of professionals is highly skilled in working with a variety of facade materials, including brick, stone, stucco, concrete, and metal. This expertise allows us to deliver high-quality, long-lasting repairs tailored to your building’s specific needs.</li>
                        <li><strong>Comprehensive Solutions: </strong>We provide a full range of facade repair services, making us a one-stop solution for all your building exterior needs. From minor repairs to extensive restorations, we handle every aspect of facade maintenance, ensuring a seamless and efficient process from start to finish.</li>
                        <li><strong>Customized Approach: </strong>Every building facade is unique, and we tailor our repair strategies to meet the specific needs and conditions of your structure. Whether it’s a historic building requiring careful restoration or a modern property needing targeted repairs, we develop a customized plan that addresses your concerns and preserves your building’s character.</li>
                        <li><strong>Quality Materials and Techniques: </strong>We use only the highest-quality materials and the latest repair techniques to ensure durable and aesthetically pleasing results. Our commitment to quality craftsmanship means that your repairs are built to last, enhancing both the appearance and functionality of your building facade.</li>
                        <li><strong>Commitment to Safety and Compliance: </strong>Safety is paramount in all our projects. We strictly adhere to all local building codes and safety regulations to protect both our workers and your property. Our team is fully trained and equipped to handle repairs safely and efficiently, minimizing any disruption to your operations.</li>
                        <li><strong>Transparent Communication: </strong>At Homex Builders Corp, we believe in open and transparent communication with our clients. We provide clear, detailed estimates and keep you informed at every stage of the repair process. This transparency helps build trust and ensures you are fully aware of the scope, timeline, and cost of your project.</li>
                        <li><strong>Competitive Pricing: </strong>We offer competitive pricing for our facade repair services, providing excellent value without compromising on quality. Our cost-effective solutions are designed to fit your budget while ensuring the highest standards of work, making us a preferred choice for property owners and managers.</li>
                        <li><strong>Proven Track Record: </strong>Homex Builders Corp has a proven track record of successful facade repair projects. Our portfolio includes a wide range of buildings, from historic landmarks to modern high-rises. Our reputation for reliability, quality, and customer satisfaction speaks for itself, making us a trusted partner in facade repairs.</li>
                        <li><strong>Environmentally Friendly Practices: </strong>We prioritize environmentally friendly practices in our repair processes, using non-toxic, sustainable materials and minimizing waste. Our cleaning products are eco-friendly, and we take care to protect the surrounding environment during repairs.</li>

                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Comprehensive Safety Protocols: </strong>Homex Builders Corp implements strict safety protocols to protect workers, occupants, and pedestrians during facade repair projects. This includes setting up appropriate scaffolding and barriers, using personal protective equipment (PPE), and adhering to Occupational Safety and Health Administration (OSHA) guidelines. The company ensures that all safety measures are in place to minimize the risk of accidents, especially when working at heights.</li>

                                        <li><strong>Structural Stability Assessment: </strong>Before beginning any facade repair work, a thorough assessment of the building's structural stability is conducted. This includes checking for cracks, bulges, loose materials, and signs of water infiltration that could compromise the building's safety. Identifying these issues early on helps in planning the repair process and preventing further damage during the repair.</li>

                                        <li><strong>Weather and Environmental Considerations: </strong>Weather conditions play a critical role in facade repair. Homex Builders Corp takes environmental factors into account and schedules repairs during optimal weather conditions to avoid issues like mortar curing problems or water infiltration. They also implement measures to protect the facade and surrounding areas from dust, debris, and moisture during the repair process.</li>

                                        <li><strong>Use of High-Quality Materials and Techniques: </strong>To ensure durability and prevent future issues, Homex Builders Corp uses high-quality, compatible materials for repairs. For instance, the mortar mix is carefully matched to the original for historic facades, and water-resistant coatings are applied to prevent water damage. The selection of materials is critical to the longevity and appearance of the repaired facade.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Advanced Diagnostic Tools and Techniques: </strong>Homex Builders Corp utilizes advanced diagnostic tools and techniques to accurately assess the condition of the facade and identify underlying issues. This may include infrared thermography to detect moisture intrusion, ground-penetrating radar to assess the integrity of masonry, and drone inspections to capture detailed images of hard-to-reach areas. These technologies enable precise identification of problem areas and inform targeted repair strategies.</li>

                                        <li><strong>Expertise in Multiple Facade Materials: </strong>The company’s team has extensive experience working with a wide range of facade materials, including brick, stone, stucco, concrete, and metal cladding. Each material has unique repair requirements, and Homex Builders Corp's expertise allows them to choose the most appropriate repair methods for each type of material, ensuring that the repairs are both effective and aesthetically consistent with the original facade.</li>

                                        <li><strong>Knowledge of Historical Preservation Techniques: </strong>For buildings with historical significance, Homex Builders Corp is skilled in using traditional methods and materials to preserve the architectural integrity of the facade. This includes sourcing period-appropriate materials, using lime-based mortars for historic masonry, and employing traditional craftsmanship techniques to maintain the building's historical character.</li>

                                        <li><strong>Understanding of Building Codes and Regulations: </strong>Homex Builders Corp ensures all facade repair work complies with local building codes, safety standards, and regulations. They stay up-to-date with the latest industry standards and guidelines to ensure that repairs are safe, effective, and compliant with all legal requirements.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Masonry Repair and Repointing: </strong>One of the key specializations of Homex Builders Corp is masonry repair and repointing. Repointing involves removing deteriorated mortar from the joints of brick or stone facades and replacing it with new mortar. This process restores the integrity of the masonry and helps prevent water penetration. Homex Builders Corp ensures that the new mortar matches the original in color, texture, and strength, preserving the facade’s appearance and historical authenticity.</li>

                                        <li><strong>Stucco and Concrete Facade Repair: </strong>Homex Builders Corp offers specialized repair services for stucco and concrete facades. This includes patching cracks, addressing spalling (flaking) concrete, and applying new stucco layers or finishes. They use high-quality stucco mixes and advanced application techniques to ensure repairs blend seamlessly with the existing facade and are resistant to future damage.</li>

                                        <li><strong>Facade Waterproofing and Protective Coatings: </strong>Waterproofing is an essential aspect of facade repair, particularly in preventing water damage and extending the life of the building exterior. Homex Builders Corp specializes in applying various types of protective coatings and sealants to facades, such as elastomeric coatings for stucco or silane/siloxane-based sealers for masonry. These treatments help protect against water infiltration, UV damage, and environmental wear.</li>

                                        <li><strong>Facade Anchoring and Stabilization: </strong>For facades that have become unstable or pose a safety risk, Homex Builders Corp offers anchoring and stabilization services. This may involve installing mechanical anchors or ties to secure loose masonry, reinforcing the structural integrity of the facade, and preventing future movement or collapse. Their expertise ensures that these interventions are both effective and minimally invasive.</li>

                                        <li><strong>Custom Aesthetic Restorations: </strong>In addition to structural repairs, Homex Builders Corp provides aesthetic restoration services to improve the appearance of facades. This includes cleaning, tuckpointing (refining the appearance of mortar joints), and replacing damaged or mismatched bricks or stones. They are skilled in color matching and texturing to ensure repairs are virtually indistinguishable from the original facade.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for your facade repair services means choosing a company with a strong commitment to quality, safety, and customer satisfaction. Our extensive experience, comprehensive service offerings, and dedication to craftsmanship make us the ideal choice for any facade repair project, big or small.
                </p>
            </div>
        </>
    );
}
