import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import RoofReplacement1 from "../assets/images/roofingservices/roof_replacement1.jpg"
import RoofReplacement2 from "../assets/images/roofingservices/roof_replacement2.jpg"
import RoofReplacement3 from "../assets/images/roofingservices/roof_replacement3.jpg"

export const RoofReplacement = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={RoofReplacement1} alt="Roof Replacement" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={RoofReplacement2} alt="Roof Replacement" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={RoofReplacement3} alt="Roof Replacement" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Roof Replacement</h3>
            <div className="text">
                <p>
                    When it’s time for a roof replacement, you need a team with expertise, precision, and a commitment to safety. At Homex Builders Corp, we offer specialized roof replacement services that provide your home with a brand-new roof designed to withstand time and weather. Whether your roof has aged beyond repair, sustained significant damage, or you’re looking to upgrade, we deliver top-quality craftsmanship and intelligent solutions.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Roof Inspection?</h3>
                <ul>
                    <li>
                        <strong>Asphalt Shingle Roof Replacement: </strong>
                        Durable and cost-effective roofing solutions with a variety of colors and styles.
                    </li>

                    <li>
                        <strong>Metal Roof Replacement: </strong>
                        Long-lasting, energy-efficient, and highly resistant to extreme weather conditions.
                    </li>

                    <li>
                        <strong>Tile Roof Replacement: </strong>
                        Elegant and durable tiles that provide a classic look with superior longevity.
                    </li>

                    <li>
                        <strong>Flat Roof Replacement: </strong>
                        Specialized flat roofing systems that offer reliable, leak-proof protection.
                    </li>

                    <li>
                        <strong>Wood Shake Roof Replacement: </strong>
                        Natural wood shingles for a rustic appearance and solid protection.
                    </li>

                    <li>
                        <strong>Slate Roof Replacement: </strong>
                        Premium slate roofing that offers unparalleled durability and a timeless aesthetic.
                    </li>

                    <li>
                        <strong>Roof Deck Replacement: </strong>
                        Complete replacement of the roof deck to ensure a solid foundation for your new roof.
                    </li>

                    <li>
                        <strong>Roof Ventilation Upgrades: </strong>
                        Improving roof ventilation systems to enhance energy efficiency and roof longevity.
                    </li>

                    <li>
                        <strong>Skylight Installation and Replacement: </strong>
                        Integrating or replacing skylights as part of your roof replacement project.
                    </li>

                    <li>
                        <strong>Gutter System Replacement: </strong>
                        Installing new gutters and downspouts to complement your roof and protect your home’s foundation.
                    </li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Roof Replacement?</h4>
                    <ul>
                        <li>
                            <strong>Experienced Professionals: </strong>At Homex Builders Corp, we bring years of expertise in replacing all types of roofing systems, from traditional asphalt shingles to modern metal and tile roofs. Our team is skilled in handling both residential and commercial projects with precision.
                        </li>
                        <li>
                            <strong>High-Quality Materials: </strong>We work with the best materials on the market to ensure your new roof is built to last. Whether you’re looking for durability, energy efficiency, or aesthetic appeal, we offer materials that meet your exact requirements.
                        </li>
                        <li>
                            <strong> Customer-Focused Service: </strong>Your satisfaction is our priority. From the initial consultation to the final inspection, we guide you through the entire process, keeping you informed and involved in every decision. Our team ensures your roof replacement is stress-free, on time, and within budget.
                        </li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Comprehensive Pre-Inspection: </strong>Before starting any replacement, we perform a thorough inspection of your existing roof. This allows us to uncover any underlying issues such as structural weaknesses or water damage that need to be addressed to ensure a solid foundation for your new roof.
                                        </li>
                                        <li>
                                            <strong>Weather Planning: </strong>We carefully plan our projects around weather conditions to avoid complications and protect your home. If unexpected weather arises, we use tarps and protective covers to ensure your home remains safe during the replacement process.
                                        </li>
                                        <li>
                                            <strong>Safety Protocols: </strong>Roof replacement can be dangerous work, but our strict safety standards ensure that our team and your property remain protected throughout the project. We use advanced safety gear, fall protection systems, and follow industry guidelines to ensure a safe, secure work environment.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Smart Diagnostics: </strong>Our replacement services go beyond just removing the old roof. We use cutting-edge technology, like thermal imaging and moisture sensors, to detect hidden problems such as leaks or poor insulation that could impact your new roof’s performance.
                                        </li>
                                        <li>
                                            <strong>Energy Efficiency Solutions: </strong>We design your new roof with energy efficiency in mind, offering solutions that can help lower your energy bills. From selecting materials with reflective properties to optimizing ventilation, we ensure your roof contributes to a more comfortable and energy-efficient home.
                                        </li>
                                        <li>
                                            <strong>Custom Design Options: </strong>Our intelligent approach to roof replacement includes helping you choose roofing materials that best suit your home's architectural style, local climate, and personal preferences. We offer a wide range of designs and colors to ensure your new roof not only protects but also enhances your home's curb appeal.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Expertise in All Roof Types: </strong>Whether you need a shingle, metal, tile, or flat roof replacement, Homex Builders Corp specializes in replacing all types of roofing systems. Our team is experienced in working with a variety of materials and designs, ensuring your new roof is installed to the highest standards.
                                        </li>
                                        <li>
                                            <strong>Storm Damage Restoration: </strong>If your roof has been damaged by severe weather, our storm restoration services are designed to repair and reinforce your roof with specialized techniques and materials that ensure long-lasting protection against future storms.
                                        </li>
                                        <li>
                                            <strong>Residential & Commercial Solutions: </strong>We cater to both residential and commercial clients, offering custom roof replacement services that meet the specific demands of each building. Whether it’s a single-family home or a large commercial property, we deliver quality results with efficiency and professionalism.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Investing in a roof replacement is a crucial decision for your home’s safety and value. With Homex Builders Corp, you receive expert service with a focus on intelligent diagnostics, safety precautions, and specialized solutions that ensure your new roof is built to last.
                </p>
                <p>
                    Contact us today to schedule your roof replacement consultation, and let our professionals deliver the quality and reliability your home deserves!
                </p>
            </div>
        </>
    );
}
