import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SubHeader } from './SubHeader';
import OwlCarousel, { Options } from 'react-owl-carousel';
import { ServicesList } from './ServicesList';

export const Remodeling = (props) => {

    return (
        <>
            <SubHeader title="Remodeling" />
            <section className="featured-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-column col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title centered">
                                    <div className="title">Remodeling Services</div>
                                    <h2><span className="theme_color">Your Trusted Remodeling Solutions</span> Partner in New York</h2>
                                </div>
                                <div className="text justified">
                                    <p>Transforming your property into the space of your dreams requires a partner who understands your vision and has the expertise to bring it to life. At Homex Builders Corp, we are proud to be New York’s trusted remodeling solutions provider, specializing in both residential and commercial renovations. With our attention to detail, dedication to quality, and focus on customer satisfaction, we help you turn your property into a beautiful, functional, and modern space.</p>
                                    <p>Whether you’re looking to update your kitchen, renovate your bathroom, or undertake a full home remodel, Homex Builders Corp has the experience and craftsmanship needed to ensure your project exceeds expectations.</p>
                                    <p>At Homex Builders Corp, we pride ourselves on delivering an exceptional customer experience. From your first consultation to the final walk-through, we prioritize communication, transparency, and professionalism. We take the time to understand your vision, offer creative solutions, and ensure that the finished product reflects your unique style and needs.</p>
                                    <p>Ready to start your remodeling journey? Whether you’re looking for a complete home renovation or a simple bathroom upgrade, Homex Builders Corp is here to turn your ideas into reality. Our expert team will work closely with you to ensure that your vision is realized, creating a space that you’ll love for years to come. Contact us today for a free consultation and let’s begin transforming your property into the space of your dreams!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServicesList serviceType="remodeling-services" title="Remodeling" />
        </>
    );
}
