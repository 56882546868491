import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import bathroom1 from "../assets/images/remodeling/bathroom1.jpg"
import bathroom2 from "../assets/images/remodeling/bathroom2.jpg"
import bathroom3 from "../assets/images/remodeling/bathroom3.jpg"

export const BathroomRemodeling = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={bathroom1} alt="Bathroom Remodeling" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={bathroom2} alt="Bathroom Remodeling" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={bathroom3} alt="Bathroom Remodeling" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Bathroom Remodeling</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers comprehensive Bathroom Remodeling Services, transforming outdated or inefficient bathrooms into beautiful, functional spaces. Here’s an overview of their services and the reasons why clients choose them:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Bathroom Remodeling Services?</h3>
                <ul>
                    <li><strong>Custom Design and Planning: </strong>Homex Builders Corp works closely with clients to create a custom bathroom design that reflects their style and meets their needs. This includes selecting fixtures, tiles, lighting, and layout options that maximize space and functionality.</li>
                    <li><strong>Full Renovations: </strong>The company handles complete bathroom renovations, from demolition to finishing touches. This includes replacing plumbing, electrical work, installing new flooring, updating fixtures, and applying fresh paint or wall treatments.</li>
                    <li><strong>Fixture Upgrades: </strong>Whether it’s a new shower, bathtub, sink, or toilet, Homex Builders Corp offers expert installation of modern, high-quality fixtures that enhance the look and performance of your bathroom.</li>
                    <li><strong>Tile and Flooring Installation:</strong> The company specializes in installing a wide range of tiles, including ceramic, porcelain, and natural stone, as well as other flooring options like vinyl and hardwood. They ensure precise installation for a durable, stylish finish.</li>
                    <li><strong>Lighting and Ventilation:</strong> Proper lighting and ventilation are key to a functional bathroom. Homex Builders Corp designs and installs lighting that enhances the ambiance while ensuring that the bathroom is well-lit. They also address ventilation needs to prevent moisture buildup and maintain air quality.</li>
                    <li><strong>Cabinetry and Storage Solutions:</strong> Custom cabinetry and storage solutions are provided to help keep your bathroom organized and clutter-free. This includes vanities, medicine cabinets, and built-in shelving that match your design aesthetic.</li>
                    <li><strong>Eco-Friendly Options:</strong> Homex Builders Corp offers eco-friendly remodeling options, including water-saving fixtures, energy-efficient lighting, and sustainable materials. This not only benefits the environment but also reduces utility costs.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Bathroom Remodeling?</h4>
                    <ul>
                        <li><strong>Experienced Professionals:</strong> Homex Builders Corp has a team of experienced designers and craftsmen who specialize in bathroom remodeling. Their expertise ensures that every project is executed with precision and attention to detail.</li>
                        <li><strong>High-Quality Materials:</strong> The company uses only the best materials, from durable tiles and flooring to premium fixtures and finishes. This commitment to quality ensures that your remodeled bathroom will stand the test of time.</li>
                        <li><strong>Personalized Service:</strong> Homex Builders Corp provides personalized service, working closely with clients to understand their vision and preferences. They offer guidance and recommendations to help you make informed decisions that align with your style and budget.</li>
                        <li><strong>Comprehensive Project Management:</strong> From initial consultation to project completion, Homex Builders Corp manages every aspect of the remodeling process. This includes coordinating with subcontractors, scheduling, and ensuring that the project stays on track and within budget.</li>
                        <li><strong>Timely Completion:</strong> The company is known for completing projects on time without compromising quality. They understand the importance of minimizing disruption to your home, so they work efficiently to meet deadlines.</li>
                        <li><strong>Transparent Pricing: </strong>Homex Builders Corp offers transparent pricing with detailed estimates that outline the costs of materials, labor, and any additional services. There are no hidden fees, and they work within your budget to deliver the best possible results.</li>
                        <li><strong>Customer Satisfaction: </strong>Client satisfaction is a top priority for Homex Builders Corp. They pride themselves on delivering exceptional results that exceed expectations, which is reflected in their positive reviews and repeat business.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Thorough Planning and Consultation: </strong>Before any work begins, Homex Builders Corp conducts a comprehensive consultation to understand the client’s needs, preferences, and budget. This planning phase ensures that the remodeling process is smooth and aligned with the client’s vision.</li>
                                        <li><strong>Detailed Site Assessment: </strong>A detailed assessment of the existing bathroom is performed to identify any structural issues, plumbing concerns, or electrical challenges. This helps in addressing potential problems before they become major issues during the remodeling process.</li>
                                        <li><strong>Waterproofing and Moisture Control: </strong>Proper waterproofing is a critical precaution in bathroom remodeling. Homex Builders Corp uses high-quality waterproofing materials and techniques to prevent water damage, mold, and mildew, ensuring the longevity of the remodel.</li>
                                        <li><strong>Safety Measures: </strong>The company prioritizes safety by adhering to all building codes and regulations. They implement safety measures to protect both workers and homeowners during the remodeling process, including proper ventilation and dust control.</li>
                                        <li><strong>Accurate Budgeting and Cost Management: </strong>Homex Builders Corp provides detailed cost estimates and ensures that the project stays within budget. They carefully manage costs by selecting appropriate materials and avoiding unnecessary expenses, while still delivering high-quality results.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Advanced Design Tools: </strong>The company uses advanced design software to create 3D renderings and floor plans, allowing clients to visualize the final outcome before construction begins. This intelligent approach to design helps in making informed decisions and adjustments early on.</li>
                                        <li><strong>Material Expertise: </strong>Homex Builders Corp has deep knowledge of various materials, including tiles, fixtures, and finishes. They guide clients in selecting materials that offer the best combination of durability, aesthetics, and cost-effectiveness.</li>
                                        <li><strong>Energy Efficiency and Sustainability: </strong>The company incorporates energy-efficient and sustainable solutions into bathroom designs, such as low-flow toilets, energy-saving lighting, and eco-friendly materials. This intelligent approach reduces environmental impact and lowers utility costs.</li>
                                        <li><strong>Smart Technology Integration: </strong>Homex Builders Corp offers the option to integrate smart technology into the bathroom, such as digital shower controls, smart mirrors, and automated lighting. These features add convenience and modernize the space.</li>
                                        <li><strong>Problem-Solving Expertise: </strong>The company’s team is skilled at troubleshooting and resolving unexpected issues that may arise during the remodeling process, such as hidden plumbing problems or structural concerns. Their expertise ensures that challenges are addressed efficiently.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Custom Bathroom Design: </strong>Homex Builders Corp specializes in creating custom bathroom designs that are tailored to the client’s unique style and functional needs. Whether it’s a modern, minimalist bathroom or a luxurious spa-like retreat, they deliver personalized solutions.</li>
                                        <li><strong>Aging-in-Place Modifications: </strong>The company is experienced in designing bathrooms that accommodate aging-in-place needs, including walk-in tubs, grab bars, non-slip flooring, and accessible shower designs. These modifications enhance safety and comfort for elderly or mobility-impaired individuals.</li>
                                        <li><strong>High-End Luxury Bathrooms:</strong> Homex Builders Corp has a specialization in creating high-end luxury bathrooms with premium materials, sophisticated designs, and top-of-the-line fixtures. This includes features like heated floors, custom cabinetry, and frameless glass showers.</li>
                                        <li><strong>Small Space Optimization: </strong>The company excels in optimizing small bathroom spaces, making the most of limited square footage through intelligent design and space-saving solutions. This includes the use of compact fixtures, built-in storage, and clever layout adjustments.</li>
                                        <li><strong>Historic Bathroom Renovations: </strong>Homex Builders Corp is skilled in renovating bathrooms in historic homes, preserving the original character and charm while updating the space with modern amenities. They carefully select materials and designs that complement the historic architecture.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for your bathroom remodeling project means working with a reliable, experienced, and customer-focused company that is dedicated to bringing your vision to life with high-quality craftsmanship and personalized service.
                </p>
            </div>
        </>
    );
}
