import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SubHeader } from './SubHeader';
import OwlCarousel, { Options } from 'react-owl-carousel';
import { ServicesList } from './ServicesList';

export const Waterproofing = (props) => {

    return (
        <>
            <SubHeader title="Waterproofing" />
            <section className="featured-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-column col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title centered">
                                    <div className="title">Waterproofing Services</div>
                                    <h2><span className="theme_color">Your Trusted Waterproofing Solutions</span> Partner in New York</h2>
                                </div>
                                <div className="text justified">
                                    <p>Water damage can cause significant and costly problems for homeowners and business owners alike, making waterproofing an essential investment for your property. At Homex Builders Corp, we are proud to be your trusted waterproofing partner in New York, delivering reliable, long-term solutions that protect against water intrusion, leaks, and flooding.</p>
                                    <p>With a commitment to excellence, our team combines years of experience, cutting-edge technology, and high-quality materials to ensure that your home or business stays dry, secure, and free from water-related issues.</p>
                                    <p>Whether you’re in Manhattan, Brooklyn, Queens, or any of the surrounding areas, Homex Builders Corp is ready to provide expert waterproofing services that protect your property from water damage. We are proud to serve the entire New York metropolitan area, ensuring that homes and businesses throughout the city remain safe and dry.</p>
                                    <p>
                                        Don’t wait for water damage to become a problem. Homex Builders Corp is here to help you protect your property with expert waterproofing solutions. Whether you need a simple repair or a comprehensive waterproofing system, we’ve got the experience and resources to handle it all.
                                        Get in touch with us today for a free consultation and let’s start protecting your home or business from water damage!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServicesList serviceType="waterproofing-services" title="Waterproofing" />
        </>
    );
}
