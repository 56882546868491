import React, { Component, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import OwlCarousel, { Options } from 'react-owl-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons';
import mixitup from 'mixitup';
import { variables } from '../Variables';

import roofingSlider from "../assets/images/home/roofing-slider.png"
import waterproofingSlider from "../assets/images/home/waterproofing-slider.png"
import remodelingSlider from "../assets/images/home/remodeling-slider.png"
import sidewalkSlider from "../assets/images/home/sidewalk-slider.png"
import exteriorSlider from "../assets/images/home/exterior-slider.png"
import masonrySlider from "../assets/images/home/masonry-slider.png"
import residentialroofing from "../assets/images/home/residentialroofing.png"
import commercialroofing from "../assets/images/home/commercialroofing.png"
import roofmaintenance from "../assets/images/home/roofmaintenance.png"
import roofinspection from "../assets/images/home/roofinspection.png"
import roofrepairs from "../assets/images/home/roofrepairs.png"
import roofreplacement from "../assets/images/home/roofreplacement.png"
import roofinstallation from "../assets/images/home/roofinstallation.png"
import balconywaterproofing from "../assets/images/home/balconywaterproofing.png"
import deckwaterproofing from "../assets/images/home/deckwaterproofing.png"
import basementwaterproofing from "../assets/images/home/basementwaterproofing.png"
import foundationwaterproofing from "../assets/images/home/foundationwaterproofing.png"
import wallwaterproofing from "../assets/images/home/wallwaterproofing.png"
import exteriorwaterproofing from "../assets/images/home/exteriorwaterproofing.png"
import masonrywaterproofing from "../assets/images/home/masonrywaterproofing.png"
import bituminouswaterproofing from "../assets/images/home/bituminouswaterproofing.png"
import elastomericwaterproofing from "../assets/images/home/elastomericwaterproofing.png"
import windowcaulking from "../assets/images/home/windowcaulking.png"
import bathroomremodeling from "../assets/images/home/bathroomremodeling.png"
import kitchenremodeling from "../assets/images/home/kitchenremodeling.png"
import basementrenovation from "../assets/images/home/basementrenovation.png"
import sidewalkrepair from "../assets/images/home/sidewalkrepair.png"
import sidewalkreplacement from "../assets/images/home/sidewalkreplacement.png"
import sidewalkinstallation from "../assets/images/home/sidewalkinstallation.png"
import sidewalkcurbrepair from "../assets/images/home/sidewalkcurbrepair.png"
import sidewalkviolationremoval from "../assets/images/home/sidewalkviolationremoval.png"
import drivewayrepair from "../assets/images/home/drivewayrepair.png"
import brickpointing from "../assets/images/home/brickpointing.png"
import brickrestoration from "../assets/images/home/brickrestoration.png"
import brownstonerestoration from "../assets/images/home/brownstonerestoration.png"
import deckrestoration from "../assets/images/home/deckrestoration.png"
import waterdamagerestoration from "../assets/images/home/waterdamagerestoration.png"
import stonerestoration from "../assets/images/home/stonerestoration.png"
import woodrestoration from "../assets/images/home/woodrestoration.png"
import masonrycleaning from "../assets/images/home/masonrycleaning.png"
import facaderepair from "../assets/images/home/facaderepair.png"
import chimneyrebuilds from "../assets/images/home/chimneyrebuilds.png"
import expansionjoints from "../assets/images/home/expansionjoints.png"
import fireescapes from "../assets/images/home/fireescapes.png"
import patios from "../assets/images/home/patios.png"
import powerwashing from "../assets/images/home/powerwashing.png"
import retainingwalls from "../assets/images/home/retainingwalls.png"
import stairrepair from "../assets/images/home/stairrepair.png"
import locallaw11 from "../assets/images/home/locallaw11.png"
import testimonial from "../assets/images/home/testimonial.jpg"
import roofing_1 from "../assets/images/projects/roofing_1.jpg"
import roofing_2 from "../assets/images/projects/roofing_2.jpg"
import roofing_3 from "../assets/images/projects/roofing_3.jpg"
import sidewalk_1 from "../assets/images/projects/sidewalk_1.jpg"
import client1 from "../assets/images/client1.jpg"
import client2 from "../assets/images/client2.jpg";
import image1 from "../assets/images/image-1.jpg";
import patern_1 from "../assets/images/patern_1.png";
import bg_blue from "../assets/images/bg_blue.jpg";

export const Home = () => {
    const [projects, setProjects] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [containerEl, setContainerEl] = useState();
    const quatationFormRef = useRef(null);
    var mixitup = require('mixitup');
    var mixer;

    useEffect(() => {
        setContainerEl(document.querySelector('.gallery'))
        fetchProjects();
        fetchTestimonials();
    })

    const fetchProjects = async () => {

        let apiUrl = variables.API_URL + 'Projects/GetProjectsForWebsite';

        await axios.get(apiUrl) // ASP.NET Core API endpoint with header
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    };

    const fetchTestimonials = async () => {

        let apiUrl = variables.API_URL + 'Testimonials/GetTestimonialsForWebsite';

        await axios.get(apiUrl) // ASP.NET Core API endpoint with header
            .then(response => {
                setTestimonials(response.data);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    };

    useEffect(() => {
        if (containerEl) {
            mixer = mixitup(containerEl);
        }
    }, [containerEl])

    const options = {
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    }

    const buttonStyle = {
        width: "40px",
        height: "40px",
        background: 'none',
        border: '0px',
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };


    const properties = {
        prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: "15px" }} fill="#fff"><path d="M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z" /></svg></button>,
        nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: "15px" }} fill="#fff"><path d="M382.678 226.804 163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z" /></svg></button>
    }

    const scrollToQuatationForm = (e) => {
        quatationFormRef.current?.scrollIntoView({
            behavior: 'smooth'
        })
    }

    return (
        <div className="page-wrapper">
            <section className="elementor-section elementor-top-section elementor-element elementor-element-ed9f54f elementor-section-full_width elementor-section-height-default elementor-section-height-default">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4a07ad9">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-0a4d4f5 elementor-widget elementor-widget-slider_revolution">
                                <div className="elementor-widget-container">
                                    <div className="wp-block-themepunch-revslider">
                                        <Slide {...properties}>
                                            <div className="each-slide-effect">
                                                <div className="slider-container" style={{ backgroundImage: `url(${roofingSlider})` }}>
                                                    <h1 className="slider-heading">Roofing Services <br /> in NYC</h1>
                                                    <h6 className="slider-subheading">
                                                        New York City's diverse buildings require specialized roofing services, and Homex Builders Corp offer expertise in both residential and commercial roofing.
                                                    </h6>
                                                    <span>
                                                        <button className="btn-wd-border" onClick={scrollToQuatationForm}>Get a Quote</button>
                                                        <a href="/roofing-services" className="btn-wd-bg">Our Services</a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="each-slide-effect">
                                                <div className="slider-container" style={{ backgroundImage: `url(${waterproofingSlider})` }}>
                                                    <h1 className="slider-heading">We Provide best Waterproofing Solutions <br /> in New York City</h1>
                                                    <h6 className="slider-subheading">
                                                        Waterproofing protects buildings in New York City in weather conditions like heavy rain, snow, and humidity can lead to leaks and structural damage. We provide best waterproofing solutions in NYC.
                                                    </h6>
                                                    <span>
                                                        <button className="btn-wd-border" onClick={scrollToQuatationForm}>Get a Quote</button>
                                                        <a href="/waterproofing" className="btn-wd-bg">Our Services</a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="each-slide-effect">
                                                <div className="slider-container" style={{ backgroundImage: `url(${remodelingSlider})` }}>
                                                    <h1 className="slider-heading">Broad Range of <br /> Remodeling Solutions</h1>
                                                    <h6 className="slider-subheading">
                                                        Our Remodeling services aimed at enhancing the functionality and aesthetics of homes and commercial spaces. Whether you're updating a kitchen, expanding your living space, or doing a complete home renovation, We offer tailored remodeling solutions to meet various design preferences.
                                                    </h6>
                                                    <span>
                                                        <button className="btn-wd-border" onClick={scrollToQuatationForm}>Get a Quote</button>
                                                        <a href="/remodeling" className="btn-wd-bg">Our Services</a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="each-slide-effect">
                                                <div className="slider-container" style={{ backgroundImage: `url(${sidewalkSlider})` }}>
                                                    <h1 className="slider-heading">Experts in <br /> Sidewalk Services</h1>
                                                    <h6 className="slider-subheading">
                                                        We provide sidewalk repair, replacement, and new installations for both Residential and Commercial sidewalks. We handle concrete work, curb installation, and addressing DOT violations.
                                                    </h6>
                                                    <span>
                                                        <button className="btn-wd-border" onClick={scrollToQuatationForm}>Get a Quote</button>
                                                        <a href="/sidewalk" className="btn-wd-bg">Our Services</a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="each-slide-effect">
                                                <div className="slider-container" style={{ backgroundImage: `url(${exteriorSlider})` }}>
                                                    <h1 className="slider-heading">Comprehensive Exterior Restoration Services</h1>
                                                    <h6 className="slider-subheading">
                                                        Exterior restoration services are crucial for maintaining the integrity, safety, and aesthetic appeal of buildings, particularly in a city like New York. Our experts ensures compliance with city regulations, safety standards, and aesthetic goals.
                                                    </h6>
                                                    <span>
                                                        <button className="btn-wd-border" onClick={scrollToQuatationForm}>Get a Quote</button>
                                                        <a href="/restoration" className="btn-wd-bg">Our Services</a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="each-slide-effect">
                                                <div className="slider-container" style={{ backgroundImage: `url(${masonrySlider})` }}>
                                                    <h1 className="slider-heading">Affordable Masonry Services in NYC</h1>
                                                    <h6 className="slider-subheading">
                                                        Homex Builders corp specializes in high-quality craftsmanship, ensuring that our masonry work is both durable and visually appealing. Whether you're looking for brickwork repair, stonework installation, or complete facade restoration, Homex can handle various masonry needs.
                                                    </h6>
                                                    <span>
                                                        <button className="btn-wd-border" onClick={scrollToQuatationForm}>Get a Quote</button>
                                                        <a href="/restoration" className="btn-wd-bg">Our Services</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">
                <div className="auto-container">
                    <div className="sec-title">
                        <div className="title">We Build Everything</div>
                        <h2><span className="theme_color">Our</span> Services</h2>
                    </div>
                    <OwlCarousel className='owl-theme' {...options}>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Commercial Roofing">
                                            <img width="370" height="240" src={commercialroofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${commercialroofing} 370w, ${commercialroofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Commercial Roofing">
                                                    Commercial Roofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Commercial roofing involves the installation, repair, and maintenance of roofs on business properties, such as office buildings, warehouses, and retail spaces. Unlike residential roofing, commercial roofing systems often cover larger surface areas and require specialized materials to accommodate the unique needs of businesses.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Residential Roofing">
                                            <img width="370" height="240" src={residentialroofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${residentialroofing} 370w, ${residentialroofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Residential Roofing">
                                                    Residential Roofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Residential roofing involves the installation, repair, and maintenance of roofs on homes. It plays a crucial role in protecting your house from the elements, enhancing curb appeal, and ensuring energy efficiency. Understanding the various types of residential roofing materials and their benefits can help you make an informed decision for your home.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Roof Maintenance">
                                            <img width="370" height="240" src={roofmaintenance} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${roofmaintenance} 370w, ${roofmaintenance} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Roof Maintenance">
                                                    Roof Maintenance
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Residential roofing involves the installation, repair, and maintenance of roofs on homes. It plays a crucial role in protecting your house from the elements, enhancing curb appeal, and ensuring energy efficiency. Understanding the various types of residential roofing materials and their benefits can help you make an informed decision for your home.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Roof Inspection">
                                            <img width="370" height="240" src={roofinspection} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${roofinspection} 370w, ${roofinspection} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Roof Inspection">
                                                    Roof Inspection
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Roof inspections in New York City (NYC) are particularly important due to the city's unique climate, building codes, and the specific challenges that urban environments present. Regular inspections help identify potential problems early, ensuring that your roof can withstand the harsh weather conditions and maintain its integrity over time.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Roof Repairs">
                                            <img width="370" height="240" src={roofrepairs} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${roofrepairs} 370w, ${roofrepairs} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Roof Repairs">
                                                    Roof Repairs
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Roof repairs in New York City (NYC) are essential for maintaining the integrity and longevity of your building, whether it's a residential property, a commercial structure, or a mixed-use building. NYC's unique climate, building styles, and local regulations require specialized knowledge and expertise when it comes to roof repairs.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Roof Replacement">
                                            <img width="370" height="240" src={roofreplacement} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${roofreplacement} 370w, ${roofreplacement} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Roof Replacement">
                                                    Roof Replacement
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Roof replacement in New York City (NYC) is a significant project that requires careful planning, expert knowledge, and adherence to local building codes. Whether you're dealing with an aging roof, severe damage, or simply upgrading your property, replacing a roof in NYC involves unique considerations due to the city's climate, architecture, and regulations.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Roof Installation">
                                            <img width="370" height="240" src={roofinstallation} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${roofinstallation} 370w, ${roofinstallation} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Roof Installation">
                                                    Roof Installation
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Roof replacement in New York City (NYC) is a significant project that requires careful planning, expert knowledge, and adherence to local building codes. Whether you're dealing with an aging roof, severe damage, or simply upgrading your property, replacing a roof in NYC involves unique considerations due to the city's climate, architecture, and regulations.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Balcony Waterproofing">
                                            <img width="370" height="240" src={balconywaterproofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${balconywaterproofing} 370w, ${balconywaterproofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Balcony Waterproofing">
                                                    Balcony Waterproofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Balcony waterproofing in New York City (NYC) is a crucial aspect of building maintenance and safety, especially given the city's climate and the importance of adhering to strict building codes. Proper waterproofing ensures that balconies are protected from water damage, which can lead to structural issues, leaks, and costly repairs if not addressed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Deck Waterproofing">
                                            <img width="370" height="240" src={deckwaterproofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${deckwaterproofing} 370w, ${deckwaterproofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Deck Waterproofing">
                                                    Deck Waterproofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Waterproofing a deck in NYC is essential to protect it from the harsh weather conditions, including rain, snow, and humidity. Proper waterproofing can help extend the lifespan of the deck, prevent damage, and maintain its aesthetic appeal.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Basement Waterproofing">
                                            <img width="370" height="240" src={basementwaterproofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${basementwaterproofing} 370w, ${basementwaterproofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Basement Waterproofing">
                                                    Basement Waterproofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Basement waterproofing in NYC is crucial due to the city's varying weather conditions and the potential for flooding, especially in low-lying areas. Proper basement waterproofing helps protect the structure from water damage, mold, mildew, and foundational issues.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Foundation Waterproofing">
                                            <img width="370" height="240" src={foundationwaterproofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${foundationwaterproofing} 370w, ${foundationwaterproofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Foundation Waterproofing">
                                                    Foundation Waterproofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Foundation waterproofing is a critical process in NYC due to the city's diverse weather conditions, high rainfall, and the risk of flooding. Protecting your foundation from water intrusion is essential to maintain the structural integrity of your building and prevent costly damage.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Wall Waterproofing">
                                            <img width="370" height="240" src={wallwaterproofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${wallwaterproofing} 370w, ${wallwaterproofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Wall Waterproofing">
                                                    Wall Waterproofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Wall waterproofing in NYC is crucial to protect buildings from the city's varying weather conditions, including heavy rain, snow, and humidity. Proper wall waterproofing helps prevent water penetration, which can lead to structural damage, mold growth, and a decrease in indoor air quality.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Exterior Waterproofing">
                                            <img width="370" height="240" src={exteriorwaterproofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${exteriorwaterproofing} 370w, ${exteriorwaterproofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Exterior Waterproofing">
                                                    Exterior Waterproofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Exterior waterproofing is a crucial process for protecting buildings in NYC from the city's varied climate, including heavy rainfall, snow, and potential flooding. It involves creating a barrier on the outside of a building to prevent water from penetrating walls, foundations, and basements. This method is particularly effective for long-term protection against water damage and is essential for maintaining the structural integrity of a building.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Masonry Waterproofing">
                                            <img width="370" height="240" src={masonrywaterproofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${masonrywaterproofing} 370w, ${masonrywaterproofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Masonry Waterproofing">
                                                    Masonry Waterproofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Masonry waterproofing is essential for protecting brick, stone, and concrete structures from water infiltration, which can lead to structural damage, efflorescence, mold growth, and deterioration of the masonry materials. In a city like NYC, where buildings are frequently exposed to harsh weather conditions, masonry waterproofing is critical for maintaining the integrity and appearance of both historic and modern structures.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Bituminous Waterproofing">
                                            <img width="370" height="240" src={bituminouswaterproofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${bituminouswaterproofing} 370w, ${bituminouswaterproofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Bituminous Waterproofing">
                                                    Bituminous Waterproofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Bituminous waterproofing is a popular method for protecting buildings, especially roofs and foundations, from water infiltration. This technique uses bitumen-based materials, which are highly effective at creating a waterproof barrier due to their durability, flexibility, and resistance to water. In NYC, where buildings are exposed to varied weather conditions, bituminous waterproofing is commonly used for both commercial and residential properties.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Elastomeric Waterproofing">
                                            <img width="370" height="240" src={elastomericwaterproofing} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${elastomericwaterproofing} 370w, ${elastomericwaterproofing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Elastomeric Waterproofing">
                                                    Elastomeric Waterproofing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Elastomeric waterproofing is a highly effective method for protecting surfaces from water penetration. It involves the application of a flexible, rubber-like coating that can expand and contract with the building's movements, providing a durable and seamless barrier against moisture. In a city like NYC, where buildings are exposed to a range of weather conditions, elastomeric waterproofing is commonly used for both exterior and interior surfaces, including roofs, walls, and foundations.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Window Caulking">
                                            <img width="370" height="240" src={windowcaulking} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${windowcaulking} 370w, ${windowcaulking} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Window Caulking">
                                                    Window Caulking
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Window caulking is an essential part of home maintenance and waterproofing that involves sealing gaps and joints around windows to prevent water infiltration, air leaks, and energy loss. Proper caulking helps protect a building from the elements, improves energy efficiency, and enhances comfort by keeping drafts, moisture, and pests out. In NYC, where buildings are exposed to varying weather conditions, window caulking is crucial for both residential and commercial properties.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Bathroom Remodeling">
                                            <img width="370" height="240" src={bathroomremodeling} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${bathroomremodeling} 370w, ${bathroomremodeling} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/WindowBathroom Remodeling">
                                                    Bathroom Remodeling
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Bathroom remodeling is a popular home improvement project that can enhance both the functionality and aesthetics of a space. Whether you're looking to update outdated fixtures, improve storage, or create a more luxurious atmosphere, a well-planned bathroom remodel can add value to your home and improve your daily routine. In NYC, where space is often limited, bathroom remodeling requires careful planning and design to make the most of the available area.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Kitchen Remodeling">
                                            <img width="370" height="240" src={kitchenremodeling} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${kitchenremodeling} 370w, ${kitchenremodeling} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Kitchen Remodeling">
                                                    Kitchen Remodeling
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Kitchen remodeling is a transformative project that can greatly enhance both the functionality and aesthetics of one of the most frequently used spaces in a home. Whether you want to modernize your kitchen, increase storage, or improve workflow, a well-planned kitchen remodel can add significant value to your home and improve your cooking and dining experience.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Basement Renovation">
                                            <img width="370" height="240" src={basementrenovation} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${basementrenovation} 370w, ${basementrenovation} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Basement Renovation">
                                                    Basement Renovation
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Basement renovation is a great way to add valuable living space to your home, whether you're looking to create a new family room, home office, rental unit, or recreational area. Renovating a basement can increase the overall square footage of your home and enhance its functionality.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/NYC Sidewalk Repair">
                                            <img width="370" height="240" src={sidewalkrepair} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${sidewalkrepair} 370w, ${sidewalkrepair} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/NYC Sidewalk Repair">
                                                    NYC Sidewalk Repair
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Sidewalk repair in NYC is crucial for maintaining safety, accessibility, and aesthetic appeal in urban environments. Sidewalks in the city are subject to wear and tear from foot traffic, weather conditions, and tree roots, which can cause damage such as cracks, uneven surfaces, or uplifted sections. Addressing these issues promptly can prevent accidents and preserve the integrity of public spaces.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Sidewalk Replacement">
                                            <img width="370" height="240" src={sidewalkreplacement} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${sidewalkreplacement} 370w, ${sidewalkreplacement} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Sidewalk Replacement">
                                                    Sidewalk Replacement
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Sidewalk replacement in NYC involves removing and installing new sidewalk sections to address severe damage or wear that cannot be effectively repaired. This process is essential for ensuring safety, accessibility, and compliance with local regulations.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Sidewalk Installation">
                                            <img width="370" height="240" src={sidewalkinstallation} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${sidewalkinstallation} 370w, ${sidewalkinstallation} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Sidewalk Installation">
                                                    Sidewalk Installation
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Sidewalk installation in NYC involves creating new sidewalk sections where none exist or replacing existing ones to enhance functionality and aesthetics. Proper installation ensures safety, durability, and compliance with local regulations.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Sidewalk Curb Repair">
                                            <img width="370" height="240" src={sidewalkcurbrepair} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${sidewalkcurbrepair} 370w, ${sidewalkcurbrepair} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Sidewalk Curb Repair">
                                                    Sidewalk Curb Repair
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Sidewalk curb repair involves addressing damage to the curbs that define and separate sidewalks from streets or other areas. Curbs are crucial for directing water flow, providing a barrier between vehicles and pedestrians, and defining property lines. Proper repair is essential to maintain safety and functionality.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Sidewalk Violation Removal">
                                            <img width="370" height="240" src={sidewalkviolationremoval} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${sidewalkviolationremoval} 370w, ${sidewalkviolationremoval} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Sidewalk Violation Removal">
                                                    Sidewalk Violation Removal
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Removing a sidewalk violation in NYC involves addressing issues identified by city authorities that require corrective action. Violations often arise due to damage, non-compliance with regulations, or failure to maintain sidewalks properly.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Driveway Repair">
                                            <img width="370" height="240" src={drivewayrepair} className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt="" srcSet={`${drivewayrepair} 370w, ${drivewayrepair} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Driveway Repair">
                                                    Driveway Repair
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Driveway repair in NYC is the process of addressing damage or deterioration in driveways to ensure they remain functional, safe, and visually appealing. In NYC, driveways can experience wear and tear due to factors like weather conditions, heavy vehicle traffic, and natural aging of materials. Proper repair helps maintain property value, safety, and overall functionality.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Brick Pointing">
                                            <img width="370" height="240" src={brickpointing}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${brickpointing} 370w, ${brickpointing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Brick Pointing">
                                                    Brick Pointing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Brick pointing is a masonry technique used to repair and restore the mortar joints between bricks in a wall or structure. Over time, the mortar between bricks can deteriorate due to weathering, moisture, or other factors, leading to gaps and weakened structural integrity. Brick pointing, also known as repointing, involves removing the old, damaged mortar and replacing it with new mortar to maintain the wall's stability, appearance, and longevity.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Brick Restoration">
                                            <img width="370" height="240" src={brickrestoration}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${brickrestoration} 370w, ${brickrestoration} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Brick Restoration">
                                                    Brick Restoration
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Brick restoration is the process of repairing, refurbishing, and preserving brick structures to restore their original appearance, functionality, and structural integrity. It involves a variety of techniques and methods to address issues such as damage, deterioration, and wear over time. This process is essential for maintaining the longevity and aesthetic value of brick buildings, especially those with historical or architectural significance.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Brownstone Restoration">
                                            <img width="370" height="240" src={brownstonerestoration}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${brownstonerestoration} 370w, ${brownstonerestoration} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Brownstone Restoration">
                                                    Brownstone Restoration
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Brownstone restoration involves the process of repairing, preserving, and refurbishing brownstone buildings, which are characterized by their distinctive brown sandstone facade. Brownstones are often found in historic neighborhoods and are known for their architectural significance and unique aesthetic appeal. Restoration aims to maintain and enhance the appearance, structural integrity, and value of these historic properties.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Deck Restoration">
                                            <img width="370" height="240" src={deckrestoration}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${deckrestoration} 370w, ${deckrestoration} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Deck Restoration">
                                                    Deck Restoration
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Deck restoration involves the process of repairing, refurbishing, and maintaining a deck to restore its original appearance and functionality. This can include tasks such as cleaning, sanding, sealing, and repairing damaged areas. Deck restoration is essential for prolonging the life of the deck, enhancing its aesthetic appeal, and ensuring its safety and performance.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Water Damage Restoration">
                                            <img width="370" height="240" src={waterdamagerestoration}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${waterdamagerestoration} 370w, ${waterdamagerestoration} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Water Damage Restoration">
                                                    Water Damage Restoration
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Water damage restoration is the process of repairing and restoring a property that has been affected by water damage. This can result from various sources, including flooding, leaks, burst pipes, or high humidity. The goal of water damage restoration is to remove excess water, dry out the affected areas, repair any damage, and prevent further issues such as mold growth or structural damage.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Stone Restoration">
                                            <img width="370" height="240" src={stonerestoration}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${stonerestoration} 370w, ${stonerestoration} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Stone Restoration">
                                                    Stone Restoration
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Stone restoration is the process of repairing, preserving, and enhancing stone surfaces to restore their original appearance and functionality. This process can involve various techniques tailored to different types of stone, such as marble, granite, limestone, or sandstone. Stone restoration is essential for maintaining the beauty and structural integrity of stone surfaces, especially in historic or high-value properties.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Wood Restoration">
                                            <img width="370" height="240" src={woodrestoration}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${woodrestoration} 370w, ${woodrestoration} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Wood Restoration">
                                                    Wood Restoration
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Wood restoration is the process of repairing, rejuvenating, and preserving wooden surfaces or structures to restore their original appearance and functionality. This process is essential for maintaining the beauty and structural integrity of wood, whether it's in furniture, flooring, cabinetry, or architectural elements.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Masonry Cleaning">
                                            <img width="370" height="240" src={masonrycleaning}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${masonrycleaning} 370w, ${masonrycleaning} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Masonry Cleaning">
                                                    Masonry Cleaning
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Masonry cleaning involves the process of removing dirt, grime, stains, and other contaminants from masonry surfaces, such as brick, stone, concrete, or stucco. This process is essential for maintaining the aesthetic appeal, structural integrity, and longevity of masonry structures.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Local Law 11">
                                            <img width="370" height="240" src={locallaw11}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${locallaw11} 370w, ${locallaw11} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Local Law 11">
                                                    Local Law 11
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Local Law 11 is a regulation specific to New York City that requires building owners to maintain and inspect the exterior walls and appurtenances of their buildings. It was enacted to ensure the safety of pedestrians and occupants by addressing potential hazards related to building facades.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Facade Repair">
                                            <img width="370" height="240" src={facaderepair}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${facaderepair} 370w, ${facaderepair} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Facade Repair">
                                                    Facade Repair
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Facade repair involves the process of fixing and restoring the exterior surface of a building. The facade is the front-facing part of a building, and it plays a critical role in both aesthetics and structural integrity. Facade repair addresses issues related to the condition of the facade, such as damage, deterioration, or wear.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Chimney Rebuilds">
                                            <img width="370" height="240" src={chimneyrebuilds}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${chimneyrebuilds} 370w, ${chimneyrebuilds} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Chimney Rebuilds">
                                                    Chimney Rebuilds
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Chimney rebuilds involve the complete reconstruction of a chimney that has deteriorated beyond repair or that no longer meets safety and structural standards. This process typically includes rebuilding the chimney from the ground up, addressing all components such as the stack, flue, and surrounding masonry.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Expansion Joints">
                                            <img width="370" height="240" src={expansionjoints}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${expansionjoints} 370w, ${expansionjoints} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Expansion Joints">
                                                    Expansion Joints
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Expansion joints are critical structural components designed to accommodate the movement of building materials due to thermal expansion, contraction, or other forces. They are used in various types of construction, including bridges, buildings, roads, and pipelines, to allow for the natural movement and prevent damage to structures.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Fire Escapes">
                                            <img width="370" height="240" src={fireescapes}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${fireescapes} 370w, ${fireescapes} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Fire Escapes">
                                                    Fire Escapes
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Fire escapes are external structures designed to provide a safe means of escape in the event of a fire or other emergencies. In New York City, they are a critical component of building safety and fire prevention.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Patios">
                                            <img width="370" height="240" src={patios}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${patios} 370w, ${patios} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Patios">
                                                    Patios
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Patios are outdoor areas adjacent to a building, typically paved and designed for recreational use, relaxation, or entertaining. In New York City, patios can be found in residential homes, commercial properties, and public spaces. They play a significant role in enhancing the quality of outdoor living and providing functional spaces for various activities.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Power Washing">
                                            <img width="370" height="240" src={powerwashing}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${powerwashing} 370w, ${powerwashing} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Power Washing">
                                                    Power Washing
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Power washing, also known as pressure washing, is a cleaning method that uses a high-pressure stream of water to remove dirt, grime, mold, mildew, and other contaminants from surfaces. This technique is effective for cleaning various surfaces and is commonly used in both residential and commercial settings.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Retaining Walls">
                                            <img width="370" height="240" src={retainingwalls}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${retainingwalls} 370w, ${retainingwalls} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Retaining Walls">
                                                    Retaining Walls
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Retaining walls are structures designed to hold back soil, rock, or other materials and prevent erosion or collapse on sloped or uneven terrain. They are used in various settings, including residential, commercial, and public projects, to manage changes in elevation and improve land use.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="services-block">
                                <div className="inner-box">
                                    <div className="image">
                                        <a href="/service-detail/Stair Repair & Building">
                                            <img width="370" height="240" src={stairrepair}
                                                className="attachment-konstructo_370x240 size-konstructo_370x240 wp-post-image" alt=""
                                                srcSet={`${stairrepair} 370w, ${stairrepair} 300w`} sizes="(max-width: 370px) 100vw, 370px" />
                                        </a>
                                    </div>
                                    <div className="lower-content">
                                        <div className="upper-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-drawing"></span>
                                            </div>
                                            <h3>
                                                <a href="/service-detail/Stair Repair & Building">
                                                    Stair Repair & Building
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="text">
                                            Stair repair and building involves the maintenance, restoration, or construction of staircases in residential, commercial, and public buildings. Stairs are crucial structural elements that provide access between different levels of a building, and their repair and construction are essential for safety, functionality, and aesthetic purposes.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
            <section className="fluid-section-one">
                <div className="outer-container clearfix">
                    <div className="image-column" style={{ backgroundImage: `url(${image1})` }} >
                        <figure className="image-box"><img src={image1} alt="Why Choose Us" /></figure>
                    </div>
                    <div className="content-column">
                        <div className="inner-box">
                            <div className="sec-title">
                                <div className="title">We Offer Best Services &amp; Solutions</div>
                                <h2><span className="theme_color">Why </span> Choose Us</h2>
                            </div>
                            <div className="text">
                                At Homex Builders Corp, we understand that choosing the right construction partner is crucial for your project's success. Here's why we stand out in the crowded New York City market:
                            </div>
                            <ul className="list-style-one clearfix">
                                <li className="col-md-6 col-sm-6 col-xs-12"><span className="icon flaticon-briefcase-1"></span>EXPERT &amp; PROFESSIONAL</li>
                                <li className="col-md-6 col-sm-6 col-xs-12"><span className="icon flaticon-diamond-1"></span>PROFESSIONAL APPROACH</li>
                                <li className="col-md-6 col-sm-6 col-xs-12"><span className="icon flaticon-bank-building"></span>HIGHT QUALITY WORK</li>
                                <li className="col-md-6 col-sm-6 col-xs-12"><span className="icon flaticon-two-fingers-up"></span>SATISFACTION GUARANTEE</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="elementor-section elementor-top-section elementor-element elementor-element-1cc30b1 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="1cc30b1" data-element_type="section">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c10d544" data-id="c10d544" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-9adffc8 elementor-widget elementor-widget-konstructo_our_recent_projects" data-id="9adffc8" data-element_type="widget" data-widget_type="konstructo_our_recent_projects.default">
                                <div className="elementor-widget-container">
                                    <section className="project-section">
                                        <div className="auto-container">
                                            <div className="sec-title centered">
                                                <div className="title">Our Best Works</div>
                                                <h2><span className="theme_color">Our</span> Recent Projects</h2>
                                            </div>
                                            <div className="filters controls">
                                                <button type="button" className="control" data-filter="all">All</button>
                                                <button type="button" className="control" data-filter=".roofing">Roofing</button>
                                                <button type="button" className="control" data-filter=".waterproofing">Waterproofing</button>
                                                <button type="button" className="control" data-filter=".remodeling">Remodeling</button>
                                                <button type="button" className="control" data-filter=".sidewalk">Sidewalk</button>
                                                <button type="button" className="control" data-filter=".restoration">Restoration</button>
                                                <button type="button" className="control" data-filter=".masonry">Masonry</button>
                                            </div>

                                            <div className="gallery">
                                                {
                                                    projects.length > 0 ? projects.map((item, i) => {
                                                        if (i < 15) {
                                                            let projectType = item.projectType.toLowerCase();
                                                            let images = item.projectImages;
                                                            let splittedPath = images.split(',');
                                                            let img = variables.projectImgPath + splittedPath[0];
                                                            return (
                                                                <div className={`col-4 p-0 mix ${projectType}`}>
                                                                    <div className="gallery-item" >
                                                                        <div className="inner-box">
                                                                            <figure className="image-box">
                                                                                <img fetchpriority="high" decoding="async" width="370" height="270" src={img} className="attachment-konstructo_370x270 size-konstructo_370x270 wp-post-image" alt="" />
                                                                                <div className="overlay-box">
                                                                                    <div className="overlay-inner">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <a href="/projects" className="image-link">
                                                                                                    <FontAwesomeIcon icon={faLink} className="icon" />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href={img} data-fancybox="images" data-caption="" className="link">
                                                                                                    <span className="icon flaticon-picture-gallery"></span>
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                        <div className="content">
                                                                                            <h3><a href="/projects">{item.projectName}</a></h3>
                                                                                            <div className="category">{item.projectType}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </figure>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                        :
                                                        <>No Project Exist</>
                                                }

                                                {/*<div className="col-4 p-0 mix roofing">*/}
                                                {/*    <div className="gallery-item" >*/}
                                                {/*        <div className="inner-box">*/}
                                                {/*            <figure className="image-box">*/}
                                                {/*                <img fetchpriority="high" decoding="async" width="370" height="270" src={roofing_1} className="attachment-konstructo_370x270 size-konstructo_370x270 wp-post-image" alt="" />*/}
                                                {/*                <div className="overlay-box">*/}
                                                {/*                    <div className="overlay-inner">*/}
                                                {/*                        <ul>*/}
                                                {/*                            <li>*/}
                                                {/*                                <a href="/projects" className="image-link">*/}
                                                {/*                                    <FontAwesomeIcon icon={faLink} className="icon" />*/}
                                                {/*                                </a>*/}
                                                {/*                            </li>*/}
                                                {/*                            <li>*/}
                                                {/*                                <a href={roofing_1} data-fancybox="images" data-caption="" className="link">*/}
                                                {/*                                    <span className="icon flaticon-picture-gallery"></span>*/}
                                                {/*                                </a>*/}
                                                {/*                            </li>*/}
                                                {/*                        </ul>*/}
                                                {/*                        <div className="content">*/}
                                                {/*                            <h3><a href="/projects">Shingles Repalcement</a></h3>*/}
                                                {/*                            <div className="category">Roofing</div>*/}
                                                {/*                        </div>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*            </figure>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="col-4 p-0 mix roofing">*/}
                                                {/*    <div className="gallery-item" >*/}
                                                {/*        <div className="inner-box">*/}
                                                {/*            <figure className="image-box">*/}
                                                {/*                <img fetchpriority="high" decoding="async" width="370" height="270" src={roofing_2} className="attachment-konstructo_370x270 size-konstructo_370x270 wp-post-image" alt="" />*/}
                                                {/*                <div className="overlay-box">*/}
                                                {/*                    <div className="overlay-inner">*/}
                                                {/*                        <ul>*/}
                                                {/*                            <li>*/}
                                                {/*                                <a href="/projects" className="image-link">*/}
                                                {/*                                    <FontAwesomeIcon icon={faLink} className="icon" />*/}
                                                {/*                                </a>*/}
                                                {/*                            </li>*/}
                                                {/*                            <li>*/}
                                                {/*                                <a href={roofing_2} data-fancybox="images" data-caption="" className="link">*/}
                                                {/*                                    <span className="icon flaticon-picture-gallery"></span>*/}
                                                {/*                                </a>*/}
                                                {/*                            </li>*/}
                                                {/*                        </ul>*/}
                                                {/*                        <div className="content">*/}
                                                {/*                            <h3><a href="/projects">ALuminium Coating</a></h3>*/}
                                                {/*                            <div className="category">Roofing</div>*/}
                                                {/*                        </div>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*            </figure>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="col-4 p-0 mix sidewalk">*/}
                                                {/*    <div className="gallery-item" style={{ display: "inline-block" }}>*/}
                                                {/*        <div className="inner-box">*/}
                                                {/*            <figure className="image-box">*/}
                                                {/*                <img decoding="async" width="370" height="270" src={sidewalk_1} className="attachment-konstructo_370x270 size-konstructo_370x270 wp-post-image" alt="" />*/}
                                                {/*                <div className="overlay-box">*/}
                                                {/*                    <div className="overlay-inner">*/}
                                                {/*                        <ul>*/}
                                                {/*                            <li>*/}
                                                {/*                                <a href="/projects" className="image-link">*/}
                                                {/*                                    <FontAwesomeIcon icon={faLink} className="icon" />*/}
                                                {/*                                </a>*/}
                                                {/*                            </li>*/}
                                                {/*                            <li>*/}
                                                {/*                                <a href={sidewalk_1} data-fancybox="images" data-caption="" className="link">*/}
                                                {/*                                    <span className="icon flaticon-picture-gallery"></span>*/}
                                                {/*                                </a>*/}
                                                {/*                            </li>*/}
                                                {/*                        </ul>*/}
                                                {/*                        <div className="content">*/}
                                                {/*                            <h3><a href="/projects">Driveway Replacement</a></h3>*/}
                                                {/*                            <div className="category">Sidewalk</div>*/}
                                                {/*                        </div>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*            </figure>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="col-4 p-0 mix roofing">*/}
                                                {/*    <div className="gallery-item" style={{ display: "inline-block" }}>*/}
                                                {/*        <div className="inner-box">*/}
                                                {/*            <figure className="image-box">*/}
                                                {/*                <img decoding="async" width="370" height="270" src={roofing_3} className="attachment-konstructo_370x270 size-konstructo_370x270 wp-post-image" alt="" />*/}
                                                {/*                <div className="overlay-box">*/}
                                                {/*                    <div className="overlay-inner">*/}
                                                {/*                        <ul>*/}
                                                {/*                            <li>*/}
                                                {/*                                <a href="/projects" className="image-link">*/}
                                                {/*                                    <FontAwesomeIcon icon={faLink} className="icon" />*/}
                                                {/*                                </a>*/}
                                                {/*                            </li>*/}
                                                {/*                            <li>*/}
                                                {/*                                <a href={roofing_3} data-fancybox="images" data-caption="" className="link">*/}
                                                {/*                                    <span className="icon flaticon-picture-gallery"></span>*/}
                                                {/*                                </a>*/}
                                                {/*                            </li>*/}
                                                {/*                        </ul>*/}
                                                {/*                        <div className="content">*/}
                                                {/*                            <h3><a href="/projects">Flashing Aluminium Coating</a></h3>*/}
                                                {/*                            <div className="category">Roofing</div>*/}
                                                {/*                        </div>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*            </figure>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonial-section" style={{ backgroundImage: `url(${patern_1})` }} >
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="testimonial-img image-column col-md-5 col-sm-5 col-xs-12">
                            <div className="image">
                                <img decoding="async" src={testimonial} alt="Awesome Image" />
                            </div>
                        </div>
                        <div className="carousel-column col-md-7 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <div className="title">Testimonial</div>
                                    <h2><span className="theme_color">Clients </span> Say</h2>
                                </div>
                                <OwlCarousel className='owl-theme' loop margin={10} nav items={1}>
                                    {
                                        testimonials.length > 0 ? testimonials.map(item => {
                                            let images = item.clientImage;
                                            let splittedPath = images.split(',');
                                            let img = variables.projectImgPath + splittedPath[0];
                                            return (
                                                <div className='item'>
                                                    <div className="testimonial-block">
                                                        <div className="inner-box">
                                                            <div className="text">
                                                                {item.text}
                                                            </div>
                                                            <div className="author-info">
                                                                <div className="author-inner">
                                                                    <div className="image">
                                                                        <img loading="lazy" decoding="async" width="80" height="80" src={img} className="attachment-konstructo_80x80 size-konstructo_80x80 wp-post-image" alt="" />
                                                                    </div>
                                                                    <h3>{item.clientName}</h3>
                                                                    <div className="designation">{item.projectName}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                        })
                                            :
                                        <></>
                                    }
                                    {/*<div className='item'>*/}
                                    {/*    <div className="testimonial-block">*/}
                                    {/*        <div className="inner-box">*/}
                                    {/*            <div className="text">*/}
                                    {/*                Working with Homex Builders Corp has been a game-changer for our project in New York City. From day one, they were professional, responsive, and fully committed to our vision. We had a complex renovation on a tight timeline, and they managed every aspect with precision, ensuring everything stayed on schedule and within budget. I highly recommend Homex Builders Corp.*/}
                                    {/*            </div>*/}
                                    {/*            <div className="author-info">*/}
                                    {/*                <div className="author-inner">*/}
                                    {/*                    <div className="image">*/}
                                    {/*                        <img loading="lazy" decoding="async" width="80" height="80" src={client1} className="attachment-konstructo_80x80 size-konstructo_80x80 wp-post-image" alt="" />*/}
                                    {/*                    </div>*/}
                                    {/*                    <h3>Emily Rodriguez</h3>*/}
                                    {/*                    <div className="designation">Brownstone Renovation, Bornx</div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className='item'>*/}
                                    {/*    <div className="testimonial-block">*/}
                                    {/*        <div className="inner-box">*/}
                                    {/*            <div className="text">*/}
                                    {/*                We hired Homex Builders Corp for a complete renovation of our Manhattan apartment, and we couldn't be more pleased with the outcome.What impressed us the most was their commitment to quality and timeliness. In a city where delays and complications are common, Homex Builders delivered on time and within budget, without cutting any corners. We're thrilled with our new space and would highly recommend Homex Builders Corp.*/}
                                    {/*            </div>*/}
                                    {/*            <div className="author-info">*/}
                                    {/*                <div className="author-inner">*/}
                                    {/*                    <div className="image">*/}
                                    {/*                        <img loading="lazy" decoding="async" width="80" height="80" src={client2} className="attachment-konstructo_80x80 size-konstructo_80x80 wp-post-image" alt="" />*/}
                                    {/*                    </div>*/}
                                    {/*                    <h3>Michael Steinberg</h3>*/}
                                    {/*                    <div className="designation">Apartment Renovation, Manhattan</div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="elementor-section elementor-top-section elementor-element elementor-element-b9f6777 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="b9f6777" data-element_type="section" ref={quatationFormRef}>
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6ce9684" data-id="6ce9684" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-49957f3 elementor-widget elementor-widget-konstructo_call_to_action" data-id="49957f3" data-element_type="widget" data-widget_type="konstructo_call_to_action.default">
                                <div className="elementor-widget-container">
                                    <section className="call-to-action-section" style={{ backgroundImage: `url(${bg_blue})` }} >
                                        <div className="auto-container">
                                            <div className="row clearfix">
                                                <div className="text-column col-md-9 col-sm-12 col-xs-12">
                                                    <div className="text">We provide experience &amp; <span className="theme_color">high level construction</span> worksolution for you!!</div>
                                                </div>

                                                <div className="btn-column col-md-3 col-sm-12 col-xs-12">
                                                    <a href="/contact-us" className="theme-btn btn-style-three">Start A Project</a>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
