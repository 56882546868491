import React, { useState } from 'react';
import axios from 'axios';
import { variables } from '../Variables';

export const AppointmentForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        service: "",
        appointmentDateTime: "",
        message: ""
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const validate = () => {
        let formErrors = {};
        if (!formData.name.trim()) {
            formErrors.name = "Name is required";
        }
        if (!formData.phone.trim()) {
            formErrors.phone = "Phone number is required";
        }

        if (!formData.email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
        }
        if (!formData.service.trim()) {
            formErrors.service = "Service type is required";
        }
        if (!formData.appointmentDateTime) {
            formErrors.appointmentDateTime = "Date and time are required";
        }
        if (!formData.message.trim()) {
            formErrors.message = "Message is required";
        }
        return formErrors;
    };

    const handleChange = e => {
        const { name, value } = e.target;
        if (name == 'phone') {
            setFormData(prevState => ({
                ...formData,
                [name]: normalizeInput(value, prevState.phone)
            }));
        }
        else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
        //const { name, value } = e.target;
        //setFormData({
        //    ...formData,
        //    [name]: value
        //});
    };

    const normalizeInput = (value, previousValue) => {
        // return nothing if no value
        if (!value) return value;

        // only allows 0-9 inputs
        const currentValue = value.replace(/[^\d]/g, "");
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {

            // returns: "x", "xx", "xxx"
            if (cvLength < 4) return currentValue;

            // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            try {
                const apiUrl = variables.API_URL + 'Executer/SendAppointmentEmail';
                const response = await axios.post(apiUrl, formData);
                if (response.status === 200) {
                    setSuccessMessage('Your appointment has been submitted successfully!');
                    setFormData({ name: "", phone: "", email: "", service: "", appointmentDateTime: "", message: "" });
                } else {
                    setErrors({ apiError: 'Something went wrong, please try again later.' });
                }
            } catch (error) {
                setErrors({ apiError: 'Network error, please try again later.' });
            }
            setIsSubmitting(false);
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="wpcf7-form init">
                <div className="row clearfix">
                    <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <p>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Name"
                            />
                            {errors.name && <p className="error">{errors.name}</p>}
                        </p>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <p>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Email"
                            />
                            {errors.email && <p className="error">{errors.email}</p>}
                        </p>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <p>
                            <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Phone"
                            />
                            {errors.phone && <p className="error">{errors.phone}</p>}
                        </p>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <p>
                            <select className="form-select" name="service" value={formData.service} onChange={handleChange}>
                                <option value="">--Select Service--</option>
                                <option value="Commercial Roofing">
                                    Commercial Roofing
                                </option>
                                <option value="Residential Roofing">
                                    Residential Roofing
                                </option>
                                <option value="Roof Maintenance">
                                    Roof Maintenance
                                </option>
                                <option value="Roof Inspection">
                                    Roof Inspection
                                </option>
                                <option value="Roof Repairs">
                                    Roof Repairs
                                </option>
                                <option value="Roof Replacement">
                                    Roof Replacement
                                </option>
                                <option value="Roof Installation">
                                    Roof Installation
                                </option>
                                <option value="Balcony Waterproofing">
                                    Balcony Waterproofing
                                </option>
                                <option value="Deck Waterproofing">
                                    Deck Waterproofing
                                </option>
                                <option value="Basement Waterproofing">
                                    Basement Waterproofing
                                </option>
                                <option value="Foundation Waterproofing">
                                    Foundation Waterproofing
                                </option>
                                <option value="Wall Waterproofing">
                                    Wall Waterproofing
                                </option>
                                <option value="Exterior Waterproofing">
                                    Exterior Waterproofing
                                </option>
                                <option value="Masonry Waterproofing">
                                    Masonry Waterproofing
                                </option>
                                <option value="Bituminous Waterproofing">
                                    Bituminous Waterproofing
                                </option>
                                <option value="Elastomeric Waterproofing">
                                    Elastomeric Waterproofing
                                </option>
                                <option value="Window Caulking">
                                    Window Caulking
                                </option>
                                <option value="Bathroom Remodeling">
                                    Bathroom Remodeling
                                </option>
                                <option value="Kitchen Remodeling">
                                    Kitchen Remodeling
                                </option>
                                <option value="Basement Renovation">
                                    Basement Renovation
                                </option>
                                <option value="NYC Sidewalk Repair">
                                    NYC Sidewalk Repair
                                </option>
                                <option value="Sidewalk Replacement">
                                    Sidewalk Replacement
                                </option>
                                <option value="Sidewalk Installation">
                                    Sidewalk Installation
                                </option>
                                <option value="Sidewalk Curb Repair">
                                    Sidewalk Curb Repair
                                </option>
                                <option value="Sidewalk Violation Removal">
                                    Sidewalk Violation Removal
                                </option>
                                <option value="Driveway Repair">
                                    Driveway Repair
                                </option>
                                <option value="Brick Pointing">
                                    Brick Pointing
                                </option>
                                <option value="Brick Restoration">
                                    Brick Restoration
                                </option>
                                <option value="Brownstone Restoration">
                                    Brownstone Restoration
                                </option>
                                <option value="Deck Restoration">
                                    Deck Restoration
                                </option>
                                <option value="Water Damage Restoration">
                                    Water Damage Restoration
                                </option>
                                <option value="Stone Restoration">
                                    Stone Restoration
                                </option>
                                <option value="Masonry Cleaning">
                                    Masonry Cleaning
                                </option>
                                <option value="Local Law 11">
                                    Local Law 11
                                </option>
                                <option value="Facade Repair">
                                    Facade Repair
                                </option>
                                <option value="Chimney Rebuilds">
                                    Chimney Rebuilds
                                </option>
                                <option value="Expansion Joints">
                                    Expansion Joints
                                </option>
                                <option value="Fire Escapes">
                                    Fire Escapes
                                </option>
                                <option value="Patios">
                                    Patios
                                </option>
                                <option value="Power Washing">
                                    Power Washing
                                </option>
                                <option value="Retaining Walls">
                                    Retaining Walls
                                </option>
                                <option value="Stair Repair & Building">
                                    Stair Repair & Building
                                </option>
                            </select>
                            {errors.service && <p className="error">{errors.service}</p>}
                        </p>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <p>
                            <input
                                type="datetime-local"
                                name="appointmentDateTime"
                                value={formData.datetime}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Appointment Date"
                            />
                            {errors.appointmentDateTime && <p className="error">{errors.appointmentDateTime}</p>}
                        </p>
                    </div>
                    <div className="form-group col-md-12 col-sm-12 co-xs-12">
                        <p>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Message"
                            />
                            {errors.message && <p className="error">{errors.message}</p>}
                        </p>
                    </div>
                    {errors.apiError && <p className="error">{errors.apiError}</p>}
                    {successMessage && <p className="success">{successMessage}</p>}
                    <div className="form-group col-md-12 col-sm-12 co-xs-12">
                        <p>
                            <button type="submit" className="theme-btn btn-style-one" disabled={isSubmitting}>
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </p>
                    </div>
                </div><div className="wpcf7-response-output" aria-hidden="true"></div>
            </form>
        </div>
    );
};