import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import sidewalkcurbrepair1 from "../assets/images/sidewalk/sidewalkcurbrepair1.jpg"
import sidewalkcurbrepair2 from "../assets/images/sidewalk/sidewalkcurbrepair2.jpg"
import sidewalkcurbrepair3 from "../assets/images/sidewalk/sidewalkcurbrepair3.jpg"

export const SidewalkCurbRepair = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={sidewalkcurbrepair1} alt="Sidewalk Curb Repair" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={sidewalkcurbrepair2} alt="Sidewalk Curb Repair" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={sidewalkcurbrepair3} alt="Sidewalk Curb Repair" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Sidewalk Curb Repair</h3>
            <div className="text">
                <p>
                    At HomeX Builders Corp, our Sidewalk Curb Repair service involves a systematic approach to restore curbs to their optimal condition, ensuring safety, durability, and compliance with local regulations. Here’s an overview of the steps and techniques we use:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Sidewalk Curb Repair Services?</h3>
                <ul>
                    <li><strong>Site Inspection: </strong>We start by thoroughly inspecting the curb to identify any damage, such as cracks, chips, or uneven surfaces. This helps us determine the extent of the repair needed.</li>
                    <li><strong>Assessment of Causes: </strong>Understanding the underlying causes of the damage (e.g., weather, traffic wear, tree roots) allows us to plan the most effective repair strategy.</li>
                    <li><strong>Repair Plan Development: </strong>Based on the assessment, we develop a tailored repair plan that addresses the specific issues identified.</li>
                    <li><strong>Permitting and Compliance: </strong>We ensure that all necessary permits are obtained and that our repair work complies with local regulations and standards.</li>
                    <li><strong>Clearing the Area:</strong> We remove any debris, vegetation, or loose material around the damaged curb to create a clean working environment.</li>
                    <li><strong>Protective Measures:</strong> Adjacent areas, such as the sidewalk and street, are protected to prevent damage during the repair process.</li>
                    <li><strong>Concrete Cutting: </strong>If necessary, we use specialized saws to cut out the damaged section of the curb precisely, minimizing the impact on the surrounding areas.</li>
                    <li><strong>Crack Repair:</strong> For minor cracks, we clean and fill them with a high-quality epoxy or polymer-modified cement, ensuring a seamless finish.</li>
                    <li>
                        <strong>Section Replacement: </strong>For more severe damage, we remove the affected section of the curb and replace it with new concrete. This involves:
                        <ul>
                            <li><strong>Formwork Installation: </strong>We set up formwork to shape the new curb section according to the existing design and dimensions.</li>
                            <li><strong>Reinforcement: </strong>If needed, we reinforce the new curb section with rebar or mesh to enhance its strength and durability.</li>
                            <li><strong>Concrete Pouring: </strong>High-strength concrete is mixed and poured into the formwork, ensuring proper compaction to eliminate air pockets.</li>
                            <li><strong>Finishing: </strong>The surface is smoothed and shaped to match the existing curb, with attention to detail to ensure a consistent appearance.</li>
                            <li><strong>Curing: </strong>The new concrete is allowed to cure properly, ensuring maximum strength and longevity.</li>
                        </ul>
                    </li>
                    <li><strong>Quality Check: </strong>After the repair is completed, we conduct a thorough inspection to ensure the curb meets our high standards of workmanship and durability.</li>
                    <li><strong>Site Cleanup: </strong>The area is cleaned up, and any debris or materials used during the repair are removed, leaving the site in excellent condition.</li>
                    <li><strong>Sealing (Optional):</strong> We can apply a sealant to the repaired curb to protect it from future wear and tear, weather damage, and staining.</li>
                    <li><strong>Maintenance Advice:</strong> We provide recommendations on how to maintain the curb to extend its lifespan and keep it in good condition.</li>
                    <li><strong>Precision Saw Cutting: </strong>Ensures clean removal of damaged sections without disturbing the surrounding areas.</li>
                    <li><strong>Polymer-Modified Cement: </strong>Used for filling cracks, providing flexibility and resistance to further cracking.</li>
                    <li><strong>Formwork and Reinforcement:</strong> Custom formwork and reinforcement techniques ensure that the new curb section matches the existing structure and is durable enough to withstand heavy use.</li>
                    <li><strong>Curing Methods: </strong>Proper curing techniques are applied to ensure the new concrete reaches its full strength and durability.</li>
                </ul>
                <h3>How we Execute Sidewalk Curb Repair in NYC</h3>
                <ul>
                    <li><strong>Site Inspection:</strong> The process begins with a thorough inspection of the damaged curb. The team assesses the extent of the damage, such as cracks, chips, or structural issues, and identifies any underlying causes.</li>
                    <li><strong>Client Consultation:</strong> Homex Builders discusses the findings with you, explaining the repair options, materials needed, and any potential challenges.</li>
                    <li><strong>Detailed Quotation:</strong> Based on the assessment, you receive a detailed cost estimate that includes labor, materials, and any additional services required.</li>
                    <li><strong>Project Timeline:</strong> Homex Builders provides a clear timeline for the repair, ensuring minimal disruption to your property or daily routine.</li>
                    <li><strong>Permit Acquisition: </strong>If required, Homex Builders takes care of obtaining any necessary permits from local authorities, ensuring that the repair work complies with local regulations and codes.</li>
                    <li><strong>Site Preparation:</strong> The repair area is prepared by marking and isolating the section of the curb that needs attention. Safety barriers or cones are set up to protect pedestrians and vehicles during the repair process.</li>
                    <li><strong>Excavation and Debris Removal: </strong>The damaged portion of the curb is carefully removed. This may involve breaking up old concrete or asphalt and clearing out debris to create a clean working area.</li>
                    <li><strong>Formwork Setup:</strong> Wooden or metal forms are placed to shape the new section of the curb, ensuring it aligns perfectly with the existing structure.</li>
                    <li><strong>Reinforcement (If Needed):</strong> If the damage is extensive, reinforcement bars (rebar) or mesh may be installed to provide additional strength to the new curb section.</li>
                    <li><strong>Material Application:</strong> The team mixes and applies fresh concrete or appropriate repair material to the prepared area. This is done with precision to match the existing curb's color, texture, and shape.</li>
                    <li><strong>Finishing:</strong> The surface is smoothed and shaped to ensure it blends seamlessly with the surrounding curb. Edges are carefully defined to maintain the curb’s functionality and appearance.</li>
                    <li><strong>Curing Process:</strong> The repaired curb is left to cure properly, allowing the material to harden and achieve full strength. The curing process is monitored to prevent any issues, such as premature cracking.</li>
                    <li><strong>Final Inspection: </strong>Once the curb has cured, Homex Builders conducts a final inspection to ensure the repair meets their quality standards and adheres to local regulations.</li>
                    <li><strong>Cleanup: </strong>The repair site is thoroughly cleaned, with all construction materials and debris removed. Any disturbed landscaping or pavement around the curb is restored to its original condition.</li>
                    <li><strong>Client Walkthrough: </strong>Homex Builders typically conducts a walkthrough with you to ensure you’re satisfied with the repair work and to answer any remaining questions.</li>
                    <li><strong>Follow-Up: </strong>After completion, Homex Builders may follow up to ensure the curb repair is holding up well and to address any concerns you might have. They might also provide tips on maintaining the curb to prevent future damage.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Sidewalk Curb Repair?</h4>
                    <p>
                        When it comes to sidewalk curb repair, selecting the right contractor ensures that the job is done efficiently, safely, and to a high standard. Here’s why HomeX Builders Corp is the best choice for your sidewalk curb repair needs:
                    </p>
                    <ul>
                        <li><strong>Specialized Expertise in Curb Repair: </strong>HomeX Builders Corp has a strong track record in sidewalk curb repair, with a team of professionals who specialize in this specific type of work. Their experience and knowledge enable them to handle various curb issues, from minor cracks to more extensive damage, ensuring a high-quality repair that restores both functionality and appearance.</li>

                        <li><strong>Thorough Assessment and Customized Solutions: </strong>Before starting any repair, HomeX Builders Corp conducts a thorough assessment of the damage. They identify the underlying causes of the problem, such as drainage issues or ground movement, and develop a tailored repair plan that addresses these issues comprehensively. This customized approach ensures long-lasting results.</li>

                        <li><strong>High-Quality Materials and Techniques: </strong>Using the right materials is crucial for a durable curb repair. HomeX Builders Corp is committed to using only the highest quality materials, whether it’s concrete, asphalt, or other durable compounds. They also employ advanced repair techniques to ensure that the repaired curb can withstand traffic, weather conditions, and wear over time.</li>

                        <li><strong>Compliance with Local Regulations: </strong>Curb repairs must comply with local codes and regulations, especially in public or commercial areas. HomeX Builders Corp has in-depth knowledge of these requirements and ensures that all repairs are up to code. This attention to detail helps you avoid potential fines or the need for further repairs in the future.</li>

                        <li><strong>Efficient and Timely Service: </strong>HomeX Builders Corp understands that curb repairs need to be completed promptly to minimize disruptions, especially in high-traffic areas. They are committed to working efficiently while maintaining high standards of quality, ensuring that your curb is repaired on time and within budget.</li>

                        <li><strong>Safety-First Approach: </strong>Safety is paramount in curb repair projects. HomeX Builders Corp prioritizes the safety of their workers, clients, and the public by adhering to strict safety protocols during the repair process. This focus on safety also ensures that the repaired curb is stable and safe for pedestrian and vehicular traffic.</li>

                        <li><strong>Transparent and Competitive Pricing: </strong>HomeX Builders Corp offers competitive pricing for their curb repair services. They provide clear and detailed estimates upfront, so you know exactly what to expect, with no hidden costs. This transparency helps you manage your budget effectively and ensures you receive great value for your investment.</li>

                        <li><strong>Proven Reputation and Client Satisfaction: </strong>HomeX Builders Corp has built a strong reputation for delivering reliable and high-quality curb repair services. Their commitment to client satisfaction is reflected in the positive reviews and testimonials from previous customers who have praised their professionalism, workmanship, and customer service.</li>
                    </ul>
                </blockquote>
                <p>
                    Choosing HomeX Builders Corp for your sidewalk curb repair means partnering with a company that prioritizes quality, safety, and customer satisfaction. Their specialized expertise, use of top-quality materials, and commitment to compliance with local regulations make them the ideal choice for ensuring your curbs are repaired effectively and durably.
                </p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Traffic and Pedestrian Control: </strong>Homex Builders Corp sets up barriers, cones, and warning signs to manage traffic flow and protect pedestrians around the worksite. This is crucial in areas with high foot traffic or near busy roads.</li>
                                        <li><strong>Inspection of Existing Conditions: </strong>Before starting the repair, they conduct a thorough inspection of the curb and surrounding sidewalk. This helps identify underlying issues like cracks, drainage problems, or subsurface damage that need to be addressed during the repair.</li>
                                        <li><strong>Compliance with Local Regulations: </strong>They ensure all repair work complies with local building codes and regulations. This includes obtaining the necessary permits and adhering to ADA (Americans with Disabilities Act) standards where applicable.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Diagnostic Tools: </strong>Homex Builders Corp uses advanced diagnostic tools to assess the extent of the curb damage. This might include laser levels, ground-penetrating radar (GPR), or other technologies that help in accurately determining the scope of the repair.</li>
                                        <li><strong>Material Selection: </strong>The company selects repair materials that match the existing curb in both appearance and durability. They consider factors such as the type of concrete used, the expected load, and environmental conditions to ensure a seamless repair.</li>
                                        <li><strong>Strategic Planning: </strong>They employ project management software to plan and execute the repair efficiently. This includes scheduling repairs during off-peak hours to minimize disruptions and ensuring that all necessary resources are on-site.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Expert Repair Teams: </strong>Homex Builders Corp has specialized teams with expertise in curb repair. Their experience ensures that repairs are not only durable but also visually consistent with the existing infrastructure.</li>
                                        <li><strong>Precision Techniques: </strong>They use precision techniques for cutting, removing, and replacing damaged curb sections. This includes methods like saw cutting and precise concrete mixing, which help achieve a high-quality repair that blends seamlessly with the existing curb.</li>
                                        <li><strong>Long-Term Solutions: </strong>Homex Builders Corp focuses on providing long-term solutions by addressing root causes of curb damage, such as poor drainage or soil erosion. They might install additional drainage systems or reinforce the base to prevent future issues.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    For dependable curb repair service that you can trust, contact HomeX Builders Corp today and let their team restore your curbs to perfect condition.
                </p>
            </div>
        </>
    );
}
