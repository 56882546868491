import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import residential_roofing1 from "../assets/images/roofingservices/residential_roofing1.jpg"
import residential_roofing2 from "../assets/images/roofingservices/residential_roofing2.jpg"
import residential_roofing3 from "../assets/images/roofingservices/residential_roofing3.jpg"

export const ResidentialRoofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={residential_roofing1} alt="Residential Roofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={residential_roofing2} alt="Residential Roofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={residential_roofing3} alt="Residential Roofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Residential Roofing</h3>
            <div className="text">
                <p>
                    At Homex Builders Corp, we are committed to providing top-notch residential roofing services that protect your home and enhance its curb appeal. Our experienced team of roofing professionals is dedicated to delivering reliable, high-quality solutions tailored to meet the unique needs of each homeowner. We use only the best materials and advanced techniques to ensure your roof remains strong and durable for years to come. Here’s a detailed overview of the residential roofing services we offer and why you should choose us for your roofing needs.
                </p>
            </div>
            <div className="text">
                <h3>Services We Offer:</h3>
                <h4>1. New Roof Installation</h4>
                <ul>
                    <li>
                        <strong>Custom Roof Design: </strong>
                        Our team works closely with you to design a roof that perfectly complements your home’s architecture while meeting your functional needs. We take into account factors like local climate, your budget, and aesthetic preferences to provide a roofing solution that’s both beautiful and practical.
                    </li>
                    <li>
                        <strong>Material Selection: </strong>
                        We offer a wide range of roofing materials, including asphalt shingles, metal, slate, tile, wood shakes, and more. Each material comes with its own set of benefits, and we help you choose the best option for your home.
                    </li>
                    <li>
                        <strong>Professional Installation: </strong>
                        Our skilled installers ensure your new roof is installed with precision and care, following industry standards to guarantee durability and longevity.
                    </li>
                </ul>
                <h4>2. Roof Replacement</h4>
                <ul>
                    <li>
                        <strong>Complete Roof Replacement: </strong>
                        Whether your roof has reached the end of its lifespan or has suffered severe damage, we offer full roof replacement services. This includes the removal of the old roof, inspection and repair of the underlying structure, and installation of a new, high-performance roofing system.
                    </li>
                    <li>
                        <strong>Energy-Efficient Upgrades: </strong>
                        If you're looking to improve your home’s energy efficiency, we provide roof replacement options that include energy-efficient materials and designs, helping to reduce your heating and cooling costs.
                    </li>
                </ul>
                <h4>3. Roof Repair Services</h4>
                <ul>
                    <li>
                        <strong>Leak Repair:</strong> Leaks can cause significant damage to your home’s interior if not addressed promptly. Our team quickly identifies the source of the leak and provides effective repair solutions to prevent further damage.
                    </li>
                    <li>
                        <strong>Storm Damage Repair:</strong> We offer prompt and reliable storm damage repair services to address any damage caused by hail, wind, or heavy rain, ensuring your roof is restored to its original condition.
                    </li>
                    <li>
                        <strong>Shingle Repair and Replacement:</strong> Damaged or missing shingles can compromise the integrity of your roof. We provide quick and effective shingle repair and replacement services to maintain the performance and appearance of your roof.
                    </li>
                </ul>
                <h4>4. Roof Inspection and Maintenance</h4>
                <ul>
                    <li>
                        <strong>Comprehensive Roof Inspections:</strong> Regular roof inspections help identify potential issues before they become major problems. Our detailed inspections assess the overall condition of your roof and provide recommendations for repairs or maintenance as needed.
                    </li>
                    <li>
                        <strong>Preventive Maintenance Programs:</strong> Our maintenance programs are designed to extend the life of your roof by providing regular inspections, cleaning, and minor repairs to address any issues early on.
                    </li>
                </ul>
                <h4>5. Roof Ventilation and Insulation</h4>
                <ul>
                    <li>
                        <strong>Ventilation Solutions:</strong> Proper roof ventilation is crucial for maintaining a healthy and efficient roofing system. We install and repair various ventilation systems, including ridge vents, soffit vents, and attic fans, to improve airflow and prevent moisture buildup.
                    </li>
                    <li>
                        <strong>Insulation Upgrades:</strong> Adequate insulation is essential for energy efficiency and comfort. We assess your current insulation levels and recommend upgrades to improve your home’s energy performance
                    </li>
                </ul>
                <h4>6. Roof Coatings and Waterproofing</h4>
                <ul>
                    <li>
                        <strong>Protective Roof Coatings:</strong> Roof coatings provide an extra layer of protection against UV rays, weather, and moisture. We offer various types of coatings, such as elastomeric and silicone, to enhance your roof’s durability and lifespan.
                    </li>
                    <li>
                        <strong>Waterproofing Solutions:</strong> To protect your roof from leaks and water damage, we offer professional waterproofing services that seal and safeguard your roof against the elements.
                    </li>
                </ul>
                <h4>7. Gutter Installation and Maintenance</h4>
                <ul>
                    <li>
                        <strong>Gutter and Downspout Installation:</strong> Properly functioning gutters are essential for directing water away from your home’s foundation. We install high-quality gutter systems designed to handle heavy rainfall and prevent water damage.
                    </li>
                    <li>
                        <strong>Gutter Cleaning and Repair: </strong> Regular gutter maintenance is crucial for preventing clogs and ensuring proper drainage. We offer gutter cleaning and repair services to keep your gutters in excellent condition.
                    </li>
                </ul>
                <h4>8. Skylight Installation and Repair</h4>
                <ul>
                    <li>
                        <strong>Skylight Installation: </strong>Skylights are a great way to add natural light and improve ventilation in your home. We provide professional skylight installation services, ensuring a perfect fit and watertight seal.
                    </li>
                    <li>
                        <strong>Skylight Repair:</strong> If your skylight is leaking or damaged, we offer repair services to restore its functionality and prevent further issues.
                    </li>
                </ul>
                <h4>9. Emergency Roofing Services</h4>
                <ul>
                    <li>
                        <strong>24/7 Emergency Repairs:</strong> Roofing emergencies can happen at any time. Our team is available 24/7 to provide emergency repair services, ensuring your home is protected from further damage.
                    </li>
                </ul>
                <h4>10. Insurance Claim Assistance</h4>
                <ul>
                    <li>
                        <strong>Claims Support:</strong> Navigating the insurance claims process can be daunting. We provide comprehensive support for filing insurance claims for roof damage, including detailed inspections, documentation, and cost estimates to help streamline the process.
                    </li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp?</h4>
                    <ul>
                        <li>
                            <strong>Experienced and Skilled Team: </strong>
                            Our team consists of highly trained and experienced roofing professionals who are committed to providing top-quality workmanship on every project. We stay up-to-date with the latest industry standards and best practices to ensure your roof is installed or repaired correctly.
                        </li>
                        <li>
                            <strong>High-Quality Materials: </strong>
                            We use only the highest-quality roofing materials from trusted manufacturers, ensuring your roof is durable, weather-resistant, and long-lasting. Whether you prefer asphalt shingles, metal roofing, or more premium materials, we have the right options for you.
                        </li>
                        <li>
                            <strong>Comprehensive Service Offering: </strong>
                            From new installations to repairs, maintenance, and emergency services, we offer a full range of residential roofing services. This means you can rely on us for all your roofing needs, whether you need a small repair or a complete roof replacement.
                        </li>
                        <li>
                            <strong>Customer-Centric Approach: </strong>
                            At Homex Builders Corp, customer satisfaction is our top priority. We work closely with you throughout the entire process, providing clear communication, detailed estimates, and personalized service to ensure your needs are met and expectations exceeded.
                        </li>
                        <li>
                            <strong>Competitive Pricing: </strong>
                            We offer competitive pricing on all our roofing services without compromising on quality. Our transparent pricing ensures you know exactly what you’re paying for, with no hidden fees or surprises.
                        </li>
                        <li>
                            <strong>Licensed and Insured: </strong>
                            We are a fully licensed and insured roofing contractor, providing peace of mind that your home is in good hands. Our adherence to safety standards and regulations further ensures a safe and efficient work environment.
                        </li>
                        <li>
                            <strong>Warranty and Guarantee: </strong>
                            We stand behind the quality of our work and offer warranties on our roofing services. This means you can have confidence that your roof will stand the test of time, and if any issues arise, we’ll be there to address them.
                        </li>
                        <li>
                            <strong>Local Expertise: </strong>
                            As a local company, we understand the specific roofing needs of homes in our area. We are familiar with local building codes, weather conditions, and common roofing issues, allowing us to provide tailored solutions that are built to last.
                        </li>
                        <li>
                            <strong>Environmentally Conscious: </strong>
                            We offer sustainable roofing options, such as solar roofing and energy-efficient materials, to help you reduce your environmental footprint and lower your energy costs.
                        </li>
                        <li>
                            <strong>Emergency Services Available: </strong>
                            Roofing emergencies can’t wait. That’s why we offer 24/7 emergency roofing services to address urgent needs and minimize potential damage to your home.
                        </li>
                    </ul>
                </blockquote>

                <p>Choosing Homex Builders Corp means choosing quality, reliability, and peace of mind. If you need residential roofing services, don't hesitate to contact us today for a free consultation and estimate. Let us help you protect your home with a roof that's built to last.</p>
                <p>When it comes to commercial roofing, ensuring the job is done right requires a combination of specialized skills, intelligent planning, and strict safety precautions. Here’s how these three elements play a critical role in delivering top-notch commercial roofing services:</p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Use of Safety Equipment: </strong>All personnel must wear appropriate safety gear, including hard hats, safety harnesses, non-slip footwear, and eye protection. This is especially important when working on steep or high roofs.
                                        </li>
                                        <li>
                                            <strong>Fall Protection Systems: </strong>Installing guardrails, safety nets, and harnesses can prevent falls from the roof, which are a leading cause of injury in roofing work.
                                        </li>
                                        <li>
                                            <strong>Weather Monitoring: </strong>Roofing work should not be performed during inclement weather, such as rain, snow, or high winds, to prevent slips, falls, and other accidents.
                                        </li>
                                        <li>
                                            <strong>Proper Ladder Use: </strong>Secure and stable ladders must be used when accessing the roof. Ladders should be inspected regularly for damage and placed on a flat, stable surface.
                                        </li>
                                        <li>
                                            <strong>Clear Work Area: </strong>The work area should be kept clean and free of debris to prevent tripping hazards. All tools and materials should be secured to prevent them from falling off the roof.
                                        </li>
                                        <li>
                                            <strong>Safe Storage of Materials: </strong>Roofing materials such as shingles, tiles, and tools should be stored properly to avoid damage and minimize safety hazards.
                                        </li>
                                        <li>
                                            <strong>Proper Lifting Techniques: </strong>To prevent injuries, workers should use proper lifting techniques when handling heavy materials, or utilize lifting equipment as needed.
                                        </li>
                                        <li>
                                            <strong>Handling Hazardous Materials: </strong>If roofing materials contain hazardous substances (like asbestos in older roofs), appropriate safety procedures, including containment and disposal protocols, should be followed.
                                        </li>
                                        <li>
                                            <strong>Roof Load Management: </strong>Understanding the weight capacity of the existing roof structure is critical. Overloading the roof with heavy materials or equipment can lead to structural failure.
                                        </li>
                                        <li>
                                            <strong>Pre-Inspection of Roof Structure: </strong>Before any roofing work begins, a thorough inspection of the underlying roof structure should be conducted to identify any weak spots, rot, or damage that could affect the safety and integrity of the roof.
                                        </li>
                                        <li>
                                            <strong>Avoid Power Lines: </strong>Special care should be taken to avoid overhead power lines and ensure that no tools or materials come into contact with live electrical wires.
                                        </li>
                                        <li>
                                            <strong>Turn Off Electricity When Necessary: </strong>If roofing work involves electrical components, such as ventilation systems or skylights, the electricity should be turned off to prevent electric shock.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Roof Assessment and Evaluation: </strong>
                                            Conducting a comprehensive assessment of the roof's current condition helps determine the best course of action, whether it’s repair, replacement, or maintenance. This includes evaluating the roof's age, material, slope, and ventilation.
                                        </li>
                                        <li>
                                            <strong>Custom Roof Design: </strong>
                                            Intelligent roofing involves designing a roof that complements the home's architecture and meets the specific needs of the homeowner, considering factors like climate, energy efficiency, and aesthetics.
                                        </li>
                                        <li>
                                            <strong>Energy Efficiency Considerations: </strong>
                                            Using energy-efficient materials, such as reflective shingles or cool roof coatings, can help reduce cooling costs and improve home comfort.
                                        </li>
                                        <li>
                                            <strong>Durable and Sustainable Materials: </strong>
                                            Choosing materials that are both durable and environmentally friendly can enhance the lifespan of the roof and reduce its environmental impact. This includes options like metal roofing, recycled shingles, or clay tiles.
                                        </li>
                                        <li>
                                            <strong>Weather-Resistant Materials: </strong>
                                            Depending on the local climate, selecting materials that can withstand specific weather conditions, such as high winds, heavy rain, or hail, is critical for long-term durability.
                                        </li>
                                        <li>
                                            <strong>Use of Roofing Software: </strong>
                                            Advanced software can be used for precise measurement and estimation, 3D modeling of roofing projects, and project management to improve accuracy and efficiency.
                                        </li>
                                        <li>
                                            <strong>Thermal Imaging for Leak Detection: </strong>
                                            Thermal imaging technology can detect areas of heat loss or moisture accumulation, allowing for targeted repairs and improving overall roof performance.
                                        </li>
                                        <li>
                                            <strong>Optimizing Ventilation: </strong>
                                            Proper ventilation helps regulate attic temperatures and prevents moisture buildup, which can lead to mold growth and roof deterioration. Intelligent design involves the strategic placement of vents and insulation to maintain a balanced attic environment.
                                        </li>
                                        <li>
                                            <strong>Insulation Upgrades: </strong>
                                            Using advanced insulation materials and techniques can enhance energy efficiency and protect the roof from temperature extremes.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Asphalt Shingle Installation and Repair: </strong>
                                            Specializing in asphalt shingles, which are the most common residential roofing material due to their affordability, durability, and variety of styles and colors.
                                        </li>
                                        <li>
                                            <strong>Architectural Shingles: </strong>
                                            Expertise in installing architectural shingles, which provide a more dimensional look and enhanced durability compared to traditional three-tab shingles.
                                        </li>
                                        <li>
                                            <strong>Metal Roof Installation:  </strong>
                                            Skilled in installing and repairing metal roofing systems, including standing seam, corrugated, and metal tiles. Metal roofs are known for their longevity, energy efficiency, and low maintenance.
                                        </li>
                                        <li>
                                            <strong>Coatings and Finishes: </strong>
                                            Specialization in applying protective coatings to metal roofs to prevent rust, corrosion, and enhance energy efficiency.
                                        </li>
                                        <li>
                                            <strong>EPDM, TPO, and PVC Roofing: </strong>
                                            Expertise in installing and repairing flat and low-slope roofing systems using materials like EPDM (ethylene propylene diene terpolymer), TPO (thermoplastic olefin), and PVC (polyvinyl chloride). These materials are known for their waterproofing capabilities and are ideal for modern flat roof designs.
                                        </li>
                                        <li>
                                            <strong>Modified Bitumen and Built-Up Roofing (BUR): </strong>
                                            Skilled in traditional flat roofing techniques such as modified bitumen and BUR, which provide multi-layered protection against the elements.
                                        </li>
                                        <li>
                                            <strong>Clay and Concrete Tile Installation: </strong>
                                            Specializing in the installation and repair of clay and concrete tile roofs, which are known for their durability, fire resistance, and ability to withstand harsh weather conditions.
                                        </li>
                                        <li>
                                            <strong>Slate Roofing: </strong>
                                            Expertise in natural slate roofing, which offers a high-end, classic look and is one of the most durable roofing options available, often lasting over a century.
                                        </li>
                                        <li>
                                            <strong>Green Roof Installation: </strong>
                                            Skilled in installing green roofs, which involve planting vegetation on a waterproof membrane. Green roofs offer benefits such as improved insulation, stormwater management, and enhanced aesthetics.
                                        </li>
                                        <li>
                                            <strong>Solar Roofing: </strong>
                                            Expertise in integrating solar panels with roofing systems, providing homeowners with sustainable energy solutions that can reduce electricity costs and improve the home's overall energy efficiency.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text">
                <p>
                    By combining these precautions, intelligent approaches, and specializations, Homex Builders Corp ensures that every residential roofing project is handled with the utmost care, precision, and expertise, delivering high-quality results that protect and enhance your home for years to come.
                </p>
            </div>  
        </>
    );
}
