import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import sidewalkrepair1 from "../assets/images/sidewalk/sidewalkrepair1.jpg"
import sidewalkrepair2 from "../assets/images/sidewalk/sidewalkrepair2.jpg"
import sidewalkrepair3 from "../assets/images/sidewalk/sidewalkrepair3.jpg"

export const NYCSidewalkRepair = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={sidewalkrepair1} alt="NYC Sidewalk Repair" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={sidewalkrepair2} alt="NYC Sidewalk Repair" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={sidewalkrepair3} alt="NYC Sidewalk Repair" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>NYC Sidewalk Repair</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers sidewalk repair services in New York City, specializing in maintaining and restoring sidewalks to ensure compliance with local regulations and improve safety and accessibility. Here are some key features of their sidewalk repair services:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Sidewalk Repair Services?</h3>
                <ul>
                    <li><strong>Comprehensive Sidewalk Repairs: </strong>Homex Builders Corp provides a full range of repair services, including fixing cracks, removing tree root damage, and leveling uneven sections. They handle both small and large-scale projects for residential and commercial properties.</li>
                    <li><strong>DOT Violation Removal: </strong>The company specializes in handling sidewalk violations issued by the NYC Department of Transportation (DOT). They take care of the entire process, from inspection to repairs, ensuring that sidewalks meet the city’s safety standards and property owners avoid fines.</li>
                    <li><strong>Concrete and Masonry Work: </strong>Homex Builders Corp uses high-quality concrete and masonry techniques to deliver long-lasting, durable sidewalk repairs. Whether it's replacing broken sections or upgrading entire walkways, they ensure the work is done to city code standards.</li>
                    <li><strong>ADA Compliance: </strong>Ensuring that sidewalks are accessible to all, Homex Builders Corp makes sure their repairs meet the requirements of the Americans with Disabilities Act (ADA). This includes making surfaces smoother and installing ramps where needed.</li>
                    <li><strong>Free Estimates: </strong>Homex Builders Corp offers free consultations and estimates, helping property owners understand the scope and cost of their sidewalk repair projects before committing.</li>
                    <li><strong>Timely and Efficient Service: </strong>Known for their quick turnaround time, Homex Builders Corp aims to complete sidewalk repairs promptly to minimize inconvenience for pedestrians and property owners.</li>
                </ul>
                <h3>How we Process Sidewalk Repair in NYC</h3>
                <ul>
                    <li><strong>Inspection and Assessment: </strong>First, conduct a thorough inspection of the sidewalk to identify cracks, trip hazards, uneven slabs, or other damages. Check if the sidewalk has a NYC DOT violation, as this will dictate the type of repair needed.</li>
                    <li><strong>Obtain Necessary Permits: </strong>For major repairs or replacements, a sidewalk repair permit from the NYC Department of Transportation (DOT) is often required. This ensures the work meets the city's standards.</li>
                    <li><strong>Excavation and Removal: </strong>If replacement is needed, the damaged sections are marked and then removed. This involves breaking up old concrete, clearing debris, and preparing the surface for new installation.</li>
                    <li><strong>Base Preparation: </strong>Proper leveling and compaction of the subgrade are critical. A well-prepared base prevents future cracking and ensures a solid foundation for the new concrete.</li>
                    <li><strong>Concrete Pouring: </strong>High-quality concrete is poured and spread over the prepared base. Ensure proper leveling and thickness to meet NYC sidewalk specifications. Expansion joints are added to prevent future cracking.</li>
                    <li><strong>Finishing: </strong>The concrete is smoothed and finished with the appropriate texture to prevent slipperiness while maintaining a clean appearance.</li>
                    <li><strong>Curing and Sealing: </strong>Allow the concrete to cure (typically for 24-48 hours) before sealing the surface to protect it from water damage and wear over time.</li>
                    <li><strong>Final Inspection: </strong>Conduct a final inspection to ensure that the repair or replacement meets both NYC DOT standards and the property owner’s expectations.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Sidewalk Repair?</h4>
                    <ul>
                        <li><strong>DOT Violation Experts: </strong>We specialize in removing DOT violations, ensuring quick compliance with city standards.</li>
                        <li><strong>Experienced Professionals: </strong>Our team has years of experience handling complex sidewalk repairs and replacements in NYC.</li>
                        <li><strong>Quality Materials: </strong>We use durable, long-lasting concrete and top-grade materials that can withstand NYC's harsh weather and heavy foot traffic.</li>
                        <li><strong>Guaranteed Safety: </strong>We focus on removing trip hazards and ensuring the safety of pedestrians, reducing your liability as a property owner.</li>
                        <li><strong>Fast and Efficient: </strong>We provide timely service to minimize disruptions and ensure your sidewalk is back in use as quickly as possible.</li>
                    </ul>
                </blockquote>
                <p>Homex Builders Corp takes a strategic and professional approach to sidewalk repair in New York City, incorporating key precautions, specialized knowledge, and intelligent solutions to meet the city's demands. Here's a detailed breakdown of their approach:</p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Compliance with NYC Regulations: </strong>Homex Builders Corp is meticulous in adhering to New York City's Department of Transportation (DOT) guidelines. This includes ensuring that the sidewalks meet the city's stringent safety and construction standards, avoiding fines or future violations for property owners.</li>
                                        <li><strong>Minimizing Disruption: </strong>They plan their repair projects to reduce disruptions to pedestrians and traffic flow. By setting up proper barriers, signage, and safety measures, they protect both workers and the public during the repair process.</li>
                                        <li><strong>Weather Considerations: </strong>In a city like NYC, where weather can be unpredictable, Homex Builders Corp takes extra care to schedule repairs during optimal weather conditions to ensure the concrete cures properly and to prevent future damage from freeze-thaw cycles.</li>
                                        <li><strong>Tree Root Management: </strong>New York City sidewalks often face damage from tree roots, which can cause cracking and uneven surfaces. Homex Builders takes precautions to either safely remove or re-route tree roots during repairs to prevent future damage while maintaining the health of the tree.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Advanced Inspection Techniques: </strong>Homex Builders Corp uses modern inspection tools, such as laser leveling and ground-penetrating radar, to accurately assess the condition of sidewalks before starting repairs. This helps them identify hidden issues like subsurface instability.</li>
                                        <li><strong>Data-Driven Solutions: </strong>They utilize data from past projects and city regulations to develop efficient repair plans. This ensures projects are completed faster, with fewer errors, and in full compliance with legal requirements.</li>
                                        <li><strong>Innovative Materials: </strong>Homex Builders uses high-quality concrete mixtures and other construction materials that are durable and weather-resistant, ensuring that sidewalks can withstand the heavy foot traffic and harsh environmental conditions typical in New York City.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>DOT Violation Removal: </strong>One of Homex Builders Corp’s primary specializations is resolving DOT sidewalk violations. They handle all aspects of the process, from the initial assessment to the completion of repairs, helping property owners get their violation notices cleared quickly.</li>
                                        <li><strong>Concrete Replacement and Repair: </strong>They specialize in all types of concrete work, including the removal of deteriorated slabs, pouring new concrete, and ensuring a smooth, level finish. They are experienced in repairing damage caused by tree roots, weather, and wear and tear from high foot traffic.</li>
                                        <li><strong>ADA-Compliant Sidewalks: </strong>Homex Builders Corp is skilled at creating ADA-compliant sidewalks, which ensure accessibility for individuals with disabilities. They often install ramps, smooth surfaces, and correct slopes to meet these standards.</li>
                                        <li><strong>Curb Repair and Installation: </strong>Homex also specializes in curb installation and repair, ensuring that not only the sidewalk but the curbs are aligned with city regulations. This is critical for ensuring proper drainage and maintaining the structural integrity of the sidewalk.</li>
                                        <li><strong>Landmark and Historical District Sidewalks: </strong>In areas where sidewalks must meet specific historical preservation guidelines, such as in landmark districts, Homex Builders Corp is skilled in working with appropriate materials and following strict restoration protocols.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Homex Builders Corp stands out due to its commitment to safety, legal compliance, and cutting-edge technology in sidewalk repair. They specialize in DOT violation removal, ADA-compliant sidewalks, and advanced repair techniques, making them a trusted partner for NYC property owners.
                </p>
            </div>
        </>
    );
}
