import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import stairrepair1 from "../assets/images/masonry/stairrepair1.jpg"
import stairrepair2 from "../assets/images/masonry/stairrepair2.jpg"
import stairrepair3 from "../assets/images/masonry/stairrepair3.jpg"

export const StairRepairBuilding = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={stairrepair1} alt="Stair Repair & Building" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={stairrepair2} alt="Stair Repair & Building" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={stairrepair3} alt="Stair Repair & Building" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Stair Repair & Building</h3>
            <div className="text">
                <p>
                    At Homex Builders Corp, we offer comprehensive stair repair and construction services designed to enhance the safety, functionality, and aesthetic appeal of your home or commercial property. Our services include:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Stair Repair & Building Services?</h3>
                <ul>
                    <li><strong>Structural Repairs:</strong> We address issues such as creaking, loose steps, or damaged stringers to ensure your stairs are safe and stable.</li>
                    <li><strong>Tread and Riser Replacement: </strong>If your stair treads or risers are worn out, we replace them with durable materials that match your existing design or provide a fresh look.</li>
                    <li><strong>Handrail and Baluster Repair: </strong>We fix or replace loose or damaged handrails and balusters, ensuring they meet safety standards while complementing the overall design.</li>
                    <li><strong>Finish Restoration: </strong>Our team can refinish your stairs, whether it’s sanding, staining, or painting, to restore their original beauty.</li>
                    <li><strong>Custom Stair Design and Build: </strong>We collaborate with you to design and construct custom stairs that suit your style and space, using high-quality materials.</li>
                    <li><strong>Indoor and Outdoor Stair Installation: </strong>Whether you need indoor staircases for your home or outdoor stairs for your deck or patio, we have the expertise to deliver.</li>
                    <li><strong>Material Selection: </strong>We offer a range of materials, including wood, metal, and composite options, ensuring that your stairs are both durable and aesthetically pleasing.</li>
                    <li><strong>Modern and Traditional Styles: </strong>Our team is skilled in building various stair styles, from sleek, modern designs to classic, traditional staircases that match your home’s architecture.</li>
                    <li><strong>Regular Maintenance: </strong>To extend the life of your stairs, we provide ongoing maintenance services, including cleaning, refinishing, and minor repairs.</li>
                    <li><strong>Safety Inspections:</strong> We conduct thorough safety inspections to identify potential issues and recommend proactive solutions to prevent accidents.</li>
                    <li><strong>Free Consultation:</strong> We offer a free initial consultation to discuss your needs, preferences, and budget, ensuring that our services align with your expectations.</li>
                    <li><strong>Project Planning: </strong>Our team assists with every step of the planning process, from choosing materials to finalizing designs, ensuring a smooth and successful project.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Stair Repair & Building?</h4>
                    <ul>
                        <li><strong>Expertise and Experience: </strong>With years of experience in the industry, our team of skilled professionals brings a wealth of knowledge to every project. We understand the intricacies of stair construction and repair, ensuring each job is done to the highest standards.</li>
                        <li><strong>Quality Craftsmanship: </strong>We pride ourselves on our attention to detail and commitment to quality. From the materials we use to the techniques we employ, we ensure that every staircase we work on is not only functional but also a work of art.</li>
                        <li><strong>Customized Solutions: </strong>We understand that every home is unique, and so are its needs. Our customized stair designs and repair solutions are tailored to meet your specific requirements, ensuring your stairs are both beautiful and practical.</li>
                        <li><strong>Safety First: </strong>Safety is our top priority. We ensure that all our stair repairs and constructions meet or exceed building codes and safety standards, giving you peace of mind that your staircase is safe for everyday use.</li>
                        <li><strong>Comprehensive Services: </strong>Whether you need a minor repair or a complete staircase overhaul, we offer a full range of services to meet your needs. From design to installation to ongoing maintenance, we are your one-stop shop for all things stairs.</li>
                        <li><strong>Customer Satisfaction: </strong>Our commitment to customer satisfaction is unwavering. We work closely with you throughout the entire process, ensuring that the final result meets your expectations and enhances the value of your home.</li>
                        <li><strong>Timely and Efficient Service: </strong>We understand the importance of timelines and work diligently to complete projects on schedule without compromising on quality. Our efficient processes ensure minimal disruption to your daily life.</li>

                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    Safety is our top priority when it comes to stair repair and construction. We begin by thoroughly inspecting the existing structure (if it’s a repair) or the site (if it’s new construction) to identify potential hazards, such as structural weaknesses, uneven surfaces, or compliance issues with building codes. For repairs, we assess the integrity of the materials and the stability of the entire staircase to determine the necessary interventions. During construction, we ensure that every step, tread, and riser is built to precise measurements, reducing the risk of accidents or injuries. We also use high-quality, durable materials that meet or exceed safety standards, ensuring the staircase remains safe and functional over time.
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Our approach to stair repair and construction is driven by intelligent design and advanced planning. We use cutting-edge software to create detailed plans and simulations, allowing us to visualize the project and identify potential issues before construction begins. This ensures that the stairs are not only structurally sound but also ergonomically designed for comfort and ease of use. We take into account factors like the intended use of the stairs, foot traffic, and aesthetic preferences to create a design that is both practical and visually appealing. Our team stays updated on the latest construction techniques and materials, allowing us to incorporate innovative solutions that enhance the durability and functionality of the stairs.
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    Homex Builders Corp specializes in providing customized stair repair and construction services tailored to the unique needs of each project. Whether it’s repairing a historic wooden staircase, constructing a modern concrete stairway, or installing intricate railings, we have the expertise to handle a wide range of styles and materials. Our specialization includes working with different architectural styles, ensuring that the new or repaired stairs seamlessly integrate with the existing structure. We also excel in tackling complex projects, such as curved staircases, multi-level stairs, or those with special design features like lighting or decorative elements. This specialization allows us to deliver results that are not only functional but also enhance the overall aesthetic of the property.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    By choosing Homex Builders Corp, you are selecting a partner dedicated to delivering excellence in stair repair and construction. Whether it’s a simple fix or a custom-built staircase, we are here to transform your vision into reality.
                </p>
            </div>
        </>
    );
}
