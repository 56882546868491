import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import powerwashing1 from "../assets/images/masonry/powerwashing1.jpg"
import powerwashing2 from "../assets/images/masonry/powerwashing2.jpg"
import powerwashing3 from "../assets/images/masonry/powerwashing3.jpg"

export const PowerWashing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={powerwashing1} alt="Power Washing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={powerwashing2} alt="Power Washing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={powerwashing3} alt="Power Washing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Power Washing</h3>
            <div className="text">
                <p>
                    At Homex Builders Corp., we provide top-tier power washing services designed to restore the beauty and cleanliness of your property. Whether it's your home's exterior, driveway, patio, or commercial building, our power washing solutions ensure that surfaces look fresh and are free from dirt, grime, and stains. Here’s a detailed look at what we offer:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Power Washing Services?</h3>
                <ul>
                    <li><strong>House Exteriors: </strong>Remove dirt, mildew, and mold from your home’s siding, leaving it looking like new.</li>
                    <li><strong>Driveways and Walkways: </strong>Eliminate oil stains, algae, and other stubborn marks that detract from your property’s curb appeal.</li>
                    <li><strong>Decks and Patios: </strong>Clean wooden decks and stone patios to preserve their condition and enhance their appearance.</li>
                    <li><strong>Fences: Power wash </strong>fences to remove buildup and restore their original color and texture.</li>
                    <li><strong>Building Exteriors: </strong>Keep your business premises looking professional and inviting by removing grime and pollutants from walls, windows, and entrances.</li>
                    <li><strong>Parking Lots and Garages: </strong>Ensure a clean and safe environment for your customers with thorough cleaning of parking areas.</li>
                    <li><strong>Sidewalks and Entrances: </strong>Enhance the first impression of your business by maintaining spotless sidewalks and entryways.</li>
                    <li><strong>Graffiti Removal: </strong>Quickly and effectively remove graffiti from walls, signs, and other surfaces.</li>
                    <li><strong>Roof Cleaning: </strong>Safely remove moss, algae, and other debris from your roof without causing damage.</li>
                    <li><strong>Gutter Cleaning: </strong>Clear gutters of leaves, dirt, and debris to prevent clogs and water damage.</li>
                    <li><strong>Pool Decks: </strong>Keep your pool area safe and inviting by removing slippery algae and buildup from surrounding surfaces.</li>

                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Power Washing?</h4>
                    <ul>
                        <li><strong>Professional Equipment and Techniques: </strong>We use industry-leading power washing equipment and advanced techniques to achieve the best results. Our machines deliver the right amount of pressure and water flow to clean surfaces effectively without causing damage.</li>

                        <li><strong>Experienced and Trained Team: </strong>Our team is highly trained and experienced in power washing a variety of surfaces. We understand the specifics of different materials and know how to treat them properly to avoid wear and tear.</li>

                        <li><strong>Environmentally Friendly Solutions: </strong>We prioritize the use of environmentally friendly cleaning solutions that are safe for your property and the surrounding environment. Our detergents are biodegradable and effective at breaking down stubborn dirt and grime.</li>

                        <li><strong>Comprehensive Service: </strong>We offer a comprehensive power washing service that includes a thorough inspection of the areas to be cleaned, meticulous execution, and a final walkthrough to ensure complete satisfaction. We don’t consider the job done until you’re happy with the results.</li>

                        <li><strong>Competitive Pricing: </strong>We provide top-quality power washing services at competitive prices. Our transparent pricing ensures you know exactly what you’re paying for, with no hidden fees or unexpected charges.</li>

                        <li><strong>Customer Satisfaction Guaranteed: </strong>At Homex Builders Corp., customer satisfaction is at the heart of what we do. We’re committed to delivering exceptional service, from the moment you contact us to the completion of your project. Our goal is to exceed your expectations every time.</li>

                        <li><strong>Flexible Scheduling: </strong>We understand that your time is valuable. That’s why we offer flexible scheduling options to fit your busy life. Whether you need us on weekends, early mornings, or late afternoons, we’ll work around your schedule.</li>

                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    Safety and care are our top priorities when performing power washing services. We begin by carefully assessing the surfaces to be cleaned, identifying the appropriate pressure levels and cleaning agents to use. This ensures that we avoid damage to delicate surfaces like wood, vinyl siding, or painted areas. We also take into account environmental factors, such as nearby plants or water sources, to prevent any potential harm from runoff. Our team is trained to use the equipment safely, wearing protective gear and taking necessary steps to avoid injuries or accidents. Additionally, we ensure that all cleaning solutions used are eco-friendly, minimizing the impact on the environment.
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Our approach to power washing is guided by intelligent decision-making and the use of advanced technology. We employ the latest equipment that allows us to adjust pressure settings precisely, ensuring effective cleaning without damaging the surface. We also utilize specialized cleaning agents tailored to remove specific types of grime, such as mold, mildew, grease, or dirt, optimizing the cleaning process for each unique situation. Our team stays updated on the latest techniques and industry best practices, ensuring that we provide the most efficient and thorough cleaning possible.
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    Homex Builders Corp specializes in providing tailored power washing solutions for a variety of surfaces and property types. Whether we’re cleaning residential homes, commercial buildings, driveways, decks, or sidewalks, we understand the specific needs of each surface. Our specialization allows us to handle complex projects, such as removing stubborn stains, cleaning multi-story buildings, or restoring weathered surfaces to their original condition. We customize our services to meet the specific requirements of each client, ensuring that the results not only meet but exceed expectations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp. for your power washing needs means opting for a team that values quality, professionalism, and customer satisfaction. Let us help you maintain the cleanliness and beauty of your property. Contact us today to schedule your power washing service!
                </p>
            </div>
        </>
    );
}
