import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import subheader from "../assets/images/subheader.jpg";

export const SubHeader = (props) => {
    const { title } = props;
    return (
        <section className="page-title" style={{ backgroundImage: `url(${subheader})` }} >
            <div className="auto-container">
                <div className="clearfix">
                    <div className="pull-left">
                        <h1>{title}</h1>
                    </div>
                    <div className="pull-right">
                        <ul className="page-breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/">Home &nbsp; <FontAwesomeIcon icon={faAngleDoubleRight} /></a>
                            </li>
                            <li className="breadcrumb-item">
                                {title}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
