import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import RoofRepairs1 from "../assets/images/roofingservices/roof_repairs1.jpg"
import RoofRepairs2 from "../assets/images/roofingservices/roof_repairs2.jpg"
import RoofRepairs3 from "../assets/images/roofingservices/roof_repairs3.jpg"

export const RoofRepairs = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={RoofRepairs1} alt="Roof Repairs" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={RoofRepairs2} alt="Roof Repairs" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={RoofRepairs3} alt="Roof Repairs" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Roof Repairs</h3>
            <div className="text">
                <p>
                    A well-maintained roof is crucial to the safety and longevity of your home. Whether your roof has suffered damage from storms, aging, or general wear and tear, Homex Builders Corp is here to provide specialized roof repair services that restore its strength and functionality. Our team uses advanced tools, specialized techniques, and strict safety measures to ensure your roof is repaired efficiently and effectively.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Roof Inspection?</h3>
                <ul>
                    <li>
                        <strong>Comprehensive Evaluation: </strong>
                        Every element of your roof, from flashing and gutters to the structural core, is thoroughly inspected using both traditional methods and advanced technologies.
                    </li>
                    <li>
                        <strong>Detailed Report: </strong>
                        Receive a full report outlining the current condition of your roof, along with recommendations for maintenance or repairs.
                    </li>
                    <li>
                        <strong>Customized Solutions: </strong>
                        Whether it’s a small repair or preventive care, our expert team will provide the right solution tailored to your roof’s needs.
                    </li>
                </ul>
                <p>
                    Don’t wait until it’s too late. Ensure your roof is in top condition by scheduling a professional roof inspection with Homex Builders Corp today!
                </p>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Roof Repairs?</h4>
                    <ul>
                        <li>
                            <strong>Specialized Expertise: </strong>
                            Our team is highly trained in repairing all types of roofing systems, from traditional asphalt shingles to more complex commercial roofing materials. No matter the scope of the damage, we have the expertise to address it.
                        </li>
                        <li>
                            <strong>Intelligent Solutions: </strong>
                            We don’t just patch the problem—we use intelligent diagnostics to identify underlying issues that could lead to future damage. Our cutting-edge tools, such as thermal imaging and moisture sensors, allow us to assess hidden problems and repair them before they escalate.
                        </li>
                        <li>
                            <strong>Safety-Focused: </strong>Safety is our top priority. Every roof repair is carried out with rigorous safety protocols to ensure the protection of your home, our team, and your family. From using secured equipment to following industry safety standards, we ensure a smooth and hazard-free process.
                        </li>
                    </ul>
                </blockquote>

                <p>Choosing Homex Builders Corp means choosing quality, reliability, and peace of mind. If you need residential roofing services, don't hesitate to contact us today for a free consultation and estimate. Let us help you protect your home with a roof that's built to last.</p>
                <p>When it comes to commercial roofing, ensuring the job is done right requires a combination of specialized skills, intelligent planning, and strict safety precautions. Here’s how these three elements play a critical role in delivering top-notch commercial roofing services:</p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Thorough Assessments: </strong>Before any repair work begins, we conduct a comprehensive inspection to identify the full extent of the damage. This ensures we address both visible and hidden issues, preventing future complications.
                                        </li>
                                        <li>
                                            <strong>Weatherproofing: </strong>We specialize in weatherproofing repairs, ensuring your roof can withstand extreme conditions. Our repairs are designed not only to fix immediate problems but to fortify your roof against future storms, leaks, and wind damage.
                                        </li>
                                        <li>
                                            <strong>Durable Repairs: </strong>Using high-quality materials, we ensure that every repair is built to last. Our work guarantees that your roof will remain strong and secure for years to come, protecting your home from any potential threats.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Advanced Diagnostics: </strong>Using state-of-the-art tools, we assess the full condition of your roof and find any underlying issues such as water intrusion, heat loss, or structural weaknesses. This intelligent approach allows us to repair not only visible damage but also prevent future problems.
                                        </li>
                                        <li>
                                            <strong>Tailored Repair Plans: </strong>Every roof is different. Our intelligent diagnostics allow us to craft a customized repair plan suited to your roof’s specific needs, ensuring optimal performance and durability.
                                        </li>
                                        <li>
                                            <strong>Energy Efficiency: </strong>Our roof repairs aren’t just about fixing damage—they’re about enhancing your home’s overall energy efficiency. By addressing issues like improper insulation or ventilation, we help reduce energy costs and improve your home's comfort.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Shingle Repairs: </strong>Whether it's cracked, curled, or missing shingles, our team specializes in repairing and replacing all types of shingle damage to keep your roof watertight.
                                        </li>
                                        <li>
                                            <strong>Flat Roofs & Commercial Systems: </strong>We have extensive experience in repairing flat roofs and other commercial systems, addressing leaks, drainage issues, and membrane damage.
                                        </li>
                                        <li>
                                            <strong>Storm Damage Restoration: </strong>From wind damage to hail strikes, we specialize in restoring roofs damaged by severe weather. Our storm restoration services ensure your roof is reinforced to handle future storms.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Don’t let roof damage compromise the safety and value of your home. With Homex Builders Corp, you receive specialized, intelligent, and precautionary roof repair services that address every issue with precision and care.
                </p>
                <p>Contact us today to schedule your roof repair consultation and let our experts restore the strength of your roof!</p>
            </div>
        </>
    );
}
