import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import sidewalkinstallation1 from "../assets/images/sidewalk/sidewalkinstallation1.jpg"
import sidewalkinstallation2 from "../assets/images/sidewalk/sidewalkinstallation2.jpg"
import sidewalkinstallation3 from "../assets/images/sidewalk/sidewalkinstallation3.jpg"

export const SidewalkInstallation = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={sidewalkinstallation1} alt="Sidewalk Installation" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={sidewalkinstallation2} alt="Sidewalk Installation" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={sidewalkinstallation3} alt="Sidewalk Installation" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Sidewalk Installation</h3>
            <div className="text">
                <p>
                    In HomeX Builders Corp's Sidewalk Installation service, we offer a comprehensive range of services to ensure a high-quality and durable sidewalk that meets your needs and enhances your property. Here’s what we provide:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Sidewalk Installation Services?</h3>
                <ul>
                    <li><strong>Client Consultation: </strong>We start with a detailed discussion to understand your requirements, preferences, and budget.</li>
                    <li><strong>Custom Design Planning: </strong>Our team designs a sidewalk layout that complements your property and meets local regulations, including considerations for aesthetics, functionality, and accessibility.</li>
                    <li><strong>Site Survey: </strong>We conduct a thorough evaluation of the site, taking into account the terrain, existing structures, and any potential obstacles.</li>
                    <li><strong>Ground Preparation: </strong>The site is cleared, graded, and compacted to create a stable foundation. We also ensure proper drainage to prevent future issues like pooling water or erosion.</li>
                    <li><strong>Permit Acquisition: </strong>We handle all necessary permits and ensure the sidewalk installation complies with local building codes and regulations.</li>
                    <li><strong>Utility Coordination: </strong>Coordination with local utility companies to avoid disruptions or damage to existing underground utilities.</li>
                    <li><strong>Material Options: </strong>We offer a variety of materials such as concrete, pavers, or decorative stone, tailored to your aesthetic and functional needs.</li>
                    <li><strong>Quality Assurance: </strong>All materials are sourced from reputable suppliers to ensure durability and longevity.</li>
                    <li><strong>Concrete Pouring or Paver Laying:</strong> Expert installation of your chosen material, ensuring a smooth, even surface.</li>
                    <li><strong>Reinforcement and Finishing: </strong>Reinforcement techniques like rebar or mesh are used where necessary. Finishing touches, such as edging or texturing, are applied to enhance durability and appearance.</li>
                    <li><strong>Curing Process: </strong>We allow the sidewalk to cure properly, ensuring it achieves maximum strength and durability.</li>
                    <li><strong>Sealing (Optional): </strong>Application of a sealant to protect the surface from weathering, staining, and wear.</li>
                    <li><strong>Quality Inspection: </strong>A thorough inspection to ensure the sidewalk meets our high standards of quality and craftsmanship.</li>
                    <li><strong>Site Cleanup: </strong>We leave your property clean and tidy, removing any debris and equipment.</li>
                    <li><strong>Maintenance Guidance: </strong>We provide advice on how to maintain your new sidewalk to keep it in top condition.</li>
                    <li><strong>Warranty: </strong>We offer a warranty on our work, standing by the quality and durability of the installation.</li>

                </ul>
                <h3>How we Process Sidewalk Installation in NYC</h3>
                <ul>
                    <li><strong>Client Consultation: </strong>Homex Builders starts by discussing your specific needs, preferences, and any concerns related to the sidewalk installation.</li>
                    <li><strong>Site Evaluation: </strong>A team visits the location to assess the site conditions, including soil stability, existing landscaping, and any potential obstacles. They also consider local regulations and any required permits.</li>
                    <li><strong>Design Proposal: </strong>Based on your input and the site evaluation, Homex Builders creates a design proposal for the sidewalk. This includes layout, dimensions, materials, and any special features like curves, textures, or patterns.</li>
                    <li><strong>Material Selection: </strong>You choose the materials for the sidewalk, with guidance from the team on the best options for durability, aesthetics, and budget.</li>
                    <li><strong>Permit Acquisition: </strong>Homex Builders handles all necessary permits required by local authorities. This step ensures that the sidewalk installation complies with zoning laws, safety standards, and other regulations.</li>
                    <li><strong>Excavation: </strong>The installation team begins by excavating the area where the sidewalk will be placed. This involves removing any grass, dirt, or old paving materials to create a clean, stable base.</li>
                    <li><strong>Subgrade Preparation: </strong>The subgrade, or underlying soil, is graded and compacted to create a level, stable surface. This step is crucial for the longevity and stability of the sidewalk.</li>
                    <li><strong>Setting Forms: </strong>Wooden or metal forms are placed along the edges of the planned sidewalk. These forms define the shape and ensure the concrete or paving materials stay in place during installation.</li>
                    <li><strong>Reinforcement: </strong>Depending on the project’s requirements, steel reinforcement bars (rebar) or mesh may be added to the formwork to strengthen the sidewalk and prevent cracking.</li>
                    <li><strong>Concrete Pouring: </strong>If you’ve opted for a concrete sidewalk, the team mixes and pours the concrete into the prepared forms. The concrete is then leveled, smoothed, and finished according to the design specifications.</li>
                    <li><strong>Alternative Materials: </strong>For other materials like pavers, bricks, or stones, the team carefully places each unit according to the design, ensuring tight joints and a level surface.</li>
                    <li><strong>Surface Finishing: </strong>The surface of the sidewalk is finished to the desired texture—smooth, broom-finished, or stamped with a pattern. This step also includes edging and jointing to prevent cracks and ensure durability.</li>
                    <li><strong>Curing Process: </strong>For concrete sidewalks, the curing process is crucial. The team ensures the concrete cures properly over several days to achieve maximum strength.</li>
                    <li><strong>Quality Inspection: </strong>Once the installation is complete, Homex Builders conducts a thorough inspection to ensure the sidewalk meets all design and safety standards.</li>
                    <li><strong>Site Cleanup: </strong>The installation site is cleaned up, with all construction debris removed, leaving your property clean and the new sidewalk ready for use.</li>
                    <li><strong>Follow-Up: </strong>After the project is completed, Homex Builders typically follows up to ensure you are satisfied with the installation. They may also offer maintenance tips to keep the sidewalk in excellent condition.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Sidewalk Installation?</h4>
                    <p>
                        When it comes to sidewalk installation, selecting the right contractor can make all the difference in the quality, durability, and overall success of the project. Here’s why HomeX Builders Corp is the ideal choice for your sidewalk installation needs:
                    </p>
                    <ul>
                        <li><strong>Proven Expertise in Sidewalk Installation: </strong>HomeX Builders Corp brings extensive experience to the table, specializing in sidewalk installation projects of all sizes. Their team of skilled professionals is well-versed in the best practices for laying sidewalks, ensuring that your installation is not only visually appealing but also structurally sound.</li>
                        <li><strong>Customized Solutions: </strong>Every property is unique, and HomeX Builders Corp understands this. They offer tailored solutions to meet the specific needs of your project. Whether you’re installing a new sidewalk for a residential property, a commercial space, or a public area, they’ll work closely with you to design and install a sidewalk that perfectly fits your requirements.</li>
                        <li><strong>Top-Quality Materials: </strong>HomeX Builders Corp is committed to using only the highest quality materials for sidewalk installations. They source durable, long-lasting materials that can withstand heavy foot traffic and harsh weather conditions, ensuring that your sidewalk remains in excellent condition for years to come.</li>
                        <li><strong>Adherence to Local Codes and Standards: </strong>Navigating local building codes and regulations can be complex, but with HomeX Builders Corp, you don’t have to worry. Their team is knowledgeable about all relevant codes and standards, ensuring that your sidewalk installation is fully compliant. This attention to detail helps you avoid potential legal issues and guarantees a safe, well-constructed sidewalk.</li>
                        <li><strong>Comprehensive Project Management: </strong>From the initial consultation to the final walkthrough, HomeX Builders Corp provides comprehensive project management. They handle every aspect of the installation process, including site preparation, material selection, and finishing touches, ensuring a seamless experience from start to finish.</li>
                        <li><strong>Commitment to Safety: </strong>Safety is a top priority for HomeX Builders Corp. Their team follows strict safety protocols during every phase of the installation, protecting both the workers and the surrounding environment. The result is a safe and accessible sidewalk that meets all safety standards.</li>
                        <li><strong>Competitive and Transparent Pricing: </strong>HomeX Builders Corp offers competitive pricing without compromising on quality. They provide detailed, upfront estimates so you know exactly what to expect, with no hidden fees or surprises. This transparency allows you to budget confidently and ensures that you get the best value for your investment.</li>
                        <li><strong>Strong Reputation and Client Satisfaction: </strong>HomeX Builders Corp has earned a solid reputation for delivering high-quality sidewalk installations. Their commitment to excellence is reflected in the positive feedback and testimonials from satisfied clients. By choosing HomeX Builders Corp, you’re partnering with a trusted company known for its reliability and outstanding results.</li>
                    </ul>
                </blockquote>
                <p>
                    Choosing HomeX Builders Corp for your sidewalk replacement needs means choosing a company that values quality, customer satisfaction, and compliance with local regulations. Their experience, use of high-quality materials, and competitive pricing make them a top choice for homeowners and businesses alike.
                </p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Site Safety Protocols: </strong>Homex Builders Corp ensures the safety of both workers and the public by setting up barriers, caution tape, and signage around the work area. This helps prevent unauthorized access and reduces the risk of accidents.</li>
                                        <li><strong>Utility Marking: </strong>Before beginning the installation, they coordinate with local utility companies to mark any underground lines, such as gas, water, or electrical cables, to avoid accidental damage during excavation.</li>
                                        <li><strong>Weather Monitoring: </strong>They closely monitor weather conditions to avoid working in unfavorable conditions, such as heavy rain or extreme temperatures, which could compromise the quality of the installation.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Site Analysis: </strong>Homex Builders Corp conducts a detailed analysis of the installation site, considering factors like soil stability, drainage patterns, and existing landscaping. This ensures the new sidewalk will be durable and properly integrated into the environment.</li>
                                        <li><strong>Advanced Materials: </strong>They select high-quality materials, tailored to the specific needs of the location. This includes choosing the right type of concrete mix, which is essential for longevity and resistance to weathering.</li>
                                        <li><strong>Project Management Software: </strong>The company uses advanced project management tools to plan and execute the installation efficiently. This includes scheduling tasks, managing resources, and ensuring that each phase of the project is completed on time.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>

                                        <li><strong>Skilled Workforce: </strong>Homex Builders Corp employs a team of highly skilled professionals who specialize in concrete work and sidewalk installations. Their expertise ensures that the sidewalk is installed correctly, with attention to detail in every aspect of the process.</li>
                                        <li><strong>Customization Options: </strong>They offer customized solutions, such as designing sidewalks with specific textures, colors, or patterns to meet the aesthetic and functional needs of the client.</li>
                                        <li><strong>Sustainable Practices:</strong> Homex Builders Corp integrates sustainable practices into their installations, such as using eco-friendly materials and techniques that reduce waste and minimize environmental impact.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    By offering these services, HomeX Builders Corp ensures that your sidewalk installation is a smooth, efficient process that results in a beautiful and lasting addition to your property.
                </p>
            </div>
        </>
    );
}
