import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SubHeader } from './SubHeader';
import OwlCarousel, { Options } from 'react-owl-carousel';
import { ServicesList } from './ServicesList';

export const Roofing = (props) => {
    return (
        <>
            <SubHeader title="Roofing" />
            <section className="featured-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-column col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title centered">
                                    <div className="title">Roofing Services</div>
                                    <h2><span className="theme_color">Your Trusted Roofing Solutions</span> Partner in New York</h2>
                                </div>
                                <div className="text justified">
                                    <p>Homex Builders Corp is your trusted roofing solutions partner in New York, committed to delivering exceptional service and quality craftsmanship. With a deep understanding of the unique demands of New York's architecture and climate, we provide tailored roofing solutions that stand the test of time.</p>
                                    <p>At Homex Builders Corp, we specialize in a comprehensive range of roofing services, including inspections, repairs, maintenance, and full roof replacements. Whether you're a homeowner looking to protect your investment or a business owner needing reliable roofing for your commercial property, our skilled team is equipped to handle projects of all sizes and complexities.</p>
                                    <p>What sets us apart is our dedication to quality and customer satisfaction. We use top-grade materials and follow industry best practices to ensure that your roof not only enhances the appearance of your property but also provides robust protection against the elements. With years of experience in the roofing industry, we have built a reputation for excellence, making us the go-to roofing partner for countless clients across New York.</p>
                                    <p>Trust Homex Builders Corp to deliver roofing solutions that meet your needs and exceed your expectations. Contact us today to schedule a consultation and discover why we're the preferred choice for roofing services in New York.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServicesList serviceType="roofing-services" title="Roofing" />
        </>
    );
}
