import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import window1 from "../assets/images/waterproofingservices/window1.jpg"
import window2 from "../assets/images/waterproofingservices/window2.jpg"
import window3 from "../assets/images/waterproofingservices/window3.jpg"

export const WindowCaulking = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={window1} alt="Window Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={window2} alt="Window Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={window3} alt="Window Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Window Caulking</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers professional Window Caulking Services designed to seal gaps around windows, preventing drafts, water leaks, and energy loss. Here’s an overview of their services and reasons why clients choose them:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Window Caulking Services?</h3>
                <ul>
                    <li><strong>New Window Installation Caulking: </strong>Homex Builders Corp provides caulking services for newly installed windows, ensuring a perfect seal that protects against air and water infiltration. This service is crucial for maintaining the energy efficiency and comfort of your building from the outset.</li>
                    <li><strong>Re-caulking Existing Windows: </strong>Over time, window caulking can deteriorate, leading to drafts, water leaks, and higher energy bills. Homex Builders Corp offers re-caulking services to renew the seal around your windows, restoring their effectiveness and preventing potential damage.</li>
                    <li><strong>Weatherproofing: </strong>The company uses high-quality, weather-resistant caulks that can withstand extreme temperatures, UV exposure, and moisture. This ensures that your windows remain well-sealed and protected against the elements year-round.</li>
                    <li><strong>Specialized Caulking for Unique Window Designs: </strong>Homex Builders Corp specializes in caulking for custom and non-standard windows, including large, arched, and uniquely shaped windows. They use tailored techniques to ensure a seamless and durable seal.</li>
                    <li><strong>Inspection and Maintenance: </strong>In addition to initial caulking, the company offers inspection and maintenance services to check the condition of existing caulking and perform necessary touch-ups. Regular maintenance helps prolong the life of your windows and caulking.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Window Caulking?</h4>
                    <ul>
                        <li><strong>Expertise and Experience: </strong>Homex Builders Corp has extensive experience in window caulking, with a team of skilled professionals who understand the intricacies of different caulking materials and application techniques. Their expertise ensures that every job is done to the highest standard.</li>
                        <li><strong>Quality Materials: </strong>The company uses only the best caulking materials, including premium-grade silicone, latex, and polyurethane caulks. These materials are chosen for their durability, flexibility, and resistance to weathering, ensuring a long-lasting seal.</li>
                        <li><strong>Attention to Detail: </strong>Homex Builders Corp is known for its meticulous attention to detail. They ensure that every inch of the window frame is properly sealed, preventing any potential issues with air leaks or water infiltration.</li>
                        <li><strong>Energy Efficiency: </strong>Proper window caulking is key to maintaining energy efficiency in a building. By sealing gaps around windows, Homex Builders Corp helps reduce energy costs, making your home or business more environmentally friendly and cost-effective.</li>
                        <li><strong>Custom Solutions: </strong>The company provides customized caulking solutions tailored to the specific needs of each project. Whether it’s a historic building, a modern commercial property, or a residential home, they have the expertise to handle any type of window.</li>
                        <li><strong>Reliable and Timely Service: </strong>Homex Builders Corp is committed to delivering reliable and timely service. They work efficiently to complete projects on schedule, minimizing disruption to your daily routine or business operations.</li>
                        <li><strong>Customer Satisfaction: </strong>The company places a strong emphasis on customer satisfaction, working closely with clients to understand their needs and deliver solutions that exceed expectations. Their commitment to quality and service has earned them a reputation for excellence.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Thorough Inspection: </strong>Before starting the caulking process, Homex Builders Corp conducts a detailed inspection of the window frames, sills, and surrounding areas. This helps identify any existing damage, gaps, or old caulk that needs to be removed.</li>
                                        <li><strong>Surface Preparation: </strong>The area around the windows is carefully cleaned to remove dirt, old caulk, and any loose material. Proper surface preparation is critical to ensure the new caulk adheres properly and provides a durable seal.</li>
                                        <li><strong>Material Selection: </strong>The type of caulk used is chosen based on the specific requirements of the project, such as weather conditions, material compatibility, and the need for flexibility. Homex Builders Corp uses high-quality, weather-resistant caulking materials to ensure long-lasting results.</li>
                                        <li><strong>Weather Considerations: </strong>Caulking is usually avoided during extremely cold or wet weather, as these conditions can affect the curing process and the adhesion of the caulk. Homex Builders Corp monitors weather forecasts to schedule work during optimal conditions.</li>
                                        <li><strong>Protection of Surrounding Areas: </strong>To prevent any damage to the window panes, frames, or nearby surfaces, Homex Builders Corp takes care to protect these areas during the caulking process. This includes using painter’s tape and drop cloths as needed.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Expert Material Knowledge: </strong>Homex Builders Corp has extensive knowledge of various caulking materials, including silicone, latex, and polyurethane caulks. This expertise allows them to select the most suitable product for each specific application, ensuring effective sealing and longevity.</li>
                                        <li><strong>Precision Application: </strong>The company employs skilled technicians who are trained in the precise application of caulk. This precision ensures a clean, even seal around windows, which is crucial for both aesthetic appeal and functionality.</li>
                                        <li><strong>Understanding of Building Dynamics: </strong>Homex Builders Corp understands how buildings expand and contract with temperature changes, and selects caulking materials that can accommodate this movement without cracking or losing adhesion.</li>
                                        <li><strong>Energy Efficiency Expertise:</strong> By applying caulk to seal gaps around windows, the company helps improve the energy efficiency of buildings, reducing drafts and heat loss. This not only enhances comfort but also contributes to lower energy bills.</li>
                                        <li><strong>Advanced Tools and Techniques: </strong>The company uses advanced tools and techniques to apply caulking with precision. This includes the use of caulking guns with variable speed settings and specialized nozzles for hard-to-reach areas.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Residential Window Caulking: </strong>Homex Builders Corp specializes in residential window caulking, helping homeowners protect their properties from water damage, drafts, and pests. Their services are tailored to meet the unique needs of each home, whether it’s a new installation or maintenance of older windows.</li>
                                        <li><strong>Commercial Window Caulking: </strong>The company also provides window caulking services for commercial buildings, where large-scale windows and glass facades require expert sealing to prevent energy loss and maintain the building’s aesthetic appeal.</li>
                                        <li><strong>Custom Window Solutions: </strong>Homex Builders Corp offers custom caulking solutions for non-standard windows, including arched, round, or custom-designed windows that require specialized attention to detail.</li>
                                        <li><strong>Restoration Projects: </strong>For older buildings or those undergoing restoration, Homex Builders Corp offers caulking services that match the original materials and aesthetics of the structure, ensuring that the integrity and appearance of the building are preserved.</li>
                                        <li><strong>Long-Term Maintenance Plans: </strong>In addition to initial caulking services, Homex Builders Corp provides long-term maintenance plans that include regular inspections and touch-ups as needed. This proactive approach helps extend the life of the caulking and ensures ongoing protection.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for window caulking services ensures that your windows are professionally sealed, enhancing the comfort, energy efficiency, and longevity of your property. Their combination of expertise, high-quality materials, and dedication to customer satisfaction makes them the preferred choice for window caulking projects.
                </p>
            </div>
        </>
    );
}
