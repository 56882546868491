import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import waterdamage1 from "../assets/images/restoration/waterdamage1.jpg"
import waterdamage2 from "../assets/images/restoration/waterdamage2.jpg"
import waterdamage3 from "../assets/images/restoration/waterdamage3.jpg"

export const WaterDamageRestoration = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={waterdamage1} alt="Water Damage Restoration" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={waterdamage2} alt="Water Damage Restoration" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={waterdamage3} alt="Water Damage Restoration" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Water Damage Restoration</h3>
            <div className="text">
                <p>
                    HomeX Builders Corp offers a comprehensive water damage restoration service aimed at mitigating and repairing the effects of water intrusion in residential or commercial properties. Here’s what their water damage restoration service typically involves:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Water Damage Restoration Services?</h3>
                <ul>
                    <li><strong>Emergency Response and Assessment: </strong>Water damage requires immediate attention, so HomeX Builders Corp typically offers 24/7 emergency services. They begin with a rapid response to assess the extent of the damage, identifying the source of the water and the areas affected. This assessment helps them develop a tailored plan for the restoration process.</li>
                    <li><strong>Water Extraction: </strong>The first step in the actual restoration is removing standing water from the affected areas. HomeX Builders Corp uses industrial-grade pumps and vacuums to extract water quickly and efficiently, minimizing further damage.</li>
                    <li><strong>Drying and Dehumidification: </strong>After water extraction, thorough drying is crucial. They use high-powered air movers, fans, and dehumidifiers to remove moisture from the air and materials like walls, floors, and furniture. This step is vital to prevent mold growth and structural damage.</li>
                    <li><strong>Cleaning and Sanitizing: </strong>Once the area is dry, they clean and sanitize all affected surfaces to remove contaminants, dirt, and bacteria brought in by the water. This may include cleaning carpets, upholstery, and other porous materials. They also apply antimicrobial treatments to prevent mold and mildew growth.</li>
                    <li><strong>Mold Remediation: </strong>If mold has already started to develop due to the water damage, HomeX Builders Corp offers mold remediation services. They identify and remove mold colonies, clean the air with HEPA filters, and treat the area to prevent future mold growth.</li>
                    <li><strong>Repair and Restoration: </strong>After the area is clean and dry, HomeX Builders Corp begins the repair process. This can involve replacing drywall, flooring, and insulation, as well as repairing structural damage. They may also repaint walls, replace damaged fixtures, and restore any affected parts of the building to their pre-damage condition.</li>
                    <li><strong>Final Inspection and Restoration Completion: </strong>A final inspection is conducted to ensure that all areas are thoroughly dried, repaired, and restored to the company’s high standards. They also check for any remaining moisture or potential issues that could lead to future problems.</li>
                    <li><strong>Insurance Assistance:</strong> HomeX Builders Corp often assists clients with the insurance claims process, providing documentation and working directly with insurance companies to ensure that claims are handled smoothly and that clients receive the coverage they’re entitled to.</li>
                </ul>
                <p>
                    HomeX Builders Corp's water damage restoration service is designed to address all aspects of water damage, from immediate response to full restoration, ensuring that your property is returned to a safe and habitable condition as quickly as possible.
                </p>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Water Damage Restoration?</h4>
                    <p>
                        When dealing with water damage, you need a reliable and experienced team to restore your property efficiently and effectively. Here’s why we are the best choice for your water damage restoration needs:
                    </p>
                    <ul>
                        <li><strong>Rapid Response and 24/7 Availability: </strong>Water damage requires immediate attention, and at Homex Builders Corp, we understand the urgency. Our team is available 24/7 to respond quickly to emergencies, minimizing damage and reducing restoration costs. We prioritize getting to your site fast, because every minute counts in preventing further damage.</li>

                        <li><strong>Comprehensive Restoration Services: </strong>We offer a full suite of water damage restoration services, including water extraction, drying, dehumidification, mold remediation, and structural repairs. Our comprehensive approach ensures that every aspect of the damage is addressed, from the visible water to hidden moisture that could lead to mold growth and structural issues.</li>

                        <li><strong>Advanced Equipment and Techniques: </strong>Our team utilizes the latest technology and equipment in water damage restoration. From industrial-grade water extractors and air movers to moisture meters and infrared cameras, we use cutting-edge tools to ensure thorough and efficient restoration. Our advanced techniques help us restore your property quickly and effectively.</li>

                        <li><strong>Certified and Experienced Professionals: </strong>At Homex Builders Corp, our team consists of certified and experienced professionals who are experts in water damage restoration. We follow industry best practices and standards, ensuring that your property is restored to its pre-damage condition safely and efficiently. Our experience allows us to handle all types of water damage, whether caused by floods, leaks, or plumbing failures.</li>

                        <li><strong>Thorough Inspection and Assessment: </strong>We start every restoration project with a thorough inspection and assessment. We identify the extent of the damage, including any hidden moisture that could cause future problems. This careful assessment allows us to create a detailed restoration plan tailored to your specific needs, ensuring no issue is overlooked.</li>

                        <li><strong>Insurance Assistance: </strong>Dealing with insurance claims after water damage can be stressful. Homex Builders Corp helps ease this burden by working directly with your insurance company. We provide detailed documentation of the damage and the restoration work, making the claims process smoother and more straightforward for you.</li>

                        <li><strong>Focus on Health and Safety: </strong>Water damage can pose serious health risks, particularly when mold and bacteria are involved. We prioritize the health and safety of your family or employees by using safe and effective methods to remove contaminants and prevent mold growth. Our team follows strict safety protocols to ensure a clean and healthy environment.</li>

                        <li><strong>Customer-Centric Approach: </strong>Your satisfaction is our top priority. We communicate with you every step of the way, keeping you informed about the progress of the restoration and addressing any concerns you may have. Our goal is to make the restoration process as stress-free as possible, delivering results that exceed your expectations.</li>

                        <li><strong>Proven Track Record: </strong>Homex Builders Corp has a strong reputation for delivering exceptional water damage restoration services. Our clients consistently praise our quick response, professionalism, and high-quality work. We’re proud of our track record and are committed to maintaining our reputation as a trusted leader in the industry.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Safety Protocols: </strong>Homex Builders Corp prioritizes safety by implementing rigorous safety measures to protect both their workers and the occupants of the affected property. This includes using personal protective equipment (PPE) to prevent exposure to contaminants, mold, and hazardous materials.</li>
                                        <li><strong>Containment Strategies: </strong>To prevent the spread of water damage, especially mold and mildew, the company uses containment barriers such as plastic sheeting and negative air pressure systems. This keeps the damage localized and minimizes further impact on the property.</li>
                                        <li><strong>Moisture Monitoring: </strong>Homex Builders utilizes state-of-the-art moisture detection tools to monitor humidity and moisture levels throughout the restoration process. This ensures that all affected areas are thoroughly dried, reducing the risk of hidden moisture leading to mold growth or structural damage.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Comprehensive Assessment: </strong>The team conducts a thorough assessment of the water damage, identifying both the visible and hidden impacts. This includes evaluating structural integrity, electrical systems, and the presence of mold or other hazards.</li>
                                        <li><strong>Advanced Technology: </strong>Homex Builders leverages advanced technology such as infrared cameras, moisture meters, and thermal imaging to detect hidden water damage that might not be immediately apparent. This intelligence allows for a more accurate and effective restoration plan.</li>
                                        <li><strong>Strategic Planning: </strong>Based on their assessment, Homex Builders develops a strategic restoration plan that prioritizes the most critical areas first, ensuring a systematic approach that addresses both immediate damage and long-term prevention.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Water Extraction and Drying: </strong>Homex Builders specializes in rapid water extraction and drying techniques. They use industrial-grade dehumidifiers, air movers, and water extractors to remove standing water and moisture quickly, minimizing damage and reducing the risk of mold.</li>
                                        <li><strong>Mold Remediation: </strong>The company has specialized expertise in mold remediation, a critical component of water damage restoration. Their team is trained to safely remove mold, treat affected areas, and apply preventive measures to avoid future mold growth.</li>
                                        <li><strong>Structural Repairs: </strong>In addition to drying and remediation, Homex Builders specializes in repairing and restoring structural elements that have been compromised by water damage. This includes replacing drywall, flooring, and insulation, as well as reinforcing weakened structures to ensure the building’s safety and integrity.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Don’t let water damage disrupt your life any longer. Contact us today for fast, reliable, and professional water damage restoration services. Let us restore your property to its original condition with our expertise and dedication.
                </p>
            </div>
        </>
    );
}
