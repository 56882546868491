import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SubHeader } from './SubHeader';
import { ContactUsForm } from './ContactUsForm';

export const ContactUs = (props) => {
    return (
        <>
            <SubHeader title="Contact Us" />
            <section className="contact-section">
                <div className="auto-container">
                    <h2><span className="theme_color">Get</span> in Touch</h2>
                    <div className="text">
                        Ready to bring your vision to life? Whether you're planning a home renovation, a commercial build, or a large-scale development, Homex Builders Corp is here to help. Our team of experts is committed to delivering high-quality, on-time, and on-budget projects across New York City. You can talk to our online representative at any time.
                    </div>
                    <div className="row clearfix">
                        <div className="form-column col-lg-9 col-md-8 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="contact-form style-two">

                                    <div className="wpcf7 js" id="wpcf7-f313-p300-o1" lang="en-US" dir="ltr">
                                        <div className="screen-reader-response">
                                            <p role="status" aria-live="polite" aria-atomic="true"></p>
                                            <ul></ul>
                                        </div>
                                        <ContactUsForm />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-column col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <ul className="list-style-two">
                                <li><span className="icon flaticon-home-1"></span><strong>827 Mace Ave Bronx,</strong>NY 10467, United States</li>
                                <li><span className="icon flaticon-envelope-1"></span><strong>Send your mail at</strong>Customer@homexbuilders.com, Homexbuildercorp@gmail.com</li>
                                <li><span className="icon flaticon-technology-2"></span><strong>Have Any Question</strong>(929) 385-3287</li>
                                <li><span className="icon flaticon-clock-1"></span><strong>Working Hours</strong>Mon-Sat:8:00am to 5:00pm</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
