import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

export const Error = (props) => {

    return (
        <>
            There is an error with image path mapping. please contact your hosting company to map images path.
        </>
    );
}
