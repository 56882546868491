import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import sidewalkreplacement1 from "../assets/images/sidewalk/sidewalkreplacement1.jpg"
import sidewalkreplacement2 from "../assets/images/sidewalk/sidewalkreplacement2.jpg"
import sidewalkreplacement3 from "../assets/images/sidewalk/sidewalkreplacement3.jpg"

export const SidewalkReplacement = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={sidewalkreplacement1} alt="Sidewalk Replacement" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={sidewalkreplacement2} alt="Sidewalk Replacement" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={sidewalkreplacement3} alt="Sidewalk Replacement" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Sidewalk Replacement</h3>
            <div className="text">
                <p>
                    In HomeX Builders Corp's Sidewalk Replacement service, you can expect a comprehensive solution tailored to meet local regulations and enhance the safety and appearance of your property. Here's what we typically provide:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Sidewalk Replacement Services?</h3>
                <ul>
                    <li><strong>Assessment and Inspection: </strong>We start by assessing the condition of the existing sidewalk, identifying any damage, uneven surfaces, or compliance issues with local codes.</li>
                    <li><strong>Demolition and Removal: </strong>The old, damaged sidewalk is carefully demolished and removed, ensuring the site is clean and ready for the new installation.</li>
                    <li><strong>Site Preparation: </strong>The area is graded and prepared to ensure a stable foundation for the new sidewalk, including proper drainage considerations.</li>
                    <li><strong>Concrete Pouring and Finishing: </strong>High-quality concrete is poured and expertly finished to create a smooth, durable, and long-lasting sidewalk that enhances the curb appeal of your property.</li>
                    <li><strong>Curing and Sealing: </strong>We allow the concrete to properly cure and apply a sealant, if requested, to protect the surface from weather damage and wear.</li>
                    <li><strong>Final Inspection and Cleanup: </strong>After the installation is complete, we perform a thorough inspection to ensure the work meets our high standards. We also clean up the site, leaving your property looking better than before.</li>
                    <li><strong>Warranty and Maintenance Advice: </strong>We stand by the quality of our work with a warranty and provide tips for maintaining your new sidewalk to keep it in top condition.</li>
                </ul>
                <h3>How we Process Sidewalk Replacement in NYC</h3>
                <ul>
                    <li><strong>Site Inspection: </strong>A team from Homex Builders visits your property to assess the condition of the existing sidewalk. They check for cracks, uneven surfaces, or compliance issues with local regulations.</li>
                    <li><strong>Client Consultation: </strong>They discuss your needs, preferences, and any specific concerns you might have about the project.</li>
                    <li><strong>Project Proposal: </strong>Based on the assessment, Homex Builders prepares a detailed proposal outlining the scope of work, materials to be used, and estimated timelines.</li>
                    <li><strong>Cost Estimate: </strong>You receive a transparent quotation that breaks down the costs, including labor, materials, and any additional services.</li>
                    <li><strong>Permitting Process: </strong>Homex Builders handles the necessary permits required by local authorities for sidewalk replacement. This ensures the project complies with all local regulations.</li>
                    <li><strong>Old Sidewalk Removal: </strong>The existing sidewalk is carefully removed, with the debris being disposed of in an environmentally friendly manner. This step includes breaking up the concrete and preparing the ground for the new installation.</li>
                    <li><strong>Subgrade Preparation: </strong>The team ensures the underlying soil or base is properly graded and compacted to provide a stable foundation for the new sidewalk.</li>
                    <li><strong>Formwork Setup: </strong>Wooden or metal forms are placed to define the shape and boundaries of the new sidewalk.</li>
                    <li><strong>Concrete Pouring: </strong>High-quality concrete is mixed and poured into the forms. The team then smoothens and finishes the surface, ensuring it’s even and has the proper slope for drainage.</li>
                    <li><strong>Curing Process: </strong>The concrete is allowed to cure properly, ensuring durability and longevity.</li>
                    <li><strong>Quality Check: </strong>After the concrete has cured, Homex Builders conducts a final inspection to ensure the sidewalk meets all safety and quality standards.</li>
                    <li><strong>Site Cleanup: </strong>The area is cleaned up, with any excess materials or debris removed, leaving your property neat and tidy.</li>
                    <li><strong>Follow-Up:</strong> Homex Builders typically follows up after completion to ensure you're satisfied with the work. They also provide advice on how to maintain the sidewalk to prevent future issues.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Sidewalk Replacement?</h4>
                    <p>
                        When considering sidewalk replacement, choosing the right contractor is crucial to ensure the job is done efficiently and to a high standard. Here's why HomeX Builders Corp stands out as an excellent choice for this service:
                    </p>
                    <ul>

                        <li><strong>Expertise and Experience: </strong>HomeX Builders Corp has years of experience in the construction industry, specializing in sidewalk replacement. Their team is skilled in handling a wide range of projects, from small residential sidewalks to large commercial spaces. This expertise ensures that your project will be completed with precision and care.</li>
                        <li><strong>Compliance with Local Regulations: </strong>Sidewalk replacements must comply with local codes and regulations, which can vary significantly depending on your location. HomeX Builders Corp has extensive knowledge of these regulations and ensures that all work meets the required standards. This helps avoid potential fines or the need for costly rework.</li>
                        <li><strong>High-Quality Materials: </strong>HomeX Builders Corp uses only the best materials for sidewalk replacements. Whether it's concrete, pavers, or any other material, they ensure that it is durable and long-lasting, which is essential for withstanding weather conditions and daily wear and tear.</li>
                        <li><strong>Customer-Centric Approach: </strong>Customer satisfaction is a top priority for HomeX Builders Corp. They offer personalized service, listening to your specific needs and preferences, and working with you throughout the project. Their transparent communication and commitment to meeting deadlines ensure a smooth experience.</li>
                        <li><strong>Competitive Pricing: </strong>While offering top-notch service, HomeX Builders Corp also provides competitive pricing. They offer clear and detailed estimates, ensuring there are no hidden costs, which gives you peace of mind when budgeting for your sidewalk replacement.</li>
                        <li><strong>Comprehensive Services: </strong>In addition to sidewalk replacement, HomeX Builders Corp can handle related services such as curb installation, driveway repair, and more. This makes them a one-stop solution for your exterior construction needs.</li>
                        <li><strong>Positive Reputation: </strong>HomeX Builders Corp has built a solid reputation in the community for delivering quality work. Positive reviews and testimonials from previous clients highlight their reliability and the excellent results they consistently achieve.</li>
                        <li><strong>Safety First: </strong>Safety is a core focus in all projects undertaken by HomeX Builders Corp. They ensure that all work is performed safely, minimizing risks to both their team and the public. This focus on safety also extends to ensuring that the sidewalks they replace are safe for pedestrians.</li>
                    </ul>
                </blockquote>
                <p>
                    Choosing HomeX Builders Corp for your sidewalk replacement needs means choosing a company that values quality, customer satisfaction, and compliance with local regulations. Their experience, use of high-quality materials, and competitive pricing make them a top choice for homeowners and businesses alike.
                </p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Safety Barriers: </strong>Homex Builders Corp sets up safety barriers around the worksite to protect pedestrians and prevent accidents. They use clear signage and reflective materials, especially in high-traffic areas.</li>
                                        <li><strong>Site Inspection: </strong>Before starting any work, they conduct a thorough site inspection to identify potential hazards like underground utilities or unstable soil conditions. This helps avoid disruptions and accidents during the construction process.</li>
                                        <li><strong>Permit Acquisition:</strong> They handle all necessary permits and adhere to local regulations, ensuring that the sidewalk replacement meets all legal requirements.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Material Selection: </strong>Homex Builders Corp uses advanced materials that are both durable and environmentally friendly. They analyze the specific needs of the area, such as climate conditions, to choose the best materials for long-lasting results.</li>
                                        <li><strong>Project Planning Software: </strong>They utilize project management software to streamline operations. This includes scheduling, resource allocation, and tracking progress, which helps in delivering the project on time and within budget.</li>
                                        <li><strong>Surveying Technology: </strong>High-precision surveying tools are used to assess the existing conditions and plan the sidewalk layout accurately. This minimizes errors and ensures the new sidewalk aligns perfectly with the surrounding infrastructure.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Experienced Workforce: </strong>Homex Builders Corp employs specialized crews with extensive experience in concrete work and sidewalk replacement. Their expertise ensures that the work is performed to the highest standards.</li>
                                        <li><strong>Custom Design Solutions: </strong>Depending on the project’s requirements, they offer custom design options, such as decorative concrete finishes or unique patterns, which enhance the aesthetic appeal of the sidewalk.</li>
                                        <li><strong>Sustainability Practices: </strong>They incorporate sustainable practices, such as using recycled materials and implementing water management systems, to minimize environmental impact.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    If you're considering sidewalk replacement, contact Homex Builders Corp for a professional and reliable service. They'll guide you through every step of the process, from the initial consultation to the final inspection.
                </p>
            </div>
        </>
    );
}
