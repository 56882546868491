import React, { Component, useRef, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleUp, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export const Layout = (props) => {
    const myRef = useRef(null);
    const pathName = useLocation();

    return (
        <div>
            <NavMenu reference={myRef} />
            <div>
                {props.children}
            </div>
            <Footer pathName={pathName.pathname} />
            <div className="scroll-to-top scroll-to-target" data-target="html" style={{ display: "none" }} onClick={() => myRef.current?.scrollIntoView({
                behavior: 'smooth'
            })}>
				<FontAwesomeIcon icon={faAngleDoubleUp} className="icon" />
            </div>
            <a href="https://wa.me/+19293854789?text=." target="_blank" className="btn-whatsapp-pulse">
                <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a href="tel:+19293854789" target="_blank" className="btn-whatsapp-pulse phone-button">
                <FontAwesomeIcon icon={faPhone} />
            </a>
        </div>
    );
}
