import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import foundation1 from "../assets/images/waterproofingservices/foundation1.jpg"
import foundation2 from "../assets/images/waterproofingservices/foundation2.jpg"
import foundation3 from "../assets/images/waterproofingservices/foundation3.jpg"

export const FoundationWaterproofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={foundation1} alt="Foundation Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={foundation2} alt="Foundation Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={foundation3} alt="Foundation Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Foundation Waterproofing</h3>
            <div className="text">
                <p>
                    Homex Builders Corp provides comprehensive foundation waterproofing solutions tailored to meet the specific needs of your home and its environment. Their services are designed to address various types of water infiltration issues, whether from groundwater, rain, or other sources.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Foundation Waterproofing Services?</h3>
                <p>
                    Homex Builders Corp provides comprehensive foundation waterproofing solutions tailored to meet the specific needs of your home and its environment. Their services are designed to address various types of water infiltration issues, whether from groundwater, rain, or other sources.
                </p>
                <ul>
                    <li><strong>Excavation and Waterproof Membranes: </strong>Homex Builders conducts professional excavation around the foundation of your home to expose the foundation walls. They then apply high-quality waterproof membranes to the exterior surfaces of the foundation. These membranes create a robust barrier that prevents water from penetrating the foundation walls.</li>
                    <li><strong>Drainage Systems Installation: </strong>Proper drainage is critical in keeping water away from your foundation. Homex Builders installs effective exterior drainage systems, such as French drains and surface drains, to redirect water away from the foundation and prevent water buildup.</li>
                    <li><strong>Foundation Wall Coatings: </strong>In addition to membranes, Homex Builders applies specialized coatings to foundation walls, such as asphalt-based or elastomeric coatings. These coatings provide an extra layer of protection against water seepage and are highly effective in sealing minor cracks and imperfections.</li>
                    <li><strong>Sealants and Epoxy Injections: </strong>For minor cracks or leaks, Homex Builders uses high-grade sealants and epoxy injections to fill and seal foundation cracks from the inside. This helps prevent water seepage and provides a durable, watertight seal.</li>
                    <li><strong>Interior Drainage Systems: </strong>Homex Builders installs interior drainage systems, such as perimeter drains and sump pumps, to capture and remove water that enters the basement or crawl space. These systems effectively manage water that may seep in through the foundation and prevent basement flooding.</li>
                    <li><strong>Vapor Barriers: </strong>Vapor barriers are installed on interior walls and floors to prevent moisture from penetrating into the living space. This is particularly useful in areas with high humidity or where groundwater pressure is a concern.</li>
                    <li><strong>Crack Repair: </strong>Homex Builders uses advanced materials and techniques to repair foundation cracks, which are often the primary entry points for water. Their repair methods restore the structural integrity of the foundation and prevent future water infiltration.</li>
                    <li><strong>Foundation Reinforcement: </strong>For foundations showing signs of weakening or shifting, Homex Builders offers reinforcement services, such as carbon fiber straps or wall anchors, to stabilize the foundation and prevent further movement or cracking.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Foundation Waterproofing?</h4>
                    <p>
                        Choosing Homex Builders Corp for foundation waterproofing services comes with several advantages that make them a trusted partner for homeowners looking to protect their property from water damage and structural issues. Here’s why you should consider their services:
                    </p>
                    <ul>
                        <li><strong>Skilled Professionals: </strong>Homex Builders has a team of highly trained and experienced professionals who specialize in foundation waterproofing. Their expertise ensures that every project is handled with precision and care, using the most effective techniques and materials.</li>
                        <li><strong>Comprehensive Knowledge: </strong>Homex Builders has extensive knowledge of the various types of foundations and the specific waterproofing methods best suited for each. Whether your home has a concrete slab, crawl space, or basement foundation, they have the expertise to provide the right solution.</li>
                        <li><strong>Durable Materials: Homex </strong>Builders uses only the best materials that are specifically designed for waterproofing applications. These materials are chosen for their durability, resistance to moisture, and ability to withstand harsh environmental conditions.</li>
                        <li><strong>Cutting-Edge Techniques: </strong>They stay updated with the latest advancements in waterproofing technology and utilize state-of-the-art tools and methods. This includes advanced coatings, membranes, and drainage systems that provide superior protection against water intrusion.</li>
                        <li><strong>Tailored Approach: </strong>Homex Builders understands that every home is unique and requires a customized approach to waterproofing. They assess the specific needs and conditions of your foundation, soil type, and local climate to develop a tailored waterproofing plan that offers the best protection.</li>
                        <li><strong>Full Range of Services: </strong>By offering both interior and exterior waterproofing solutions, Homex Builders provides a comprehensive range of services that can address all potential sources of water intrusion. This holistic approach ensures complete protection for your foundation.</li>
                        <li><strong>Transparent Communication: </strong>Homex Builders values transparent and open communication with their clients. They take the time to explain the waterproofing process, discuss the materials and methods they will use, and ensure you are fully informed and comfortable with the work being done.</li>
                        <li><strong>Warranty and Support: </strong>They offer warranties on their waterproofing services, providing you with peace of mind that your investment is protected. They also provide ongoing support and maintenance services to ensure your foundation remains dry and secure over time.</li>
                        <li><strong>Satisfied Customers: </strong>Homex Builders has a strong reputation for delivering high-quality foundation waterproofing services, with numerous positive reviews and testimonials from satisfied customers. Their proven track record demonstrates their reliability and commitment to excellence.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    Homex Builders Corp takes several important precautions to ensure the safety, effectiveness, and durability of their foundation waterproofing services. These precautions help prevent common problems such as water infiltration, foundation deterioration, and mold growth, which can compromise the structural integrity of a home.
                                </p>
                                <p>
                                    <ul>

                                        <li><strong>Comprehensive Site Assessment: </strong>Before beginning any foundation waterproofing project, Homex Builders conducts a thorough assessment of the property. This assessment identifies potential water entry points, assesses the soil condition around the foundation, and determines the level of moisture or damage already present.</li>
                                        <li><strong>Proper Surface Preparation: </strong>Proper surface preparation is essential for effective waterproofing. Homex Builders ensures that all foundation surfaces are clean, dry, and free from cracks or debris. This preparation may involve cleaning, repairing, or sealing cracks and gaps in the foundation to ensure a smooth and solid surface for the waterproofing materials to adhere to.</li>
                                        <li><strong>High-Quality Waterproofing Materials: </strong>To ensure long-lasting protection, Homex Builders uses only high-quality, industry-approved waterproofing materials. These materials are chosen for their durability, flexibility, and ability to withstand moisture, temperature fluctuations, and environmental stressors.</li>
                                        <li><strong>Safety Measures: </strong>Homex Builders follows strict safety protocols to protect both their workers and your property during the waterproofing process. This includes using appropriate personal protective equipment (PPE), securing the work area, and ensuring proper ventilation when applying waterproofing products.</li>
                                        <li><strong>Weather Considerations: </strong>The timing of the waterproofing application is carefully planned to avoid adverse weather conditions. Homex Builders schedules their work during dry weather to ensure that waterproofing materials can cure properly and provide maximum protection against water infiltration.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Homex Builders Corp leverages its deep knowledge and extensive experience in foundation waterproofing to deliver high-quality, reliable services. Their intelligence in this area comes from years of industry experience and staying updated on the latest technologies and techniques.
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Advanced Waterproofing Techniques: </strong>Homex Builders employs a range of advanced waterproofing techniques tailored to the specific needs of each foundation type. This includes the use of exterior waterproofing membranes, drainage systems, interior sealants, and epoxy injections to address different sources and types of water intrusion.</li>
                                        <li><strong>Customized Solutions: </strong>Homex Builders recognizes that every foundation is unique, requiring a customized approach to waterproofing. They carefully assess the foundation type, soil conditions, local climate, and water table levels to develop a tailored waterproofing plan that addresses the specific needs and vulnerabilities of each home.</li>
                                        <li><strong>Innovative Technologies: </strong>Homex Builders utilizes the latest waterproofing technologies, such as liquid-applied membranes, polyurethane injections, and advanced drainage solutions. These technologies provide superior protection compared to traditional methods and are designed to adapt to the natural movements of the foundation.</li>
                                        <li><strong>Knowledgeable Team: </strong>The team at Homex Builders consists of highly trained and experienced professionals who understand the complexities of foundation waterproofing. Their expertise enables them to quickly diagnose water intrusion problems, recommend effective solutions, and execute the work with precision.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    Homex Builders Corp specializes in foundation waterproofing, making them experts in protecting your home from water damage and preserving its structural integrity. Their specialization is evident in several key areas:
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Comprehensive Waterproofing Systems: </strong>Homex Builders offers complete waterproofing systems that combine multiple methods to provide maximum protection against water intrusion. This includes both exterior and interior solutions, as well as integrated drainage systems to address all potential sources of water entry.</li>
                                        <li><strong>Foundation Repair and Reinforcement: </strong>In addition to waterproofing, Homex Builders specializes in foundation repair and reinforcement. They have the expertise to identify and repair cracks, weaknesses, and other structural issues that could compromise the effectiveness of waterproofing efforts.</li>
                                        <li><strong>Mold and Mildew Prevention: </strong>Homex Builders understands the importance of preventing mold and mildew growth in basements and crawl spaces. Their waterproofing solutions are designed not only to keep water out but also to create a dry, healthy environment that discourages the growth of mold and mildew.</li>
                                        <li><strong>Drainage Optimization: </strong>Proper drainage is a critical component of foundation waterproofing, and Homex Builders specializes in designing and installing efficient drainage systems. This includes exterior drainage solutions, such as French drains, surface grading, and interior systems like sump pumps and perimeter drains.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Homex Builders Corp provides a comprehensive range of foundation waterproofing services designed to protect your home from water damage and ensure its long-term stability. Their focus on high-quality materials, advanced techniques, and customized solutions, combined with their commitment to customer satisfaction, makes them a top choice for homeowners looking to safeguard their foundations.
                </p>
                <p>
                    Choosing Homex Builders Corp for your foundation waterproofing needs means opting for a company that is dedicated to delivering quality results and maintaining the integrity of your home. Contact Homex Builders Corp today to learn more about their foundation waterproofing services and how they can help protect your home.
                </p>
            </div>
        </>
    );
}
