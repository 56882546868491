import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { useParams } from "react-router-dom";
import { SubHeader } from './SubHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CommercialRoofing } from "../services/CommercialRoofing";
import { ResidentialRoofing } from "../services/ResidentialRoofing";
import { RoofMaintenance } from "../services/RoofMaintenance";
import { RoofInspection } from "../services/RoofInspection";
import { RoofRepairs } from "../services/RoofRepairs";
import { RoofReplacement } from "../services/RoofReplacement";
import { RoofInstallation } from "../services/RoofInstallation";
import { BalconyWaterproofing } from "../services/BalconyWaterproofing";
import { DeckWaterproofing } from "../services/DeckWaterproofing";
import { BasementWaterproofing } from "../services/BasementWaterproofing";
import { FoundationWaterproofing } from "../services/FoundationWaterproofing";
import { WallWaterproofing } from "../services/WallWaterproofing";
import { ExteriorWaterproofing } from "../services/ExteriorWaterproofing";
import { MasonryWaterproofing } from "../services/MasonryWaterproofing";
import { BituminousWaterproofing } from "../services/BituminousWaterproofing";
import { ElastomericWaterproofing } from "../services/ElastomericWaterproofing";
import { WindowCaulking } from "../services/WindowCaulking";
import { BathroomRemodeling } from "../services/BathroomRemodeling";
import { KitchenRemodeling } from "../services/KitchenRemodeling";
import { BasementRenovation } from "../services/BasementRenovation";
import { NYCSidewalkRepair } from "../services/NYCSidewalkRepair";
import { SidewalkReplacement } from "../services/SidewalkReplacement";
import { SidewalkInstallation } from "../services/SidewalkInstallation";
import { SidewalkCurbRepair } from "../services/SidewalkCurbRepair";
import { SidewalkViolationRemoval } from "../services/SidewalkViolationRemoval";
import { DrivewayRepair } from "../services/DrivewayRepair";
import { BrickPointing } from "../services/BrickPointing";
import { BrickRestoration } from "../services/BrickRestoration";
import { BrownstoneRestoration } from "../services/BrownstoneRestoration";
import { DeckRestoration } from "../services/DeckRestoration";
import { WaterDamageRestoration } from "../services/WaterDamageRestoration";
import { StoneRestoration } from '../services/StoneRestoration';
import { MasonryCleaning } from '../services/MasonryCleaning';
import { ChimneyRebuilds } from '../services/ChimneyRebuilds';
import { FacadeRepair } from '../services/FacadeRepair';
import { ExpansionJoints } from '../services/ExpansionJoints';
import { FireEscapes } from '../services/FireEscapes';
import { Error } from "../services/Error";
import { Patios } from '../services/Patios';
import { PowerWashing } from '../services/PowerWashing';
import { RetainingWalls } from '../services/RetainingWalls';
import { StairRepairBuilding } from '../services/StairRepairBuilding';
import { LocalLaw11 } from '../services/LocalLaw11';

export const ServiceDetail = (props) => {
    const { serviceName } = useParams();

    return (
        <>
            <SubHeader title={serviceName} />
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="sidebar-side col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <aside className="sidebar default-sidebar">
                                <div id="nav_menu-5" className="sidebar-widget service-widget widget_nav_menu">
                                    <div className="menu-services-menu-container">
                                        <ul id="menu-services-menu" className="menu">
                                            <li className={`menu-item ${serviceName == "Commercial Roofing" ? "active" : ""}`}><a href="/service-detail/Commercial Roofing"><FontAwesomeIcon icon={faArrowRight} />Commercial Roofing</a></li>
                                            <li className={`menu-item ${serviceName == "Residential Roofing" ? "active" : ""}`}><a href="/service-detail/Residential Roofing"><FontAwesomeIcon icon={faArrowRight} />Residential Roofing</a></li>
                                            <li className={`menu-item ${serviceName == "Roof Maintenance" ? "active" : ""}`}>   <a href="/service-detail/Roof Maintenance"><FontAwesomeIcon icon={faArrowRight} />Roof Maintenance</a></li>
                                            <li className={`menu-item ${serviceName == "Roof Inspection" ? "active" : ""}`}>    <a href="/service-detail/Roof Inspection"><FontAwesomeIcon icon={faArrowRight} />Roof Inspection</a></li>
                                            <li className={`menu-item ${serviceName == "Roof Repairs" ? "active" : ""}`}>       <a href="/service-detail/Roof Repairs"><FontAwesomeIcon icon={faArrowRight} />Roof Repairs</a></li>
                                            <li className={`menu-item ${serviceName == "Roof Replacement" ? "active" : ""}`}>   <a href="/service-detail/Roof Replacement"><FontAwesomeIcon icon={faArrowRight} />Roof Replacement</a></li>
                                            <li className={`menu-item ${serviceName == "Roof Installation" ? "active" : ""}`}>  <a href="/service-detail/Roof Installation"><FontAwesomeIcon icon={faArrowRight} />Roof Installation</a></li>
                                            <li className={`menu-item ${serviceName == "Balcony Waterproofing" ? "active" : ""}`}>  <a href="/service-detail/Balcony Waterproofing"><FontAwesomeIcon icon={faArrowRight} />Balcony Waterproofing</a></li>
                                            <li className={`menu-item ${serviceName == "Deck Waterproofing" ? "active" : ""}`}> <a href="/service-detail/Deck Waterproofing"><FontAwesomeIcon icon={faArrowRight} />Deck Waterproofing</a></li>
                                            <li className={`menu-item ${serviceName == "Basement Waterproofing" ? "active" : ""}`}> <a href="/service-detail/Basement Waterproofing"><FontAwesomeIcon icon={faArrowRight} />Basement Waterproofing</a></li>
                                            <li className={`menu-item ${serviceName == "Foundation Waterproofing" ? "active" : ""}`}>   <a href="/service-detail/Foundation Waterproofing"><FontAwesomeIcon icon={faArrowRight} />Foundation Waterproofing</a></li>
                                            <li className={`menu-item ${serviceName == "Wall Waterproofing" ? "active" : ""}`}> <a href="/service-detail/Wall Waterproofing"><FontAwesomeIcon icon={faArrowRight} />Wall Waterproofing</a></li>
                                            <li className={`menu-item ${serviceName == "Exterior Waterproofing" ? "active" : ""}`}> <a href="/service-detail/Exterior Waterproofing"><FontAwesomeIcon icon={faArrowRight} />Exterior Waterproofing</a></li>
                                            <li className={`menu-item ${serviceName == "Masonry Waterproofing" ? "active" : ""}`}>  <a href="/service-detail/Masonry Waterproofing"><FontAwesomeIcon icon={faArrowRight} />Masonry Waterproofing</a></li>
                                            <li className={`menu-item ${serviceName == "Bituminous Waterproofing" ? "active" : ""}`}><a href="/service-detail/Bituminous Waterproofing"><FontAwesomeIcon icon={faArrowRight} />Bituminous Waterproofing</a></li>
                                            <li className={`menu-item ${serviceName == "Elastomeric Waterproofing" ? "active" : ""}`}><a href="/service-detail/Elastomeric Waterproofing"><FontAwesomeIcon icon={faArrowRight} />Elastomeric Waterproofing</a></li>
                                            <li className={`menu-item ${serviceName == "Window Caulking" ? "active" : ""}`}>    <a href="/service-detail/Window Caulking"><FontAwesomeIcon icon={faArrowRight} />Window Caulking</a></li>
                                            <li className={`menu-item ${serviceName == "Bathroom Remodeling" ? "active" : ""}`}><a href="/service-detail/Bathroom Remodeling"><FontAwesomeIcon icon={faArrowRight} />Bathroom Remodeling</a></li>
                                            <li className={`menu-item ${serviceName == "Kitchen Remodeling" ? "active" : ""}`}> <a href="/service-detail/Kitchen Remodeling"><FontAwesomeIcon icon={faArrowRight} />Kitchen Remodeling</a></li>
                                            <li className={`menu-item ${serviceName == "Basement Renovation" ? "active" : ""}`}><a href="/service-detail/Basement Renovation"><FontAwesomeIcon icon={faArrowRight} />Basement Renovation</a></li>
                                            <li className={`menu-item ${serviceName == "NYC Sidewalk Repair" ? "active" : ""}`}><a href="/service-detail/NYC Sidewalk Repair"><FontAwesomeIcon icon={faArrowRight} />NYC Sidewalk Repair</a></li>
                                            <li className={`menu-item ${serviceName == "Sidewalk Replacement" ? "active" : ""}`}>   <a href="/service-detail/Sidewalk Replacement"><FontAwesomeIcon icon={faArrowRight} />Sidewalk Replacement</a></li>
                                            <li className={`menu-item ${serviceName == "Sidewalk Installation" ? "active" : ""}`}>  <a href="/service-detail/Sidewalk Installation"><FontAwesomeIcon icon={faArrowRight} />Sidewalk Installation</a></li>
                                            <li className={`menu-item ${serviceName == "Sidewalk Curb Repair" ? "active" : ""}`}>   <a href="/service-detail/Sidewalk Curb Repair"><FontAwesomeIcon icon={faArrowRight} />Sidewalk Curb Repair</a></li>
                                            <li className={`menu-item ${serviceName == "Sidewalk Violation Removal" ? "active" : ""}`}> <a href="/service-detail/Sidewalk Violation Removal"><FontAwesomeIcon icon={faArrowRight} />Sidewalk Violation Removal</a></li>
                                            <li className={`menu-item ${serviceName == "Driveway Repair" ? "active" : ""}`}>    <a href="/service-detail/Driveway Repair"><FontAwesomeIcon icon={faArrowRight} />Driveway Repair</a></li>
                                            <li className={`menu-item ${serviceName == "Brick Pointing" ? "active" : ""}`}>     <a href="/service-detail/Brick Pointing"><FontAwesomeIcon icon={faArrowRight} />Brick Pointing</a></li>
                                            <li className={`menu-item ${serviceName == "Brick Restoration" ? "active" : ""}`}>  <a href="/service-detail/Brick Restoration"><FontAwesomeIcon icon={faArrowRight} />Brick Restoration</a></li>
                                            <li className={`menu-item ${serviceName == "Brownstone Restoration" ? "active" : ""}`}> <a href="/service-detail/Brownstone Restoration"><FontAwesomeIcon icon={faArrowRight} />Brownstone Restoration</a></li>
                                            <li className={`menu-item ${serviceName == "Deck Restoration" ? "active" : ""}`}>   <a href="/service-detail/Deck Restoration"><FontAwesomeIcon icon={faArrowRight} />Deck Restoration</a></li>
                                            <li className={`menu-item ${serviceName == "Water Damage Restoration" ? "active" : ""}`}>   <a href="/service-detail/Water Damage Restoration"><FontAwesomeIcon icon={faArrowRight} />Water Damage Restoration</a></li>
                                            <li className={`menu-item ${serviceName == "Stone Restoration" ? "active" : ""}`}>  <a href="/service-detail/Stone Restoration"><FontAwesomeIcon icon={faArrowRight} />Stone Restoration</a></li>
                                            <li className={`menu-item ${serviceName == "Wood Restoration" ? "active" : ""}`}>   <a href="/service-detail/Wood Restoration"><FontAwesomeIcon icon={faArrowRight} />Wood Restoration</a></li>
                                            <li className={`menu-item ${serviceName == "Masonry Cleaning" ? "active" : ""}`}>   <a href="/service-detail/Masonry Cleaning"><FontAwesomeIcon icon={faArrowRight} />Masonry Cleaning</a></li>
                                            <li className={`menu-item ${serviceName == "Local Law 11" ? "active" : ""}`}>       <a href="/service-detail/"><FontAwesomeIcon icon={faArrowRight} />Local Law 11</a></li>
                                            <li className={`menu-item ${serviceName == "Facade Repair" ? "active" : ""}`}>      <a href="/service-detail/Local Law 11"><FontAwesomeIcon icon={faArrowRight} />Facade Repair</a></li>
                                            <li className={`menu-item ${serviceName == "Chimney Rebuilds" ? "active" : ""}`}>   <a href="/service-detail/Chimney Rebuilds"><FontAwesomeIcon icon={faArrowRight} />Chimney Rebuilds</a></li>
                                            <li className={`menu-item ${serviceName == "Expansion Joints" ? "active" : ""}`}>   <a href="/service-detail/Expansion Joints"><FontAwesomeIcon icon={faArrowRight} />Expansion Joints</a></li>
                                            <li className={`menu-item ${serviceName == "Fire Escapes" ? "active" : ""}`}>       <a href="/service-detail/Fire Escapes"><FontAwesomeIcon icon={faArrowRight} />Fire Escapes</a></li>
                                            <li className={`menu-item ${serviceName == "Patios" ? "active" : ""}`}>             <a href="/service-detail/Patios"><FontAwesomeIcon icon={faArrowRight} />Patios</a></li>
                                            <li className={`menu-item ${serviceName == "Power Washing" ? "active" : ""}`}>      <a href="/service-detail/Power Washing"><FontAwesomeIcon icon={faArrowRight} />Power Washing</a></li>
                                            <li className={`menu-item ${serviceName == "Retaining Walls" ? "active" : ""}`}>    <a href="/service-detail/Retaining Walls"><FontAwesomeIcon icon={faArrowRight} />Retaining Walls</a></li>
                                            <li className={`menu-item ${serviceName == "Stair Repair & Building" ? "active" : ""}`}><a href="/service-detail/Stair Repair & Building"><FontAwesomeIcon icon={faArrowRight} />Stair Repair & Building</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div id="konstructo_quick_contact-1" className="sidebar-widget service-widget widget_konstructo_quick_contact">
                                    <div className="solution-box" style={{ backgroundImage: "url(https://wp1.yogsthemes.com/wp/konstructo/wp-content/uploads/2022/01/image-2.jpg)" }} >
                                        <div className="inner">
                                            <div className="title">Quick Contact</div>
                                            <h2>Get Solution</h2>
                                            <div className="text">Contact us at <a href="tel:9293853287">(929) 385-3287</a> or submit a business inquiry online.</div>
                                            <a className="solution-btn theme-btn" href="/contact-us">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                        <div className="content-side col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="services-single">
                                <div className="inner-box">
                                    {
                                        serviceName == "Commercial Roofing" ?
                                            <CommercialRoofing serviceContent="" />
                                            :
                                            serviceName == "Residential Roofing" ?
                                                <ResidentialRoofing serviceContent="" />
                                                :
                                                serviceName == "Roof Maintenance" ?
                                                    <RoofMaintenance serviceContent="" />
                                                    :
                                                    serviceName == "Roof Inspection" ?
                                                        <RoofInspection serviceContent="" />
                                                        :
                                                        serviceName == "Roof Repairs" ?
                                                            <RoofRepairs serviceContent="" />
                                                            :
                                                            serviceName == "Roof Replacement" ?
                                                                <RoofReplacement serviceContent="" />
                                                                :
                                                                serviceName == "Roof Installation" ?
                                                                    <RoofInstallation serviceContent="" />
                                                                    :
                                                                    serviceName == "Balcony Waterproofing" ?
                                                                        <BalconyWaterproofing serviceContent="" />
                                                                        :
                                                                        serviceName == "Deck Waterproofing" ?
                                                                            <DeckWaterproofing serviceContent="" />
                                                                            :
                                                                            serviceName == "Basement Waterproofing" ?
                                                                                <BasementWaterproofing serviceContent="" />
                                                                                :
                                                                                serviceName == "Foundation Waterproofing" ?
                                                                                    <FoundationWaterproofing serviceContent="" />
                                                                                    :
                                                                                    serviceName == "Wall Waterproofing" ?
                                                                                        <WallWaterproofing serviceContent="" />
                                                                                        :
                                                                                        serviceName == "Exterior Waterproofing" ?
                                                                                            <ExteriorWaterproofing serviceContent="" />
                                                                                            :
                                                                                            serviceName == "Masonry Waterproofing" ?
                                                                                                <MasonryWaterproofing serviceContent="" />
                                                                                                :
                                                                                                serviceName == "Bituminous Waterproofing" ?
                                                                                                    <BituminousWaterproofing serviceContent="" />
                                                                                                    :
                                                                                                    serviceName == "Elastomeric Waterproofing" ?
                                                                                                        <ElastomericWaterproofing serviceContent="" />
                                                                                                        :
                                                                                                        serviceName == "Window Caulking" ?
                                                                                                            <WindowCaulking serviceContent="" />
                                                                                                            :
                                                                                                            serviceName == "Bathroom Remodeling" ?
                                                                                                                <BathroomRemodeling serviceContent="" />
                                                                                                                :
                                                                                                                serviceName == "Kitchen Remodeling" ?
                                                                                                                    <KitchenRemodeling serviceContent="" />
                                                                                                                    :
                                                                                                                    serviceName == "Basement Renovation" ?
                                                                                                                        <BasementRenovation serviceContent="" />
                                                                                                                        :
                                                                                                                        serviceName == "NYC Sidewalk Repair" ?
                                                                                                                            <NYCSidewalkRepair serviceContent="" />
                                                                                                                            :
                                                                                                                            serviceName == "Sidewalk Replacement" ?
                                                                                                                                <SidewalkReplacement serviceContent="" />
                                                                                                                                :
                                                                                                                                serviceName == "Sidewalk Installation" ?
                                                                                                                                    <SidewalkInstallation serviceContent="" />
                                                                                                                                    :
                                                                                                                                    serviceName == "Sidewalk Curb Repair" ?
                                                                                                                                        <SidewalkCurbRepair serviceContent="" />
                                                                                                                                        :
                                                                                                                                        serviceName == "Sidewalk Violation Removal" ?
                                                                                                                                            <SidewalkViolationRemoval serviceContent="" />
                                                                                                                                            :
                                                                                                                                            serviceName == "Driveway Repair" ?
                                                                                                                                                <DrivewayRepair serviceContent="" />
                                                                                                                                            :
                                                                                                                                                serviceName == "Brick Pointing" ?
                                                                                                                                                    <BrickPointing serviceContent="" />
                                                                                                                                            :
                                                                                                                                                    serviceName == "Brick Restoration" ?
                                                                                                                                                        <BrickRestoration serviceContent="" />
                                                                                                                                            :
                                                                                                                                                        serviceName == "Brownstone Restoration" ?
                                                                                                                                                            <BrownstoneRestoration serviceContent="" />
                                                                                                                                            :
                                                                                                                                                            serviceName == "Deck Restoration" ?
                                                                                                                                                                <DeckRestoration serviceContent="" />
                                                                                                                                            :
                                                                                                                                                                serviceName == "Water Damage Restoration" ?
                                                                                                                                                                    <WaterDamageRestoration serviceContent="" />
                                                                                                                                                                    :
                                                                                                                                                                    serviceName == "Stone Restoration" ?
                                                                                                                                                                        <StoneRestoration serviceContent="" />
                                                                                                                                                                        :
                                                                                                                                                                        serviceName == "Masonry Cleaning" ?
                                                                                                                                                                            <MasonryCleaning serviceContent="" />
                                                                                                                                                                            :
                                                                                                                                                                            serviceName == "Chimney Rebuilds" ?
                                                                                                                                                                                <ChimneyRebuilds serviceContent="" />
                                                                                                                                                                                :
                                                                                                                                                                                serviceName == "Facade Repair" ?
                                                                                                                                                                                    <FacadeRepair serviceContent="" />
                                                                                                                                                                                    :
                                                                                                                                                                                    serviceName == "Expansion Joints" ?
                                                                                                                                                                                        <ExpansionJoints serviceContent="" />
                                                                                                                                                                                        :
                                                                                                                                                                                        serviceName == "Fire Escapes" ?
                                                                                                                                                                                            <FireEscapes serviceContent="" />
                                                                                                                                                                                            :
                                                                                                                                                                                            serviceName == "Patios" ?
                                                                                                                                                                                                <Patios serviceContent="" />
                                                                                                                                                                                                :
                                                                                                                                                                                                serviceName == "Power Washing" ?
                                                                                                                                                                                                    <PowerWashing serviceContent="" />
                                                                                                                                                                                                :
                                                                                                                                                                                                    serviceName == "Retaining Walls" ?
                                                                                                                                                                                                        <RetainingWalls serviceContent="" />
                                                                                                                                                                                                        :
                                                                                                                                                                                                        serviceName == "Stair Repair & Building" ?
                                                                                                                                                                                                            <StairRepairBuilding serviceContent="" />
                                                                                                                                                                                                            :
                                                                                                                                                                                                            serviceName == "Local Law 11" ?
                                                                                                                                                                                                            <LocalLaw11 serviceContent="" />
                                                                                                                                                                                                            :
                                                                                                                                            <Error />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="call-to-action-section-two" style={{ backgroundImage: "url(https://wp1.yogsthemes.com/wp/konstructo/wp-content/themes/konstructonew/assets/images/background/4.jpg)" }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="column col-md-7 col-sm-12 col-xs-12">
                            <h2><span className="theme_color">Homex Builders</span> Corp</h2>
                            <div className="text">If you have any construction &amp; renovation work need, simply call our 24 hour emergecny number.</div>
                        </div>
                        <div className="btn-column col-md-5 col-sm-12 col-xs-12">
                            <div className="number">
                                (929) 385-3287
                                <span className="theme_color"> or </span>
                                <a href="/contact-us" className="theme-btn btn-style-five">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}