import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import balcony1 from "../assets/images/waterproofingservices/balcony1.jpg"
import balcony2 from "../assets/images/waterproofingservices/balcony2.jpg"
import balcony3 from "../assets/images/waterproofingservices/balcony3.jpg"

export const BalconyWaterproofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={balcony1} alt="Baclony Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={balcony2} alt="Baclony Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={balcony3} alt="Baclony Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Baclony Waterproofing</h3>
            <div className="text">
                <p>
                    Balcony waterproofing is crucial for protecting your home from water damage and ensuring the longevity of your outdoor space. At Homex Builders Corp, we specialize in providing top-tier balcony waterproofing services that prevent leaks, structural damage, and mold growth, keeping your balcony safe and dry.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Balcony Waterproofing Services?</h3>
                <ul>
                    <li><strong>Comprehensive Waterproofing Assessment</strong>
                        A thorough inspection of your balcony to identify any existing water damage, weak spots, or potential leakage areas.</li>
                    <li> <strong>Waterproof Membrane Installation</strong>
                        Application of high-quality waterproof membranes that create a durable barrier against moisture penetration, tailored to your balcony’s specific needs.</li>
                    <li><strong>Sealant Application</strong>
                        Use of professional-grade sealants around edges, joints, and corners to prevent water from seeping into vulnerable areas.</li>
                    <li><strong>Surface Repair and Preparation</strong>
                        Repairing cracks, leveling surfaces, and preparing the balcony to ensure optimal adherence of waterproofing materials.</li>
                    <li><strong>Drainage System Installation or Upgrade</strong>
                        Installing or improving balcony drainage systems to efficiently direct water away, preventing pooling and potential damage.</li>
                    <li><strong>Anti-Slip Coatings</strong>
                        Adding anti-slip coatings to the waterproofed surface for enhanced safety and durability, especially in wet conditions.</li>
                    <li><strong>Ongoing Maintenance Plans</strong>
                        Offering maintenance services to regularly check and maintain the waterproofing integrity, ensuring long-term protection.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Balcony Waterproofing?</h4>
                    <p>Choosing Homex Builders Corp for your balcony waterproofing needs means partnering with a company committed to quality, reliability, and customer satisfaction:</p>
                    <ul>
                        <li><strong>Expertise and Experience:</strong> Our team has years of experience in balcony waterproofing, ensuring that every project is handled with precision and attention to detail.</li>
                        <li><strong>Premium Materials:</strong> We use only the highest quality waterproofing materials, selected for their durability and effectiveness in protecting against moisture.</li>
                        <li><strong>Customized Solutions:</strong> We tailor our waterproofing solutions to fit the specific conditions of your balcony, ensuring maximum protection and longevity.</li>
                        <li><strong>Customer-Centric Approach:</strong> Your satisfaction is our priority. We work closely with you throughout the process, providing clear communication and addressing any concerns you may have.</li>
                        <li><strong>Competitive Pricing:</strong> We offer fair and transparent pricing, delivering exceptional value without compromising on the quality of our services.</li>
                        <li><strong>Long-Term Warranty:</strong> Our services come with a robust warranty, giving you peace of mind that your investment is protected.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>Balcony waterproofing requires careful planning and execution to prevent potential issues. At Homex Builders Corp, we take the following precautions:</p>
                                <p>
                                    <ul>
                                        <li><strong>Thorough Inspection: </strong>Before starting any waterproofing work, we conduct a detailed inspection of your balcony to identify any existing damage, structural weaknesses, or potential problem areas that could affect the waterproofing process.</li>
                                        <li><strong>Weather Monitoring: </strong>We closely monitor weather conditions to schedule work during dry periods, reducing the risk of moisture interfering with the application of waterproofing materials.</li>
                                        <li><strong>Proper Surface Preparation: </strong>We ensure the surface is clean, dry, and properly prepared before applying any waterproofing solutions. This includes repairing cracks, leveling the surface, and removing debris.</li>
                                        <li><strong>Safety Protocols: </strong>The safety of our team and your property is paramount. We follow strict safety protocols to protect both workers and residents during the waterproofing process.</li>
                                        <li><strong>Quality Control: </strong>Throughout the project, we conduct regular quality checks to ensure that every layer of waterproofing is applied correctly and effectively.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>At Homex Builders Corp, we employ intelligent solutions to deliver the best waterproofing results:</p>
                                <p>
                                    <ul>
                                        <li><strong>Tailored Waterproofing Systems: </strong>We understand that every balcony is unique, so we design customized waterproofing systems that address your specific needs, whether it’s membrane installation, sealant application, or drainage solutions.</li>
                                        <li><strong>Advanced Waterproofing Materials: </strong>We use cutting-edge materials that offer superior performance in preventing water infiltration. Our products are chosen for their durability, flexibility, and resistance to harsh weather conditions.</li>
                                        <li><strong>Innovative Techniques: </strong>Our team stays up-to-date with the latest waterproofing technologies and techniques. This includes the use of advanced tools for precise application and state-of-the-art methods to ensure a flawless finish.</li>
                                        <li><strong>Long-Term Solutions: </strong>We focus on providing waterproofing solutions that offer long-term protection. Our approach minimizes the need for frequent maintenance, saving you time and money in the long run.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>Homex Builders Corp specializes in delivering high-quality balcony waterproofing services tailored to various types of balconies and materials:</p>
                                <p>
                                    <ul>
                                        <li><strong>Concrete Balconies: </strong>We have extensive experience in waterproofing concrete balconies, using specialized coatings and membranes that bond well with concrete to prevent cracking and water seepage.</li>
                                        <li><strong>Tiled Balconies: </strong>For tiled balconies, we offer waterproofing solutions that protect the underlying structure while maintaining the integrity of the tile installation. We use flexible membranes that prevent water from penetrating through tile joints.</li>
                                        <li><strong>Wooden Balconies: </strong>Wooden balconies require a different approach, and we specialize in applying waterproofing treatments that protect the wood from moisture without compromising its natural look and feel.</li>
                                        <li><strong>Balconies with Complex Structures: </strong>Whether your balcony has unique architectural features or a complex drainage system, we have the expertise to develop a waterproofing strategy that addresses these challenges effectively.</li>
                                        <li><strong>Multi-Layered Waterproofing Systems: </strong>We specialize in multi-layered waterproofing systems that offer comprehensive protection, including membranes, sealants, and top coatings designed to work together for optimal results.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    When you choose Homex Builders Corp for your balcony waterproofing project, you’re choosing a team that cares about the longevity and safety of your home. Contact us today to schedule a consultation and get a free estimate.
                </p>
            </div>
        </>
    );
}
