import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import expansionjoints1 from "../assets/images/masonry/expansionjoints1.jpg"
import expansionjoints2 from "../assets/images/masonry/expansionjoints2.jpg"
import expansionjoints3 from "../assets/images/masonry/expansionjoints3.jpg"

export const ExpansionJoints = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={expansionjoints1} alt="Expansion Joints" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={expansionjoints2} alt="Expansion Joints" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={expansionjoints3} alt="Expansion Joints" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Expansion Joints</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers specialized expansion joint services designed to maintain the structural integrity and safety of your building. Expansion joints are critical components in construction, allowing for movement caused by thermal expansion, contraction, and other environmental factors without causing damage to the building's structure. Properly installed and maintained expansion joints prevent cracks and structural damage, ensuring the longevity and safety of your building. Here’s an overview of our expansion joint services and the reasons to choose Homex Builders Corp for your project:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Expansion Joints Services?</h3>
                <ul>
                    <li><strong>Expansion Joint Installation: </strong>We provide professional installation of expansion joints for new construction and renovation projects. Our team of experts ensures that joints are correctly positioned and installed to accommodate movement and prevent stress on building materials. We work with a variety of expansion joint types, including elastomeric, rubber, and metal joints, tailored to the specific needs of your building.</li>
                    <li><strong>Expansion Joint Replacement: </strong>Over time, expansion joints can degrade due to weather exposure, heavy use, or natural wear and tear. We offer replacement services for damaged or outdated expansion joints, removing the old joints and installing new ones that meet current safety standards and requirements. Our replacement services help extend the life of your building and prevent costly repairs down the road.</li>
                    <li><strong>Expansion Joint Repair: </strong>If your expansion joints show signs of minor damage, such as cracking, splitting, or displacement, our repair services can address these issues promptly. We repair joints to restore their functionality and effectiveness, ensuring that they continue to accommodate movement and protect the building structure.</li>
                    <li><strong>Sealing and Waterproofing: </strong>Expansion joints are particularly vulnerable to water intrusion, which can lead to significant structural damage. We offer sealing and waterproofing services to protect expansion joints from moisture and prevent leaks. Using high-quality sealants and waterproofing materials, we ensure a watertight seal that enhances the durability and effectiveness of your expansion joints.</li>
                    <li><strong>Inspection and Maintenance: </strong>Regular inspection and maintenance of expansion joints are crucial to identifying potential problems before they lead to more significant issues. We provide comprehensive inspection services to assess the condition of your expansion joints and recommend necessary maintenance or repairs. Our proactive approach helps keep your building safe and extends the lifespan of your expansion joints.</li>
                    <li><strong>Custom Expansion Joint Solutions: </strong>Every building has unique needs, and we offer custom expansion joint solutions tailored to your specific requirements. Whether you need joints for high-traffic areas, seismic joints for earthquake-prone regions, or fire-rated joints for enhanced safety, we provide customized solutions that ensure optimal performance and compliance with all building codes.</li>

                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Expansion Joints?</h4>
                    <ul>
                        <li><strong>Expertise and Experience: </strong>Homex Builders Corp brings years of experience and expertise in expansion joint services. Our team consists of highly skilled professionals who understand the complexities of expansion joint installation, repair, and maintenance. This expertise ensures that every project is handled with precision and care, providing you with the best results.</li>
                        <li><strong>Quality Materials and Workmanship: </strong>We use only the highest-quality materials and employ the best practices in the industry to ensure durable and reliable expansion joint services. Our commitment to quality craftsmanship means that your expansion joints will be installed, repaired, or replaced to the highest standards, ensuring long-term performance and safety.</li>
                        <li><strong>Comprehensive Service Offerings: </strong>From installation and replacement to repair and maintenance, Homex Builders Corp provides a full range of expansion joint services to meet all your building’s needs. Our comprehensive approach means you don’t need to coordinate with multiple contractors, simplifying the process and ensuring consistent quality throughout.</li>
                        <li><strong>Customized Solutions: </strong>We understand that each building is unique and requires tailored solutions. Our team works closely with you to develop custom expansion joint solutions that match your building’s specific requirements and challenges. This personalized approach ensures that your expansion joints provide maximum effectiveness and protection.</li>
                        <li><strong>Commitment to Safety and Compliance: </strong>Safety is a top priority at Homex Builders Corp. Our expansion joint services are conducted in full compliance with local building codes and safety regulations, ensuring the safety of your building and its occupants. We take all necessary precautions to protect both our team and your property during every project.</li>
                        <li><strong>Transparent Communication: </strong>We believe in clear and open communication with our clients. From the initial consultation to project completion, we keep you informed every step of the way. Our transparent approach ensures you understand the scope of work, timeline, and costs involved, giving you peace of mind throughout the process.</li>
                        <li><strong>Competitive Pricing: </strong>Homex Builders Corp offers competitive pricing for our expansion joint services without compromising on quality. We provide detailed, transparent quotes, ensuring you know exactly what to expect with no hidden fees. Our cost-effective solutions provide excellent value, helping you maintain your building’s safety and integrity without breaking the bank.</li>
                        <li><strong>Proven Track Record: </strong>We have a proven track record of successful expansion joint projects across a wide range of buildings, from commercial high-rises to residential properties. Our reputation for reliability, quality, and customer satisfaction makes us a trusted choice for expansion joint services.</li>
                        <li><strong>Environmentally Friendly Practices: </strong>We are committed to environmentally friendly practices in all our services. We use sustainable materials and methods to minimize our environmental impact, ensuring that our work not only protects your building but also contributes to a greener future.</li>

                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Thorough Structural Assessment: </strong>Before starting any work on expansion joints, Homex Builders Corp conducts a comprehensive structural assessment to understand the building's specific movement patterns and stresses. This includes analyzing factors such as thermal expansion, contraction rates, load-bearing capacities, and potential seismic activity. This assessment helps in designing and selecting the most appropriate type of expansion joint for the structure.</li>

                                        <li><strong>Safety Measures and Compliance: </strong>Safety is a top priority when working on expansion joints, particularly in high-risk areas such as exterior facades, roofing, and between different structural components. Homex Builders Corp adheres to strict safety protocols, including using appropriate personal protective equipment (PPE), setting up safety barriers, and employing fall protection systems when working at heights. They also ensure that all work complies with Occupational Safety and Health Administration (OSHA) regulations and local building codes.</li>

                                        <li><strong>Material Quality Control: </strong>The company carefully selects high-quality materials for expansion joints, considering factors like durability, flexibility, weather resistance, and compatibility with the building materials. This ensures that the expansion joints can effectively accommodate movement without degrading over time. Homex Builders Corp inspects all materials before installation to confirm they meet the required standards for performance and safety.</li>

                                        <li><strong>Minimizing Environmental Impact: </strong>Homex Builders Corp takes steps to minimize the environmental impact of their work, especially when removing or replacing old expansion joints. They follow proper disposal protocols for old materials and use environmentally friendly sealants and joint materials that are low in volatile organic compounds (VOCs).</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Expertise in Different Types of Expansion Joints: </strong>Homex Builders Corp has extensive experience with various types of expansion joints, including building expansion joints, floor joints, roof joints, and facade joints. Each type requires different materials and installation techniques. For example, floor expansion joints must be designed to withstand foot traffic and mechanical loads, while roof expansion joints need to be watertight and resistant to UV radiation.</li>

                                        <li><strong>Advanced Diagnostic and Planning Techniques: </strong>The company uses advanced diagnostic tools and techniques to evaluate the condition of existing expansion joints and determine the best repair or replacement strategy. This may include infrared thermography to detect hidden damage, moisture meters to check for water infiltration, and structural monitoring tools to assess movement patterns. Homex Builders Corp leverages this data to develop precise, customized solutions for each project.</li>

                                        <li><strong>Understanding Thermal and Seismic Movements: </strong>Homex Builders Corp's team is knowledgeable about the impact of thermal expansion and seismic activity on building structures. They consider these factors when designing and installing expansion joints, ensuring that joints are capable of absorbing the anticipated movement and preventing stress buildup in the structure. This expertise is particularly important in regions prone to significant temperature fluctuations or seismic activity.</li>

                                        <li><strong>Knowledge of Industry Standards and Innovations: </strong>The company stays up-to-date with the latest industry standards, innovations, and best practices in expansion joint technology. This includes understanding new materials, such as advanced elastomers and composites, and innovative installation methods that improve joint performance and longevity. Homex Builders Corp applies this knowledge to provide high-quality, durable expansion joint solutions.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Custom Design and Fabrication: </strong>Homex Builders Corp specializes in custom designing and fabricating expansion joints tailored to the specific needs of a building. This involves selecting the right materials and designing the joint to accommodate the specific types of movement expected in the structure. Custom fabrication ensures that the expansion joint fits perfectly and performs optimally under the building's unique conditions.</li>

                                        <li><strong>Seismic Expansion Joints: </strong>For buildings in seismically active areas, Homex Builders Corp offers specialized seismic expansion joint solutions. These joints are designed to accommodate significant lateral and vertical movements during an earthquake, helping to maintain the structural integrity of the building and protect occupants. The company uses advanced materials and designs to ensure these joints are both flexible and robust.</li>

                                        <li><strong>Waterproofing and Fire-Rated Expansion Joints: </strong>The company also specializes in waterproof and fire-rated expansion joints. Waterproof expansion joints are critical in preventing water infiltration in areas like roofs and facades, while fire-rated expansion joints help contain fire and prevent its spread between different building sections. Homex Builders Corp selects materials that provide the necessary fire resistance and waterproofing qualities, ensuring safety and compliance with building codes.</li>

                                        <li><strong>Expansion Joint Repair and Replacement: </strong>In addition to new installations, Homex Builders Corp provides repair and replacement services for existing expansion joints. Over time, joints can deteriorate due to environmental exposure, material fatigue, or improper installation. The company assesses the condition of old joints, removes damaged materials, and installs new, high-performance joints to restore functionality and safety.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for your expansion joint services means partnering with a company dedicated to quality, safety, and customer satisfaction. Our extensive experience, comprehensive service offerings, and commitment to excellence make us the ideal choice for all your expansion joint needs.
                </p>
                <p>
                    Contact Homex Builders Corp today to schedule a consultation or request a quote, and let us help you protect and maintain the integrity of your building with our professional expansion joint services.
                </p>
            </div>
        </>
    );
}
