import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import brownstonerestoration1 from "../assets/images/restoration/brownstonerestoration1.jpg"
import brownstonerestoration2 from "../assets/images/restoration/brownstonerestoration2.jpg"
import brownstonerestoration3 from "../assets/images/restoration/brownstonerestoration3.jpg"

export const BrownstoneRestoration = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={brownstonerestoration1} alt="Brownstone Restoration" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={brownstonerestoration2} alt="Brownstone Restoration" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={brownstonerestoration3} alt="Brownstone Restoration" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Brownstone Restoration</h3>
            <div className="text">
                <p>
                    Homex Builders Corp follows a specialized process for brownstone restoration services, aiming to preserve the architectural beauty and structural integrity of historic brownstone buildings. Here's a step-by-step outline of their process:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Brownstone Restoration Services?</h3>
                <ul>
                    <li><strong>Thorough Evaluation:</strong> The team begins with a detailed inspection of the brownstone facade to assess the extent of damage, including cracks, spalling (flaking), moisture damage, and any structural issues.</li>
                    <li><strong>Historical Consideration: </strong>Since many brownstone buildings have historical significance, Homex Builders pays careful attention to the original design, materials, and craftsmanship during the assessment.</li>
                    <li><strong>Surface Cleaning: </strong>The brownstone surface is cleaned using gentle methods, such as low-pressure washing or steam cleaning, to remove dirt, grime, and pollutants without damaging the stone. In some cases, chemical cleaners might be used for tougher stains.</li>
                    <li><strong>Old Mortar Removal:</strong> Deteriorated or loose mortar is removed from between the stones to prepare the surface for repointing. This process prevents further degradation and ensures that the new mortar will bond well.</li>
                    <li><strong>Stone Patch Testing:</strong> Homex Builders carefully selects or customizes a restoration compound to match the original color, texture, and composition of the brownstone. The team often conducts patch tests to ensure the material blends seamlessly with the existing structure.</li>
                    <li><strong>Crack and Spall Repairs:</strong> Small cracks and spalled areas are repaired using a tinted stone patching material that mimics the look and durability of natural brownstone. This helps restore the stone’s aesthetic and prevents further damage.</li>
                    <li><strong>Stone Replacement (if necessary):</strong> Severely damaged or missing sections of brownstone may be replaced with new stone that matches the original in appearance and quality. Homex sources high-grade replacement stone to ensure it integrates smoothly.</li>
                    <li><strong>Mortar Matching:</strong> The team carefully matches the color, texture, and composition of the new mortar to the original. This is critical for both structural integrity and visual consistency.</li>
                    <li><strong>Applying New Mortar:</strong> Fresh mortar is applied to the joints between the brownstones using hand tools to fill gaps, secure the stones, and prevent water infiltration. Homex Builders ensures a clean and professional finish that preserves the original appearance.</li>
                    <li><strong>Sanding and Smoothing: </strong>If needed, the team sands the patched areas to ensure a smooth, even surface that blends with the surrounding stone.</li>
                    <li><strong>Re-creating Architectural Details: </strong>Homex Builders can restore or replicate intricate architectural features, such as cornices, moldings, and carvings, that are common in brownstone facades. Specialized artisans may be involved in this step to ensure accuracy and craftsmanship.</li>
                    <li><strong>Application of Sealants: </strong>To protect the restored brownstone from moisture, weather damage, and pollutants, a breathable, waterproof sealant is applied. This helps extend the life of the restoration work while maintaining the stone’s natural appearance.</li>
                    <li><strong>Detailed Inspection: </strong>Once all repairs and restoration steps are complete, Homex Builders conducts a thorough final inspection to ensure the brownstone facade meets high-quality standards in both appearance and durability.</li>
                    <li><strong>Final Adjustments: </strong>Any necessary touch-ups or refinements are made to ensure the restored surface is cohesive, polished, and true to the building’s original design.</li>
                    <li><strong>Preservation Tips:</strong> Homex Builders often provides clients with recommendations for maintaining their brownstone facade, including cleaning routines and monitoring for early signs of damage, ensuring the restoration lasts for decades.</li>

                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Brownstone Restoration?</h4>
                    <p>
                        Choosing us for your brownstone restoration service means working with a team that understands the unique beauty and craftsmanship of brownstone buildings. Here's why we're the right choice for your project:
                    </p>
                    <ul>
                        <li><strong>Specialized Expertise:</strong> Brownstone restoration requires specific skills, and we have years of experience working on these iconic structures. Our team is trained to handle the delicate nature of brownstone, ensuring precise restoration that preserves the building’s historical charm.</li>
                        <li><strong>High-Quality Materials:</strong> We use authentic materials to restore the original look and feel of your brownstone. Whether it’s matching the stone or using the right mortar, we ensure that the restoration blends seamlessly with the existing architecture.</li>
                        <li><strong>Historic Preservation Focus: </strong>We understand the importance of maintaining the historical integrity of brownstone buildings. Our restoration process is designed to carefully preserve the building's architectural details while reinforcing its strength and durability.</li>
                        <li><strong>Skilled Craftsmanship: </strong>Our restoration specialists are highly skilled in masonry and fine detailing, ensuring that every aspect of the restoration is handled with precision. From repairing cracks to restoring intricate stone carvings, we bring your brownstone back to its original glory.</li>
                        <li><strong>Licensed and Insured:</strong> We are fully licensed and insured, which means you can trust that your property is protected throughout the restoration process. Safety and professionalism are always at the forefront of our work.</li>
                        <li><strong>Customized Restoration Plans: </strong>No two brownstones are the same, and we offer tailored restoration solutions based on your building’s unique needs. Whether it’s a full façade restoration or targeted repairs, we provide a plan that works for your budget and timeline.</li>
                        <li><strong>Proven Track Record: </strong>Our portfolio includes numerous successful brownstone restorations, with satisfied clients who trust our expertise. We are committed to delivering high-quality results that meet both aesthetic and structural standards.</li>
                    </ul>
                </blockquote>
                <p>
                    When it comes to restoring your brownstone, we combine craftsmanship, attention to detail, and a deep respect for the history of your property. Reach out to us to bring your brownstone back to its timeless beauty and structural integrity.
                </p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Safety First:</strong> Homex Builders Corp prioritizes safety by implementing stringent protocols during restoration. This includes the use of scaffolding, harnesses, and protective gear to ensure worker safety and prevent damage to the surrounding areas.</li>
                                        <li><strong>Material Integrity: </strong>The company meticulously sources and tests all restoration materials, ensuring compatibility with the original brownstone. This prevents long-term deterioration and maintains the structural integrity of the building.</li>
                                        <li><strong>Environmental Considerations: </strong>Homex Builders is mindful of environmental impact, using eco-friendly products where possible and ensuring proper disposal of debris and hazardous materials.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Historical Knowledge: </strong>The team at Homex Builders is well-versed in the history and architecture of brownstone buildings. This knowledge allows them to approach each project with an understanding of the specific needs and challenges inherent in restoring historic structures.</li>
                                        <li><strong>Advanced Techniques:</strong> They utilize cutting-edge technology, such as laser scanning and 3D modeling, to assess damage and plan restorations with precision. This level of intelligence ensures that restorations are not only accurate but also preserve the building’s historical integrity.</li>
                                        <li><strong>Problem-Solving Expertise: </strong>The company’s team of engineers and architects is skilled at diagnosing complex structural issues that may not be apparent at first glance. Their ability to identify and address underlying problems is a key factor in successful restorations.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Brownstone Focus: </strong>Homex Builders Corp specializes in brownstone restoration, making them experts in the nuances of this particular material. Their deep understanding of brownstone’s properties enables them to restore these buildings to their original grandeur.</li>
                                        <li><strong>Custom Restoration Solutions: </strong>They offer tailored solutions for each project, whether it’s a full façade restoration, window and door repair, or intricate masonry work. This specialization ensures that each aspect of the restoration meets the highest standards.</li>
                                        <li><strong>Compliance and Certification: </strong>Homex Builders is fully licensed and certified for historic restoration work. Their compliance with local preservation laws and regulations ensures that all work is legally sound and meets the required preservation standards.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    By following these meticulous steps, Homex Builders Corp ensures that brownstone structures are not only restored to their former beauty but are also protected against future wear and weather damage.
                </p>
            </div>
        </>
    );
}
