import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import { Container } from 'reactstrap';
import { SubHeader } from './SubHeader';
import OwlCarousel, { Options } from 'react-owl-carousel';
import { variables } from '../Variables';

import success1 from "../assets/images/aboutus/success-1.jpg"
import success2 from "../assets/images/aboutus/success-2.jpg"
import year from "../assets/images/aboutus/year.png"
import testimonial from "../assets/images/home/testimonial.jpg"
import client1 from "../assets/images/client1.jpg"
import client2 from "../assets/images/client2.jpg"
import image1 from "../assets/images/image-1.jpg"
import patern_1 from "../assets/images/patern_1.png"

export const AboutUs = (props) => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        fetchTestimonials();
    })

    const fetchTestimonials = async () => {

        let apiUrl = variables.API_URL + 'Testimonials/GetTestimonialsForWebsite';

        await axios.get(apiUrl) // ASP.NET Core API endpoint with header
            .then(response => {
                setTestimonials(response.data);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    };

    return (
        <>
            <SubHeader title="About Us" />

            <section className="success-section">
                <div className="auto-container">
                    <div className="row clearfix">

                        <div className="image-column col-md-5 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="image">
                                    <img decoding="async" src={success1} alt="Success" />
                                </div>
                                <div className="small-img">
                                    <img decoding="async" src={success2} alt="Success" />
                                </div>
                            </div>
                        </div>
                        <div className="content-column col-md-7 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="since-year clearfix">
                                    <div className="work">Years OF<strong>Success</strong><span>Work</span></div>
                                </div>

                                <div className="text">
                                    Since its founding, Homex Builders Corp has built a reputation as one of NYC's premier general contractors by delivering high-quality, on-time, and on-budget construction projects. From brownstone restorations to high-rise developments, the company has consistently exceeded client expectations.
                                </div>

                                <div className="fact-counter">
                                    <div className="row clearfix">
                                        <div className="column counter-column col-md-4 col-sm-6 col-xs-12">
                                            <div className="inner">
                                                <div className="count-outer count-box counted">
                                                    <span className="count-text" data-speed="3500" data-stop="200">30</span>
                                                    <h4 className="counter-title">Projects</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column counter-column col-md-4 col-sm-6 col-xs-12">
                                            <div className="inner">
                                                <div className="count-outer count-box counted">
                                                    <span className="count-text" data-speed="3500" data-stop="895">15</span>
                                                    <h4 className="counter-title">Employers</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column counter-column col-md-4 col-sm-6 col-xs-12">
                                            <div className="inner">
                                                <div className="count-outer count-box counted">
                                                    <span className="count-text" data-speed="3500" data-stop="954">40</span>
                                                    <h4 className="counter-title">Customers</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="fluid-section-one pb-70">
                <div className="outer-container clearfix">
                    <div className="image-column" style={{ backgroundImage: `url(${image1})` }} >
                        <figure className="image-box">
                            <img decoding="async" src={image1} alt="Why Choose Us" />
                        </figure>
                    </div>
                    <div className="content-column">
                        <div className="inner-box">
                            <div className="sec-title">
                                <div className="title">We Offer Best Services &amp; Solutions</div>
                                <h2><span className="theme_color">Why </span> Choose Us</h2>
                            </div>
                            <div className="text">
                                At Homex Builders Corp, we understand that choosing the right construction partner is crucial for your project's success. Here's why we stand out in the crowded New York City market:
                            </div>
                            <ul className="list-style-one clearfix">
                                <li className="col-md-6 col-sm-6 col-xs-12"><span className="icon flaticon-briefcase-1"></span>EXPERT &amp; PROFESSIONAL</li>
                                <li className="col-md-6 col-sm-6 col-xs-12"><span className="icon flaticon-diamond-1"></span>PROFESSIONAL APPROACH</li>
                                <li className="col-md-6 col-sm-6 col-xs-12"><span className="icon flaticon-bank-building"></span>HIGHT QUALITY WORK</li>
                                <li className="col-md-6 col-sm-6 col-xs-12"><span className="icon flaticon-two-fingers-up"></span>SATISFACTION GUARANTEE</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonial-section" style={{ backgroundImage: `url(${patern_1})` }} >
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="testimonial-img image-column col-md-5 col-sm-5 col-xs-12">
                            <div className="image">
                                <img decoding="async" src={testimonial} alt="Awesome Image" />
                            </div>
                        </div>
                        <div className="carousel-column col-md-7 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <div className="title">Testimonial</div>
                                    <h2><span className="theme_color">Clients </span> Say</h2>
                                </div>
                                <OwlCarousel className='owl-theme' loop margin={10} nav items={1}>
                                    {
                                        testimonials.length > 0 ? testimonials.map(item => {
                                            let images = item.clientImage;
                                            let splittedPath = images.split(',');
                                            let img = variables.projectImgPath + splittedPath[0];
                                            return (
                                                <div className='item'>
                                                    <div className="testimonial-block">
                                                        <div className="inner-box">
                                                            <div className="text">
                                                                {item.text}
                                                            </div>
                                                            <div className="author-info">
                                                                <div className="author-inner">
                                                                    <div className="image">
                                                                        <img loading="lazy" decoding="async" width="80" height="80" src={img} className="attachment-konstructo_80x80 size-konstructo_80x80 wp-post-image" alt="" />
                                                                    </div>
                                                                    <h3>{item.clientName}</h3>
                                                                    <div className="designation">{item.projectName}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                        })
                                            :
                                            <></>
                                    }
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
