import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import exterior1 from "../assets/images/waterproofingservices/exterior1.jpg"
import exterior2 from "../assets/images/waterproofingservices/exterior2.jpg"
import exterior3 from "../assets/images/waterproofingservices/exterior3.jpg"

export const ExteriorWaterproofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={exterior1} alt="Exterior Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={exterior2} alt="Exterior Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={exterior3} alt="Exterior Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Exterior Waterproofing</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers specialized exterior waterproofing services designed to protect your home’s exterior surfaces and foundation from water damage, mold growth, and structural issues. Exterior waterproofing is essential to safeguard your property from rain, groundwater, and environmental moisture, which can compromise the integrity of your home over time. Here’s an overview of the exterior waterproofing services provided by Homex Builders Corp and why you should choose them for your waterproofing needs.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Exterior Waterproofing Services?</h3>
                <p>
                    Homex Builders Corp provides comprehensive exterior waterproofing solutions tailored to the specific needs of your home and its surrounding environment. Their services are designed to prevent water infiltration, manage surface water, and protect your property from water-related damage.
                </p>
                <ul>
                    <li><strong>Excavation and Membrane Application: </strong>Homex Builders begins by carefully excavating around the exterior of your foundation to expose the foundation walls. They then apply high-quality waterproof membranes to these walls, creating a durable barrier that prevents water from penetrating through the foundation. These membranes are flexible and designed to accommodate minor foundation movements without cracking.</li>
                    <li><strong>Drainage Systems Installation: </strong>To effectively manage surface water, Homex Builders installs exterior drainage systems such as French drains, surface drains, and gutter extensions. These systems are designed to redirect water away from your foundation, reducing hydrostatic pressure and preventing water accumulation around the base of your home.</li>
                    <li><strong>Foundation Wall Coatings: </strong>In addition to membranes, Homex Builders applies specialized coatings to foundation walls, such as asphalt-based or elastomeric coatings. These coatings provide an extra layer of protection against water seepage and are highly effective in sealing minor cracks and imperfections in the foundation walls.</li>
                    <li><strong>Waterproof Coatings and Sealants: </strong>Homex Builders applies high-quality waterproof coatings and sealants to exterior walls to protect them from water penetration. These coatings are designed to withstand harsh weather conditions, UV exposure, and temperature fluctuations, providing long-lasting protection.</li>
                    <li><strong>Waterproof Membranes for Walls: </strong>For additional protection, Homex Builders installs waterproof membranes on exterior walls. These membranes act as a physical barrier that prevents water from coming into contact with the wall surface, effectively stopping water infiltration and protecting the structural integrity of the walls.</li>
                    <li><strong>Crack and Joint Sealing: </strong>Homex Builders focuses on sealing all cracks, gaps, and joints in exterior walls, which are common entry points for water. They use high-quality sealants and epoxy injections to fill and seal these vulnerable areas, preventing water from seeping through.</li>
                    <li><strong>Driveway and Walkway Waterproofing: </strong>Homex Builders also offers waterproofing services for driveways, walkways, and other exterior surfaces that are prone to water damage. They apply specialized sealants and coatings that protect these surfaces from moisture, cracking, and erosion, extending their lifespan and maintaining their appearance.</li>
                    <li><strong>Deck and Patio Waterproofing: </strong>For decks, patios, and other outdoor living spaces, Homex Builders provides waterproofing solutions that protect against water damage, UV exposure, and mold growth. This includes the application of waterproof coatings, sealants, and membranes designed specifically for wood, composite, or concrete surfaces.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Exterior Waterproofing?</h4>
                    <p>
                        Choosing Homex Builders Corp for exterior waterproofing services comes with several advantages that make them a trusted choice for homeowners looking to protect their property from water damage and maintain the integrity of their home’s exterior. Here’s why you should consider their services:
                    </p>
                    <ul>
                        <li><strong>Skilled Professionals: </strong>Homex Builders has a team of highly trained and experienced professionals who specialize in exterior waterproofing. Their expertise ensures that every project is handled with precision and care, using the most effective techniques and materials.</li>
                        <li><strong>Comprehensive Knowledge:</strong> With years of experience in the industry, Homex Builders understands the unique challenges associated with waterproofing different types of exterior surfaces. They have the knowledge to recommend the most suitable waterproofing solutions based on the specific needs of your home.</li>
                        <li><strong>Customized Approach:</strong> Homex Builders provides customized waterproofing solutions tailored to the specific conditions of your property. They consider factors such as foundation type, wall material, local climate, and potential water sources to develop a comprehensive waterproofing plan.</li>
                        <li><strong>Range of Services:</strong> By offering a full range of exterior waterproofing solutions, Homex Builders can address all potential sources of water intrusion. This holistic approach ensures complete protection for your home’s exterior.</li>
                        <li><strong>Durable Materials:</strong> Homex Builders uses only high-quality, durable materials that are designed to withstand moisture, UV exposure, temperature changes, and environmental stressors. This commitment to quality ensures that your waterproofing solution is both effective and long-lasting.</li>
                        <li><strong>Latest Technology: </strong>Homex Builders stays updated with the latest advancements in waterproofing technology. They use state-of-the-art tools and products, such as liquid-applied membranes, elastomeric coatings, and high-performance sealants, which provide superior protection compared to traditional methods.</li>
                        <li><strong>Transparent Communication: </strong>Homex Builders values transparent communication with their clients. They take the time to explain their waterproofing process, the materials they use, and the expected outcomes, ensuring that you are fully informed and confident in their services.</li>
                        <li><strong>Warranty and Support: </strong>Homex Builders offers warranties on their waterproofing services, providing you with peace of mind that your investment is protected. They also provide ongoing support and maintenance services to ensure that your exterior remains protected over time.</li>
                        <li><strong>Satisfied Customers: </strong>Homex Builders Corp has a strong reputation for delivering high-quality exterior waterproofing services, as evidenced by numerous positive reviews and testimonials from satisfied customers. Their proven track record speaks to their reliability and commitment to excellence.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    Homex Builders Corp takes several important precautions to ensure the safety, effectiveness, and durability of their exterior waterproofing services. These precautions are designed to prevent water infiltration, minimize damage, and maintain the structural integrity of your property.
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Thorough Property Assessment: </strong>Before starting any waterproofing project, Homex Builders conducts a comprehensive assessment of your property’s exterior. This involves inspecting the foundation, walls, and surrounding landscape to identify potential water entry points, existing damage, and areas most vulnerable to water intrusion.</li>
                                        <li><strong>Surface Preparation: </strong>Proper surface preparation is crucial for effective waterproofing. Homex Builders ensures that all exterior surfaces are clean, dry, and free of dirt, debris, and previous coatings that may impede the adhesion of waterproofing materials. For foundation walls, this may involve excavation to expose the surface fully and repair any existing cracks or imperfections.</li>
                                        <li><strong>High-Quality Waterproofing Materials: </strong>To ensure long-lasting protection, Homex Builders uses only high-quality, industry-approved waterproofing materials. These materials are specifically designed to withstand moisture, UV exposure, temperature fluctuations, and environmental stressors, providing durable protection against water infiltration.</li>
                                        <li><strong>Proper Drainage Systems:</strong> Effective waterproofing is not just about sealing surfaces but also managing water flow. Homex Builders installs exterior drainage systems, such as French drains, surface drains, and gutter extensions, to redirect water away from the foundation and prevent water buildup that could lead to hydrostatic pressure and water penetration.</li>
                                        <li><strong>Weather Considerations:</strong> The timing of waterproofing application is carefully planned to avoid adverse weather conditions. Homex Builders schedules their work during dry weather to ensure that waterproofing materials can cure properly and adhere effectively to exterior surfaces.</li>
                                        <li><strong>Safety Measures:</strong> Safety is a top priority for Homex Builders. They follow strict safety protocols to protect both their workers and your property during the waterproofing process. This includes using appropriate personal protective equipment (PPE), securing the work area, and adhering to local building codes and regulations.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Homex Builders Corp leverages its deep knowledge and extensive experience in exterior waterproofing to provide high-quality, reliable solutions. Their intelligence in this field comes from years of experience and continuous learning about the latest waterproofing technologies and techniques.
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Advanced Waterproofing Techniques: </strong>Homex Builders employs a range of advanced waterproofing techniques tailored to the specific needs of your property. This includes using exterior waterproofing membranes, liquid-applied coatings, elastomeric paints, and sealants that create a robust barrier against water intrusion.</li>
                                        <li><strong>Customized Solutions:</strong> Recognizing that every property is unique, Homex Builders provides customized waterproofing solutions based on the specific conditions and requirements of each home. They consider factors such as foundation type, wall material, local climate, water table levels, and landscaping to develop a comprehensive waterproofing plan.</li>
                                        <li><strong>Innovative Technologies: </strong>Homex Builders stays up-to-date with the latest advancements in waterproofing technology. They use state-of-the-art products and tools, such as flexible waterproof membranes, breathable coatings, and high-performance sealants, which provide superior protection and durability compared to traditional methods.</li>
                                        <li><strong>Knowledgeable Team: </strong>The team at Homex Builders consists of highly trained professionals who understand the complexities of exterior waterproofing. Their expertise allows them to quickly diagnose water intrusion problems, recommend effective solutions, and execute the work with precision.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    Homex Builders Corp specializes in exterior waterproofing, making them experts in protecting your home from water damage and preserving its structural integrity. Their specialization is evident in several key areas:
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Foundation Waterproofing: </strong>Homex Builders has specialized knowledge in waterproofing foundations, which is critical to preventing water from penetrating and damaging the structure. They use high-quality membranes and coatings that provide a flexible, watertight barrier that adapts to minor foundation movements.</li>
                                        <li><strong>Wall Waterproofing: </strong>For exterior walls, Homex Builders provides specialized waterproofing solutions, including waterproof coatings, membranes, and sealants that protect against rain, snow, and groundwater. Their solutions are designed to prevent water from penetrating the walls and causing structural damage or mold growth.</li>
                                        <li><strong>Surface Waterproofing:</strong> Homex Builders offers waterproofing services for exterior surfaces such as driveways, walkways, patios, and decks. They apply specialized sealants and coatings that protect these surfaces from moisture, cracking, and erosion, extending their lifespan and maintaining their appearance.</li>
                                        <li><strong>Drainage Optimization: </strong>Proper drainage is a critical component of exterior waterproofing, and Homex Builders specializes in designing and installing efficient drainage systems. This includes exterior drainage solutions, such as French drains and gutter extensions, to manage surface water effectively and prevent it from pooling around the foundation.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Homex Builders Corp’s exterior waterproofing services are characterized by their expertise, comprehensive solutions, use of high-quality materials, and commitment to customer satisfaction. Their focus on both foundation and wall waterproofing ensures that every potential source of water intrusion is addressed, providing maximum protection for your home’s exterior.
                </p>
                <p>
                    By choosing Homex Builders Corp for your exterior waterproofing needs, you are opting for a company that prioritizes quality, safety, and long-term results. Their customized solutions and advanced techniques make them a trusted choice for maintaining the integrity of your home.
                </p>
                <p>
                    If you’re looking to protect your home’s exterior from water damage and ensure a healthy living environment, consider reaching out to Homex Builders Corp. Their expertise and dedication to excellence will help ensure your property remains dry, safe, and secure.
                </p>
            </div>
        </>
    );
}
