import { Home } from "./components/Home";
import { AboutUs } from "./components/AboutUs";
import { ContactUs } from "./components/ContactUs";
import { Projects } from "./components/Projects";
import { Roofing } from "./components/Roofing";
import { Waterproofing } from "./components/Waterproofing";
import { Remodeling } from "./components/Remodeling";
import { Sidewalk } from "./components/Sidewalk";
import { Restoration } from "./components/Restoration";
import { Masonry } from "./components/Masonry";
import { ServiceDetail } from "./components/ServiceDetail";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/about-us',
    element: <AboutUs />
  },
  {
      path: '/contact-us',
      element: <ContactUs />
  },
  {
      path: '/projects',
      element: <Projects />
    },
    {
        path: '/roofing-services',
        element: <Roofing />
    },
    {
        path: '/waterproofing-services',
        element: <Waterproofing />
    },
    {
        path: '/remodeling-services',
        element: <Remodeling />
    },
    {
        path: '/sidewalk-services',
        element: <Sidewalk />
    },
    {
        path: '/restoration-services',
        element: <Restoration />
    },
    {
        path: '/masonry-services',
        element: <Masonry />
    },
    {
        path: '/service-detail/:serviceName?',
        element: <ServiceDetail />
    }
];

export default AppRoutes;
