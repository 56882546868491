import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SubHeader } from './SubHeader';
import OwlCarousel, { Options } from 'react-owl-carousel';
import { ServicesList } from './ServicesList';

export const Masonry = (props) => {

    return (
        <>
            <SubHeader title="Masonry" />
            <section className="featured-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-column col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title centered">
                                    <div className="title">Masonry Services</div>
                                    <h2><span className="theme_color">Your Trusted Masonry Solutions</span> Partner in New York</h2>
                                </div>
                                <div className="text justified">
                                    <p>
                                        At Homex Builders Corp, we specialize in delivering top-tier masonry services throughout New York. With a dedication to quality craftsmanship and a deep understanding of New York’s architectural styles, we offer a full range of masonry solutions designed to enhance the structural integrity, functionality, and aesthetic appeal of your property. Whether you need brickwork, stone installation, or concrete repairs, Homex Builders Corp is your trusted masonry partner for residential and commercial projects alike.
                                    </p>
                                    <p>
                                        Our team of skilled masons combines traditional techniques with modern tools to deliver high-quality results that stand the test of time. From repairs and restoration to new construction, we bring both technical expertise and artistic precision to every project.
                                    </p>
                                    <p>
                                        What sets us apart is our dedication to quality and customer satisfaction. We use top-grade materials and follow industry best practices to ensure that your roof not only enhances the appearance of your property but also provides robust protection against the elements. With years of experience in the roofing industry, we have built a reputation for excellence, making us the go-to roofing partner for countless clients across New York.
                                    </p>
                                    <p>
                                        Trust Homex Builders Corp to deliver Masonry solutions that meet your needs and exceed your expectations. Contact us today to schedule a consultation and discover why we're the preferred choice for roofing services in New York.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServicesList serviceType="masonry-services" title="Masonry" />
        </>
    );
}
