import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SubHeader } from './SubHeader';
import OwlCarousel, { Options } from 'react-owl-carousel';
import { ServicesList } from './ServicesList';

export const Sidewalk = (props) => {

    return (
        <>
            <SubHeader title="Sidewalk" />
            <section className="featured-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-column col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title centered">
                                    <div className="title">Sidewalk Services</div>
                                    <h2><span className="theme_color">Your Trusted Sidewalk Solutions</span> Partner in New York</h2>
                                </div>
                                <div className="text justified">
                                    <p>
                                        At Homex Builders Corp, we specialize in providing high-quality, reliable sidewalk solutions across New York City. Whether you need sidewalk repair, installation, or replacement, our expert team delivers durable, code-compliant results that enhance the safety, aesthetics, and value of your property. As New York's trusted sidewalk solutions partner, we understand the importance of keeping sidewalks in excellent condition to ensure pedestrian safety and avoid potential violations from the Department of Transportation (DOT).
                                        From residential properties to commercial developments, our sidewalk services are designed to meet the unique needs of New Yorkers while adhering to the strict standards and regulations of the city.
                                    </p>
                                    <p>
                                        Proper sidewalk maintenance is essential for ensuring the safety and accessibility of your property. Cracks, uneven surfaces, and other forms of damage can lead to trips and falls, posing a liability risk for property owners. Additionally, unmaintained sidewalks can result in DOT violations, leading to costly fines and repair orders.
                                        Regular sidewalk inspections, timely repairs, and proactive maintenance can save you money and prevent accidents. At Homex Builders Corp, we offer routine maintenance services to help keep your sidewalks in top condition year-round.
                                    </p>
                                    <p>
                                        Whether you need a simple sidewalk repair or a full replacement, Homex Builders Corp is here to help. Our team of experienced professionals will assess your sidewalk, provide expert recommendations, and deliver high-quality solutions that keep your property safe and compliant with city regulations.
                                        Contact us today for a free consultation and let us take care of all your sidewalk needs in New York City!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServicesList serviceType="sidewalk-services" title="Sidewalk" />
        </>
    );
}
