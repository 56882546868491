import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import commercial_roofing1 from "../assets/images/roofingservices/commercial_roofing1.jpg"
import commercial_roofing2 from "../assets/images/roofingservices/commercial_roofing2.jpg"
import commercial_roofing3 from "../assets/images/roofingservices/commercial_roofing3.jpg"

export const CommercialRoofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);

        //if (!document.getElementById(tabTarget).classList.contains("active-tab")) {
        //    document.getElementById(tabTarget).classList.add("active-tab");
        //}
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={commercial_roofing1} alt="Commercial Roofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={commercial_roofing2} alt="Commercial Roofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={commercial_roofing3} alt="Commercial Roofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Commercial Roofing</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers comprehensive commercial roofing services designed to meet the unique needs of businesses across New York. Our expertise covers all aspects of commercial roofing, ensuring your property is protected, energy-efficient, and compliant with local regulations.
                </p>
            </div>
            <div className="text">
                <h3>Services We Offer:</h3>
                <ul>
                    <li>
                        <strong>Roof Installation: </strong>
                        We specialize in installing a wide range of commercial roofing systems, including flat roofs, TPO, EPDM, PVC, and metal roofing. Our team ensures precise installation for maximum durability and performance.
                    </li>
                    <li>
                        <strong>Roof Repair: </strong>
                        Whether it's a minor leak or significant damage, our skilled professionals are equipped to handle repairs of all sizes. We quickly identify issues and implement effective solutions to minimize disruption to your business.
                    </li>
                    <li>
                        <strong>Roof Maintenance: </strong>
                        Regular maintenance is crucial for extending the lifespan of your commercial roof. We offer customized maintenance plans that include inspections, cleaning, and minor repairs to keep your roof in top condition year-round.
                    </li>
                    <li>
                        <strong>Roof Replacement: </strong>
                        When repair isn't enough, we provide complete roof replacement services. We work efficiently to replace your old roof with minimal downtime, ensuring your business continues to operate smoothly.
                    </li>
                    <li>
                        <strong>Roof Coating: </strong>
                        To enhance the longevity and energy efficiency of your existing roof, we offer high-quality roof coatings. These coatings provide a protective barrier against UV rays, weathering, and other environmental factors.
                    </li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp?</h4>
                    <ul>
                        <li>
                            <strong>Experienced Professionals: </strong>
                            Our team is composed of highly trained roofing experts with years of experience in commercial roofing projects.
                        </li>
                        <li>
                            <strong>Quality Materials: </strong>
                            We use only the best materials from trusted manufacturers to ensure long-lasting results.
                        </li>
                        <li>
                            <strong>Customized Solutions: </strong>
                            We understand that every business is different, which is why we offer tailored roofing solutions to meet your specific needs.
                        </li>
                        <li>
                            <strong>Safety and Compliance: </strong>
                            We adhere to all safety standards and local building codes, ensuring your commercial roofing project is completed safely and legally.
                        </li>
                    </ul>
                </blockquote>

                <p>For reliable, efficient, and high-quality commercial roofing services, trust Homex Builders Corp. Contact us today to discuss your project and get a free consultation.</p>
                <p>When it comes to commercial roofing, ensuring the job is done right requires a combination of specialized skills, intelligent planning, and strict safety precautions. Here’s how these three elements play a critical role in delivering top-notch commercial roofing services:</p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Safety Standards:</strong> Adherence to OSHA guidelines is non-negotiable. This includes the use of personal protective equipment (PPE), fall protection systems, and scaffolding to safeguard workers. Regular safety training and site audits are conducted to maintain a safe working environment.
                                        </li>
                                        <li>
                                            <strong>Worksite Management:</strong> To protect your business operations, we implement secure work zones, use barriers to control debris, and minimize disruption. We also plan our work to avoid peak business hours, ensuring that your operations continue smoothly.
                                        </li>
                                        <li>
                                            <strong>Environmental Considerations:</strong> During the roofing process, we use low-VOC materials and eco-friendly practices to reduce environmental impact. We also ensure proper disposal of old materials, preventing contamination and promoting sustainability.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Advanced Diagnostics: </strong>
                                                Before any work begins, we use tools like thermal imaging, drone inspections, and moisture detection to accurately assess the condition of your roof. This intelligent approach allows us to identify issues that may not be visible to the naked eye, ensuring a thorough and effective solution.
                                        </li>
                                        <li>
                                            <strong>Data-Driven Decisions:</strong>
                                                We utilize predictive analytics to forecast potential roofing issues based on historical data and current conditions. This proactive approach helps prevent costly repairs and extends the lifespan of your roof
                                        </li>
                                        <li>
                                            <strong>Technology Integration:</strong>
                                                From 3D modeling to project management software, we incorporate cutting-edge technology into every phase of the roofing process. This ensures precise planning, efficient execution, and real-time updates for our clients
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Diverse Roofing Systems: </strong>
                                                We specialize in installing and maintaining a variety of commercial roofing systems, including TPO, EPDM, PVC, metal roofing, and green roofs. Each system is selected based on your building’s specific needs, ensuring optimal performance and longevity.
                                        </li>
                                        <li>
                                            <strong>Custom Solutions: </strong>
                                                Understanding that every commercial property is unique, we tailor our services to meet the specific requirements of your building. Whether it’s a high-rise, warehouse, or retail space, we provide roofing solutions that align with your operational needs and budget.
                                        </li>
                                        <li>
                                            <strong>Energy Efficiency Expertise: </strong>
                                                Our specialization includes the installation of energy-efficient roofing systems and coatings that help reduce cooling costs and improve building sustainability. We stay informed on the latest industry trends to provide solutions that are both eco-friendly and cost-effective.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
