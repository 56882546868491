import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import fireescapes1 from "../assets/images/masonry/fireescapes1.jpg"
import fireescapes2 from "../assets/images/masonry/fireescapes2.jpg"
import fireescapes3 from "../assets/images/masonry/fireescapes3.jpg"

export const FireEscapes = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={fireescapes1} alt="Fire Escapes" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={fireescapes2} alt="Fire Escapes" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={fireescapes3} alt="Fire Escapes" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Fire Escapes</h3>
            <div className="text">
                <p>
                    Homex Builders Corp provides comprehensive fire escape services designed to ensure the safety, functionality, and compliance of fire escape systems for residential, commercial, and industrial buildings. Fire escapes are a critical component of a building's emergency evacuation plan, and their proper maintenance, repair, and installation are essential for the safety of occupants. Here’s an overview of our fire escape services and the reasons why you should choose Homex Builders Corp for your project:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Fire Escapes Services?</h3>
                <ul>
                    <li><strong>Fire Escape Installation: </strong>We offer professional fire escape installation services for new buildings or properties needing an additional or replacement fire escape. Our team designs and installs fire escapes that meet all local building codes, safety regulations, and aesthetic preferences, ensuring both compliance and seamless integration with your building’s exterior.</li>
                    <li><strong>Fire Escape Inspection and Certification: </strong>Regular inspections are crucial to maintaining the safety and compliance of fire escapes. We provide thorough inspection services to evaluate the condition of your fire escape system, checking for structural integrity, signs of rust or corrosion, proper anchorage, and overall functionality. After the inspection, we provide certification if the fire escape meets all safety standards, or we recommend necessary repairs.</li>
                    <li><strong>Fire Escape Repairs and Maintenance: </strong>If your fire escape shows signs of wear and tear, rust, or structural damage, we offer expert repair services to restore its safety and functionality. Our repair services include welding, metal replacement, rust removal, and repainting to protect against further corrosion. Regular maintenance is also provided to ensure your fire escape remains in good working condition.</li>
                    <li><strong>Fire Escape Restoration: </strong>For older buildings with historic or ornate fire escapes, we offer restoration services that preserve the architectural integrity while bringing the fire escape up to current safety standards. Our team carefully restores metalwork, replaces damaged components, and applies protective coatings to extend the life of your fire escape.</li>
                    <li><strong>Fire Escape Removal and Replacement: </strong>When a fire escape is beyond repair or no longer compliant with safety regulations, we offer removal and replacement services. Our team safely dismantles the existing structure and installs a new, compliant fire escape system that ensures the safety of your building’s occupants.</li>
                    <li><strong>Fire Escape Painting and Coating: </strong>To protect fire escapes from rust and corrosion, we offer specialized painting and coating services. Using high-quality, weather-resistant paints and coatings, we enhance the durability and appearance of your fire escape, extending its lifespan and ensuring it remains safe and functional.</li>
                    
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Fire Escapes?</h4>
                    <ul>
                        <li><strong>Extensive Experience and Expertise: </strong>Homex Builders Corp brings years of experience and specialized knowledge in fire escape services. Our team of professionals is skilled in all aspects of fire escape installation, repair, maintenance, and restoration, ensuring that every project is completed to the highest standards of safety and quality.</li>
                        <li><strong>Comprehensive Service Offerings: </strong>We provide a full range of fire escape services, making us a one-stop solution for all your fire escape needs. From installation and repairs to inspections and certifications, we handle every aspect of fire escape safety and compliance, streamlining the process and ensuring consistent quality.</li>
                        <li><strong>Quality Craftsmanship and Materials: </strong>We use only the highest-quality materials and employ skilled craftsmen to ensure that every fire escape we work on is safe, durable, and visually appealing. Our commitment to quality means that your fire escape will not only meet all safety standards but will also enhance the aesthetic of your building.</li>
                        <li><strong>Customized Solutions: </strong>We understand that every building has unique requirements, and we provide customized fire escape solutions tailored to your specific needs. Whether you need a new installation, a historic restoration, or a targeted repair, we develop a plan that meets your safety needs and budget.</li>
                        <li><strong>Compliance with Safety Standards: </strong>At Homex Builders Corp, safety is our top priority. Our fire escape services are conducted in full compliance with all local building codes and fire safety regulations, ensuring that your fire escape system meets all legal requirements and provides a safe means of egress in an emergency.</li>
                        <li><strong>Transparent Communication: </strong>We believe in maintaining open and honest communication with our clients. From the initial consultation to project completion, we keep you informed at every stage of the process. Our detailed estimates and clear explanations ensure you understand the scope, timeline, and cost of your project, providing peace of mind and confidence in our services.</li>
                        <li><strong>Competitive Pricing: </strong>We offer competitive pricing for our fire escape services without compromising on quality. Our transparent pricing structure ensures you receive excellent value for your investment, and our detailed quotes have no hidden fees or unexpected costs.</li>
                        <li><strong>Proven Track Record: </strong>Homex Builders Corp has a proven track record of delivering high-quality fire escape services to satisfied clients across various building types, from historic properties to modern high-rises. Our reputation for reliability, professionalism, and customer satisfaction makes us a trusted choice for fire escape projects.</li>
                        <li><strong>Responsive and Reliable Service: </strong>We understand the urgency that often accompanies fire escape repairs or compliance updates. Our team is responsive and reliable, ready to address your needs promptly and efficiently to minimize any disruptions to your property and ensure continuous safety for occupants.</li>
                        <li><strong>Environmentally Friendly Practices: </strong>We are committed to environmentally friendly practices in all our services. We use sustainable materials, minimize waste, and apply eco-friendly coatings and paints, ensuring our work not only protects your building but also supports a greener environment.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    We prioritize safety above all else when designing and installing fire escapes. Our process begins with a thorough assessment of the building’s layout, occupancy, and specific fire risks. We ensure strict adherence to all relevant building codes and fire safety regulations, taking into account factors like load-bearing capacity, egress routes, and accessibility. We also select materials that are not only durable but also fire-resistant, ensuring long-term safety and reliability. Regular inspections and maintenance schedules are also part of our commitment to ensuring that fire escapes remain in optimal condition over time.

                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Our approach to fire escape design is deeply rooted in intelligence, leveraging advanced technology and smart design principles. We use state-of-the-art software for detailed planning and simulation, allowing us to anticipate potential challenges and optimize our designs for efficiency and safety. We integrate modern features such as automated alert systems, emergency lighting, and non-slip surfaces to enhance safety during an emergency. Our team stays current with the latest developments in fire safety technology, ensuring that we bring the most effective solutions to every project.
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    We specialize in creating tailored fire escape solutions that meet the unique needs of each building we work on. Whether it's a residential property, a commercial complex, or an industrial facility, we understand that different structures have different requirements. Our expertise allows us to design fire escapes that not only meet safety standards but also complement the architectural aesthetics of the building. This specialization means we are equipped to handle complex and custom projects, providing solutions that are both functional and visually integrated into the building’s design.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for your fire escape services means partnering with a company dedicated to quality, safety, and customer satisfaction. Our extensive experience, comprehensive service offerings, and commitment to excellence make us the ideal choice for all your fire escape needs, whether it's installation, repair, inspection, or restoration.
                </p>
                <p>
                    Contact Homex Builders Corp today to schedule a consultation or request a quote, and let us help you ensure the safety, compliance, and longevity of your building's fire escape system.
                </p>
            </div>
        </>
    );
}
