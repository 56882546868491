import React, { Component, useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './NavMenu.css';

import logo from "../assets/images/logo.png"

export const NavMenu = (props) => {
    const { reference } = props;
    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = (e) => {
        setCollapsed(prevState => !prevState);
    }

    const handleLinkClick = () => {
        setCollapsed(true);
    };

    const toggleSubMenu = (e) => {
        let target = e.target;
        if (e.target.nodeName == 'svg') {
            target = e.target.parentElement;
        }
        if (e.target.nodeName == 'path') {
            let svg = e.target.parentElement;
            target = svg.parentElement;
        }
        let prevSibling = target.previousSibling;
        if (prevSibling.style.display == "block") {
            prevSibling.style.display = "none";
        }
        else {
            prevSibling.style.display = "block";
        }
    }

    return (
        <header className="main-header" ref={reference}>
            <div className="header-top">
                <div className="auto-container">
                    <div className="clearfix">
                        <div className="top-left pull-left">
                            <ul className="clearfix">
                                <li>Welcome to Homex Builders Corp</li>
                            </ul>
                        </div>
                        <div className="top-right pull-right">
                            <ul className="list">
                                <li>Have any question? <a href="tel:+19293854789">(929) 385-4789</a> | <a href="tel:+19293854789">(929) 385-3287</a></li>
                            </ul>
                            <ul className="social-nav">
                                <li>
                                    <a href="https://www.facebook.com/share/J8qGQr2s1oTxBvP3/?mibextid=LQQJ4d" target="_blank" style={{ color: "rgb(255, 255, 255)" }} className="fa fa-facebook">
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </a>
                                </li>
                                {/*<li>*/}
                                {/*    <a href="#" style={{ color: "rgb(255, 255, 255)" }} className="fa fa-pinterest">*/}
                                {/*        <FontAwesomeIcon icon={faLinkedin} />*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                <li>
                                    <a href="https://www.instagram.com/homexbuilderscorpnyc/" target="_blank" style={{ color: "rgb(255, 255, 255)" }} className="fa fa-twitter">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-upper">
                <div className="auto-container">
                    <div className="clearfix">

                        <div className="pull-left logo-box">
                            <div className="logo">
                                <a href="/" title="Konstructo">
                                    <img src={logo} alt="Homex Builders Corp" />
                                </a>
                            </div>
                        </div>

                        <div className="pull-right upper-right clearfix">

                            <div className="upper-column info-box">
                                <div className="icon-box"><span className="flaticon-home-1"></span></div>
                                <ul>
                                    <li><strong>827 Mace Ave Bronx,</strong></li>
                                    <li>NY 10467, United States</li>
                                </ul>
                            </div>

                            <div className="upper-column info-box">
                                <div className="icon-box"><span className="flaticon-note"></span></div>
                                <ul>
                                    <li><strong>Send your mail at</strong></li>
                                    <li>Customer@homexbuilders.com</li>
                                    <li className="d-none-lg">Homexbuildercorp@gmail.com</li>
                                </ul>
                            </div>

                            <div className="upper-column info-box">
                                <div className="icon-box"><span className="flaticon-clock-1"></span></div>
                                <ul>
                                    <li><strong>Working Hours</strong></li>
                                    <li>Mon-Sat:8:00am to 5.00pm</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="header-lower">
                <div className="auto-container">
                    <div className="nav-outer clearfix">

                        <Navbar className="main-menu navbar-expand-sm navbar-toggleable-sm" fluid light>
                            <div className="navbar-header">
                                <NavbarToggler onClick={toggleNavbar} className="navbar-toggle" />
                            </div>
                            <Collapse className="navbar-collapse" isOpen={!collapsed} navbar>
                                <ul className="navigation">
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/">
                                            Home
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-254">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/about-us">
                                            About Us
                                        </NavLink>
                                    </NavItem>

                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/roofing-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Roofing
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Commercial Roofing">
                                                    Commercial Roofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Residential Roofing">
                                                    Residential Roofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Maintenance">
                                                    Roof Maintenance
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Inspection">
                                                    Roof Inspection
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Repairs">
                                                    Roof Repairs
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Replacement">
                                                    Roof Replacement
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Installation">
                                                    Roof Installation
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/waterproofing-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Waterproofing
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Balcony Waterproofing">
                                                    Balcony Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Deck Waterproofing">
                                                    Deck Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Basement Waterproofing">
                                                    Basement Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Foundation Waterproofing">
                                                    Foundation Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Wall Waterproofing">
                                                    Wall Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Exterior Waterproofing">
                                                    Exterior Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Masonry Waterproofing">
                                                    Masonry Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Bituminous Waterproofing">
                                                    Bituminous Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Elastomeric Waterproofing">
                                                    Elastomeric Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Window Caulking">
                                                    Window Caulking
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/remodeling-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Remodeling
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Bathroom Remodeling">
                                                    Bathroom Remodeling
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Kitchen Remodeling">
                                                    Kitchen Remodeling
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Basement Renovation">
                                                    Basement Renovation
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/sidewalk-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Sidewalk
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/NYC Sidewalk Repair">
                                                    NYC Sidewalk Repair
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Sidewalk Replacement">
                                                    Sidewalk Replacement
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Sidewalk Installation">
                                                    Sidewalk Installation
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Sidewalk Curb Repair">
                                                    Sidewalk Curb Repair
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Sidewalk Violation Removal">
                                                    Sidewalk Violation Removal
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Driveway Repair">
                                                    Driveway Repair
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/restoration-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Restoration
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Brick Pointing">
                                                    Brick Pointing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Brick Restoration">
                                                    Brick Restoration
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Brownstone Restoration">
                                                    Brownstone Restoration
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Deck Restoration">
                                                    Deck Restoration
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Water Damage Restoration">
                                                    Water Damage Restoration
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Stone Restoration">
                                                    Stone Restoration
                                                </NavLink>
                                            </NavItem>
                                            {/*<NavItem className="menu-item">*/}
                                            {/*    <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Wood Restoration">*/}
                                            {/*        Wood Restoration*/}
                                            {/*    </NavLink>*/}
                                            {/*</NavItem>*/}
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Masonry Cleaning">
                                                    Masonry Cleaning
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Local Law 11">
                                                    Local Law 11
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/masonry-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Masonry
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Facade Repair">
                                                    Facade Repair
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Chimney Rebuilds">
                                                    Chimney Rebuilds
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Expansion Joints">
                                                    Expansion Joints
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Fire Escapes">
                                                    Fire Escapes
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Patios">
                                                    Patios
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Power Washing">
                                                    Power Washing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Retaining Walls">
                                                    Retaining Walls
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Stair Repair & Building">
                                                    Stair Repair & Building
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/projects">
                                            Projects
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="menu-item">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/contact-us">
                                            Contact Us
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </Collapse>
                        </Navbar>
                    </div>
                </div>
            </div>

            <div className="sticky-header">
                <div className="auto-container clearfix">
                    <div className="logo pull-left">
                        <a href="/" title="Konstructo">
                            <img src={logo} alt="logo" />
                        </a>
                    </div>

                    <div className="right-col pull-right">
                        <Navbar className="main-menu navbar-expand-sm navbar-toggleable-sm" container light>
                            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                                <ul className="navigation navbar-nav flex-grow">
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/">
                                            Home
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-254">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/about-us">
                                            About Us
                                        </NavLink>
                                    </NavItem>

                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/roofing-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Roofing
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Commercial Roofing">
                                                    Commercial Roofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Residential Roofing">
                                                    Residential Roofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Maintenance">
                                                    Roof Maintenance
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Inspection">
                                                    Roof Inspection
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Repairs">
                                                    Roof Repairs
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Replacement">
                                                    Roof Replacement
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} onClick={handleLinkClick} to="/service-detail/Roof Installation">
                                                    Roof Installation
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/waterproofing-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Waterproofing
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Balcony Waterproofing">
                                                    Balcony Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Deck Waterproofing">
                                                    Deck Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Basement Waterproofing">
                                                    Basement Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Foundation Waterproofing">
                                                    Foundation Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Wall Waterproofing">
                                                    Wall Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Exterior Waterproofing">
                                                    Exterior Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Masonry Waterproofing">
                                                    Masonry Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Bituminous Waterproofing">
                                                    Bituminous Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Elastomeric Waterproofing">
                                                    Elastomeric Waterproofing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Window Caulking">
                                                    Window Caulking
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/remodeling-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Remodeling
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Bathroom Remodeling">
                                                    Bathroom Remodeling
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Kitchen Remodeling">
                                                    Kitchen Remodeling
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Basement Renovation">
                                                    Basement Renovation
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/sidewalk-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Sidewalk
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/NYC Sidewalk Repair">
                                                    NYC Sidewalk Repair
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Sidewalk Replacement">
                                                    Sidewalk Replacement
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Sidewalk Installation">
                                                    Sidewalk Installation
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Sidewalk Curb Repair">
                                                    Sidewalk Curb Repair
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Sidewalk Violation Removal">
                                                    Sidewalk Violation Removal
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Driveway Repair">
                                                    Driveway Repair
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/restoration-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Restoration
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Brick Pointing">
                                                    Brick Pointing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Brick Restoration">
                                                    Brick Restoration
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Brownstone Restoration">
                                                    Brownstone Restoration
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Deck Restoration">
                                                    Deck Restoration
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Water Damage Restoration">
                                                    Water Damage Restoration
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Stone Restoration">
                                                    Stone Restoration
                                                </NavLink>
                                            </NavItem>
                                            {/*<NavItem className="menu-item">*/}
                                            {/*    <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Wood Restoration">*/}
                                            {/*        Wood Restoration*/}
                                            {/*    </NavLink>*/}
                                            {/*</NavItem>*/}
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Masonry Cleaning">
                                                    Masonry Cleaning
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Local Law 11">
                                                    Local Law 11
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item menu-item-has-children dropdown">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/masonry-services" data-toggle="dropdown1" aria-expanded="false" data-scroll="" data-options="easing: easeOutQuart">
                                            Masonry
                                        </NavLink>
                                        <ul role="menu" className="submenu">
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Facade Repair">
                                                    Facade Repair
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Chimney Rebuilds">
                                                    Chimney Rebuilds
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Expansion Joints">
                                                    Expansion Joints
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Fire Escapes">
                                                    Fire Escapes
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Patios">
                                                    Patios
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Power Washing">
                                                    Power Washing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Retaining Walls">
                                                    Retaining Walls
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="menu-item">
                                                <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/service-detail/Stair Repair & Building">
                                                    Stair Repair & Building
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div className="dropdown-btn" onClick={toggleSubMenu} ><FontAwesomeIcon icon={faAngleDown} /></div>
                                    </NavItem>
                                    <NavItem className="menu-item">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/projects">
                                            Projects
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="menu-item">
                                        <NavLink tag={Link} className="hvr-underline-from-left1" onClick={handleLinkClick} to="/contact-us">
                                            Contact Us
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </Collapse>
                        </Navbar>
                    </div>
                </div>
            </div>

            {/* <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                <NavbarBrand tag={Link} onClick={handleLinkClick} to="/">HomexBuilders</NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" onClick={handleLinkClick} to="/">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" onClick={handleLinkClick} to="/counter">Counter</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" onClick={handleLinkClick} to="/fetch-data">Fetch data</NavLink>
                        </NavItem>
                    </ul>
                </Collapse>
            </Navbar>
            </header> */}
        </header>
    );
}
