import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import roof_maintenance1 from "../assets/images/roofingservices/roof_maintenance1.jpg"
import roof_maintenance2 from "../assets/images/roofingservices/roof_maintenance2.jpg"
import roof_maintenance3 from "../assets/images/roofingservices/roof_maintenance3.jpg"

export const RoofMaintenance = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={roof_maintenance1} alt="Roof Maintenance" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={roof_maintenance2} alt="Roof Maintenance" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={roof_maintenance3} alt="Roof Maintenance" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Roof Maintenance</h3>
            <div className="text">
                <p>
                    Homex Builder Corp offers a range of roof maintenance services designed to keep your roof in optimal condition, ensuring longevity and preventing costly repairs. Below is an overview of the detailed services they provide for roof maintenance:
                </p>
            </div>
            <div className="text">
                <h3>Roof Maintenance Services We Offer:</h3>
                <h4>1. Roof Inspection</h4>
                <p className="m-0-pl-8">Homex Builder Corp conducts thorough inspections to identify any potential issues early on. This service includes:</p>
                <ul>
                    <li>
                        <strong>Leak Detection: </strong>
                        Checking for signs of water penetration or leaks.
                    </li>
                    <li>
                        <strong>Shingle Integrity: </strong>
                        Inspecting shingles for damage, wear, or missing pieces.
                    </li>
                    <li>
                        <strong>Structural Review: </strong>
                        Assessing the overall structure for signs of damage or weakness.
                    </li>
                </ul>
                <h4>2. Roof Cleaning</h4>
                <p className="m-0-pl-8">Debris buildup, mold, and algae can cause serious damage over time. Their cleaning services include:</p>
                <ul>
                    <li>
                        <strong>Debris Removal: </strong>
                        Clearing leaves, branches, and other materials from gutters and roof surfaces.
                    </li>
                    <li>
                        <strong>Moss and Algae Treatment: </strong>
                        Using specialized cleaning solutions to prevent moss and algae growth.
                    </li>
                    <li>
                        <strong>Surface Cleaning: </strong>
                        Power washing to restore the roof’s original appearance.
                    </li>
                </ul>
                <h4>3. Gutter Maintenance</h4>
                <p className="m-0-pl-8">Gutters are essential for water drainage, and Homex ensures they are functioning properly by:</p>
                <ul>
                    <li>
                        <strong>Gutter Cleaning: </strong>Removing blockages to prevent overflow and water damage.
                    </li>
                    <li>
                        <strong>Gutter Repair and Replacement: </strong>Fixing or replacing damaged sections to maintain proper water flow.
                    </li>
                </ul>
                <h4>4. Shingle and Tile Replacement</h4>
                <p className="m-0-pl-8">For roofs with damaged or missing shingles, Homex offers:</p>
                <ul>
                    <li>
                        <strong>Shingle Repair: </strong>Replacing broken or worn shingles to prevent leaks.
                    </li>
                    <li>
                        <strong>Tile Replacement: </strong>Ensuring all roof tiles are intact and secure to protect against weather damage.
                    </li>
                </ul>
                <h4>5. Flashing Repair</h4>
                <p className="m-0-pl-8">Damaged flashing around chimneys, vents, or skylights can lead to leaks. Homex provides:</p>
                <ul>
                    <li>
                        <strong>Flashing Inspection: </strong>Checking for cracks, gaps, or wear.
                    </li>
                    <li>
                        <strong>Flashing Repair/Replacement: </strong>Fixing or replacing faulty flashing to prevent water from seeping into the roof.
                    </li>
                </ul>
                <h4>6. Waterproofing</h4>
                <ul>
                    <li>
                        <strong>Skylight Sealing: </strong>Inspecting and sealing around skylights to prevent leaks.
                    </li>
                    <li>
                        <strong>Vent Maintenance: </strong>Checking roof ventilation systems to ensure they are clear and functioning.
                    </li>
                </ul>
                <h4>7. Storm Damage Repair</h4>
                <p className="m-0-pl-8">In case of severe weather, Homex offers emergency services including:</p>
                <ul>
                    <li>
                        <strong>Wind Damage Repair: </strong>Fixing or replacing wind-damaged sections of the roof.
                    </li>
                    <li>
                        <strong>Hail Damage Restoration: </strong>Replacing sections damaged by hail, ensuring structural integrity.
                    </li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp?</h4>
                    <ul>
                        <li>
                            <strong>Experienced and Skilled Team: </strong>
                            Our team consists of highly trained and experienced roofing professionals who are committed to providing top-quality workmanship on every project. We stay up-to-date with the latest industry standards and best practices to ensure your roof is installed or repaired correctly.
                        </li>
                        <li>
                            <strong>High-Quality Materials: </strong>
                            We use only the highest-quality roofing materials from trusted manufacturers, ensuring your roof is durable, weather-resistant, and long-lasting. Whether you prefer asphalt shingles, metal roofing, or more premium materials, we have the right options for you.
                        </li>
                        <li>
                            <strong>Comprehensive Service Offering: </strong>
                            From new installations to repairs, maintenance, and emergency services, we offer a full range of residential roofing services. This means you can rely on us for all your roofing needs, whether you need a small repair or a complete roof replacement.
                        </li>
                        <li>
                            <strong>Customer-Centric Approach: </strong>
                            At Homex Builders Corp, customer satisfaction is our top priority. We work closely with you throughout the entire process, providing clear communication, detailed estimates, and personalized service to ensure your needs are met and expectations exceeded.
                        </li>
                        <li>
                            <strong>Competitive Pricing: </strong>
                            We offer competitive pricing on all our roofing services without compromising on quality. Our transparent pricing ensures you know exactly what you’re paying for, with no hidden fees or surprises.
                        </li>
                        <li>
                            <strong>Licensed and Insured: </strong>
                            We are a fully licensed and insured roofing contractor, providing peace of mind that your home is in good hands. Our adherence to safety standards and regulations further ensures a safe and efficient work environment.
                        </li>
                        <li>
                            <strong>Warranty and Guarantee: </strong>
                            We stand behind the quality of our work and offer warranties on our roofing services. This means you can have confidence that your roof will stand the test of time, and if any issues arise, we’ll be there to address them.
                        </li>
                        <li>
                            <strong>Local Expertise: </strong>
                            As a local company, we understand the specific roofing needs of homes in our area. We are familiar with local building codes, weather conditions, and common roofing issues, allowing us to provide tailored solutions that are built to last.
                        </li>
                        <li>
                            <strong>Environmentally Conscious: </strong>
                            We offer sustainable roofing options, such as solar roofing and energy-efficient materials, to help you reduce your environmental footprint and lower your energy costs.
                        </li>
                        <li>
                            <strong>Emergency Services Available: </strong>
                            Roofing emergencies can’t wait. That’s why we offer 24/7 emergency roofing services to address urgent needs and minimize potential damage to your home.
                        </li>
                    </ul>
                </blockquote>

                <p>Choosing Homex Builders Corp means choosing quality, reliability, and peace of mind. If you need residential roofing services, don't hesitate to contact us today for a free consultation and estimate. Let us help you protect your home with a roof that's built to last.</p>
                <p>When it comes to commercial roofing, ensuring the job is done right requires a combination of specialized skills, intelligent planning, and strict safety precautions. Here’s how these three elements play a critical role in delivering top-notch commercial roofing services:</p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Use of Safety Equipment: </strong>All personnel must wear appropriate safety gear, including hard hats, safety harnesses, non-slip footwear, and eye protection. This is especially important when working on steep or high roofs.
                                        </li>
                                        <li>
                                            <strong>Fall Protection Systems: </strong>Installing guardrails, safety nets, and harnesses can prevent falls from the roof, which are a leading cause of injury in roofing work.
                                        </li>
                                        <li>
                                            <strong>Weather Monitoring: </strong>Roofing work should not be performed during inclement weather, such as rain, snow, or high winds, to prevent slips, falls, and other accidents.
                                        </li>
                                        <li>
                                            <strong>Proper Ladder Use: </strong>Secure and stable ladders must be used when accessing the roof. Ladders should be inspected regularly for damage and placed on a flat, stable surface.
                                        </li>
                                        <li>
                                            <strong>Clear Work Area: </strong>The work area should be kept clean and free of debris to prevent tripping hazards. All tools and materials should be secured to prevent them from falling off the roof.
                                        </li>
                                        <li>
                                            <strong>Safe Storage of Materials: </strong>Roofing materials such as shingles, tiles, and tools should be stored properly to avoid damage and minimize safety hazards.
                                        </li>
                                        <li>
                                            <strong>Proper Lifting Techniques: </strong>To prevent injuries, workers should use proper lifting techniques when handling heavy materials, or utilize lifting equipment as needed.
                                        </li>
                                        <li>
                                            <strong>Handling Hazardous Materials: </strong>If roofing materials contain hazardous substances (like asbestos in older roofs), appropriate safety procedures, including containment and disposal protocols, should be followed.
                                        </li>
                                        <li>
                                            <strong>Roof Load Management: </strong>Understanding the weight capacity of the existing roof structure is critical. Overloading the roof with heavy materials or equipment can lead to structural failure.
                                        </li>
                                        <li>
                                            <strong>Pre-Inspection of Roof Structure: </strong>Before any roofing work begins, a thorough inspection of the underlying roof structure should be conducted to identify any weak spots, rot, or damage that could affect the safety and integrity of the roof.
                                        </li>
                                        <li>
                                            <strong>Avoid Power Lines: </strong>Special care should be taken to avoid overhead power lines and ensure that no tools or materials come into contact with live electrical wires.
                                        </li>
                                        <li>
                                            <strong>Turn Off Electricity When Necessary: </strong>If roofing work involves electrical components, such as ventilation systems or skylights, the electricity should be turned off to prevent electric shock.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Roof Assessment and Evaluation: </strong>
                                            Conducting a comprehensive assessment of the roof's current condition helps determine the best course of action, whether it’s repair, replacement, or maintenance. This includes evaluating the roof's age, material, slope, and ventilation.
                                        </li>
                                        <li>
                                            <strong>Custom Roof Design: </strong>
                                            Intelligent roofing involves designing a roof that complements the home's architecture and meets the specific needs of the homeowner, considering factors like climate, energy efficiency, and aesthetics.
                                        </li>
                                        <li>
                                            <strong>Energy Efficiency Considerations: </strong>
                                            Using energy-efficient materials, such as reflective shingles or cool roof coatings, can help reduce cooling costs and improve home comfort.
                                        </li>
                                        <li>
                                            <strong>Durable and Sustainable Materials: </strong>
                                            Choosing materials that are both durable and environmentally friendly can enhance the lifespan of the roof and reduce its environmental impact. This includes options like metal roofing, recycled shingles, or clay tiles.
                                        </li>
                                        <li>
                                            <strong>Weather-Resistant Materials: </strong>
                                            Depending on the local climate, selecting materials that can withstand specific weather conditions, such as high winds, heavy rain, or hail, is critical for long-term durability.
                                        </li>
                                        <li>
                                            <strong>Use of Roofing Software: </strong>
                                            Advanced software can be used for precise measurement and estimation, 3D modeling of roofing projects, and project management to improve accuracy and efficiency.
                                        </li>
                                        <li>
                                            <strong>Thermal Imaging for Leak Detection: </strong>
                                            Thermal imaging technology can detect areas of heat loss or moisture accumulation, allowing for targeted repairs and improving overall roof performance.
                                        </li>
                                        <li>
                                            <strong>Optimizing Ventilation: </strong>
                                            Proper ventilation helps regulate attic temperatures and prevents moisture buildup, which can lead to mold growth and roof deterioration. Intelligent design involves the strategic placement of vents and insulation to maintain a balanced attic environment.
                                        </li>
                                        <li>
                                            <strong>Insulation Upgrades: </strong>
                                            Using advanced insulation materials and techniques can enhance energy efficiency and protect the roof from temperature extremes.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Asphalt Shingle Installation and Repair: </strong>
                                            Specializing in asphalt shingles, which are the most common residential roofing material due to their affordability, durability, and variety of styles and colors.
                                        </li>
                                        <li>
                                            <strong>Architectural Shingles: </strong>
                                            Expertise in installing architectural shingles, which provide a more dimensional look and enhanced durability compared to traditional three-tab shingles.
                                        </li>
                                        <li>
                                            <strong>Metal Roof Installation:  </strong>
                                            Skilled in installing and repairing metal roofing systems, including standing seam, corrugated, and metal tiles. Metal roofs are known for their longevity, energy efficiency, and low maintenance.
                                        </li>
                                        <li>
                                            <strong>Coatings and Finishes: </strong>
                                            Specialization in applying protective coatings to metal roofs to prevent rust, corrosion, and enhance energy efficiency.
                                        </li>
                                        <li>
                                            <strong>EPDM, TPO, and PVC Roofing: </strong>
                                            Expertise in installing and repairing flat and low-slope roofing systems using materials like EPDM (ethylene propylene diene terpolymer), TPO (thermoplastic olefin), and PVC (polyvinyl chloride). These materials are known for their waterproofing capabilities and are ideal for modern flat roof designs.
                                        </li>
                                        <li>
                                            <strong>Modified Bitumen and Built-Up Roofing (BUR): </strong>
                                            Skilled in traditional flat roofing techniques such as modified bitumen and BUR, which provide multi-layered protection against the elements.
                                        </li>
                                        <li>
                                            <strong>Clay and Concrete Tile Installation: </strong>
                                            Specializing in the installation and repair of clay and concrete tile roofs, which are known for their durability, fire resistance, and ability to withstand harsh weather conditions.
                                        </li>
                                        <li>
                                            <strong>Slate Roofing: </strong>
                                            Expertise in natural slate roofing, which offers a high-end, classic look and is one of the most durable roofing options available, often lasting over a century.
                                        </li>
                                        <li>
                                            <strong>Green Roof Installation: </strong>
                                            Skilled in installing green roofs, which involve planting vegetation on a waterproof membrane. Green roofs offer benefits such as improved insulation, stormwater management, and enhanced aesthetics.
                                        </li>
                                        <li>
                                            <strong>Solar Roofing: </strong>
                                            Expertise in integrating solar panels with roofing systems, providing homeowners with sustainable energy solutions that can reduce electricity costs and improve the home's overall energy efficiency.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text">
                <p>
                    Regular maintenance can help you avoid costly repairs in the future, and Homex Builder Corp’s comprehensive roof maintenance services are designed to ensure your roof remains in top condition year-round. Reach out to Homex Builder Corp for an inspection or consultation today!
                </p>
            </div>  
        </>
    );
}
