import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import RoofInspection1 from "../assets/images/roofingservices/roof_inspection1.jpg"
import RoofInspection2 from "../assets/images/roofingservices/roof_inspection2.jpg"
import RoofInspection3 from "../assets/images/roofingservices/roof_inspection3.jpg"

export const RoofInspection = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={RoofInspection1} alt="Roof Inspection" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={RoofInspection2} alt="Roof Inspection" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={RoofInspection3} alt="Roof Inspection" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Roof Inspection</h3>
            <div className="text">
                <p>
                    Your roof is your home’s shield against the elements, but even the best-built roofs require regular inspections to maintain their strength and durability. With Homex Builders Corp, you get more than just an inspection – you get expert analysis backed by intelligence, specialization, and a commitment to safety.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Roof Inspection?</h3>
                <ul>
                    <li>
                        <strong>Comprehensive Evaluation: </strong>
                        Every element of your roof, from flashing and gutters to the structural core, is thoroughly inspected using both traditional methods and advanced technologies.
                    </li>
                    <li>
                        <strong>Detailed Report: </strong>
                        Receive a full report outlining the current condition of your roof, along with recommendations for maintenance or repairs.
                    </li>
                    <li>
                        <strong>Customized Solutions: </strong>
                        Whether it’s a small repair or preventive care, our expert team will provide the right solution tailored to your roof’s needs.
                    </li>
                </ul>
                <p>
                    Don’t wait until it’s too late. Ensure your roof is in top condition by scheduling a professional roof inspection with Homex Builders Corp today!
                </p>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp?</h4>
                    <ul>
                        <li>
                            <strong>Specialized Expertise: </strong>
                            Our team of roofing experts is specialized in all types of roofs, from residential shingles to complex commercial systems. We have the experience and technical knowledge to spot potential problems that others may overlook.
                        </li>
                        <li>
                            <strong>Intelligent Inspections: </strong>
                            We utilize the latest tools and technologies—like drone surveys, thermal imaging, and moisture detection systems—to ensure every part of your roof is inspected with precision. These intelligent solutions help us detect issues before they escalate.
                        </li>
                        <li>
                            <strong>Safety Precautions: </strong>
                            We prioritize safety, adhering to strict protocols for every inspection. This protects not only our team but also your home and property. We ensure every inspection is carried out with minimal disruption and zero risk.
                        </li>
                    </ul>
                </blockquote>

                <p>Choosing Homex Builders Corp means choosing quality, reliability, and peace of mind. If you need residential roofing services, don't hesitate to contact us today for a free consultation and estimate. Let us help you protect your home with a roof that's built to last.</p>
                <p>When it comes to commercial roofing, ensuring the job is done right requires a combination of specialized skills, intelligent planning, and strict safety precautions. Here’s how these three elements play a critical role in delivering top-notch commercial roofing services:</p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Proactive Problem Detection: </strong>We identify potential issues like leaks, damaged shingles, and structural weaknesses early on, preventing small problems from becoming major expenses.
                                        </li>
                                        <li>
                                            <strong>Weather Damage Prevention: </strong>Specialized inspections assess your roof's readiness for harsh weather, ensuring it can withstand storms, heavy rains, or snow. We focus on storm-proofing and durability.
                                        </li>
                                        <li>
                                            <strong>Extended Roof Lifespan: </strong>Regular inspections help maintain your roof’s structural integrity, extending its life and protecting your investment.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
