import React, { Component, useEffect, useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import { AppointmentForm } from './AppointmentForm';

import logFooter from "../assets/images/logo-footer.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import footer_bg from "../assets/images/footer_bg.jpg"

export const Footer = (props) => {
    const { pathName } = props;

    return (
        <>
            <section className="elementor-section elementor-top-section elementor-element elementor-element-6a77e5d elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="6a77e5d" data-element_type="section">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f3e31a3" data-id="f3e31a3" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-ea48e0e elementor-widget elementor-widget-konstructo_contact_us" data-id="ea48e0e" data-element_type="widget" data-widget_type="konstructo_contact_us.default">
                                <div className="elementor-widget-container">
                                    <section className={`contact-info-section ${pathName == "/contact-us" && pathName != "" ? "d-none" : ""}`}>
                                        <div className="map-section">
                                            <div className="map-outer">
                                                <div className="map-canvas">
                                                    {/*<iframe src="./Home_files/embed.html"></iframe>*/}
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3017.389334892671!2d-73.86242039999999!3d40.8633293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f35c53232d89%3A0xee0f6358d16c3a90!2s827%20Mace%20Ave%2C%20Bronx%2C%20NY%2010467%2C%20USA!5e0!3m2!1sen!2s!4v1724436725304!5m2!1sen!2s" width="600" height="450" style={{ border: "0", width: "100%" }} allowFullScreen="" loading="lazy" referrerPolicy="no - referrer - when - downgrade"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="auto-container">
                                            <div className="inner-container">
                                                <div className="row clearfix">
                                                    <div className="info-column col-md-4 col-sm-12 col-xs-12">
                                                        <div className="inner-column">
                                                            <ul className="list-style-two">
                                                                <li><span className="icon flaticon-home-1"></span><strong>827 Mace Ave Bronx,</strong>NY 10467, United States</li>
                                                                <li>
                                                                    <span className="icon flaticon-envelope-1"></span>
                                                                    <strong>Send your mail at</strong>Customer@homexbuilders.com, Homexbuildercorp@gmail.com
                                                                </li>
                                                                <li><span className="icon flaticon-technology-2"></span><strong>Have Any Question</strong>(929) 385-3287</li>
                                                                <li><span className="icon flaticon-clock-1"></span><strong>Working Hours</strong>Mon-Sat:8:00am to 5:00pm</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="form-column col-md-8 col-sm-12 col-xs-12">
                                                        <div className="inner-column">
                                                            <h2>Book an Appointment</h2>
                                                            <div className="contact-form">
                                                                <div className="wpcf7 js" id="wpcf7-f153-p848-o1" lang="en-US" dir="ltr">
                                                                    <div className="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true"></p> <ul></ul></div>
                                                                    <AppointmentForm />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="main-footer" style={{ backgroundImage: `url(${footer_bg})` }}>
                <div className="auto-container">
                    <div className="widgets-section">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget widget_konstructo_about_company">
                                    <div className="logo-widget">
                                        <div className="logo">
                                            <a href="/">
                                                <img src={logFooter} alt="Homex Builders Corp" />
                                            </a>
                                        </div>
                                        <div className="text">
                                            Homex Builders Corp is a leading construction company specializing in residential and commercial projects. Known for its high-quality craftsmanship and attention to detail, Homex Builders Corp has built a reputation for delivering exceptional results on time and within budget. The company offers a wide range of services, including architectural design, project management, and construction consulting.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                                <div id="nav_menu-1" className="footer-widget widget_nav_menu">
                                    <div className="footer-title"><h2>Services</h2></div>
                                    <div className="menu-services-menu-container">
                                        <ul id="menu-services-menu" className="menu">
                                            <li id="menu-item-663" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-663">
                                                <NavLink tag={Link} to="/roofing-services">
                                                    <FontAwesomeIcon icon={faCaretRight} />Roofing
                                                </NavLink>
                                            </li>
                                            <li id="menu-item-661" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-661">
                                                <NavLink tag={Link} to="/waterproofing">
                                                    <FontAwesomeIcon icon={faCaretRight} />Waterproofing
                                                </NavLink>
                                            </li>
                                            <li id="menu-item-662" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-662">
                                                <NavLink tag={Link} to="/remodeling">
                                                    <FontAwesomeIcon icon={faCaretRight} />Remodeling
                                                </NavLink>
                                            </li>
                                            <li id="menu-item-664" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-664">
                                                <NavLink tag={Link} to="/sidewalk">
                                                    <FontAwesomeIcon icon={faCaretRight} />Sidewalk
                                                </NavLink>
                                            </li>
                                            <li id="menu-item-665" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-665">
                                                <NavLink tag={Link} to="/restoration">
                                                    <FontAwesomeIcon icon={faCaretRight} />Restoration
                                                </NavLink>
                                            </li>
                                            <li id="menu-item-666" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-666">
                                                <NavLink tag={Link} to="/masonry">
                                                    <FontAwesomeIcon icon={faCaretRight} />Masonry
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                                <div id="nav_menu-2" className="footer-widget widget_nav_menu">
                                    <div className="footer-title"><h2>Extra Links</h2></div>
                                    <div className="menu-footer-extra-links-container">
                                        <ul id="menu-footer-extra-links" className="menu">
                                            <li id="menu-item-656" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-656">
                                                <NavLink tag={Link} to="/">
                                                    <FontAwesomeIcon icon={faCaretRight} />Home
                                                </NavLink>
                                            </li>
                                            <li id="menu-item-658" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-658">
                                                <NavLink tag={Link} to="/about-us">
                                                    <FontAwesomeIcon icon={faCaretRight} />About Us
                                                </NavLink>
                                            </li>
                                            <li id="menu-item-659" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-659">
                                                <NavLink tag={Link} to="/projects">
                                                    <FontAwesomeIcon icon={faCaretRight} />Projects
                                                </NavLink>
                                            </li>
                                            <li id="menu-item-655" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-655">
                                                <NavLink tag={Link} to="/contact-us">
                                                    <FontAwesomeIcon icon={faCaretRight} />Contact Us
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                                <div id="nav_menu-1" className="footer-widget widget_nav_menu">
                                    <div className="footer-title"><h2>Follow Us</h2></div>
                                    <div className="menu-services-menu-container">
                                        <ul className="social-icon-one">
                                            <li>
                                                <a target="_blank" href="https://www.facebook.com/share/J8qGQr2s1oTxBvP3/?mibextid=LQQJ4d" target="_blank" style={{ backgroundColor: "rgba(0, 0, 0, 0)", color: "rgb(255, 255, 255)" }}>
                                                    <FontAwesomeIcon icon={faFacebook} />
                                                </a>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <a target="_blank" href="https://www.linkedin.com/" style={{ backgroundColor: "rgba(0, 0, 0, 0)", color: "rgb(255, 255, 255)" }}>*/}
                                            {/*        <FontAwesomeIcon icon={faLinkedin} />*/}
                                            {/*    </a>*/}
                                            {/*</li>*/}
                                            <li>
                                                <a target="_blank" href="https://www.instagram.com/homexbuilderscorpnyc/" target="_blank" style={{ backgroundColor: "rgba(0, 0, 0, 0)", color: "rgb(255, 255, 255)" }}>
                                                    <FontAwesomeIcon icon={faInstagram} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="column col-md-6 col-sm-12 col-xs-12">
                                <div className="copyright">Copyright © 2024 <a href="/">Homex Builders Corp,</a> All Right Reserved</div>
                            </div>
                            <div className="column col-md-6 col-sm-12 col-xs-12">
                                <ul className="footer-nav">
                                    <li><a href="/contact-us">Contactus</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
