import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import patios1 from "../assets/images/masonry/patios1.jpg"
import patios2 from "../assets/images/masonry/patios2.jpg"
import patios3 from "../assets/images/masonry/patios3.jpg"

export const Patios = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={patios1} alt="Patios" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={patios2} alt="Patios" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={patios3} alt="Patios" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Patios</h3>
            <div className="text">
                <p>
                    At Homex Builders Corp., we are dedicated to transforming your outdoor spaces into beautiful, functional, and long-lasting areas where you can relax, entertain, and enjoy time with family and friends. Our patio services are designed to meet the highest standards of quality and craftsmanship. Here’s what we offer:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Patios Services?</h3>
                <ul>
                    <li><strong>Custom Patio Design: </strong>We collaborate with you to create a patio design that perfectly complements your home’s architecture and meets your lifestyle needs. Whether you prefer a modern, minimalist look or a more traditional design, our team will bring your vision to life.</li>

                    <li><strong>Material Selection and Consultation: </strong>Choosing the right materials is crucial for a durable and aesthetically pleasing patio. We offer a wide selection of high-quality materials, including natural stone, pavers, concrete, and brick, ensuring that your patio is both beautiful and built to last.</li>

                    <li><strong>Professional Installation: </strong>Our skilled builders ensure that every patio is installed with precision and care. From the foundation to the finishing touches, we handle every aspect of the installation process, ensuring your patio is not only visually appealing but also structurally sound.</li>

                    <li><strong>Patio Remodeling and Expansion: </strong>If you already have a patio that needs updating or expanding, we offer comprehensive remodeling services. We can upgrade your existing patio to enhance its functionality, aesthetics, and value.</li>

                    <li><strong>Maintenance and Repair: </strong>To keep your patio looking its best over the years, we provide maintenance and repair services. From cleaning and sealing to fixing cracks and other wear and tear, we ensure your patio remains in top condition.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Patios?</h4>
                    <ul>
                        <li><strong>Expertise and Experience: </strong>With years of experience in the construction industry, our team has the expertise to handle projects of any size and complexity. We understand the nuances of patio construction and are committed to delivering results that exceed your expectations.</li>

                        <li><strong>Quality Craftsmanship: </strong>We take pride in our work, and it shows in the quality of our craftsmanship. Each project is treated with the utmost care, ensuring that every detail is perfect. Your satisfaction is our top priority.</li>

                        <li><strong>Tailored Solutions: </strong>At Homex Builders Corp., we understand that every home is unique. That’s why we offer customized solutions tailored to your specific needs and preferences. We work closely with you to ensure the final product aligns with your vision.</li>

                        <li><strong>Customer-Centric Approach: </strong>Our customer-centric approach means we put your needs first. From the initial consultation to the final walkthrough, we are with you every step of the way, ensuring clear communication and a smooth process.</li>

                        <li><strong>Competitive Pricing: </strong>We believe in providing high-quality services at competitive prices. Our goal is to deliver exceptional value, ensuring you get the best possible results within your budget.</li>

                        <li><strong>Reliable and Timely Service: </strong>We respect your time and ensure that every project is completed on schedule. Our team is reliable, professional, and dedicated to delivering outstanding results promptly.</li>

                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    At Homex Builders Corp, safety and durability are at the forefront of our patio services. We begin each project with a thorough site assessment, identifying any potential risks such as drainage issues, soil stability, and local weather conditions. We take into account the surrounding environment to ensure that the patio is designed to withstand various elements, from heavy rainfall to extreme temperatures. The materials we select are chosen for their longevity and resilience, ensuring that the patio remains safe and sturdy over time. Additionally, we adhere to all local building codes and regulations, ensuring that every patio we build meets the highest safety standards.
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Our patio services are driven by intelligent design and the latest technology. We use advanced design software to create precise plans that optimize the use of space and resources. This allows us to visualize the project from multiple angles, ensuring that the final result is both beautiful and practical. Our designs consider factors like sun exposure, wind direction, and the intended use of the space, whether it’s for entertaining, relaxation, or outdoor dining. We also incorporate smart features, such as integrated lighting, automated irrigation systems, and sustainable materials, to enhance the functionality and environmental friendliness of our patios.
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    Homex Builders Corp specializes in creating customized patios that reflect the unique preferences and lifestyles of our clients. We understand that each outdoor space is different, and we tailor our designs to complement the architecture of the home and the natural landscape. Whether it’s a sleek, modern patio or a rustic, natural stone design, our team has the expertise to deliver a wide range of styles. Our specialization extends to the careful selection of materials, the incorporation of unique design elements, and the ability to handle complex installations, such as multi-level patios or those with integrated water features.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    By choosing Homex Builders Corp. for your patio services, you're opting for a team that values quality, precision, and customer satisfaction. Let us help you create the outdoor space of your dreams. Contact us today to get started on your project!
                </p>
            </div>
        </>
    );
}
