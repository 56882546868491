import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import kitchen1 from "../assets/images/remodeling/kitchen1.jpg"
import kitchen2 from "../assets/images/remodeling/kitchen2.jpg"
import kitchen3 from "../assets/images/remodeling/kitchen3.jpg"

export const KitchenRemodeling = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={kitchen1} alt="Kitchen Remodeling" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={kitchen2} alt="Kitchen Remodeling" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={kitchen3} alt="Kitchen Remodeling" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Kitchen Remodeling</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers exceptional Kitchen Remodeling Services designed to transform your kitchen into a functional and aesthetically pleasing space. Here’s an overview of their services and why clients choose them:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Kitchen Remodeling Services?</h3>
                <ul>
                    <li><strong>Custom Kitchen Design: </strong>Homex Builders Corp works closely with clients to create a custom kitchen design that reflects their style and meets their needs. This includes layout planning, selecting cabinetry, countertops, flooring, and appliances, ensuring that every element is perfectly coordinated.</li>
                    <li><strong>Full Renovations: </strong>The company handles comprehensive kitchen renovations, including demolition, plumbing and electrical updates, installation of new fixtures and appliances, and applying final finishes like painting and tiling.</li>
                    <li><strong>Cabinetry and Storage Solutions: </strong>Homex Builders Corp specializes in creating custom cabinetry and innovative storage solutions to maximize space and functionality. This includes the design and installation of cabinets, pantries, and kitchen islands tailored to your specific needs.</li>
                    <li><strong>Countertop Installation: </strong>They offer a wide range of countertop materials, including granite, quartz, marble, and solid surface options. Homex Builders Corp ensures precise installation for a durable, high-end finish that enhances the beauty of your kitchen.</li>
                    <li><strong>Flooring and Backsplashes: </strong>The company provides expert installation of kitchen flooring and backsplashes, using materials such as tile, hardwood, vinyl, and stone. They ensure that these elements complement the overall design and add to the kitchen’s functionality.</li>
                    <li><strong>Lighting and Electrical Work: </strong>Proper lighting is key to a functional kitchen. Homex Builders Corp designs and installs lighting solutions that enhance both the ambiance and practicality of the space, including task lighting, under-cabinet lighting, and decorative fixtures.</li>
                    <li><strong>Appliance Integration: </strong>The company ensures seamless integration of new appliances, including ranges, ovens, refrigerators, and dishwashers, into the kitchen design. This includes custom cabinetry and countertops to fit specific appliance dimensions.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Kitchen Remodeling?</h4>
                    <ul>
                        <li><strong>Experienced Professionals: </strong>Homex Builders Corp has a team of experienced designers and craftsmen who specialize in kitchen remodeling. Their expertise ensures that every aspect of the project is executed with precision and attention to detail.</li>
                        <li><strong>High-Quality Materials: </strong>The company uses premium materials for all elements of the kitchen, from cabinetry to countertops to flooring. This commitment to quality ensures that your kitchen will not only look beautiful but also stand the test of time.</li>
                        <li><strong>Personalized Service:</strong> Homex Builders Corp offers personalized service, working closely with clients to understand their vision and preferences. They provide expert guidance on materials, design choices, and layout options to create a kitchen that perfectly suits your lifestyle.</li>
                        <li><strong>Comprehensive Project Management: </strong>From initial consultation to final inspection, Homex Builders Corp manages every aspect of the kitchen remodeling process. This includes coordinating with subcontractors, scheduling work, and ensuring that the project stays on track and within budget.</li>
                        <li><strong>Timely Completion: </strong>The company is committed to completing projects on time without compromising on quality. They understand the importance of minimizing disruption to your daily routine and work efficiently to meet deadlines.</li>
                        <li><strong>Transparent Pricing: </strong>Homex Builders Corp provides clear, detailed estimates with no hidden costs. They work within your budget, offering solutions that deliver the best value for your investment.</li>
                        <li><strong>Customer Satisfaction: </strong>Client satisfaction is a top priority. Homex Builders Corp is known for delivering exceptional results that exceed expectations, which is reflected in their positive reviews and repeat business.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Licensing and Insurance: </strong>Ensure that the company is properly licensed and insured to perform remodeling work in your area. This protects you from liability and ensures that the company meets local regulations.</li>
                                        <li><strong>References and Reviews: </strong>Check customer reviews and ask for references to gauge the quality of their previous work.</li>
                                        <li><strong>Detailed Contract:</strong> Get a detailed written contract that outlines the scope of work, materials to be used, timelines, and payment terms.</li>
                                        <li><strong>Permits: </strong>Verify that the company will handle any necessary permits required for the remodeling work.</li>
                                        <li><strong>Budget and Hidden Costs: </strong>Have a clear budget and be aware of potential hidden costs that might arise during the remodeling process.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Design Expertise: </strong>The company employs skilled designers who understand both classic and modern kitchen aesthetics. They are equipped with knowledge of layout efficiency, such as ergonomics and the work triangle (relationship between the stove, refrigerator, and sink), which optimizes the kitchen’s functionality.</li>
                                        <li><strong>3D Design Tools and Software: </strong>Homex Builders uses 3D modeling software to offer clients a realistic preview of their remodeled kitchen. This allows homeowners to make informed decisions about layout, materials, and finishes before the physical work begins.</li>
                                        <li><strong>Sustainability Practices: </strong>Homex Builders integrates eco-friendly practices, recommending sustainable materials (such as bamboo flooring, recycled countertops) and energy-efficient appliances to minimize the kitchen’s environmental impact.</li>
                                        <li><strong>Technical Proficiency: </strong>A successful remodel requires deep technical know-how in plumbing, electrical work, and structural elements. Homex Builders ensures that its team includes certified professionals in each area, ensuring that any new installations (e.g., lighting, appliances, water lines) are done correctly and safely.</li>
                                        <li><strong>Knowledge of Regulations: </strong>The company stays up to date on local building codes and ensures that all electrical, plumbing, and structural modifications comply with these regulations, preventing any legal issues down the road.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Custom Kitchen Solutions: </strong>Homex Builders excels at delivering customized kitchen designs based on the client’s specific needs. Whether it’s a small galley kitchen or a large open-concept kitchen, they create personalized solutions that balance aesthetics and practicality.</li>
                                        <li><strong>Cabinetry and Countertops: </strong>The company specializes in custom cabinetry design, allowing clients to choose from a wide variety of finishes, styles, and configurations that fit their space and storage needs. They also provide high-quality countertop materials, including quartz, granite, marble, and butcher block.</li>
                                        <li><strong>Lighting and Smart Kitchen Integration:</strong> Homex Builders offers expertise in lighting design, from task lighting (under-cabinet lights) to ambient lighting (recessed ceiling lights) to create a functional and inviting space. They also integrate smart home technologies, such as smart appliances and voice-activated systems for a future-ready kitchen.</li>
                                        <li><strong>Space Maximization: </strong>Homex specializes in transforming small and awkwardly-shaped kitchens into more spacious and efficient areas by optimizing storage solutions (pull-out pantries, hidden cabinets), and reconfiguring layouts to maximize usable space.</li>
                                        <li><strong>Trends and Innovations:</strong> They stay up to date with the latest kitchen design trends and incorporate innovative materials and techniques like floating islands, hidden appliances, and modular storage solutions.</li>
                                        <li><strong>High-End Materials and Finishes:</strong> Homex Builders prioritizes the use of premium materials, ensuring that all surfaces, from the flooring to the backsplash, are durable, easy to maintain, and stylish. They also provide a range of choices in high-end finishes, such as soft-close drawers, touchless faucets, and designer tiles.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Homex Builders Corp is a company that places emphasis on quality, safety, and intelligence in its kitchen remodeling services. They focus on delivering custom and modern kitchens that enhance functionality while maintaining aesthetic appeal. With their attention to detail, expertise in technology, and commitment to client satisfaction, they offer comprehensive solutions that make the remodeling process smoother, safer, and aligned with the latest industry standards.
                </p>
            </div>
        </>
    );
}
