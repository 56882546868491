import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import brickpointing1 from "../assets/images/restoration/brickpointing1.jpg"
import brickpointing2 from "../assets/images/restoration/brickpointing2.jpg"
import brickpointing3 from "../assets/images/restoration/brickpointing3.jpg"

export const BrickPointing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={brickpointing1} alt="Brick Pointing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={brickpointing2} alt="Brick Pointing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={brickpointing3} alt="Brick Pointing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Brick Pointing</h3>
            <div className="text">
                <p>
                    Homex Builders Corp follows a structured and professional process for performing brick pointing services to ensure the highest quality of work. Here’s a step-by-step breakdown of how they typically do it:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Brick Pointing Services?</h3>
                <ul>
                    <li><strong>Initial Evaluation:</strong> The team inspects the brick structure to assess the condition of the mortar joints and identify areas of damage or wear.</li>
                    <li><strong>Moisture & Structural Check:</strong> They check for moisture infiltration, cracks, and any signs of structural weakening in the brickwork.</li>
                    <li><strong>Surface Cleaning:</strong> The brick surface is cleaned using water or specialized cleaning agents to remove dirt, debris, and old mortar. This ensures a clean surface for new mortar application.</li>
                    <li><strong>Old Mortar Removal: </strong>The deteriorated or loose mortar is carefully removed from between the brick joints using tools like grinders or chisels. This step is crucial to ensure that the new mortar will bond well.</li>
                    <li><strong>Color and Composition Matching:</strong> Homex Builders ensures that the new mortar matches the existing one in both color and composition. This maintains the historical and aesthetic integrity of the building, especially in restoration projects.</li>
                    <li><strong>Filling the Joints:</strong> New mortar is applied to the joints using trowels, filling them to the correct depth and ensuring a tight, weather-resistant bond.</li>
                    <li><strong>Tuckpointing (Optional): </strong>If specified, Homex can also perform tuckpointing, using two colors of mortar to create the appearance of thin, even joints for a more refined look.</li>
                    <li><strong>Shaping the Mortar: </strong>The new mortar is shaped and finished to blend seamlessly with the existing brickwork. This includes smoothing the joints to create a neat, cohesive look.</li>
                    <li><strong>Cleanup:</strong> Excess mortar is removed, and the brick surface is cleaned again to ensure a polished finish.</li>
                    <li><strong>Curing Time: </strong>The mortar is allowed to cure, typically over several days, ensuring it hardens properly and forms a strong bond with the bricks.</li>
                    <li><strong>Final Inspection: </strong>Once the mortar is cured, Homex Builders performs a final inspection to ensure the pointing is uniform, secure, and aesthetically aligned with the rest of the structure.</li>
                    <li><strong>Protective Coating: </strong>A waterproof sealant may be applied to the brick and mortar to protect against future moisture damage and extend the lifespan of the pointing.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Brick Pointing?</h4>
                    <p>
                        Choosing Homex Builders Corp for brick pointing is a solid decision because of their expertise, attention to detail, and commitment to quality. Here's why they stand out:
                    </p>
                    <ul>
                        <li><strong>Specialized Experience: </strong>Homex Builders Corp has extensive experience in masonry work, including brick pointing. They understand the nuances of this process, which involves removing old mortar and replacing it with fresh, durable material to maintain the structure's integrity and appearance.</li>
                        <li><strong>Quality Materials:</strong> They prioritize using high-quality mortar that matches the existing brickwork, ensuring a seamless finish that enhances both durability and aesthetics.</li>
                        <li><strong>Licensed and Insured:</strong> With Homex Builders Corp, you’re working with a fully licensed and insured company, giving you peace of mind throughout the project.</li>
                        <li><strong>Customer-Focused Approach: </strong>Homex Builders Corp is known for its excellent customer service, offering personalized solutions tailored to the specific needs of each building.</li>
                        <li><strong>Proven Track Record: </strong>Their portfolio and client testimonials showcase successful projects, ensuring that your brick pointing will be completed efficiently and to the highest standard.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Safety First:</strong> Homex Builders prioritizes worker and site safety. They strictly follow OSHA guidelines, including proper scaffolding and personal protective equipment (PPE), like helmets, harnesses, and gloves, to prevent accidents.</li>
                                        <li><strong>Material Handling: </strong>The company uses quality control to ensure only the best mortar and bricks are used. They test materials for weather resistance, ensuring they’ll stand up to harsh elements like rain, snow, and extreme temperatures.</li>
                                        <li><strong>Environmental Precautions: </strong>Dust containment measures, such as tarps and wet-cutting tools, reduce airborne particles during the grinding process. This helps protect both workers and the surrounding environment.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Advanced Inspection Tools: </strong>Homex Builders utilizes cutting-edge technology like moisture meters and thermal cameras to assess brick damage and detect underlying structural issues. This allows for precise planning of brick pointing repairs.</li>
                                        <li><strong>Accurate Mortar Matching: </strong>They understand the importance of selecting the correct mortar for each project. Homex ensures that the color, texture, and composition of the mortar are perfectly matched to maintain the integrity and aesthetic of the original structure.</li>
                                        <li><strong>Tailored Solutions: </strong>Homex Builders doesn’t take a one-size-fits-all approach. They carefully assess the age of the building, climate factors, and structural stability to recommend custom pointing solutions that will extend the life of the brickwork.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Historic Building Restoration: </strong>Homex has a strong reputation in preserving the integrity of historic buildings. Their team has deep knowledge of traditional pointing techniques, ensuring that historic structures retain their original appearance while receiving modern reinforcements.</li>
                                        <li><strong>Weatherproofing Techniques: </strong>Specialized in enhancing the durability of brick structures, they offer weatherproofing services that prevent moisture ingress and cracking, key causes of structural damage over time.</li>
                                        <li><strong>Skilled Craftsmanship: </strong>Their masons are highly skilled in tuckpointing, repointing, and brick restoration. With specialized training, they ensure each project meets the highest industry standards, providing a seamless finish.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Homex Builders Corp ensures that each step is performed with precision, guaranteeing a durable and visually appealing result. They prioritize quality and customer satisfaction throughout the entire brick pointing process.
                </p>
                <p>Would you like to discuss specific projects? call us now.</p>
            </div>
        </>
    );
}
