import React, { useState } from 'react';
import axios from 'axios';
import { variables } from '../Variables';

export const ContactUsForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: ""
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const validate = () => {
        let formErrors = {};
        if (!formData.name.trim()) {
            formErrors.name = "Name is required";
        }
        if (!formData.email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
        }
        if (!formData.subject.trim()) {
            formErrors.subject = "Subject is required";
        }
        if (!formData.phone.trim()) {
            formErrors.phone = "Phone number is required";
        }

        if (!formData.message.trim()) {
            formErrors.message = "Message is required";
        }
        return formErrors;
    };

    const handleChange = e => {
        const { name, value } = e.target;
        if (name == 'phone') {
            setFormData(prevState => ({
                ...formData,
                [name]: normalizeInput(value, prevState.phone)
            }));
        }
        else {
            setFormData({
                ...formData,
                [name]: value
            });
        }

        //setFormData(prevState => ({
        //    phone: normalizeInput(value, prevState.phone)

        //return normalizeInput(value, "")
    };

    const normalizeInput = (value, previousValue) => {
        // return nothing if no value
        if (!value) return value;

        // only allows 0-9 inputs
        const currentValue = value.replace(/[^\d]/g, "");
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {

            // returns: "x", "xx", "xxx"
            if (cvLength < 4) return currentValue;

            // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            try {
                const apiUrl = variables.API_URL + 'Executer/SendContactUsEmail';
                const response = await axios.post(apiUrl, formData);
                if (response.status === 200) {
                    setSuccessMessage('Your message has been sent successfully!');
                    setFormData({ name: "", email: "", subject: "", phone: "", message: "" });
                } else {
                    setErrors({ apiError: 'Something went wrong, please try again later.' });
                }
            } catch (error) {
                setErrors({ apiError: 'Network error, please try again later.' });
            }
            setIsSubmitting(false);
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="wpcf7-form init">
                <div className="row clearfix">
                    <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <p>
                            <span className="wpcf7-form-control-wrap">
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                    placeholder="Name"
                                />
                            </span>
                            {errors.name && <p className="error">{errors.name}</p>}
                        </p>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <p>
                            <span className="wpcf7-form-control-wrap">
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                                    placeholder="Email"
                                />
                            </span>
                            {errors.email && <p className="error">{errors.email}</p>}
                        </p>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <p>
                            <span className="wpcf7-form-control-wrap">
                                <input
                                    type="text"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                    placeholder="Subject"
                                />
                            </span>
                            {errors.subject && <p className="error">{errors.subject}</p>}
                        </p>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <p>
                            <span className="wpcf7-form-control-wrap">
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                    placeholder="Phone"
                                />
                            </span>
                            {errors.phone && <p className="error">{errors.phone}</p>}
                        </p>
                    </div>
                    <div className="form-group col-md-12 col-sm-12 co-xs-12">
                        <p>
                            <span className="wpcf7-form-control-wrap">
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                    placeholder="Your Massage"
                                />
                            </span>
                            {errors.message && <p className="error">{errors.message}</p>}
                        </p>
                    </div>
                    {errors.apiError && <p className="error">{errors.apiError}</p>}
                    {successMessage && <p className="success">{successMessage}</p>}
                    <div className="form-group col-md-12 col-sm-12 co-xs-12">
                        <p>
                            <button type="submit" className="theme-btn btn-style-one" disabled={isSubmitting}>
                                {isSubmitting ? 'Sending...' : 'Submit'}
                            </button>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    );
};