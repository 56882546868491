import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import masonrycleaning1 from "../assets/images/restoration/masonrycleaning1.jpg"
import masonrycleaning2 from "../assets/images/restoration/masonrycleaning2.jpg"
import masonrycleaning3 from "../assets/images/restoration/masonrycleaning3.jpg"

export const LocalLaw11 = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <h3>Local Law 11</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers comprehensive Local Law 11 services that ensure your building complies with New York City's stringent façade inspection requirements. Local Law 11, also known as the Façade Inspection & Safety Program (FISP), mandates periodic inspections and necessary repairs of building exteriors taller than six stories to maintain public safety. Here’s an overview of our services and why you should choose Homex Builders Corp for your Local Law 11 compliance needs:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Local Law 11 Services?</h3>
                <ul>

                    <li><strong>Detailed Façade Inspections: </strong>We provide thorough and precise inspections of building façades, identifying any issues that could pose safety risks. Our inspections are conducted by experienced professionals who are well-versed in the requirements of Local Law 11, ensuring a comprehensive assessment of all exterior components, including walls, windows, balconies, and fire escapes.</li>
                    <li><strong>Condition Assessment and Reporting: </strong>After inspecting your building, we provide a detailed condition assessment report that categorizes any issues found as either Safe, Safe with a Repair and Maintenance Program (SWARMP), or Unsafe. Our reports are clear, concise, and submitted promptly to the NYC Department of Buildings (DOB), keeping you in compliance with all regulations.</li>
                    <li><strong>Repair and Restoration Services: </strong>Homex Builders Corp offers full repair and restoration services to address any issues identified during inspections. From minor repairs to extensive façade restoration projects, we have the expertise to ensure your building meets all safety standards. Our services include brick pointing, waterproofing, lintel replacement, and more, all performed by skilled craftsmen.</li>
                    <li><strong>Compliance Management: </strong>Navigating Local Law 11 requirements can be complex, but we manage the entire compliance process for you. We handle everything from filing required forms to scheduling follow-up inspections, ensuring you meet all deadlines and avoid costly penalties.</li>
                    <li><strong>Preventive Maintenance Programs: </strong>To help maintain compliance and prevent future issues, we offer preventive maintenance programs tailored to your building's specific needs. Regular maintenance helps identify potential problems early, reducing the need for more extensive and costly repairs down the line.</li>

                </ul>
                <blockquote>
                    <h4>Why Choose Us for Local Law 11 Services?</h4>
                    <ul>
                        <li><strong>Extensive Experience and Expertise: </strong>With years of experience in the industry, Homex Builders Corp has a deep understanding of Local Law 11 requirements and the skills necessary to ensure compliance. Our team consists of highly trained professionals who specialize in façade inspections and repairs, ensuring quality work and reliable results.</li>
                        <li><strong>Thorough and Accurate Inspections: </strong>We pride ourselves on our meticulous approach to inspections. Our experts use the latest technology and techniques to thoroughly inspect every aspect of your building's exterior, leaving no stone unturned. This attention to detail ensures that all potential safety hazards are identified and addressed.</li>
                        <li><strong>Comprehensive Service Offering: </strong>Homex Builders Corp provides a one-stop solution for all your Local Law 11 needs, from initial inspections to repairs and ongoing maintenance. This comprehensive service offering means you don’t need to juggle multiple contractors; we handle everything, making the process smooth and efficient.</li>
                        <li><strong>Commitment to Safety and Compliance: </strong>Safety is at the heart of what we do. We are dedicated to ensuring that your building is not only compliant with Local Law 11 but also safe for its occupants and the public. Our commitment to safety extends to our work practices, ensuring that all repairs and maintenance are conducted in the safest manner possible.</li>
                        <li><strong>Transparent Communication and Documentation: </strong>We believe in maintaining clear and open communication with our clients. From initial consultations to the final report submission, we keep you informed at every stage of the process. Our documentation is thorough, providing you with all the information needed to understand the scope of work and any necessary repairs.</li>
                        <li><strong>Competitive Pricing and Value: </strong>Homex Builders Corp offers competitive pricing without sacrificing quality. We understand the financial implications of Local Law 11 compliance and strive to provide cost-effective solutions that deliver the best value for your investment.</li>
                        <li><strong>Proven Track Record and Reputation: </strong>We have built a solid reputation for delivering high-quality Local Law 11 services. Our portfolio includes numerous satisfied clients who have successfully navigated Local Law 11 requirements with our help. This track record demonstrates our ability to deliver reliable, high-quality service.</li>
                        <li><strong>Responsive and Reliable Service: </strong>We understand the urgency that often accompanies Local Law 11 compliance, especially if an Unsafe condition has been identified. Homex Builders Corp is responsive and reliable, ready to address your needs promptly and efficiently to keep your building safe and compliant.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for your Local Law 11 services ensures that you are partnering with a reliable, experienced, and professional company dedicated to maintaining the safety and compliance of your building. Our comprehensive service offerings, commitment to quality, and focus on customer satisfaction make us the ideal choice for your Local Law 11 needs.
                </p>
            </div>
        </>
    );
}
