import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import retainingwalls1 from "../assets/images/masonry/retainingwalls1.jpg"
import retainingwalls2 from "../assets/images/masonry/retainingwalls2.jpg"
import retainingwalls3 from "../assets/images/masonry/retainingwalls3.jpg"

export const RetainingWalls = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={retainingwalls1} alt="Retaining Walls" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={retainingwalls2} alt="Retaining Walls" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={retainingwalls3} alt="Retaining Walls" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Retaining Walls</h3>
            <div className="text">
                <p>
                    At Homex Builders Corp., we specialize in designing and constructing retaining walls that are both functional and aesthetically pleasing. Whether you need a retaining wall for structural support, erosion control, or simply to enhance the beauty of your landscape, our team is equipped to handle projects of any scale and complexity. Here’s a detailed overview of our retaining wall services:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Retaining Walls Services?</h3>
                <ul>
                    <li><strong>Custom Retaining Wall Design: </strong>We offer personalized design services to create retaining walls that seamlessly blend with your existing landscape and architecture. Our design process considers your specific needs, whether it's to manage sloped terrain, create garden beds, or add a decorative element to your yard.</li>
                    <li><strong>Material Selection and Sourcing: </strong>Choosing the right materials is crucial for the durability and appearance of your retaining wall. We provide a wide range of material options, including:
                        <ul>
                            <li><strong>Natural Stone: </strong>For a timeless, organic look that integrates beautifully with natural landscapes.</li>
                            <li><strong>Concrete Blocks: </strong>Durable and versatile, perfect for both residential and commercial applications.</li>
                            <li><strong>Brick: </strong>Offers a classic, sturdy look, ideal for traditional or colonial-style properties.</li>
                            <li><strong>Timber: </strong>A cost-effective option that blends well with rustic or natural settings. Our team will help you select the best materials based on your aesthetic preferences, budget, and the specific demands of your project.</li>
                        </ul>
                    </li>
                    <li><strong>Engineering and Structural Integrity: </strong>Our retaining walls are engineered for strength and stability, ensuring they can withstand the pressures of soil and water over time. We take into account critical factors such as drainage, load-bearing capacity, and soil composition to design walls that are not only beautiful but also structurally sound.</li>

                    <li><strong>Professional Installation: </strong>Our experienced builders are skilled in the precise installation of retaining walls, ensuring that every element is properly aligned and secured. We handle all aspects of the installation, from site preparation and excavation to the final finishing touches, ensuring your wall is built to last.</li>

                    <li><strong>Retaining Wall Repair and Maintenance: </strong>In addition to new construction, we offer repair and maintenance services for existing retaining walls. Whether your wall is showing signs of wear and tear, or you need to address drainage issues, our team can restore its integrity and appearance.</li>

                    <li><strong>Decorative and Multi-Purpose Retaining Walls: </strong>Beyond functional walls, we also design decorative and multi-purpose retaining walls. These walls can incorporate seating, planters, and other features to enhance the usability and visual appeal of your outdoor space.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Retaining Walls?</h4>
                    <ul>
                        <li><strong>Expertise in Retaining Wall Construction: </strong>With years of experience in building retaining walls, our team understands the complexities involved in creating structures that are both functional and aesthetically pleasing. We have successfully completed numerous retaining wall projects, earning a reputation for quality and reliability.</li>

                        <li><strong>Tailored Solutions: </strong>At Homex Builders Corp., we don’t believe in one-size-fits-all solutions. We take the time to understand your specific needs and design retaining walls that meet those requirements, whether you’re dealing with challenging terrain, looking to prevent erosion, or simply enhancing your landscape.</li>

                        <li><strong>High-Quality Materials: </strong>We use only the best materials available, ensuring that your retaining wall is durable, weather-resistant, and built to stand the test of time. Our commitment to quality means you get a wall that not only serves its purpose but also adds value to your property.</li>

                        <li><strong>Professional and Reliable Service: </strong>Our team is known for its professionalism and dedication to customer satisfaction. From the initial consultation to project completion, we maintain clear communication and adhere to agreed-upon timelines. We respect your property and ensure a clean, orderly worksite throughout the construction process.</li>

                        <li><strong>Comprehensive Warranty: </strong>We stand behind our work with a comprehensive warranty, giving you peace of mind knowing that your investment is protected. Our commitment to quality means that we’re not just building a retaining wall—we’re building trust.</li>

                        <li><strong>Competitive Pricing: </strong>We offer competitive pricing without compromising on quality. Our transparent pricing structure ensures that you understand the costs involved upfront, with no hidden fees or surprises.</li>

                        <li><strong>Environmentally Friendly Practices: </strong>Where possible, we use eco-friendly materials and construction practices. We design retaining walls that not only serve your needs but also contribute positively to the surrounding environment.</li>

                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    Safety and stability are the cornerstones of our retaining wall services. We start by conducting a thorough site assessment, analyzing soil composition, slope gradient, drainage patterns, and potential load-bearing requirements. This allows us to design walls that can withstand the pressures exerted by the earth behind them, preventing issues like soil erosion or wall failure. We ensure proper drainage systems are integrated into the wall design to manage water flow and reduce hydrostatic pressure, which can compromise the wall's integrity. Additionally, we adhere strictly to local building codes and engineering standards, ensuring that each retaining wall we construct is safe and compliant with regulations.
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Our approach to building retaining walls is guided by intelligent design and the use of advanced engineering techniques. We employ state-of-the-art software to model and simulate various scenarios, ensuring that the retaining wall will perform effectively under different conditions. We take into account factors like the type of materials used, the height of the wall, and the load it needs to support, optimizing the design for both strength and durability. We also consider the aesthetic aspects, ensuring that the retaining wall complements the surrounding landscape and architecture. Our team stays informed about the latest innovations in construction and materials, allowing us to incorporate new technologies and methods that enhance the wall's functionality and appearance.
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    Homex Builders Corp specializes in creating custom retaining wall solutions tailored to the specific needs of each project. Whether it's a small garden wall or a large-scale commercial installation, we understand that different environments require different approaches. Our expertise covers a wide range of materials, including natural stone, concrete, brick, and modular blocks, allowing us to deliver solutions that not only meet structural needs but also achieve the desired aesthetic. We also specialize in handling challenging terrains, such as steep slopes or uneven ground, where precise engineering and design are crucial. Our specialized knowledge ensures that we can create retaining walls that are both beautiful and built to last.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    By choosing Homex Builders Corp. for your retaining wall project, you’re selecting a team that values precision, quality, and customer satisfaction. Let us help you enhance your landscape with a retaining wall that is both beautiful and functional. Contact us today to discuss your project and receive a free consultation!
                </p>
            </div>
        </>
    );
}
