import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import deck1 from "../assets/images/waterproofingservices/deck1.jpg"
import deck2 from "../assets/images/waterproofingservices/deck2.jpg"
import deck3 from "../assets/images/waterproofingservices/deck3.jpg"

export const DeckWaterproofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={deck1} alt="Deck Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={deck2} alt="Deck Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={deck3} alt="Deck Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Deck Waterproofing</h3>
            <div className="text">
                <p>
                    Your deck is a valuable extension of your living space, providing a place to relax and entertain. However, exposure to the elements can cause significant wear and tear over time. Homex Builders Corp offers professional deck waterproofing services to protect your deck from water damage, prolong its lifespan, and maintain its beauty.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Deck Waterproofing Services?</h3>
                <ul>
                    <li><strong>Initial Deck Inspection</strong>
                        Comprehensive assessment of your deck’s condition to identify any existing water damage, cracks, or areas that need special attention.</li>
                    <li><strong>Surface Preparation</strong>
                    Thorough cleaning and preparation of the deck surface, including sanding and repairing any damaged areas to ensure proper adhesion of waterproofing materials.</li>
                    <li><strong>Application of Waterproof Coatings</strong>
                    Use of high-quality waterproofing coatings specifically designed for decks, providing a durable barrier against moisture, UV rays, and temperature fluctuations.</li>
                    <li><strong>Sealing of Joints and Gaps</strong>
                    Professional sealing of all joints, seams, and gaps to prevent water penetration and protect the structural integrity of the deck.</li>
                    <li><strong>Anti-Slip Finishes</strong>
                    Application of anti-slip finishes to enhance safety, especially in wet conditions, while maintaining the deck's aesthetic appeal.</li>
                    <li><strong>Ongoing Maintenance and Inspection</strong>
                    Offering periodic maintenance services to reapply waterproofing layers as needed and inspect for any signs of wear, ensuring long-term protection.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Deck Waterproofing?</h4>
                    <p>
                        Choosing Homex Builders Corp for your deck waterproofing needs ensures that you receive top-notch service from a team dedicated to excellence. Here’s why we stand out:
                    </p>
                    <ul>
                        <li><strong>Expertise and Experience: </strong>With years of experience in deck waterproofing, our team has the knowledge and skills to handle projects of all sizes and complexities. We understand the specific challenges decks face and apply solutions that work.</li>
                        <li><strong>High-Quality Materials: </strong>We use only premium waterproofing products that are proven to be effective and long-lasting. These materials not only protect your deck but also enhance its appearance.</li>
                        <li><strong>Customized Solutions: </strong>We recognize that each deck is unique, and we tailor our waterproofing approach to meet your specific needs, whether you have a wood, composite, or concrete deck.</li>
                        <li><strong>Attention to Detail: </strong>Our meticulous attention to detail ensures that every aspect of your deck waterproofing project is handled with care, from the initial inspection to the final finish.</li>
                        <li><strong>Customer Satisfaction: </strong>Your satisfaction is our priority. We maintain open communication throughout the process, ensuring that all your concerns are addressed and that you’re delighted with the results.</li>
                        <li><strong>Competitive Pricing: </strong>We offer transparent pricing with no hidden costs, providing you with the best value for your investment. Our goal is to deliver exceptional service at a price that fits your budget.</li>
                        <li><strong>Long-Term Protection: </strong>Our waterproofing solutions are designed to provide long-lasting protection, reducing the need for frequent maintenance and repairs, and helping you enjoy your deck for years to come.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    Homex Builders Corp takes several precautions to ensure the effectiveness and durability of their deck waterproofing services. These precautions are designed to prevent common issues like water damage, rot, and mold growth, which can significantly affect the longevity of your deck.
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Thorough Inspection: </strong>Before beginning any waterproofing project, Homex Builders conducts a detailed inspection of the deck. This step is crucial to identify existing damage, moisture levels, and any structural weaknesses that need to be addressed before applying waterproofing solutions.</li>
                                        <li><strong>Surface Preparation: </strong>Proper surface preparation is key to effective waterproofing. Homex Builders ensures that all surfaces are clean, dry, and free of debris or mold. They may also sand down rough areas to create a smooth surface that allows the waterproofing material to adhere better.</li>
                                        <li><strong>Use of High-Quality Materials: </strong>Homex Builders uses only high-quality waterproofing materials and sealants, which are specifically designed for outdoor use and resistant to UV rays, temperature changes, and heavy foot traffic. This helps in providing a long-lasting protective layer that minimizes water penetration.</li>
                                        <li><strong>Application of Multiple Layers: </strong>To ensure maximum protection, Homex Builders often applies multiple coats of waterproofing products. This layered approach provides a more robust barrier against water intrusion and enhances the deck's durability.</li>
                                        <li><strong>Weather Considerations: </strong>The timing of the waterproofing application is carefully planned to avoid extreme weather conditions. Homex Builders ensures that the weather is suitable, typically choosing days with moderate temperatures and no rain forecast, to allow the waterproofing materials to set properly.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Homex Builders Corp leverages their extensive experience and knowledge in deck waterproofing to provide high-quality services. Their intelligence in this area comes from years of industry experience and continuous learning about the latest waterproofing technologies and techniques.
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Expert Knowledge of Materials: </strong>Homex Builders has a deep understanding of the various waterproofing materials available, such as sealants, membranes, and coatings. They can recommend the most suitable products based on the type of deck (wood, composite, concrete, etc.) and the specific environmental conditions of the location.</li>
                                        <li><strong>Customized Solutions: </strong>Recognizing that every deck is unique, Homex Builders offers tailored waterproofing solutions to meet specific customer needs. Whether it’s a residential wooden deck or a commercial concrete terrace, they customize their approach to ensure optimal protection and aesthetic appeal.</li>
                                        <li><strong>Advanced Techniques: </strong>Homex Builders stays up-to-date with the latest advancements in waterproofing techniques. They employ modern methods such as liquid-applied membranes and elastomeric coatings, which provide superior flexibility and durability compared to traditional waterproofing solutions.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    Homex Builders Corp specializes in comprehensive deck protection services, with a particular focus on waterproofing. Their specialization is evident in several key areas:
                                </p>
                                <p>
                                    <ul>

                                        <li><strong>Deck Type Specialization: </strong>They are proficient in waterproofing various types of decks, including wood, composite, and concrete decks. Each material requires a different approach to waterproofing, and Homex Builders has the expertise to handle each type effectively.</li>
                                        <li><strong>Integrated Waterproofing Systems: </strong>Beyond just applying a waterproofing layer, Homex Builders often incorporates integrated waterproofing systems that combine various methods (such as surface treatments, membranes, and drainage systems) to provide comprehensive protection against water damage.</li>
                                        <li><strong>Preventative Maintenance Programs: </strong>Homex Builders offers preventative maintenance services to extend the life of the waterproofing treatment. This includes regular inspections, touch-ups, and reapplications as needed to maintain optimal deck protection over time.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for your deck waterproofing needs ensures that you receive a service marked by careful precautions, expert intelligence, and specialized knowledge. Their commitment to using high-quality materials, applying advanced techniques, and customizing solutions based on specific deck requirements makes them a reliable choice for maintaining the longevity and beauty of your outdoor spaces.
                </p>
                <p>
                    For optimal results and peace of mind, consider reaching out to Homex Builders Corp to discuss your specific deck waterproofing needs and take advantage of their expertise.
                </p>
            </div>
        </>
    );
}
