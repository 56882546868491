import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import chimneyrebuilds1 from "../assets/images/masonry/chimneyrebuilds1.jpg"
import chimneyrebuilds2 from "../assets/images/masonry/chimneyrebuilds2.jpg"
import chimneyrebuilds3 from "../assets/images/masonry/chimneyrebuilds3.jpg"

export const ChimneyRebuilds = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={chimneyrebuilds1} alt="Chimney Rebuilds" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={chimneyrebuilds2} alt="Chimney Rebuilds" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={chimneyrebuilds3} alt="Chimney Rebuilds" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Chimney Rebuilds</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers comprehensive chimney rebuild services designed to restore the structural integrity, safety, and aesthetic appeal of your chimney. Whether your chimney is damaged due to weather, age, or poor maintenance, we have the expertise and experience to provide a solution tailored to your specific needs. Here’s an overview of our chimney rebuild services and the reasons why you should choose Homex Builders Corp for your project:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Chimney Rebuilds Services?</h3>
                <ul>
                    <li><strong>Full Chimney Rebuilds: </strong>We offer full chimney rebuilds for chimneys that have suffered significant damage or deterioration. This service involves dismantling the existing chimney down to the roofline or further, as needed, and rebuilding it from the ground up. We use high-quality materials and adhere to all building codes and regulations to ensure a durable and safe rebuild.</li>
                    <li><strong>Partial Chimney Rebuilds: </strong>If only a section of your chimney is damaged, we provide partial rebuild services. This approach is less invasive and more cost-effective than a full rebuild, focusing only on the sections that require repair. Our team assesses the condition of your chimney and determines the most appropriate level of intervention needed to restore its function and appearance.</li>
                    <li><strong>Chimney Crown and Cap Replacements: </strong>The chimney crown and cap are crucial in protecting the chimney structure from water damage and debris. We offer replacement services for both components, ensuring your chimney is properly sealed and protected from the elements. Our replacements are built to last, using durable materials that provide long-term protection.</li>
                    <li><strong>Masonry Repair and Tuckpointing: </strong>Over time, the mortar between the bricks of your chimney can deteriorate, compromising its structural integrity. Our masonry repair and tuckpointing services involve removing the damaged mortar and replacing it with fresh mortar that matches the original color and texture. This process not only restores the structural integrity of your chimney but also enhances its aesthetic appeal.</li>
                    <li><strong>Waterproofing and Flashing Repair: </strong>Chimneys are particularly vulnerable to water damage, which can lead to serious structural problems if not addressed. We offer chimney waterproofing services using high-quality, breathable sealants that protect against moisture while allowing the chimney to "breathe." Additionally, we repair or replace chimney flashing to ensure a watertight seal between the chimney and the roof.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Chimney Rebuilds?</h4>
                    <ul>
                        <li><strong>Extensive Experience and Expertise: </strong>With years of experience in masonry and chimney services, Homex Builders Corp has the knowledge and expertise to handle any chimney rebuild project, big or small. Our team is composed of skilled masons who understand the complexities of chimney structures and have a track record of delivering high-quality rebuilds.</li>
                        <li><strong>Quality Craftsmanship: </strong>At Homex Builders Corp, we take pride in our craftsmanship. We use only the best materials and proven techniques to ensure that every chimney rebuild is completed to the highest standards. Our attention to detail and commitment to quality means that your rebuilt chimney will not only function properly but will also look great.</li>
                        <li><strong>Customized Solutions: </strong>We understand that every chimney and home is unique, and we tailor our services to meet your specific needs. Whether you require a full rebuild or a simple crown replacement, we work closely with you to develop a solution that fits your budget and meets your expectations.</li>
                        <li><strong>Safety and Compliance: </strong>Safety is a top priority for Homex Builders Corp. Our chimney rebuild services are carried out in full compliance with local building codes and regulations to ensure the safety of your home and family. Additionally, we use safe work practices and take all necessary precautions to prevent accidents and injuries during the rebuild process.</li>
                        <li><strong>Competitive Pricing: </strong>We offer competitive pricing for our chimney rebuild services without compromising on quality. We provide transparent, detailed quotes so you know exactly what to expect, with no hidden fees or surprises. Our goal is to provide excellent value for your investment, ensuring a safe and reliable chimney that will stand the test of time.</li>
                        <li><strong>Timely and Reliable Service: </strong>We understand the inconvenience that comes with having a damaged or unsafe chimney. That’s why we strive to provide prompt, reliable service to get your chimney back in working order as quickly as possible. Our team is responsive and efficient, minimizing disruptions to your daily routine.</li>
                        <li><strong>Reputation for Excellence: </strong>Homex Builders Corp has built a strong reputation for excellence in chimney rebuild services. Our satisfied clients can attest to our professionalism, reliability, and high-quality work. We are proud of the trust we have built with homeowners throughout the community and are committed to maintaining that trust with every project we undertake.</li>
                        <li><strong>Comprehensive Aftercare: </strong>Our commitment to you doesn’t end once the rebuild is complete. We provide comprehensive aftercare services to ensure your chimney remains in good condition for years to come. This includes routine maintenance recommendations, tips for care, and access to our team for any future needs or concerns.</li>

                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                        <li data-tab="prod-details4" className={`tab-btn ${tabName == "prod-details4" ? "active-btn" : ""}`} onClick={switchTab}>Post-Rebuild</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Safety Measures: </strong>Homex Builders Corp prioritizes safety on all chimney rebuild projects. This includes providing proper scaffolding, fall protection systems, and personal protective equipment (PPE) for their workers. They conduct regular safety training sessions to ensure that all team members are aware of the potential hazards associated with chimney work, such as working at heights, handling heavy materials, and exposure to dust and debris.</li>

                                        <li><strong>Structural Assessment: </strong>Before beginning a chimney rebuild, Homex Builders Corp conducts a thorough structural assessment of the chimney and the surrounding roof area. This includes checking for signs of wear, damage, and potential safety hazards, such as loose bricks, deteriorating mortar, or water damage. Understanding the underlying issues helps prevent future problems and ensures a solid foundation for the rebuild.</li>

                                        <li><strong>Environmental Considerations: </strong>The company takes into account weather conditions and environmental factors when scheduling chimney rebuilds. They avoid working in adverse weather conditions such as high winds, rain, or extreme temperatures that could compromise the quality of the work or the safety of the workers.</li>

                                        <li><strong>Material Quality Control: </strong>Homex Builders Corp ensures that only high-quality, durable materials are used for chimney rebuilds. This includes choosing the right type of bricks or stones, using water-resistant mortar, and selecting appropriate flashing materials to prevent water penetration. All materials are inspected before use to ensure they meet the company’s standards for quality and durability.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Expertise in Chimney Construction and Design: </strong>Homex Builders Corp has a team of skilled masons who specialize in chimney construction and design. They are knowledgeable about different types of chimneys (such as brick, stone, and prefabricated metal chimneys) and the unique requirements for each. The team understands the nuances of chimney architecture, including the importance of proper flue sizing, liner installation, and chimney crown construction.</li>

                                        <li><strong>Compliance with Building Codes and Standards: </strong>Homex Builders Corp ensures that all chimney rebuilds comply with local building codes and standards. They stay up-to-date with the latest regulations and best practices in chimney construction, including those related to fire safety, structural integrity, and ventilation. This compliance is critical to ensure that rebuilt chimneys are safe, functional, and long-lasting.</li>

                                        <li><strong>Advanced Diagnostic Tools and Techniques: </strong>The company uses advanced diagnostic tools and techniques to assess the condition of the chimney and identify any hidden issues. This might include using chimney cameras to inspect the flue interior, infrared thermography to detect heat leaks or structural weaknesses, and moisture meters to identify water damage. These tools help provide a comprehensive understanding of the chimney’s condition before any work begins.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Full and Partial Chimney Rebuilds: </strong>Homex Builders Corp offers both full and partial chimney rebuilds based on the extent of the damage and the specific needs of the client. A full rebuild involves dismantling the chimney down to the roofline and rebuilding it from the ground up, while a partial rebuild focuses on repairing or replacing only the damaged sections. Their expertise allows them to determine the most appropriate and cost-effective solution for each situation.</li>

                                        <li><strong>Waterproofing and Flashing Installation: </strong>One of the key areas of specialization for Homex Builders Corp is chimney waterproofing and flashing installation. Proper flashing is crucial for preventing water leaks and damage to both the chimney and the surrounding roof structure. They use high-quality flashing materials and techniques to ensure a watertight seal, protecting the chimney from future water damage.</li>

                                        <li><strong>Chimney Liner Installation and Replacement: </strong>As part of their chimney rebuild services, Homex Builders Corp specializes in chimney liner installation and replacement. Chimney liners are essential for protecting the chimney walls from heat and corrosive byproducts of combustion. They offer a range of liner options, including stainless steel and ceramic liners, to suit different types of fuel-burning appliances and customer preferences.</li>

                                        <li><strong>Custom Masonry Work and Aesthetic Enhancements: </strong>Beyond structural repairs, Homex Builders Corp is skilled in custom masonry work and aesthetic enhancements. They can match the chimney’s materials and design to the existing architecture of the home, ensuring a seamless and visually appealing result. This includes offering a variety of brick or stone options, custom crown designs, and decorative elements like corbels and shoulders.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details4" ? "active-tab" : ""}`} id="prod-details4">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Regular Inspections and Maintenance Plans: </strong>After completing a chimney rebuild, Homex Builders Corp offers ongoing inspection and maintenance services to help clients keep their chimneys in top condition. Regular inspections can help identify minor issues before they become major problems, saving homeowners time and money in the long run.</li>

                                        <li><strong>Customer Education and Support: </strong>Homex Builders Corp believes in educating their clients about proper chimney care and maintenance. They provide guidance on best practices for using fireplaces and stoves, the importance of regular cleaning, and how to spot early signs of chimney damage. This proactive approach helps clients maintain the safety and longevity of their rebuilt chimneys.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for your chimney rebuild services means choosing a company with a proven track record of delivering high-quality work, exceptional customer service, and reliable, safe solutions. Whether you need a full chimney rebuild or a simple repair, our team is ready to help restore your chimney to its best condition.
                </p>
            </div>
        </>
    );
}
