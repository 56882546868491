import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import stonerestoration1 from "../assets/images/restoration/stonerestoration1.jpg"
import stonerestoration2 from "../assets/images/restoration/stonerestoration2.jpg"
import stonerestoration3 from "../assets/images/restoration/stonerestoration3.jpg"

export const StoneRestoration = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={stonerestoration1} alt="Stone Restoration" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={stonerestoration2} alt="Stone Restoration" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={stonerestoration3} alt="Stone Restoration" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Stone Restoration</h3>
            <div className="text">
                <p>
                    HomeX Builders Corp provides a specialized stone restoration service aimed at rejuvenating and preserving the beauty and integrity of various types of stone surfaces. Here's what they typically include in their stone restoration service:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Stone Restoration Services?</h3>
                <ul>
                    <li><strong>Inspection and Assessment: </strong>The process starts with a detailed inspection of the stone surface to assess its condition. This includes identifying cracks, chips, stains, or signs of wear and tear. They determine the type of stone and the appropriate restoration techniques needed.</li>
                    <li><strong>Cleaning: </strong>The next step involves deep cleaning the stone to remove dirt, grime, mold, and any surface contaminants. HomeX Builders Corp uses specialized cleaning agents and equipment designed specifically for stone surfaces to avoid damage while effectively cleaning.</li>
                    <li><strong>Repair and Filling: </strong>After cleaning, they address any physical damage. This can include repairing cracks, filling chips, and replacing damaged tiles or stone pieces. For natural stone, they may use color-matched epoxy or other materials to ensure repairs blend seamlessly with the original surface.</li>
                    <li><strong>Grinding and Honing: </strong>For stone surfaces that have lost their luster or are uneven, HomeX Builders Corp may grind and hone the stone. Grinding helps to remove surface imperfections, while honing brings back a smooth finish. This step is essential for restoring the stone’s natural beauty and preparing it for polishing.</li>
                    <li><strong>Polishing: </strong>Once the surface is even and smooth, they polish the stone to restore its shine. Depending on the stone type, this might involve using diamond polishing pads or other specialized tools to achieve the desired level of gloss.</li>
                    <li><strong>Sealing: </strong>To protect the restored stone surface from future damage, they apply a high-quality sealer. This helps to prevent stains, water damage, and wear from regular use. The sealer also enhances the stone's natural color and prolongs its life.</li>
                    <li><strong>Final Inspection and Maintenance Advice: </strong>After restoration, a final inspection ensures that the stone meets HomeX Builders Corp's quality standards. They also provide customers with advice on how to maintain the stone surface to keep it looking pristine for as long as possible.</li>
                </ul>
                <p>
                    HomeX Builders Corp's stone restoration service is designed to restore the original beauty of your stone surfaces, whether it's marble, granite, limestone, or another type of natural stone, while also ensuring their durability and longevity.
                </p>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Stone Restoration?</h4>
                    <p>
                        When it comes to stone restoration, Homex Builders Corp stands out as the top choice for several compelling reasons. Here’s why you should choose us:
                    </p>
                    <ul>

                        <li><strong>Specialized Expertise: </strong>Stone restoration is a delicate process that requires specific knowledge and skills. At Homex Builders Corp, we have a team of experts who specialize in restoring all types of stone surfaces, from marble and granite to limestone and travertine. Our deep understanding of stone properties and restoration techniques ensures that your surfaces will be handled with the utmost care and precision.</li>

                        <li><strong>Comprehensive Restoration Services: </strong>We offer a full range of stone restoration services, including cleaning, polishing, sealing, and repair. Whether your stone surfaces are dull, scratched, stained, or chipped, we have the right solution to bring them back to life. Our comprehensive approach ensures that every aspect of your stone’s condition is addressed, leaving it looking as good as new.</li>

                        <li><strong>Advanced Technology and Techniques: </strong>At Homex Builders Corp, we stay at the forefront of industry advancements. We use state-of-the-art equipment and the latest restoration techniques to achieve the best possible results. Our methods are both effective and gentle, ensuring that your stone surfaces are restored without any damage.</li>

                        <li><strong>Quality Materials and Products: </strong>We use only the highest quality materials and products in our restoration processes. From the finest polishing compounds to the most durable sealants, every product we use is selected for its effectiveness and safety. This commitment to quality ensures that your stone surfaces will not only look great but also be protected against future damage.</li>

                        <li><strong>Attention to Detail: </strong>Stone restoration requires a meticulous approach, and we pride ourselves on our attention to detail. We carefully assess the condition of your stone surfaces and develop a tailored restoration plan that addresses every issue. Our focus on the finer details ensures a flawless finish that enhances the natural beauty of the stone.</li>

                        <li><strong>Experienced and Professional Team: </strong>Our team is composed of experienced professionals who are passionate about stone restoration. We approach each project with dedication and professionalism, ensuring that the work is completed to the highest standards. Our team’s expertise and commitment to excellence make us a trusted partner for your stone restoration needs.</li>

                        <li><strong>Proven Results and Customer Satisfaction: </strong>Homex Builders Corp has a solid reputation for delivering outstanding results. Our portfolio showcases numerous successful stone restoration projects, and our clients consistently praise our work. We are committed to ensuring your satisfaction, and we won’t rest until you are delighted with the results.</li>

                        <li><strong>Competitive Pricing: </strong>We offer our top-notch stone restoration services at competitive prices. Our pricing is transparent, with no hidden fees, and we work within your budget to deliver exceptional value. Quality restoration doesn’t have to break the bank, and we ensure you get the best return on your investment.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Structural Assessment: </strong>Homex Builders Corp conducts a thorough assessment of the deck’s underlying structure before any stonework begins. This ensures that the deck can support the weight of the stone, preventing future structural issues.</li>
                                        <li><strong>Protective Measures: </strong>During the installation process, they implement protective measures to safeguard surrounding areas, including covering nearby plants, furniture, and existing surfaces to prevent damage from dust, debris, or heavy materials.</li>
                                        <li><strong>Weather-Appropriate Scheduling: </strong>Work is scheduled around weather conditions to ensure that stone installation occurs in optimal conditions. This prevents issues like improper setting or water infiltration during the installation process.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Material Expertise: </strong>Homex Builders possesses extensive knowledge of various stone materials, including natural stone, pavers, and engineered stone. Their understanding of the properties, durability, and maintenance needs of each type allows them to recommend the best options for your deck.</li>
                                        <li><strong>Design Precision: </strong>The company uses advanced design tools, such as CAD (Computer-Aided Design) software, to create precise layouts that optimize the aesthetic and functional aspects of stone decking. This ensures a seamless integration with the overall design of your outdoor space.</li>
                                        <li><strong>Problem-Solving Skills: </strong>Homex Builders is adept at identifying and solving potential issues before they become problems. This includes addressing drainage concerns, ensuring proper grading, and selecting materials that will withstand the specific environmental conditions of your property.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Stone Installation: </strong>Homex Builders Corp specializes in the installation of stone surfaces for decks, including patios, walkways, and other outdoor spaces. Our expertise ensures that the stone is laid with precision, durability, and an eye for aesthetic detail.</li>
                                        <li><strong>Custom Stonework: </strong>They offer customized stone solutions tailored to your specific preferences, whether you’re looking for a rustic natural stone appearance, a modern paver design, or something in between. Their specialization in custom work allows them to deliver unique and personalized results.</li>
                                        <li><strong>Restoration and Repair: </strong>In addition to installation, Homex Builders also specializes in the restoration and repair of existing stone decks. Whether it’s re-grouting, repairing cracks, or replacing damaged stones, their expertise ensures that your deck remains safe and visually appealing for years to come.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    In conclusion, Homex Builders Corp excels in deck stone services through their focus on precautionary measures, intelligent planning, and specialized skills in stone installation and restoration. Our comprehensive approach ensures that every stone deck project is completed to the highest standards, combining beauty, functionality, and long-lasting quality.
                </p>
                <p>
                    Revive the elegance and beauty of your stone surfaces with Homex Builders Corp. Contact us today to schedule a consultation and discover how we can transform your stone surfaces. Let us restore the timeless beauty of your stonework with our expert care and attention.
                </p>
            </div>
        </>
    );
}
