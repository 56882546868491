import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import violationremoval1 from "../assets/images/sidewalk/violationremoval1.jpg"
import violationremoval2 from "../assets/images/sidewalk/violationremoval2.jpg"
import violationremoval3 from "../assets/images/sidewalk/violationremoval3.jpg"

export const SidewalkViolationRemoval = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={violationremoval1} alt="Sidewalk Violation Removal" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={violationremoval2} alt="Sidewalk Violation Removal" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={violationremoval3} alt="Sidewalk Violation Removal" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Sidewalk Violation Removal</h3>
            <div className="text">
                <p>
                    At HomeX Builders Corp, our Sidewalk Violation Removal service is designed to efficiently address and resolve violations issued by local authorities. We ensure that your property complies with municipal codes and regulations, avoiding potential fines and legal issues. Here are the steps and techniques we use:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Sidewalk Violation Removal Services?</h3>
                <ul>
                    <li><strong>Review of Violation Notice: </strong>We start by thoroughly reviewing the violation notice issued by the city or local authority to understand the specific issues cited.</li>
                    <li><strong>Site Inspection: </strong>A detailed inspection of the sidewalk is conducted to assess the extent of the violation and identify any underlying causes of the problem.</li>
                    <li><strong>Consultation: </strong>We discuss the findings with you, explaining the necessary steps to remove the violation and providing a clear plan of action.</li>
                    <li><strong>Permit Acquisition: </strong>We handle the process of obtaining any required permits to perform the necessary repairs or replacements, ensuring compliance with local regulations.</li>
                    <li><strong>Coordination with Authorities: </strong>We communicate with local authorities as needed to clarify requirements and ensure that our repair plan meets all standards.</li>
                    <li><strong>Safety Measures: </strong>We set up barriers, signs, and other safety measures to protect pedestrians and traffic during the repair work.</li>
                    <li><strong>Clearing the Area: </strong>The site is cleared of any debris, vegetation, or obstacles that could interfere with the repair process.</li>

                    <li>
                        <strong>Violation-Specific Repairs: </strong>
                        The specific repairs depend on the nature of the violation. Common issues include trip hazards, cracks, uneven surfaces, and improper slopes. Our techniques vary accordingly:
                        <ul>
                            <li><strong>Grinding: </strong>For minor height differences, we use concrete grinding techniques to level the surface, eliminating trip hazards.</li>
                            <li><strong>Concrete Lifting (Mudjacking): </strong>If sections of the sidewalk have settled unevenly, we use mudjacking or slab lifting techniques to raise the affected areas and restore a level surface.</li>
                            <li><strong>Filling: </strong>Small cracks are filled with a high-quality epoxy or sealant to prevent further deterioration.</li>
                            <li><strong>Section Replacement: </strong>For larger cracks, we may cut out and replace the damaged section with new concrete to ensure a smooth, durable surface.</li>
                            <li><strong>Resurfacing: </strong>For widespread unevenness, we apply a new layer of concrete or overlay to create a uniform surface.</li>
                            <li><strong>Regrading: </strong>In cases where the sidewalk slope is incorrect, we regrade the area to ensure proper drainage and compliance with ADA (Americans with Disabilities Act) standards.</li>
                            <li><strong>Demolition: </strong>Severely damaged or non-compliant sections are carefully demolished and removed.</li>
                            <li><strong>Reconstruction: </strong>New concrete is poured and finished to meet local code requirements, including proper slope, thickness, and alignment.</li>
                        </ul>
                    </li>
                    <li><strong>Proper Curing: </strong>The newly repaired or replaced sections are allowed to cure fully, ensuring strength and durability.</li>
                    <li><strong>Finishing Touches: </strong>We apply finishing techniques such as broom finishes or edge treatments to match the surrounding sidewalk and enhance safety.</li>
                    <li><strong>Quality Inspection: </strong>A final inspection is conducted to ensure that all repairs are up to code and that the violation has been fully addressed.</li>
                    <li><strong>Official Clearance: </strong>We coordinate with local authorities to confirm that the violation has been cleared and that your property is in compliance.</li>
                    <li><strong>Cleanup: </strong>The site is thoroughly cleaned, and all debris and equipment are removed, leaving your property in pristine condition.</li>
                    <li><strong>Documentation: </strong>We provide you with detailed documentation of the work performed, including before-and-after photos, permits, and inspection reports, which can be used to confirm compliance.</li>

                </ul>
                <h3>Techniques Used</h3>
                <ul>
                    <li><strong>Precision Concrete Grinding: </strong>To eliminate trip hazards by leveling uneven surfaces.</li>
                    <li><strong>Mudjacking (Concrete Lifting): </strong>To raise sunken sidewalk sections and restore a level surface.</li>
                    <li><strong>High-Strength Concrete Mixes:</strong> Ensures durability and longevity of repairs, particularly in high-traffic areas.</li>
                    <li><strong>Epoxy and Sealants: </strong>Used for crack repairs, providing flexibility and resistance to future damage.</li>
                    <li><strong>Custom Formwork and Reinforcement: </strong>Used in section replacements to ensure the new sidewalk meets all code requirements and integrates seamlessly with existing structures.</li>
                </ul>
                <h3>How we Execute Sidewalk Violation Removal in NYC</h3>
                <ul>

                    <li><strong>Review of Violation Notice: </strong>Homex Builders begins by thoroughly reviewing the violation notice you received from the local municipality or Department of Transportation (DOT). This step helps them understand the specific issues cited, such as cracks, uneven surfaces, or trip hazards.</li>
                    <li><strong>Site Inspection: </strong>A team is dispatched to inspect the sidewalk in person. They assess the extent of the damage or non-compliance and identify the exact areas that need to be addressed.</li>
                    <li><strong>Detailed Proposal: </strong>Based on the assessment, Homex Builders prepares a detailed proposal outlining the scope of work required to remove the violation. This includes the materials, methods, and timeframes for the repair.</li>
                    <li><strong>Transparent Quotation: </strong>You receive a clear and itemized cost estimate for the project, covering all aspects of the violation removal, including labor, materials, and any necessary permits.</li>
                    <li><strong>Permit Application: </strong>If the repair work requires permits, Homex Builders handles the application process with the relevant local authorities. This ensures that all work is legally compliant and up to code.</li>
                    <li><strong>Coordination with Local Authorities: </strong>Homex Builders coordinates directly with the DOT or other relevant agencies to schedule inspections and ensure that the work meets all regulatory requirements.</li>
                    <li><strong>ite Preparation: </strong>The area around the sidewalk is prepared for work. This may involve setting up safety barriers, signage, and other measures to protect pedestrians and property during the repair process.</li>
                    <li><strong>Demolition of Damaged Sections: </strong>The team carefully removes the damaged or non-compliant sections of the sidewalk. This might include breaking up old concrete, removing debris, and ensuring the area is ready for new construction.</li>
                    <li><strong>Subgrade Preparation: </strong>The underlying soil or base is re-graded and compacted to ensure a stable foundation for the new sidewalk. Proper drainage is also considered to prevent future issues.</li>
                    <li><strong>Formwork and Reinforcement: </strong>Forms are set up to shape the new sidewalk, and reinforcement bars (rebar) may be installed if necessary to increase the durability of the repair.</li>
                    <li><strong>Concrete Pouring and Finishing: </strong>Fresh concrete is mixed and poured into the forms, then smoothed and finished to match the surrounding sidewalk. The surface is carefully treated to ensure it is level, slip-resistant, and aesthetically consistent.</li>
                    <li><strong>Curing Process: </strong>The concrete is allowed to cure properly, which typically takes several days. This ensures that the sidewalk reaches its full strength and durability.</li>
                    <li><strong>Final Inspection: </strong>Once the curing process is complete, Homex Builders conducts a final inspection to ensure that the repairs meet all the requirements outlined in the violation notice. They also ensure that the work complies with local building codes and standards.</li>
                    <li><strong>Coordination with Inspectors: </strong>Homex Builders schedules a follow-up inspection with the local authorities to have the violation officially cleared. They handle all necessary documentation and ensure that the sidewalk is now in full compliance.</li>
                    <li><strong>Certification of Completion: </strong>Once the violation is removed and the work is approved, you receive a certification of completion, which serves as proof that the violation has been addressed.</li>
                    <li><strong>Cleanup: </strong>The worksite is thoroughly cleaned, with all construction materials and debris removed. The area is restored to its original condition, with any disturbed landscaping or pavement around the sidewalk repaired.</li>
                    <li><strong>Final Walkthrough: </strong>Homex Builders conducts a final walkthrough with you to ensure you are satisfied with the work and to address any remaining questions or concerns.</li>
                    <li><strong>Follow-Up: </strong>After the project is completed, Homex Builders may follow up to ensure that the sidewalk remains in good condition and to provide any necessary maintenance tips.</li>

                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Sidewalk Violation Removal?</h4>
                    <p>
                        Choosing Homex Builders Corp for your Sidewalk Violation Removal service offers several benefits that make them a reliable and effective option for handling your sidewalk compliance needs. Here are some reasons why you might consider them:
                    </p>
                    <ul>
                        <li><strong>Expertise in Local Regulations: </strong>Homex Builders Corp has extensive experience navigating the complex rules and regulations surrounding sidewalk violations in your area. They are familiar with local codes and can ensure that the removal and repair process complies fully with city ordinances, preventing future violations and fines.</li>
                        <li><strong>Comprehensive Service Offerings: </strong>They provide a complete range of services for sidewalk violation removal, from initial inspection and violation notices to final repair and compliance checks. This means you won’t have to coordinate with multiple contractors or worry about any part of the process being overlooked.</li>
                        <li><strong>Quality Workmanship: </strong>Homex Builders Corp is known for high-quality workmanship, using durable materials and skilled labor to ensure that your sidewalk is repaired to a standard that not only meets but often exceeds local requirements. This level of quality helps prevent future issues, saving you money in the long run.</li>
                        <li><strong>Efficient Project Management: </strong>With their streamlined processes and experienced project managers, Homex Builders Corp can complete sidewalk violation removal projects efficiently, minimizing disruption to your property and reducing the time it takes to get back into compliance. They understand the urgency of these matters and prioritize quick turnarounds.</li>
                        <li><strong>Customer-Centric Approach: </strong>The company prides itself on its customer service, offering clear communication throughout the process and ensuring that you are fully informed at every stage. They are responsive to client needs and provide support from the initial consultation through to project completion.</li>
                        <li><strong>Cost-Effective Solutions: </strong>By providing competitive pricing and a transparent quote process, Homex Builders Corp ensures you understand the costs upfront with no hidden fees. Their solutions are designed to be cost-effective, providing value for money without sacrificing quality or compliance.</li>
                        <li><strong>Licensed and Insured: </strong>Homex Builders Corp is fully licensed and insured, giving you peace of mind that the work will be completed safely and professionally. This also protects you from any liability should any accidents occur on your property during the project.</li>
                        <li><strong>Reputation and Reliability: </strong>They have built a solid reputation in the community for reliable, consistent service. Positive customer reviews and word-of-mouth recommendations are a testament to their dedication to delivering satisfactory results.</li>
                    </ul>
                </blockquote>
                <p>
                    If you're dealing with a sidewalk violation and need a quick, efficient, and reliable solution, Homex Builders Corp stands out as a top choice due to their expertise, comprehensive services, quality workmanship, customer focus, and commitment to compliance and safety. Reach out to them today for a consultation and see how they can help you resolve your sidewalk violation issue efficiently and effectively.
                </p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Regulatory Compliance: </strong>Homex Builders Corp ensures that all work complies with local regulations and codes, particularly those outlined in the violation notice. They review the violation details thoroughly to understand the specific requirements and avoid any legal repercussions.</li>
                                        <li><strong>Safety Measures: </strong>The company implements strict safety protocols, including setting up barriers and caution tape around the work area to protect pedestrians and workers. They also ensure that any debris or materials are managed safely to prevent accidents.</li>
                                        <li><strong>Utility Check: </strong>Before beginning any excavation or repair work, they coordinate with utility companies to mark underground lines. This precaution helps prevent accidental damage to water, gas, or electrical lines, which could lead to additional violations or hazards.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Violation Assessment: </strong>Homex Builders Corp conducts a detailed assessment of the sidewalk violation. They identify the exact causes of the violation, such as cracks, uneven surfaces, or trip hazards, and develop a targeted plan to address each issue effectively.</li>
                                        <li><strong>Advanced Technology: </strong>The company uses advanced tools and technology to guide the repair process. For example, they might use laser levels to ensure that the repaired sidewalk is perfectly even and complies with ADA (Americans with Disabilities Act) standards.</li>
                                        <li><strong>Project Management </strong>Tools: They employ project management software to ensure that the violation removal process is completed efficiently and within the required timeframe. This helps in coordinating all aspects of the project, from labor to materials, ensuring no steps are overlooked.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Expertise in Violation Corrections: </strong>Homex Builders Corp has specialized teams who are experienced in addressing common sidewalk violations. Their knowledge of local codes and regulations ensures that all repairs are compliant and that the violation is fully resolved.</li>
                                        <li><strong>Custom Solutions: </strong>Depending on the violation, they provide custom repair solutions. This could involve leveling uneven slabs, replacing damaged sections, or installing ramps to meet accessibility standards. Each repair is tailored to the specific requirements of the violation notice.</li>
                                        <li><strong>Quality Assurance: </strong>They implement a thorough quality assurance process to ensure that all repairs meet or exceed local standards. This might include post-repair inspections to verify that the work has fully addressed the violation and will pass any follow-up inspections by city authorities.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    If you have received a sidewalk violation notice, contact Homex Builders Corp for a professional and efficient removal process. Their experienced team will guide you through every step, ensuring your sidewalk is brought up to code and the violation is cleared promptly.
                </p>
            </div>
        </>
    );
}
