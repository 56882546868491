import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import bituminous1 from "../assets/images/waterproofingservices/bituminous1.jpg"
import bituminous2 from "../assets/images/waterproofingservices/bituminous2.jpg"
import bituminous3 from "../assets/images/waterproofingservices/bituminous3.jpg"

export const BituminousWaterproofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={bituminous1} alt="Bituminous Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={bituminous2} alt="Bituminous Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={bituminous3} alt="Bituminous Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Bituminous Waterproofing</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers specialized Bituminous Waterproofing Services designed to protect structures from water infiltration. Here’s an overview of their services and reasons why clients choose them:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Bituminous Waterproofing Services?</h3>
                <ul>
                    <li><strong>Bituminous Membrane Application:</strong> Homex Builders Corp applies bituminous membranes, which are highly effective in preventing water penetration. These membranes are typically installed on roofs, basements, and other vulnerable areas where water ingress is a concern.</li>
                    <li><strong>Bituminous Coatings:</strong> The company uses bituminous coatings, also known as asphalt coatings, to seal and protect masonry surfaces. These coatings provide a durable and flexible barrier against water, making them ideal for a wide range of applications.</li>
                    <li><strong>Bituminous Sheet Waterproofing:</strong> This method involves the application of pre-formed bituminous sheets that are heat-sealed or cold-applied to create a seamless waterproof layer. This technique is particularly effective for large surface areas and complex shapes.</li>
                    <li><strong>Cold-Applied Bituminous Waterproofing:</strong> Homex Builders Corp offers cold-applied bituminous solutions that do not require heating, reducing installation time and minimizing the risk of fire hazards. This method is especially suitable for sensitive environments.</li>
                    <li><strong> Customized Solutions:</strong> The company provides tailored bituminous waterproofing solutions based on the specific needs of each project, ensuring maximum protection and longevity.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Bituminous Waterproofing?</h4>
                    <ul>
                        <li><strong>Expertise and Experience: </strong>Homex Builders Corp has extensive experience in bituminous waterproofing, with a team of highly skilled professionals who understand the intricacies of bitumen-based materials. Their expertise ensures that each project is executed to the highest standards.</li>
                        <li><strong>High-Quality Materials: </strong>The company uses only premium-grade bituminous materials that are known for their durability, flexibility, and resistance to harsh weather conditions. This ensures long-lasting protection for your property.</li>
                        <li><strong>Comprehensive Solutions: </strong>Homex Builders Corp offers a full range of bituminous waterproofing services, from initial consultation and site assessment to installation and maintenance. Their comprehensive approach ensures that all aspects of waterproofing are covered.</li>
                        <li><strong>Attention to Detail: </strong>The company is known for its meticulous attention to detail, ensuring that every inch of the surface is properly sealed and protected. This level of precision helps prevent any potential water infiltration issues.</li>
                        <li><strong>Safety and Compliance: </strong>Homex Builders Corp adheres to strict safety protocols and complies with all relevant building codes and standards. This commitment to safety ensures that both the workers and the property are protected throughout the project.</li>
                        <li><strong>Cost-Effective Solutions: </strong>Despite offering high-quality services, Homex Builders Corp provides competitive pricing, making their bituminous waterproofing solutions accessible and cost-effective for a wide range of clients.</li>
                        <li><strong>Customer Satisfaction: </strong>The company places a strong emphasis on customer satisfaction, working closely with clients to understand their needs and delivering solutions that exceed expectations. Positive client testimonials and repeat business are a testament to their dedication.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>

                                        <li><strong>Surface Preparation: </strong>Ensuring that the surface is clean, dry, and free from any debris, dust, or oil before applying bituminous materials. This preparation is essential for proper adhesion and the effectiveness of the waterproofing layer.</li>
                                        <li><strong>Weather Monitoring: </strong>Careful attention is paid to weather conditions before and during the application process. Bituminous waterproofing is typically avoided during rainy or extremely humid conditions to prevent issues with curing and adhesion.</li>
                                        <li><strong>Temperature Control: </strong>The temperature of the bitumen and the application surface is monitored to ensure optimal application. Extreme temperatures can affect the viscosity of bitumen, impacting its performance.</li>
                                        <li><strong>Safety Protocols:</strong> The application of bituminous materials, especially hot-applied methods, requires strict adherence to safety guidelines. Proper protective equipment (PPE) is worn by all workers, and fire safety measures are in place to prevent accidents.</li>
                                        <li><strong>Layering Techniques: </strong>Multiple layers of bituminous materials are often applied, with each layer given adequate time to cure before the next is applied. This layering ensures a robust and durable waterproof barrier.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Material Expertise: </strong>Homex Builders Corp has deep knowledge of various bituminous materials, including their chemical properties, durability, and suitability for different environments. This expertise allows them to select the best products for each project.</li>
                                        <li><strong>Advanced Application Techniques:</strong> The company employs advanced application techniques, such as torch-on methods, cold-applied systems, and self-adhesive membranes, depending on the specific needs of the project. These techniques are chosen based on factors like site accessibility, environmental conditions, and the type of structure.</li>
                                        <li><strong>Moisture Detection Technology:</strong> Before and after application, the company uses moisture detection tools to identify any potential water ingress points and to ensure that the waterproofing system is functioning as intended.</li>
                                        <li><strong>Environmental Considerations:</strong> Homex Builders Corp prioritizes the use of environmentally friendly bituminous products that are low in volatile organic compounds (VOCs) and have a reduced environmental impact, aligning with modern sustainability practices.</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Roof Waterproofing: </strong>Homex Builders Corp specializes in bituminous waterproofing for flat and sloped roofs, offering solutions that protect against harsh weather conditions and extend the lifespan of roofing materials.</li>
                                        <li><strong>Foundation and Basement Waterproofing: </strong>The company provides bituminous waterproofing for foundations and basements, ensuring that underground structures are protected from groundwater infiltration and hydrostatic pressure.</li>
                                        <li><strong>Bridge and Tunnel Waterproofing:</strong> With expertise in infrastructure projects, Homex Builders Corp also offers specialized bituminous waterproofing services for bridges and tunnels, providing long-lasting protection against water damage in challenging environments.</li>
                                        <li><strong>Customized Solutions: </strong>The company tailors its waterproofing solutions to the specific needs of each project, whether it’s a residential building, commercial property, or large infrastructure project. This customization ensures optimal performance and durability.</li>
                                        <li><strong>Long-Term Maintenance and Support:</strong> In addition to installation, Homex Builders Corp offers ongoing maintenance services, including regular inspections and repairs, to ensure that the bituminous waterproofing system remains effective over time.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for bituminous waterproofing services means opting for a reliable, experienced, and customer-focused partner dedicated to protecting your property from water damage.
                </p>
            </div>
        </>
    );
}
