import React, { Component, useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons';
import mixitup from 'mixitup';
import { variables } from '../Variables';

import testimonial from "../assets/images/home/testimonial.jpg"
import roofing_1 from "../assets/images/projects/roofing_1.jpg"
import roofing_2 from "../assets/images/projects/roofing_2.jpg"
import roofing_3 from "../assets/images/projects/roofing_3.jpg"
import sidewalk_1 from "../assets/images/projects/sidewalk_1.jpg"

export const Projects = (props) => {
    const [projects, setProjects] = useState([]);
    const [containerEl, setContainerEl] = useState();
    var mixitup = require('mixitup');
    var mixer;

    useEffect(() => {
        setContainerEl(document.querySelector('.gallery'));
        fetchProjects();
    })

    const fetchProjects = async () => {

        let apiUrl = variables.API_URL + 'Projects/GetProjectsForWebsite';

        await axios.get(apiUrl) // ASP.NET Core API endpoint with header
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    };

    useEffect(() => {
        if (containerEl) {
            mixer = mixitup(containerEl);
        }
    }, [containerEl])
    return (
        <section className="project-section">
            <div className="auto-container">
                <div className="sec-title centered">
                    <div className="title">Our Best Works</div>
                    <h2><span className="theme_color">Our</span> Recent Projects</h2>
                </div>
                <div className="filters controls">
                    <button type="button" className="control" data-filter="all">All</button>
                    <button type="button" className="control" data-filter=".roofing">Roofing</button>
                    <button type="button" className="control" data-filter=".waterproofing">Waterproofing</button>
                    <button type="button" className="control" data-filter=".remodeling">Remodeling</button>
                    <button type="button" className="control" data-filter=".sidewalk">Sidewalk</button>
                    <button type="button" className="control" data-filter=".restoration">Restoration</button>
                    <button type="button" className="control" data-filter=".masonry">Masonry</button>
                </div>

                <div className="gallery">
                    {
                        projects.length > 0 ? projects.map(item => {
                            let projectType = item.projectType.toLowerCase();
                            let images = item.projectImages;
                            let splittedPath = images.split(',');
                            let img = variables.projectImgPath + splittedPath[0];
                            return (
                                <div className={`col-4 p-0 mix ${projectType}`}>
                                    <div className="gallery-item" >
                                        <div className="inner-box">
                                            <figure className="image-box">
                                                <img fetchpriority="high" decoding="async" width="370" height="270" src={img} className="attachment-konstructo_370x270 size-konstructo_370x270 wp-post-image" alt="" />
                                                <div className="overlay-box">
                                                    <div className="overlay-inner">
                                                        <ul>
                                                            <li>
                                                                <a href="/projects" className="image-link">
                                                                    <FontAwesomeIcon icon={faLink} className="icon" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href={img} data-fancybox="images" data-caption="" className="link">
                                                                    <span className="icon flaticon-picture-gallery"></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="content">
                                                            <h3><a href="/projects">{item.projectName}</a></h3>
                                                            <div className="category">{item.projectType}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            )

                        })
                            :
                        <>No Project Exist</>
                    }
                </div>
            </div>
        </section>
    );
}
