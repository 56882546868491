import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import drivewayrepair1 from "../assets/images/sidewalk/drivewayrepair1.jpg"
import drivewayrepair2 from "../assets/images/sidewalk/drivewayrepair2.jpg"
import drivewayrepair3 from "../assets/images/sidewalk/drivewayrepair3.jpg"

export const DrivewayRepair = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={drivewayrepair1} alt="Driveway Repair" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={drivewayrepair2} alt="Driveway Repair" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={drivewayrepair3} alt="Driveway Repair" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Driveway Repair</h3>
            <div className="text">
                <p>
                    At Homex Builders Corp, we offer a comprehensive range of services for driveway repair to meet various customer needs and ensure that driveways are safe, functional, and visually appealing. Here's a list of services we provide:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Driveway Repair Services?</h3>
                <ul>
                    <li>
                        <strong>Crack Repair and Filling: </strong>
                        <ul>
                            <li>Repairing small to medium-sized cracks to prevent further damage.</li>
                            Using high-quality crack fillers and sealants to ensure a smooth, durable finish.
                        </ul>
                    </li>
                    <li>
                        <strong>Pothole Repair: </strong>
                        <ul>
                            <li>Removing loose material and filling potholes with durable asphalt or concrete.</li>
                            Compaction and finishing to blend seamlessly with the existing driveway surface.
                        </ul>
                    </li>
                    <li>
                        <strong>Resurfacing and Overlays: </strong>
                        <ul>
                            <li>Applying a new layer of asphalt or concrete over the existing driveway.</li>
                            Improving the surface without the need for a full replacement, extending the life of the driveway.
                        </ul>
                    </li>
                    <li>
                        <strong>Driveway Leveling and Slab Jacking: </strong>
                        <ul>
                            <li>Correcting uneven or sunken areas of concrete driveways through slab jacking.</li>
                            Using specialized techniques to lift and level driveway sections, preventing trip hazards and water pooling.
                        </ul>
                    </li>
                    <li>
                        <strong>Sealcoating: </strong>
                        <ul>
                            <li>Applying a protective sealant to asphalt driveways to prevent damage from water, oil, and UV rays.</li>
                            Enhancing the appearance and extending the lifespan of your driveway.
                        </ul>
                    </li>
                    <li>
                        <strong>Driveway Replacement: </strong>
                        <ul>
                            <li>Complete removal and replacement of old, damaged driveways with new asphalt, concrete, or pavers.</li>
                            Customizing driveway designs to meet client preferences and increase property value.
                        </ul>
                    </li>
                    <li>
                        <strong>Driveway Expansion and Extension: </strong>
                        <ul>
                            <li>Extending the driveway to accommodate more vehicles or provide additional parking space.</li>
                            <li>Designing and constructing new sections that blend seamlessly with the existing driveway.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Concrete Driveway Repair: </strong>
                        <ul>
                            <li>Fixing cracks, chips, and other damage to concrete driveways.</li>
                            <li>Applying resurfacing techniques for a fresh, uniform look.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Asphalt Driveway Repair: </strong>
                        <ul>
                            <li>Patching and resurfacing to address minor and major damage.</li>
                            <li>Using quality asphalt materials to ensure a durable and long-lasting repair.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Driveway Edging and Border Installation: </strong>
                        <ul>
                            <li>Adding decorative and functional edging to enhance the appearance and stability of the driveway.</li>
                            <li>Using materials like brick, stone, or concrete to match the overall landscape design.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Drainage Solutions and Water Management: </strong>
                        <ul>
                            <li>Installing proper drainage systems to prevent water pooling and erosion around the driveway.</li>
                            <li>Implementing solutions like French drains or channel drains to manage runoff effectively.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Snow and Ice Damage Repair: </strong>
                        <ul>
                            <li>Repairing damage caused by freeze-thaw cycles, snow plows, and ice melt chemicals.</li>
                            <li>Restoring the driveway surface to prevent further winter-related damage.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Stain Removal and Surface Cleaning: </strong>
                        <ul>
                            <li>Removing oil stains, rust marks, and other blemishes from driveway surfaces.</li>
                            <li>Using pressure washing and specialized cleaning agents to restore the driveway’s appearance.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decorative Driveway Finishes: </strong>
                        <ul>
                            <li>Offering decorative options like stamped concrete, exposed aggregate, or colored asphalt.</li>
                            <li>Enhancing curb appeal with customized designs and finishes.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Driveway Maintenance Services: </strong>
                        <ul>
                            <li>Regular inspections and maintenance to catch issues early and prevent major repairs.</li>
                            <li>Reapplying sealants and filling minor cracks as part of routine upkeep.</li>

                        </ul>
                    </li>
                </ul>
                <h3>How we Execute Driveway Repair in NYC</h3>
                <ul>
                    <li><strong>Site Visit: </strong>The first step is to conduct a thorough inspection of the driveway to assess the extent of the damage. This includes checking for cracks, potholes, sinking, and drainage issues.</li>
                    <li><strong>Damage Evaluation: </strong>Identify the types and causes of damage. Common issues include cracks, potholes, and surface wear. The cause of damage (e.g., weather conditions, heavy traffic, improper installation) will determine the appropriate repair strategy.</li>
                    <li><strong>Material Inspection:</strong> Evaluate the current materials used in the driveway (asphalt, concrete, pavers) to decide on the compatible repair materials.</li>
                    <li><strong>Cleaning:</strong> Clear the driveway of any debris, dirt, and loose material. This might involve sweeping, pressure washing, or using an air blower.</li>
                    <li><strong>Marking Damaged Areas: </strong>Mark areas that need repair to distinguish them from the rest of the driveway.</li>
                    <li><strong>Crack Preparation: </strong>For cracks, use a wire brush or grinder to clean out the crack, removing any loose debris and widening it slightly to ensure the repair material bonds properly.</li>
                    <li><strong>Crack Filling: </strong>Small cracks are filled with a crack filler, typically a liquid asphalt or a patching compound for concrete driveways. For larger cracks, a cold patch asphalt mix or a cementitious compound might be used.</li>
                    <li><strong>Pothole Patching: </strong>For potholes, the area is cleaned, and loose debris is removed. A cold patch or hot asphalt mix is applied to the hole, compacted with a tamper or roller to ensure a solid, even patch.</li>
                    <li><strong>Edge Repair: </strong>If the edges of the driveway are damaged, they are repaired by cutting out the damaged sections and filling them with new asphalt or concrete, depending on the material.</li>
                    <li><strong>Resurfacing Preparation: </strong>If the damage is extensive or the surface is uneven, resurfacing might be required. This involves milling or grinding down the top layer of the driveway to create a smooth base.</li>
                    <li><strong>Applying New Surface: </strong>A new layer of asphalt or concrete is laid down over the driveway. For asphalt driveways, a hot mix asphalt is typically spread and compacted. For concrete, a new layer of concrete is poured and smoothed out.</li>
                    <li><strong>Sealing: </strong>Once the repairs are done, the driveway is sealed to protect it from future damage. For asphalt, a sealant is applied to the surface to protect it from UV rays, water, and chemicals. For concrete, a sealant or coating is used to prevent water penetration and enhance durability.</li>
                    <li><strong>Curing Time:</strong> Allow the driveway to cure properly. Asphalt typically requires 24 to 48 hours to cure, while concrete may take several days.</li>
                    <li><strong>Inspection: </strong>Conduct a final inspection to ensure all repairs are properly completed and the surface is even and sealed.</li>
                    <li><strong>Clean-Up:</strong> Clean up the site, removing any tools, equipment, and debris.</li>
                    <li><strong>Walkthrough:</strong> Conduct a walkthrough with the customer to show the repairs and explain any maintenance tips.</li>
                    <li><strong>Maintenance Tips:</strong> Provide maintenance tips, such as avoiding heavy traffic for the first few days, regular sealing, and keeping the driveway clean to extend its lifespan.</li>
                    <li><strong>Follow-Up:</strong> A follow-up visit or call might be scheduled to ensure the customer is satisfied with the repair work and address any concerns or additional needs.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Driveway Repair?</h4>
                    <p>
                        Choosing Homex Builders Corp for your driveway repair service comes with several advantages that ensure quality, reliability, and long-lasting results. Here’s why Homex Builders Corp should be your go-to choice:
                    </p>
                    <ul>
                        <li><strong>Expertise in Driveway Repair and Maintenance: </strong>Homex Builders Corp has extensive experience in driveway repair, specializing in fixing various types of driveways, including concrete, asphalt, brick, and gravel. Their team understands the common problems like cracks, potholes, drainage issues, and uneven surfaces, and they have the expertise to address these efficiently.</li>

                        <li><strong>Comprehensive Range of Services: </strong>They offer a full spectrum of services for driveway maintenance and repair, including crack filling, resurfacing, sealing, patching, and complete driveway replacement. This means you can rely on them for any level of repair, from minor fixes to major overhauls.</li>

                        <li><strong>Licensed and Certified Professionals: </strong>Homex Builders Corp employs licensed and certified professionals who are trained in the latest driveway repair techniques and safety protocols. This ensures that all work is performed to the highest standards, reducing the likelihood of future issues and extending the lifespan of your driveway.</li>

                        <li><strong>Quality Materials and Techniques: </strong>They use only high-quality materials that are designed to withstand heavy use and harsh weather conditions. Whether it's asphalt, concrete, or pavers, Homex Builders Corp ensures that the materials used are durable and long-lasting. Their commitment to quality materials and advanced repair techniques guarantees a durable finish that can withstand the test of time.</li>

                        <li><strong>Advanced Equipment and Technology: </strong>Homex Builders Corp utilizes advanced equipment and cutting-edge technology to perform repairs efficiently and accurately. This allows them to complete projects faster without compromising on quality, reducing inconvenience to you and ensuring a smoother repair process.</li>

                        <li><strong>Timely and Reliable Service: </strong>They are known for their punctuality and reliability, ensuring that projects are completed on time and within the agreed-upon budget. Homex Builders Corp understands the importance of minimizing downtime, especially when your driveway is a critical access point for your home or business.</li>

                        <li><strong>Affordable and Transparent Pricing: </strong>Homex Builders Corp provides clear and upfront pricing with no hidden fees. They offer competitive rates and free estimates, so you know exactly what to expect before any work begins. This transparency helps build trust and ensures there are no surprises when it comes to costs.</li>

                        <li><strong>Strong Customer Focus: </strong>The company prides itself on its customer service, working closely with clients to understand their needs and preferences. They provide personalized solutions tailored to your specific situation, ensuring that the repair meets your expectations and requirements.</li>

                        <li><strong>Positive Reputation and Reviews: </strong>Homex Builders Corp has a strong reputation in the community for delivering high-quality driveway repair services. Positive customer reviews and testimonials highlight their commitment to excellence, reliability, and customer satisfaction.</li>

                        <li><strong>Long-Term Solutions and Warranties: </strong>Homex Builders Corp not only repairs driveways but also offers solutions that prevent future problems. They provide warranties on their workmanship and materials, giving you peace of mind that your investment is protected.</li>
                    </ul>
                </blockquote>
                <p>
                    Choosing Homex Builders Corp for your driveway repair service means you’re opting for a company with a proven track record of delivering high-quality, reliable, and cost-effective solutions. With their expertise, quality materials, and commitment to customer satisfaction, Homex Builders Corp is well-equipped to handle all your driveway repair needs efficiently and professionally.
                </p>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Personal Protective Equipment (PPE): </strong>Workers typically wear hard hats, gloves, safety boots, and high-visibility clothing. This minimizes the risk of injury from heavy machinery, flying debris, or hazardous materials.</li>
                                        <li><strong>Equipment Safety: </strong>Regular checks and maintenance of tools and equipment are crucial to prevent malfunctions that could lead to accidents.</li>
                                        <li><strong>Site Preparation: </strong>Properly preparing the work site by marking out the area, setting up barriers, and using caution signs to warn nearby pedestrians and vehicles of ongoing work.</li>
                                        <li><strong>Weather Monitoring: </strong>Ensuring that the work is not done during adverse weather conditions that could compromise the repair quality or safety of the workers (e.g., rain or extreme cold can affect asphalt laying).</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Assessment and Analysis: </strong>Before beginning repairs, a thorough assessment of the existing driveway condition is crucial. This might include evaluating cracks, potholes, drainage issues, and the base’s structural integrity.</li>
                                        <li><strong>Customized Repair Plan: </strong>Developing a repair plan tailored to the specific issues of each driveway. This might include choosing the right materials (asphalt, concrete, etc.) and methods (patching, resurfacing, or complete reconstruction).</li>
                                        <li><strong>Material Selection: </strong>Choosing high-quality materials suited to the local climate and usage patterns. For example, using asphalt mixes that are more resistant to temperature fluctuations or concrete with added strength for high-traffic areas.</li>
                                        <li><strong>Drainage Considerations: </strong>Incorporating drainage solutions to prevent water accumulation, which can undermine the driveway’s foundation and lead to future damage.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Specialized Equipment and Techniques: </strong>Using specialized machinery such as asphalt pavers, concrete mixers, compactors, and seal coating machines ensures a professional finish and long-lasting repair.</li>
                                        <li><strong>Subgrade Preparation: </strong>Ensuring the subgrade or foundation of the driveway is adequately prepared. This involves compacting the soil, removing debris, and sometimes adding a layer of crushed stone for stability.</li>
                                        <li><strong>Crack Sealing and Filling: </strong>For minor cracks, using specialized fillers and sealants to prevent water infiltration. For more extensive damage, cutting out and replacing damaged sections might be necessary.</li>
                                        <li><strong>Resurfacing and Overlaying: </strong>For driveways with widespread surface damage but sound structural integrity, resurfacing with a new layer of asphalt or concrete can extend the driveway's life.</li>
                                        <li><strong>Curing and Setting Time: </strong>Allowing proper curing and setting time for materials like concrete, which is critical for achieving the desired strength and durability.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Homex Builders Corp is dedicated to providing comprehensive driveway repair services that cater to a wide range of needs. From minor crack repairs to full driveway replacements and decorative finishes, we offer solutions designed to maintain the functionality, safety, and appearance of your driveway. Contact us today to discuss your specific needs and schedule a consultation!
                </p>
            </div>
        </>
    );
}
