import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import basement1 from "../assets/images/waterproofingservices/basement1.jpg"
import basement2 from "../assets/images/waterproofingservices/basement2.jpg"
import basement3 from "../assets/images/waterproofingservices/basement3.jpg"

export const BasementWaterproofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={basement1} alt="Basement Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={basement2} alt="Basement Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={basement3} alt="Basement Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Basement Waterproofing</h3>
            <div className="text">
                <p>
                    When considering basement waterproofing services, Homex Builders Corp stands out as a reliable and expert provider. Here’s an overview of their basement waterproofing services and why choosing Homex Builders Corp is a wise decision for protecting your home.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Basement Waterproofing Services?</h3>
                <ul>
                    <li><strong>Sealants and Coatings: </strong>Homex Builders applies high-quality waterproof sealants and coatings to interior walls and floors to create a moisture-resistant barrier. These materials are designed to prevent water seepage and are particularly effective for minor water issues.</li>
                    <li><strong>Interior Drainage Systems: </strong>For more significant water intrusion problems, Homex Builders installs interior drainage systems, such as French drains and sump pumps. These systems are designed to collect and redirect water away from the basement, preventing flooding and water damage.</li>
                    <li><strong>Vapor Barriers: </strong>They also install vapor barriers on basement walls and floors to prevent moisture from penetrating into the living space. This is particularly useful in areas with high humidity or where groundwater pressure is a concern.</li>
                    <li><strong>Excavation and Foundation Sealing: </strong>Homex Builders offers exterior waterproofing services that involve excavating around the foundation of the home to apply a waterproof membrane. This creates a protective barrier that prevents water from penetrating the foundation walls.</li>
                    <li><strong>Drainage Systems: </strong>They install exterior drainage systems, such as French drains, to divert water away from the foundation. Proper drainage is critical in preventing water buildup around the foundation, which can lead to seepage and damage.</li>
                    <li><strong>Foundation Crack Repair: </strong>Homex Builders also specializes in repairing foundation cracks that can allow water to seep into the basement. They use advanced materials and techniques to seal cracks and restore the integrity of the foundation.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Basement Waterproofing?</h4>
                    <p>
                        Choosing Homex Builders Corp for basement waterproofing comes with several benefits that make them a preferred choice for homeowners looking to protect their homes from water damage. Here’s why you should consider their services:
                    </p>
                    <ul>
                        <li><strong>Skilled Professionals: </strong>Homex Builders Corp has a team of highly skilled professionals with years of experience in basement waterproofing. Their expertise ensures that every project is handled with the utmost care and precision, using the most effective techniques and materials available.</li>
                        <li><strong>Knowledge of Local Conditions: </strong>With a deep understanding of the local climate and soil conditions, Homex Builders can recommend the most suitable waterproofing solutions for your specific location. This local knowledge is crucial in addressing the unique challenges posed by different environments.</li>
                        <li><strong>Customized Approach: </strong>Homex Builders takes a customized approach to basement waterproofing, tailoring their solutions to the specific needs and conditions of your home. Whether you need minor repairs or a complete waterproofing system, they have the expertise to provide the right solution.</li>
                        <li><strong>Range of Services: </strong>Offering both interior and exterior waterproofing options, Homex Builders provides a comprehensive range of services to address all potential sources of water intrusion. This holistic approach ensures maximum protection for your home.</li>
                        <li><strong>Top-Grade Products: </strong>Homex Builders uses only high-quality, durable materials that are designed to withstand harsh conditions and provide long-lasting protection. This commitment to quality ensures that your waterproofing solution is effective and reliable.</li>
                        <li><strong>Latest Technology: </strong>They stay updated with the latest advancements in waterproofing technology, utilizing state-of-the-art tools and products to deliver superior results. This allows them to offer solutions that are both effective and innovative.</li>
                        <li><strong>Transparent Communication: </strong>Homex Builders values transparent communication with their clients. They take the time to explain their waterproofing process, the materials they use, and the expected outcomes, ensuring that you are fully informed and confident in your decision.</li>
                        <li><strong>Warranty and Support: </strong>They provide warranties on their waterproofing services, giving you peace of mind that your investment is protected. Additionally, they offer ongoing support and maintenance services to ensure that your basement remains dry and secure.</li>
                        <li><strong>Positive Reviews and Testimonials: </strong>Homex Builders Corp has a strong reputation for delivering high-quality waterproofing services, as evidenced by numerous positive reviews and testimonials from satisfied customers. Their proven track record speaks to their reliability and commitment to excellence.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    Homex Builders Corp takes several precautions to ensure the safety and effectiveness of their basement waterproofing services. These precautions help to prevent common issues such as water seepage, mold growth, and foundation damage, ensuring long-lasting protection for your home.
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Detailed Site Assessment: </strong>Before starting any waterproofing project, Homex Builders conducts a thorough inspection of the basement and surrounding areas. This helps to identify the sources of water intrusion, assess the extent of any existing damage, and determine the most effective waterproofing solutions.</li>
                                        <li><strong>Proper Preparation: </strong>Effective waterproofing starts with proper preparation. Homex Builders ensures that all surfaces are clean, dry, and free from debris, mold, or mildew before applying any waterproofing materials. This step is crucial to ensure that the materials adhere properly and provide a strong, lasting seal.</li>
                                        <li><strong>High-Quality Materials: </strong>To ensure durability and reliability, Homex Builders uses only high-quality, industry-approved waterproofing materials. These materials are specifically chosen for their ability to withstand moisture, resist mold growth, and endure temperature fluctuations.</li>
                                        <li><strong>Weather Considerations: </strong>The timing of waterproofing application is carefully planned to avoid adverse weather conditions. Homex Builders schedules the work during dry weather to ensure that the materials set correctly and provide maximum protection against water intrusion.</li>
                                        <li><strong>Safety Protocols: </strong>Safety is a top priority for Homex Builders. They follow strict safety protocols to protect both their workers and your home during the waterproofing process. This includes using appropriate personal protective equipment (PPE), ensuring proper ventilation in confined spaces, and adhering to local building codes and regulations.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    Homex Builders Corp leverages its extensive knowledge and experience in basement waterproofing to deliver high-quality services. Their intelligence in this field is reflected in their use of advanced techniques, customized solutions, and continuous learning about the latest waterproofing technologies.
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Advanced Techniques: </strong>Homex Builders employs a range of advanced waterproofing techniques to address different types of water intrusion problems. This includes the use of interior and exterior drainage systems, such as French drains, sump pumps, and vapor barriers, as well as the application of waterproof sealants and membranes.</li>
                                        <li><strong>Customized Solutions: </strong>Recognizing that every basement is unique, Homex Builders provides customized waterproofing solutions tailored to the specific conditions and needs of each home. They consider factors such as the type of foundation, soil conditions, and local climate to develop a comprehensive waterproofing plan.</li>
                                        <li><strong>Innovative Technology: </strong>Homex Builders stays current with the latest advancements in waterproofing technology. They utilize cutting-edge tools and products, such as liquid-applied membranes and polyurethane injections, which provide superior flexibility and durability compared to traditional waterproofing methods.</li>
                                        <li><strong>Knowledgeable Staff: </strong>The team at Homex Builders comprises highly trained and experienced professionals who understand the complexities of basement waterproofing. Their expertise allows them to quickly diagnose problems and implement effective solutions, minimizing disruption to your home.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    Homex Builders Corp specializes in basement waterproofing, making them experts in protecting your home from water damage. Their specialization is evident in several key areas:
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Comprehensive Waterproofing Systems: </strong>Homex Builders offers complete waterproofing systems that combine multiple methods and technologies to provide maximum protection against water intrusion. This includes both interior and exterior solutions to address all potential sources of water entry.</li>
                                        <li><strong>Foundation Repair and Reinforcement: </strong>In addition to waterproofing, Homex Builders specializes in foundation repair and reinforcement. They have the expertise to identify and fix foundation cracks, which are often a source of water seepage, and to reinforce the foundation to prevent future issues.</li>
                                        <li><strong>Mold and Mildew Prevention: </strong>Homex Builders understands the importance of preventing mold and mildew growth in basements. Their waterproofing solutions are designed not only to keep water out but also to maintain a dry, healthy environment that discourages the growth of mold and mildew.</li>
                                        <li><strong>Drainage Solutions: </strong>Effective drainage is a critical component of basement waterproofing, and Homex Builders specializes in designing and installing efficient drainage systems. This includes exterior drainage solutions, such as French drains and grading, as well as interior systems that manage and redirect water away from the basement.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Homex Builders Corp offers a comprehensive range of basement waterproofing services that cater to the specific needs of each home. Their expertise, use of high-quality materials, commitment to customer satisfaction, and comprehensive approach make them a top choice for homeowners looking to protect their basements from water damage. Choosing Homex Builders Corp ensures that you are working with a trusted partner dedicated to safeguarding your home’s foundation and providing long-term peace of mind.
                </p>
                <p>
                    If you’re experiencing water issues in your basement or want to proactively protect your home, contact Homex Builders Corp today to schedule a consultation and learn more about their waterproofing solutions.
                </p>
            </div>
        </>
    );
}
