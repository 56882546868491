import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import masonrycleaning1 from "../assets/images/restoration/masonrycleaning1.jpg"
import masonrycleaning2 from "../assets/images/restoration/masonrycleaning2.jpg"
import masonrycleaning3 from "../assets/images/restoration/masonrycleaning3.jpg"

export const MasonryCleaning = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={masonrycleaning1} alt="Masonry Cleaning" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={masonrycleaning2} alt="Masonry Cleaning" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={masonrycleaning3} alt="Masonry Cleaning" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Masonry Cleaning</h3>
            <div className="text">
                <p>
                    Homex Builders Corp provides professional masonry cleaning services designed to maintain and restore the appearance and structural integrity of brick, stone, and other masonry surfaces. These services are essential for both residential and commercial properties to ensure that exterior masonry stays clean, safe, and visually appealing. Below is a detailed overview of the masonry cleaning services offered by Homex Builders Corp:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Masonry Cleaning Services?</h3>
                <ul>
                    <li><strong>Initial Inspection: </strong>Homex Builders Corp begins with a comprehensive assessment of the masonry surface to determine its condition, age, and type of contaminants present, such as dirt, mold, mildew, algae, efflorescence (salt deposits), and graffiti.</li>
                    <li><strong>Customized Cleaning Plan: </strong>Based on the inspection, a tailored cleaning plan is developed to address the specific needs of the masonry while preserving its integrity. The plan includes selecting the appropriate cleaning methods and materials.</li>
                    <li><strong>Power Washing: </strong>This method involves using high-pressure water jets to remove surface dirt, stains, and other contaminants. Power washing is particularly effective for removing loose dirt, grime, and biological growth from hard surfaces like brick, stone, and concrete.</li>
                    <li><strong>Soft Washing:</strong> For more delicate masonry surfaces, soft washing is used. This method utilizes low-pressure water combined with specially formulated cleaning solutions to gently clean without causing damage. Soft washing is ideal for older or more fragile masonry that might be damaged by high-pressure water.</li>
                    <li><strong>Chemical Cleaning: </strong>When dealing with stubborn stains, graffiti, or efflorescence, chemical cleaning agents are applied. These chemicals are specifically chosen to break down and remove the contaminants without harming the masonry. Homex Builders Corp uses environmentally friendly and biodegradable solutions to minimize environmental impact.</li>
                    <li><strong>Abrasive Cleaning: </strong>For severe cases, abrasive cleaning techniques like sandblasting or soda blasting may be employed. These methods involve blasting a fine abrasive material at the masonry surface to remove tough stains or coatings. Abrasive cleaning is used cautiously to avoid surface damage.</li>
                    <li><strong>Efflorescence Removal: </strong>Efflorescence, which is the white, powdery residue that appears on masonry surfaces due to salt deposits, can be effectively removed using specialized cleaning techniques. Homex Builders Corp uses a combination of gentle scrubbing and chemical treatments to dissolve and remove these deposits without damaging the masonry.</li>
                    <li><strong>Graffiti Removal: </strong>Graffiti can be a significant issue for many property owners. Homex Builders Corp offers specialized graffiti removal services that use safe and effective solvents designed to break down paint and other markings without causing damage to the underlying masonry. This process may involve repeated applications for more stubborn graffiti.</li>
                    <li><strong>Mold and Mildew Treatment: </strong>Mold and mildew can cause staining and deteriorate the appearance of masonry surfaces. Homex Builders Corp provides mold and mildew removal services that include cleaning and applying preventative treatments to inhibit future growth. This service is especially important in damp climates where mold and mildew are more likely to develop.</li>
                    <li><strong>Post-Cleaning Treatments: </strong>After cleaning, Homex Builders Corp often applies protective sealants or water repellents to the masonry surface. These treatments help protect the masonry from future staining, water damage, and biological growth, extending the life and appearance of the surface.</li>
                    <li><strong>Restoration and Repair: </strong>In addition to cleaning, Homex Builders Corp offers masonry restoration and repair services. This includes repointing mortar joints, repairing cracks, replacing damaged bricks or stones, and restoring the structural integrity of the masonry. These services are essential for older buildings or masonry that has suffered damage over time.</li>
                    <li><strong>Safety and Compliance: </strong>Homex Builders Corp prioritizes safety and compliance with all local regulations. Their team is trained to handle hazardous materials safely and follows industry best practices to ensure both their safety and the safety of the property and its occupants during the cleaning process.</li>
                    <li><strong>Regular Maintenance Programs: </strong>To maintain the appearance and condition of masonry surfaces, Homex Builders Corp offers ongoing maintenance programs. These programs include scheduled cleanings and inspections to address issues before they become significant problems, saving property owners time and money in the long run.</li>
                    <li><strong>Customer Satisfaction and Support: </strong>Homex Builders Corp is committed to customer satisfaction. They provide clear communication throughout the cleaning process, ensuring property owners are informed and comfortable with the methods and outcomes. Follow-up support is available to address any concerns or additional needs after the cleaning service is completed.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Masonry Cleaning?</h4>
                    <p>
                        When considering a masonry cleaning service, choosing us comes with several compelling advantages that make it a top choice for your needs. Here are some reasons why you should consider us for your masonry cleaning services:
                    </p>
                    <ul>
                        <li><strong>Expertise and Experience: </strong>Homex Builders Corp has years of experience in the masonry cleaning industry. Their team is composed of skilled professionals who are well-versed in the latest techniques and best practices for cleaning a variety of masonry surfaces, including brick, stone, and concrete. This experience ensures that they can handle any type of masonry cleaning job, whether it is a small residential project or a large commercial building.</li>

                        <li><strong>Advanced Cleaning Techniques: </strong>Homex Builders Corp utilizes advanced cleaning methods that are both effective and safe for your masonry surfaces. They employ environmentally friendly cleaning agents that are tough on dirt, grime, and pollutants but gentle on the masonry, preventing damage and preserving the integrity of the material. Their techniques include power washing, chemical cleaning, and abrasive cleaning, tailored to the specific needs of your masonry.</li>

                        <li><strong>Customized Solutions: </strong>Every masonry surface is unique, and so are its cleaning needs. Homex Builders Corp offers customized cleaning solutions tailored to the specific type of masonry and the level of cleaning required. Whether your masonry is stained, has biological growth like moss or algae, or needs graffiti removal, Homex Builders Corp has the expertise to provide a solution that will restore your masonry to its original beauty.</li>

                        <li><strong>Comprehensive Services: </strong>In addition to masonry cleaning, Homex Builders Corp offers a range of related services that can complement your cleaning project. These services include masonry repair, sealing, and restoration, allowing you to address all your masonry needs with a single, trusted contractor. This comprehensive approach ensures that not only is your masonry clean, but it is also well-maintained and protected for the long term.</li>

                        <li><strong>Commitment to Customer Satisfaction: </strong>Customer satisfaction is a top priority for Homex Builders Corp. They are committed to delivering high-quality service and ensuring that clients are happy with the results. This commitment is reflected in their attention to detail, clear communication throughout the project, and willingness to go the extra mile to meet customer expectations.</li>

                        <li><strong>Competitive Pricing: </strong>Homex Builders Corp offers competitive pricing for their masonry cleaning services without compromising on quality. They provide transparent quotes with no hidden fees, ensuring you understand exactly what you are paying for. Their cost-effective solutions make it easier to maintain your property without breaking the bank.</li>

                        <li><strong>Reputation and Reliability: </strong>Homex Builders Corp has built a strong reputation in the industry for reliability and professionalism. They are known for their punctuality, thoroughness, and the ability to deliver projects on time and within budget. Choosing Homex Builders Corp means partnering with a company you can trust to deliver consistent, high-quality results.</li>

                        <li><strong>Safety and Compliance: </strong>Safety is a key concern in any cleaning project, particularly those involving high-pressure washing or chemical cleaners. Homex Builders Corp adheres to all safety guidelines and regulations, ensuring that their cleaning practices are safe for both their team and your property. They are also fully insured, giving you peace of mind throughout the cleaning process.</li>

                    </ul>
                </blockquote>
            </div>
            <div className="text">
                <p>
                    Our masonry cleaning services are comprehensive, covering everything from basic surface cleaning to more specialized treatments like efflorescence removal and graffiti removal. Our approach is tailored to the specific needs of each property, ensuring effective and safe cleaning while preserving the integrity of the masonry. Whether you need a one-time deep clean or regular maintenance, We provides reliable and professional masonry cleaning services to keep your property looking its best.
                </p>
            </div>
        </>
    );
}
