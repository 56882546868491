import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import elastomeric1 from "../assets/images/waterproofingservices/elastomeric1.jpg"
import elastomeric2 from "../assets/images/waterproofingservices/elastomeric2.jpg"
import elastomeric3 from "../assets/images/waterproofingservices/elastomeric3.jpg"

export const ElastomericWaterproofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={elastomeric1} alt="Elastomeric Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={elastomeric2} alt="Elastomeric Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={elastomeric3} alt="Elastomeric Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Elastomeric Waterproofing</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers specialized Elastomeric Waterproofing Services, providing flexible and durable solutions for protecting buildings from water damage. Here’s an overview of these services and reasons why clients choose them:
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Elastomeric Waterproofing Services?</h3>
                <ul>
                    <li><strong>Elastomeric Coatings:</strong> Homex Builders Corp applies elastomeric coatings, which are high-performance, flexible, and weather-resistant. These coatings are ideal for exterior surfaces like walls, roofs, and decks, offering superior protection against water infiltration while accommodating structural movement.</li>
                    <li><strong>Crack Bridging: </strong>Elastomeric waterproofing systems are designed to bridge cracks in surfaces, preventing water from seeping through. This makes them particularly effective in environments where structures are subject to expansion, contraction, or minor settling.</li>
                    <li><strong>UV Resistance:</strong> The elastomeric coatings used by Homex Builders Corp are highly resistant to UV radiation, which prevents the coatings from degrading under prolonged exposure to sunlight. This ensures long-lasting protection for exposed surfaces.</li>
                    <li><strong>Application Flexibility: </strong>These coatings can be applied to a variety of substrates, including concrete, masonry, wood, and metal. This versatility makes elastomeric waterproofing suitable for a wide range of residential, commercial, and industrial projects.</li>
                    <li><strong>Seamless Application: </strong>The elastomeric waterproofing system is applied as a continuous, seamless membrane, eliminating the risk of water ingress at joints or seams, which are common weak points in traditional waterproofing methods.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Elastomeric Waterproofing?</h4>
                    <ul>
                        <li><strong>Proven Expertise:</strong> Homex Builders Corp has extensive experience in applying elastomeric waterproofing systems. Their team of skilled professionals is well-versed in the latest application techniques, ensuring that each project is completed to the highest standards.</li>
                        <li><strong>Quality Materials: </strong>The company uses top-tier elastomeric products that are known for their durability, flexibility, and long-lasting performance. This commitment to quality materials ensures that clients receive the best possible protection for their properties.</li>
                        <li><strong>Customized Solutions: </strong>Understanding that each project has unique needs, Homex Builders Corp offers customized elastomeric waterproofing solutions. They assess the specific conditions of each site to provide a tailored approach that maximizes effectiveness and efficiency.</li>
                        <li><strong>Comprehensive Services: </strong>Homex Builders Corp provides a full range of services, from initial consultation and assessment to application and maintenance. This comprehensive approach ensures that all aspects of the waterproofing project are managed effectively, giving clients peace of mind.</li>
                        <li><strong>Long-Term Durability:</strong> The elastomeric waterproofing systems installed by Homex Builders Corp are designed for long-term durability, with the ability to withstand extreme weather conditions, temperature fluctuations, and mechanical stress, thereby reducing the need for frequent repairs or replacements.</li>
                        <li><strong>Environmentally Friendly: </strong>The company is committed to sustainability, using elastomeric products that are environmentally friendly, with low VOCs and a reduced carbon footprint, contributing to greener building practices.</li>
                        <li><strong>Exceptional Customer Service: </strong>Homex Builders Corp is known for its customer-centric approach, working closely with clients to understand their needs and deliver solutions that exceed expectations. Their commitment to customer satisfaction is evident in their strong track record of successful projects and positive client feedback.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Surface Preparation: </strong>Before applying elastomeric coatings, the surface must be thoroughly cleaned and prepped. This includes removing any dirt, debris, loose materials, or previous coatings to ensure a strong bond between the substrate and the elastomeric membrane.</li>
                                        <li><strong>Moisture Testing: </strong>Moisture content in the substrate is carefully checked prior to application. If the substrate is too wet, it can compromise the adhesion and effectiveness of the elastomeric coating. Homex Builders Corp ensures that surfaces are dry enough to allow for optimal application.</li>
                                        <li><strong>Crack and Joint Sealing: </strong>Any existing cracks, joints, or gaps in the substrate are sealed before the elastomeric coating is applied. This precaution prevents water from seeping through these vulnerable points, ensuring a continuous waterproof barrier.</li>
                                        <li><strong>Environmental Considerations: </strong>Weather conditions are closely monitored. Application is typically avoided during rainy or extremely humid conditions, as these can affect the curing process of elastomeric coatings. The coatings are best applied during dry, mild weather for optimal performance.</li>
                                        <li><strong>Safety Protocols: </strong>Safety is a top priority. Homex Builders Corp follows strict safety protocols, including the use of proper personal protective equipment (PPE) and ensuring the worksite is safe for both workers and building occupants.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Material Selection: </strong>Homex Builders Corp uses high-performance elastomeric materials that are specifically formulated for durability, flexibility, and UV resistance. These materials are chosen based on the specific needs of each project, ensuring long-lasting protection.</li>
                                        <li><strong>Advanced Application Techniques: </strong>The company employs cutting-edge techniques to apply elastomeric coatings. This includes spray application for seamless coverage, ensuring that even the most complex surfaces are fully protected.</li>
                                        <li><strong>Crack Bridging Technology: </strong>Elastomeric coatings are known for their ability to bridge small cracks and gaps in the substrate. Homex Builders Corp leverages this technology to enhance the waterproofing performance, particularly in structures that are prone to movement or minor settling.</li>
                                        <li><strong>Environmental Intelligence: </strong>The company is committed to sustainability, using elastomeric coatings that are low in volatile organic compounds (VOCs) and environmentally friendly. This aligns with modern building practices and reduces the environmental impact of waterproofing projects.</li>
                                        <li><strong>Data-Driven Assessment: </strong>Moisture detection and analysis tools are used to assess the condition of the substrate and surrounding environment. This data-driven approach ensures that the waterproofing solution is tailored to the specific conditions of the site, maximizing effectiveness.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Exterior Wall Waterproofing: </strong>Homex Builders Corp specializes in applying elastomeric waterproofing to exterior walls, protecting buildings from water penetration while allowing the structure to breathe. This is particularly important for maintaining the structural integrity and appearance of the building over time.</li>
                                        <li><strong>Roof Waterproofing: </strong>The company offers elastomeric waterproofing for roofs, providing a flexible and durable barrier against water infiltration. This service is ideal for flat and low-slope roofs, where standing water can be a significant concern.</li>
                                        <li><strong>Balconies and Decks: </strong>Homex Builders Corp provides specialized waterproofing for balconies and decks, where water exposure and foot traffic can lead to deterioration over time. Elastomeric coatings are applied to create a protective, slip-resistant surface that stands up to the elements.</li>
                                        <li><strong>Commercial and Residential Projects: </strong>Whether it’s a small residential property or a large commercial building, Homex Builders Corp tailors its elastomeric waterproofing services to meet the unique needs of each project. This versatility ensures that all clients receive the most effective and appropriate solution.</li>
                                        <li><strong>Maintenance and Repair: </strong>In addition to installation, Homex Builders Corp offers maintenance services to ensure the longevity of elastomeric waterproofing systems. This includes regular inspections, touch-ups, and repairs to address any wear and tear over time.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Choosing Homex Builders Corp for elastomeric waterproofing services means selecting a reliable, experienced, and customer-focused partner who is dedicated to protecting your property with high-quality, long-lasting waterproofing solutions.
                </p>
            </div>
        </>
    );
}
