import React, { Component, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import masonry1 from "../assets/images/waterproofingservices/masonry1.jpg"
import masonry2 from "../assets/images/waterproofingservices/masonry2.jpg"
import masonry3 from "../assets/images/waterproofingservices/masonry3.jpg"

export const MasonryWaterproofing = (props) => {
    const { serviceContent } = props;
    const [tabName, setTabName] = useState("prod-details1");

    const switchTab = (e) => {
        let tabTarget = e.target.getAttribute("data-tab");

        setTabName(tabTarget);
    }

    return (
        <>
            <div className="services-gallery">
                <div className="row clearfix">
                    <div className="column col-md-8 col-sm-8 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={masonry1} alt="Masonry Waterproofing" />
                        </div>
                    </div>
                    <div className="column col-md-4 col-sm-4 col-xs-12">
                        <div className="image">
                            <img decoding="async" src={masonry2} alt="Masonry Waterproofing" />
                        </div>
                        <div className="image">
                            <img decoding="async" src={masonry3} alt="Masonry Waterproofing" />
                        </div>
                    </div>
                </div>
            </div>
            <h3>Masonry Waterproofing</h3>
            <div className="text">
                <p>
                    Homex Builders Corp offers specialized masonry waterproofing services designed to protect brick, stone, and concrete surfaces from water damage, efflorescence, mold growth, and structural deterioration. Masonry waterproofing is crucial to maintaining the integrity and appearance of your home’s exterior by preventing water infiltration, which can lead to long-term damage. Here’s an overview of the masonry waterproofing services provided by Homex Builders Corp and why you should choose them for your waterproofing needs.
                </p>
            </div>
            <div className="text">
                <h3>What’s Included in Our Masonry Waterproofing Services?</h3>
                <p>
                    Homex Builders Corp provides a range of masonry waterproofing solutions tailored to different types of masonry surfaces and construction materials. Their services are designed to prevent water penetration, manage moisture, and protect your home from water-related damage.
                </p>
                <ul>
                    <li><strong>Clear Waterproofing Sealants:</strong> Homex Builders applies high-quality clear waterproofing sealants to masonry surfaces. These sealants penetrate deep into the porous material of brick, stone, or concrete, creating a water-resistant barrier that prevents moisture from penetrating without altering the appearance of the masonry. Clear sealants are ideal for preserving the natural look of your home’s exterior.</li>
                    <li><strong>Breathable Waterproofing Coatings:</strong> To ensure that moisture does not get trapped within the masonry, Homex Builders uses breathable waterproofing coatings. These coatings allow moisture vapor to escape while preventing liquid water from entering the masonry, effectively protecting against water damage while maintaining the masonry’s ability to “breathe.”</li>
                    <li><strong>Crack Repair:</strong> Cracks in masonry can be significant entry points for water, leading to deeper structural issues over time. Homex Builders specializes in identifying and repairing cracks in masonry surfaces. They use advanced techniques such as epoxy injections and flexible sealants to fill and seal cracks, preventing water from infiltrating through these weak points.</li>
                    <li><strong>Joint Repointing:</strong> Mortar joints between bricks or stones can deteriorate over time, allowing water to seep in. Homex Builders provides joint repointing services, which involve removing damaged mortar and replacing it with new, water-resistant mortar. This not only improves the water resistance of the masonry but also restores its structural integrity.</li>
                    <li><strong>Silicone-Based Water Repellents:</strong> For additional protection, Homex Builders applies silicone-based water repellents that are specifically formulated for masonry surfaces. These repellents create an invisible barrier that repels water while allowing the masonry to retain its natural color and texture.</li>
                    <li><strong>Elastomeric Coatings:</strong> For surfaces exposed to harsh weather conditions, Homex Builders uses elastomeric coatings that provide a flexible, durable layer of protection. These coatings expand and contract with the masonry, providing a robust defense against water penetration, wind-driven rain, and freeze-thaw cycles.</li>
                    <li><strong>Masonry Cleaning and Restoration:</strong> Before applying any waterproofing treatments, Homex Builders provides thorough masonry cleaning services to remove dirt, mold, mildew, and efflorescence. This ensures a clean surface that allows for better adhesion of waterproofing materials and enhances the overall appearance of the masonry.</li>
                    <li><strong>Ongoing Maintenance Programs:</strong> To maintain the effectiveness of waterproofing treatments, Homex Builders offers ongoing maintenance programs. This includes regular inspections, touch-ups, and reapplications of waterproofing products as needed to keep masonry surfaces protected over time.</li>
                </ul>
                <blockquote>
                    <h4>Why Choose Homex Builders Corp for Your Masonry Waterproofing?</h4>
                    <p>
                        Choosing Homex Builders Corp for masonry waterproofing services comes with several benefits that make them a preferred choice for homeowners looking to protect their masonry surfaces from water damage and maintain the integrity and appearance of their property. Here’s why you should consider their services:
                    </p>
                    <ul>
                        <li><strong>Skilled Professionals: </strong>Homex Builders has a team of highly skilled and experienced professionals who specialize in masonry waterproofing. Their expertise ensures that every project is handled with precision and care, using the most effective techniques and materials.</li>
                        <li><strong>In-Depth Knowledge: </strong>With years of experience in the industry, Homex Builders understands the unique challenges associated with waterproofing different types of masonry surfaces. They have the knowledge to recommend the most suitable waterproofing solutions based on the specific needs of your home.</li>
                        <li><strong>Customized Approach: </strong>Homex Builders provides customized waterproofing solutions tailored to the specific conditions of your masonry surfaces. They consider factors such as the type of masonry (e.g., brick, stone, concrete), exposure to water sources, and local climate to develop a comprehensive waterproofing plan.</li>
                        <li><strong>Full Range of Services:</strong> By offering a full range of masonry waterproofing solutions, including crack repair, joint repointing, and surface treatments, Homex Builders ensures complete protection for your home’s masonry surfaces.</li>
                        <li><strong>Durable Materials: </strong>Homex Builders uses only high-quality, durable materials that are specifically designed for masonry waterproofing. These materials are chosen for their ability to withstand moisture, temperature changes, and environmental stressors, ensuring long-lasting protection.</li>
                        <li><strong>Latest Technology: </strong>Homex Builders stays updated with the latest advancements in waterproofing technology. They use state-of-the-art products and tools, such as silicone-based water repellents and elastomeric coatings, that provide superior protection compared to traditional methods.</li>
                        <li><strong>Transparent Communication:</strong> Homex Builders values transparent communication with their clients. They take the time to explain their waterproofing process, the materials they use, and the expected outcomes, ensuring that you are fully informed and confident in their services.</li>
                        <li><strong>Warranty and Support:</strong> Homex Builders offers warranties on their waterproofing services, providing you with peace of mind that your investment is protected. They also provide ongoing support and maintenance services to ensure that your masonry surfaces remain protected over time.</li>
                        <li><strong>Satisfied Customers:</strong> Homex Builders Corp has a strong reputation for delivering high-quality masonry waterproofing services, as evidenced by numerous positive reviews and testimonials from satisfied customers. Their proven track record speaks to their reliability and commitment to excellence.</li>
                    </ul>
                </blockquote>
            </div>
            <div className="product-info-tabs">
                <div className="services-tabs tabs-box">
                    <ul className="tab-btns tab-buttons clearfix">
                        <li data-tab="prod-details1" className={`tab-btn ${tabName == "prod-details1" ? "active-btn" : ""}`} onClick={switchTab}>Precautions</li>
                        <li data-tab="prod-details2" className={`tab-btn ${tabName == "prod-details2" ? "active-btn" : ""}`} onClick={switchTab}>Intelligence</li>
                        <li data-tab="prod-details3" className={`tab-btn ${tabName == "prod-details3" ? "active-btn" : ""}`} onClick={switchTab}>Specializations</li>
                    </ul>
                    <div className="tabs-content">
                        <div className={`tab ${tabName == "prod-details1" ? "active-tab" : ""}`} id="prod-details1">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Thorough Inspection: </strong>Before starting any waterproofing project, a comprehensive inspection of the masonry structure is conducted to identify potential issues like cracks, leaks, and weak spots.</li>
                                        <li><strong>Surface Preparation: </strong>The masonry surface is cleaned thoroughly to remove dirt, debris, and any previous coatings that could interfere with the waterproofing application. Proper surface preparation is crucial for the adhesion and effectiveness of waterproofing materials.</li>
                                        <li><strong>Weather Considerations: </strong>Weather conditions are carefully considered. Waterproofing applications are generally avoided during extreme temperatures, high humidity, or when rain is expected, as these conditions can affect the curing process and performance of the waterproofing materials.</li>
                                        <li><strong>Safety Measures: </strong>Safety protocols are strictly followed, including the use of personal protective equipment (PPE) for workers and ensuring the worksite is safe for both the workers and occupants of the building.</li>
                                        <li><strong>Material Selection: </strong>Only high-quality, durable materials that are suitable for the specific type of masonry and environmental conditions are selected. This ensures long-lasting protection.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details2" ? "active-tab" : ""}`} id="prod-details2">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Advanced Techniques: </strong>Homex Builders Corp utilizes cutting-edge waterproofing techniques and technologies, such as crystalline waterproofing, liquid-applied membranes, and injection grouting, to effectively seal and protect masonry structures.</li>
                                       <li><strong> Expert Knowledge: </strong>The company’s team has in-depth knowledge of various masonry materials (brick, stone, concrete) and understands how different types of masonry react to water exposure. This expertise allows them to choose the most appropriate waterproofing solution.</li>
                                        <li><strong>Moisture Detection Tools: </strong>Advanced moisture detection tools are used to assess the moisture content within masonry walls and identify hidden water intrusion points. This data-driven approach helps in creating targeted waterproofing strategies.</li>
                                       <li><strong> Sustainability Focus: </strong>Homex Builders Corp is committed to using environmentally friendly waterproofing materials and methods that not only protect structures but also minimize environmental impact.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={`tab ${tabName == "prod-details3" ? "active-tab" : ""}`} id="prod-details3">
                            <div className="content">
                                <p>
                                    <ul>
                                        <li><strong>Historical Masonry: </strong>The company specializes in the waterproofing of historical masonry structures, ensuring that the original aesthetics are preserved while modern waterproofing solutions are applied.</li>
                                        <li><strong>Commercial and Residential Projects: </strong>Homex Builders Corp is equipped to handle both large-scale commercial projects and smaller residential ones, providing tailored waterproofing solutions to meet the specific needs of each type of property.</li>
                                        <li><strong>Custom Waterproofing Solutions: </strong>The company offers customized waterproofing solutions based on the unique challenges and requirements of each project, ensuring optimal protection and longevity of the masonry work.</li>
                                        <li><strong>Long-Term Maintenance Plans: </strong>They also provide long-term maintenance plans to ensure that the waterproofing remains effective over time, including regular inspections and touch-up work as needed.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                <p>
                    Homex Builders Corp’s masonry waterproofing services are characterized by their expertise, comprehensive solutions, use of high-quality materials, and commitment to customer satisfaction. Their focus on both preventive measures and restoration ensures that every potential source of water intrusion is addressed, providing maximum protection for your home’s masonry surfaces.
                </p>
                <p>
                    By choosing Homex Builders Corp for your masonry waterproofing needs, you are opting for a company that prioritizes quality, safety, and long-term results. Their customized solutions and advanced techniques make them a trusted choice for maintaining the integrity and appearance of your home.
                </p>
                <p>
                    If you’re looking to protect your masonry surfaces from water damage and maintain a healthy, beautiful exterior, consider reaching out to Homex Builders Corp. Their expertise and dedication to excellence will help ensure your home remains dry, safe, and structurally sound.
                </p>
            </div>
        </>
    );
}
