import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SubHeader } from './SubHeader';
import OwlCarousel, { Options } from 'react-owl-carousel';
import { ServicesList } from './ServicesList';

export const Restoration = (props) => {

    return (
        <>
            <SubHeader title="Restoration" />
            <section className="featured-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-column col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title centered">
                                    <div className="title">Restoration Services</div>
                                    <h2><span className="theme_color">Your Trusted Restoration Solutions</span> Partner in New York</h2>
                                </div>
                                <div className="text justified">
                                    <p>
                                        When disaster strikes or your property begins to show signs of age, you need a partner who can restore it to its former glory.
                                        At Homex Builders Corp, we are proud to be New York's trusted restoration solutions provider, specializing in the repair, rehabilitation, and preservation of residential and commercial properties.
                                        From water and fire damage restoration to structural repairs and historical restorations, we have the expertise, tools, and dedication to bring your property back to life.
                                        Our experienced team understands the importance of acting swiftly and skillfully to restore your property, ensuring that it is safe, functional, and aesthetically pleasing once again.
                                    </p>
                                    <p>
                                        From Manhattan to Brooklyn, Queens to the Bronx, Homex Builders Corp serves clients throughout New York City. We are proud to provide top-quality restoration services that meet the needs of both residential and commercial properties.
                                    </p>
                                    <p>
                                        If your property has suffered damage or is in need of restoration, Homex Builders Corp is here to help. With our experience, expertise, and commitment to quality, we provide the restoration solutions you need to restore your property to its original condition.
                                        Contact us today for a free consultation, and let us help you bring your property back to life!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServicesList serviceType="restoration-services" title="Restoration" />
        </>
    );
}
